import { gql } from "graphql-request";

export const GET_ALL_USERS = gql`
  query UsersAggrid($aggridInput: AnyAggridInput!) {
    usersAggrid(aggridInput: $aggridInput) {
      results {
        unique_id
        role_list {
          name
          description
          unique_id
        }
        title_name
        position
        phone
        nick_name
        national_id
        organization
        last_updator_unique_id
        last_updated_date
        last_name
        last_login_date
        is_user_active
        inactive_remarks
        img_url
        id
        first_name
        email
        dob
        department
        creator_unique_id
        created_date
      }
      count
    }
  }
`;

export const GET_ALL_USERS_NO_AGGRID = gql`
  query Users {
    users {
      unique_id
      role_list {
        name
        description
        unique_id
      }
      title_name
      position
      phone
      nick_name
      national_id
      organization
      last_updator_unique_id
      last_updated_date
      last_name
      last_login_date
      is_user_active
      inactive_remarks
      img_url
      id
      first_name
      email
      dob
      department
      creator_unique_id
      created_date
    }
  }
`;

export const GET_USER = gql`
  query User($uniqueInput: UserWhereUniqueInput!) {
    user(uniqueInput: $uniqueInput) {
      unique_id
      role_list {
        name
        description
        unique_id
      }
      title_name
      position
      phone
      nick_name
      national_id
      organization
      last_updator_unique_id
      last_updated_date
      last_updated_by {
        title_name
        first_name
        last_name
      }
      created_by {
        title_name
        first_name
        last_name
      }
      last_name
      last_login_date
      is_user_active
      inactive_remarks
      img_url
      id
      first_name
      email
      dob
      department
      creator_unique_id
      created_date
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query RolesAggrid($aggridInput: AnyAggridInput) {
    rolesAggrid(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        id
        name
        description
        permission_list {
          access_key_name
          access_value
        }
        user_list {
          id
          unique_id
          first_name
          last_name
          img_url
          department
          position
          is_user_active
        }
      }
    }
  }
`;

export const GET_ALL_ROLES_NO_AGGRID = gql`
  query Roles {
    roles {
      unique_id
      id
      name
      description
      permission_list {
        access_key_name
        access_value
      }
      user_list {
        id
        unique_id
        first_name
        last_name
        img_url
        department
        position
        is_user_active
      }
    }
  }
`;

export const GET_ROLE = gql`
  query Role($uniqueInput: RoleWhereUniqueInput) {
    role(uniqueInput: $uniqueInput) {
      unique_id
      id
      name
      description
      permission_list {
        access_key_name
        access_value
      }
      user_list {
        id
        unique_id
        first_name
        last_name
        nick_name
        title_name
        img_url
        department
        position
        is_user_active
      }
    }
  }
`;
