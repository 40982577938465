import FilterNoneOutlinedIcon from "@mui/icons-material/FilterNoneOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Box, IconButton, InputAdornment } from "@mui/material";
import ControlledSelect from "../../Controlled/ControlledSelect";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import { formatDate } from "../../../utils/date-converter";
import { formatNumber } from "../../../utils/dataTransformer";
import ModalUI from "../../UI/ModalUI";
import { useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";

const summaryTemplateOptions = [
  {
    id: 1,
    label: "คอนเฟิร์มออเดอร์ที่มีรายไอเทม 1 (บัญชีบริษัท)",
    value: "item_list_confirmation",
  },
  {
    id: 2,
    label: "คอนเฟิร์มออเดอร์ที่มีรายไอเทม 2 (บัญชีส่วนตัว)",
    value: "item_list_confirmation_personal",
  },
  {
    id: 3,
    label: "คอนเฟิร์มบัญชีเท่านั้น (บัญชีบริษัท)",
    value: "sales_confirmation",
  },
  {
    id: 4,
    label: "คอนเฟิร์มบัญชีเท่านั้น (บัญชีส่วนตัว)",
    value: "sales_confirmation_personal",
  },
  {
    id: 5,
    label: "คอนเฟิร์มยอดเงิน COD",
    value: "cod_confirmation",
  },
  {
    id: 6,
    label: "แจ้งเลข Tracking No.",
    value: "tracking_report",
  },
];

const SummaryTemplateForm = ({
  control,
  errors,
  openSummaryModal,
  closeSummaryModalHandler,
  disabled,
  summaryTemplate,
  setSummaryTemplate,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const watchItemList = useWatch({ control, name: "item_list" });
  const watchPaymentList = useWatch({ control, name: "order_payment_list" });
  const watchTotalPrice = useWatch({ control, name: "total_price" });
  const watchTotalDiscount = useWatch({ control, name: "total_discount" });
  const watchTotalAmount = useWatch({ control, name: "total_amount" });
  const watchDeliveryDate = useWatch({ control, name: "delivery_date" });
  const watchTrackingNo = useWatch({ control, name: "tracking_number" });
  const watchIsDelivery = useWatch({ control, name: "is_delivery" });

  const getSummaryTemplateText = (type) => {
    switch (type) {
      case "item_list_confirmation": {
        const itemListText = watchItemList.reduce((acc, item, index) => {
          const sku_name = [
            item.item_name,
            `สี${item.color}`,
            `แขน${item.arm}`,
            `คอ${item.neck}`,
            `ไซส์ ${item.size}`,
          ]
            .filter((item) => item !== null && item !== "-")
            .join(", ");
          return (
            acc +
            `${index + 1}. ${sku_name} จำนวน ${item.qty} ตัว ราคารวม ${
              item.price_per_unit * item.qty - item.discount
            } บาท\n`
          );
        }, "");
        setSummaryTemplate(
          `📌 รบกวนลูกค้าตรวจสอบออเดอร์ก่อนโอนเงิน‼️\n${itemListText}\nรวมทั้งหมด   ${watchTotalPrice} บาท\nรวมส่วนลดทั้งหมด   ${watchTotalDiscount} บาท\nยอดรวมทั้งสิ้น   ${watchTotalAmount} บาท\n\nหากถูกต้องและลูกค้ายืนยันการสั่งซื้อสามารถโอนเงินได้ที่\nเลขบัญชี ธนาคารไทยพาณิชย์ 885-300-9366\nชื่อบัญชี บริษัท ไอแอมพรีเมี่ยม จำกัด\n***โอนแล้วรบกวนส่งสลิปทุกครั้ง***`
        );
        break;
      }
      case "item_list_confirmation_personal": {
        const itemListText = watchItemList.reduce((acc, item, index) => {
          const sku_name = [
            item.item_name,
            `สี${item.color}`,
            `แขน${item.arm}`,
            `คอ${item.neck}`,
            `ไซส์ ${item.size}`,
          ]
            .filter((item) => item !== null && item !== "-")
            .join(", ");
          return (
            acc +
            `${index + 1}. ${sku_name} จำนวน ${item.qty} ตัว ราคารวม ${
              item.price_per_unit * item.qty - item.discount
            } บาท\n`
          );
        }, "");
        setSummaryTemplate(
          `📌 รบกวนลูกค้าตรวจสอบออเดอร์ก่อนโอนเงิน‼️\n${itemListText}\nรวมทั้งหมด   ${watchTotalPrice} บาท\nรวมส่วนลดทั้งหมด   ${watchTotalDiscount} บาท\nยอดรวมทั้งสิ้น   ${watchTotalAmount} บาท\n\nหากถูกต้องและลูกค้ายืนยันการสั่งซื้อสามารถโอนเงินได้ที่\nเลขบัญชี ธ.ทหารไทยธนชาต (TTB) 2062690876\nชื่อบัญชี นายวุฒิเมศร์ พลพิมลศักดิ์\n***โอนแล้วรบกวนส่งสลิปทุกครั้ง***`
        );
        break;
      }
      case "sales_confirmation":
        setSummaryTemplate(
          `📌 รบกวนลูกค้าตรวจสอบออเดอร์ก่อนโอนเงิน‼️\nหากถูกต้องและลูกค้ายืนยันการสั่งซื้อสามารถโอนเงินได้ที่\nเลขบัญชี ธนาคารไทยพาณิชย์ 885-300-9366\nชื่อบัญชี บริษัท ไอแอมพรีเมี่ยม จำกัด\n ***โอนแล้วรบกวนส่งสลิปทุกครั้ง***`
        );
        break;
      case "sales_confirmation_personal":
        setSummaryTemplate(
          `📌 รบกวนลูกค้าตรวจสอบออเดอร์ก่อนโอนเงิน‼️\nหากถูกต้องและลูกค้ายืนยันการสั่งซื้อสามารถโอนเงินได้ที่\nเลขบัญชี ธ.ทหารไทยธนชาต (TTB) 2062690876\nชื่อบัญชี นายวุฒิเมศร์ พลพิมลศักดิ์\n ***โอนแล้วรบกวนส่งสลิปทุกครั้ง***`
        );
        break;
      case "cod_confirmation": {
        const codPaymentList = watchPaymentList.filter(
          (payment) => payment.payment_method === "COD"
        );
        let codBalanceAmount =
          codPaymentList.length > 0
            ? formatNumber(
                codPaymentList[codPaymentList.length - 1].remaining_amount
              )
            : "-";
        setSummaryTemplate(
          `📦 สินค้าของทางร้านเป็นงานผลิตตามสั่งทุกตัว หากลูกค้าสั่งซื้อสินค้าแบบเก็บปลายทาง\n❌ทางร้านไม่มีนโยบายให้ยกเลิกสินค้านะคะ❌\nสินค้าจะจัดส่งถึงลูกค้าภายใน 3-5วันทำการ(ไม่รวมวันหยุด และ เสาร์ อาทิตย์)\n📦ก่อนจัดส่งจะมีพนักงานส่งของโทรหา รบกวนลูกค้ารับโทรศัพท์จากพนักงานส่งของด้วยนะคะ\nลูกค้าสามารถชำระเงินค่าสินค้า ${codBalanceAmount} บาท กับพนักงานส่งของได้เลยค่ะ ขอบคุณค่ะ🙏🙏`
        );
        break;
      }
      case "tracking_report":
        setSummaryTemplate(`ทางร้านแอมอะเลิฟได้จัดส่งสินค้าเรียบร้อยแล้ว มีข้อมูลดังต่อไปนี้\nสถานะจัดส่ง ${watchIsDelivery}\nวันที่ส่ง  ${formatDate(
          watchDeliveryDate
        )}\nหมายเลขติดตามสินค้า  ${watchTrackingNo}
        `);
        break;
      default:
        setSummaryTemplate("");
    }
  };
  return (
    <ModalUI
      open={openSummaryModal}
      handleClose={closeSummaryModalHandler}
      maxWidth="sm"
      startIcon={
        <Box
          sx={{
            backgroundColor: "rgba(177, 208, 255, 0.2)",
            color: "#1F5BB2",
            p: 1,
            borderRadius: 100,
          }}
        >
          <FilterNoneOutlinedIcon />
        </Box>
      }
      title="คัดลอกสรุปใบสั่งขาย"
    >
      <ControlledSelect
        sx={{ width: 300, mb: 2 }}
        name="summary_template"
        control={control}
        error={errors.summary_template}
        options={summaryTemplateOptions}
        // disabled={disabled}
        onChange={(e) => {
          getSummaryTemplateText(e.target.value);
        }}
      />
      <CustomizedTextField
        multiline={true}
        rows={5}
        disabled={true}
        value={summaryTemplate}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ position: "absolute", top: 25, right: 15 }}>
              <CustomizedTooltip title={"กดเพื่อคัดลอก"}>
                <IconButton
                  onClick={() => {
                    enqueueSnackbar("คัดลอกแล้ว", {
                      variant: "info",
                    });
                    navigator.clipboard.writeText(summaryTemplate);
                  }}
                  edge="end"
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </InputAdornment>
          ),
        }}
      />
    </ModalUI>
  );
};

export default SummaryTemplateForm;
