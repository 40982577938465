import dayjs from "dayjs";
import { Box } from "@mui/system";
import { useCallback } from "react";
import CustomizedSelect from "../Custom/CustomizedSelect";
import ControlledDatePicker from "../Controlled/ControlledDatePicker";
import { Controller, useWatch } from "react-hook-form";
import { useMemo } from "react";
import { useEffect } from "react";

export default function NewReportDateFilter({
  control,
  setValue,
  getValues,
  t,
  updateDashboard,
}) {
  const watchDateType = useWatch({ control, name: "dateType" });

  const filterChangedDate = useCallback(
    (startDate, endDate) => {
      setValue("date", startDate);
      setValue("dateTo", endDate);
    },
    [setValue]
  );

  const dateOption = useMemo(
    () => [
      { label: "วัน", value: "lastWeek" },
      { label: "เดือน", value: "currentYear" },
      { label: "ปี", value: "allYears" },
    ],
    []
  );

  const onChangeDateHandle = useCallback(() => {
    const dateType = getValues("dateType");
    let startDate = new Date(dayjs().startOf("day").subtract(1, "day"));
    let endDate = new Date(dayjs().endOf("day"));
    if (dateType === "lastWeek") {
      startDate = new Date(dayjs().startOf("day").subtract(6, "day"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "currentYear") {
      startDate = new Date(dayjs().startOf("year"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "allYears") {
      startDate = new Date(dayjs("01/01/2023").startOf("year"));
      endDate = new Date(dayjs().endOf("day"));
    }
    return filterChangedDate(startDate, endDate);
  }, [filterChangedDate, getValues]);

  useEffect(() => {
    if (watchDateType) onChangeDateHandle();
  }, [onChangeDateHandle, watchDateType]);

  useEffect(() => {
    if (watchDateType) onChangeDateHandle();
  }, [onChangeDateHandle, watchDateType]);

  useEffect(() => {
    if (watchDateType) onChangeDateHandle();
  }, [onChangeDateHandle, watchDateType]);

  return (
    <Box display="flex" gap={2}>
      <Controller
        control={control}
        name={"dateType"}
        render={({ field }) => (
          <CustomizedSelect {...field} options={dateOption} label={"ประเภท"} />
        )}
      />
      <ControlledDatePicker
        control={control}
        name="date"
        label={t("date.from")}
      />
      <ControlledDatePicker
        control={control}
        name="dateTo"
        label={t("date.to")}
      />
    </Box>
  );
}
