import { v4 as uuidv4 } from "uuid";

export const departmentType = [
  {
    id: uuidv4(),
    label: "วางแผน",
    value: "วางแผน",
  },
  {
    id: uuidv4(),
    label: "ขาย",
    value: "ขาย",
  },
  {
    id: uuidv4(),
    label: "ฝ่ายผลิต",
    value: "ฝ่ายผลิต",
  },
  {
    id: uuidv4(),
    label: "บัญชี",
    value: "บัญชี",
  },
  {
    id: uuidv4(),
    label: "การตลาด",
    value: "การตลาด",
  },
];

export const positionType = [
  {
    id: uuidv4(),
    label: "กรรมการ",
    value: "กรรมการ",
  },
  {
    id: uuidv4(),
    label: "super",
    value: "super",
  },
  {
    id: uuidv4(),
    label: "แอดมิน",
    value: "แอดมิน",
  },
  {
    id: uuidv4(),
    label: "ฝ่ายผลิต",
    value: "ฝ่ายผลิต",
  },
  {
    id: uuidv4(),
    label: "พนักงานบัญชี",
    value: "พนักงานบัญชี",
  },
  {
    id: uuidv4(),
    label: "เซลล์",
    value: "เซลล์",
  },
  {
    id: uuidv4(),
    label: "ฝ่ายขาย",
    value: "ฝ่ายขาย",
  },
];

export const organizationType = [
  { id: uuidv4(), label: "AAL", value: "AAL" },
  { id: uuidv4(), label: "IAP", value: "IAP" },
];
