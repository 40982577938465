import { useState, useEffect, Fragment } from "react";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Typography,
  ListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import CustomizedTextField from "../Custom/CustomizedTextField";
import CustomizedButton from "../Custom/CustomizedButton";
import ModalUI from "../UI/ModalUI";
import GlobalService from "../../services/Global";
import { useTranslation } from "react-i18next";
import SalesService from "../../services/Sales";
import ManufactureService from "../../services/Manufacture";
import ContactService from "../../services/Contact";

const filter = createFilterOptions();
// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

const ControlledServerSideCreatable = ({
  control,
  sx,
  name,
  fieldToSet, // in case the field is used in array and such
  setValue,
  getValues,
  error,
  defaultOptions,
  onInputChange,
  onKeyDown,
  onChange,
  helperText,
  title,
  placeholder,
  viewOnly,
  required,
  documentType,
  endAdornment,
  actionAfterAdd,
  actionAfterDelete,
  actionAfterSelect,
  isTagCreatable,
}) => {
  // const [isInit, setIsInit] = useState(false);
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [optionToAdd, setOptionToAdd] = useState("");
  const [optionToDelete, setOptionToDelete] = useState("");
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [tagIsUsed, setTagIsUsed] = useState(false);
  const [documentsWithMatchingTag, setDocumentsWithMatchingTag] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let active = true;
    // if (!isInit) {
    const getOptions = async () => {
      const loadedOptions = await GlobalService.getCreatables({
        usage_field_type: documentType,
        usage_field_name: fieldToSet || name,
      });
      // await sleep(1e3);
      if (active) {
        setOptions(
          loadedOptions.map((option) => {
            return {
              value: option.name,
              label: option.name,
              isDeletable: true,
            };
          })
        );
      }
    };
    getOptions();
    // setIsInit(true);
    // }
    return () => {
      active = false;
    };
  }, []);

  const cancelAddHandler = () => {
    setAddModalIsOpen(false);
    setValue(name, "");
  };

  const addOptionHandler = async () => {
    setValue(name, optionToAdd);
    setOptions((prevOptions) => [
      ...prevOptions,
      { value: optionToAdd, label: optionToAdd, isDeletable: true },
    ]);
    try {
      await GlobalService.createCreatable({
        usage_field_type: documentType,
        usage_field_name: fieldToSet || name,
        name: optionToAdd,
      });
    } catch (err) {
      console.log(err);
    }
    setOptionToAdd("");
    setAddModalIsOpen(false);
    if (actionAfterAdd) {
      actionAfterAdd(optionToAdd);
    }
  };

  const cancelDeleteHandler = () => {
    setDeleteModalIsOpen(false);
    setValue(name, "");
    setTagIsUsed(false);
    setDocumentsWithMatchingTag([]);
  };

  const deleteOptionHandler = async () => {
    try {
      if (isTagCreatable) {
        await GlobalService.deleteCreatableWithTags({
          name_usage_field_type_usage_field_name: {
            usage_field_type: documentType,
            usage_field_name: fieldToSet || name,
            name: optionToDelete,
          },
        });
      } else {
        await GlobalService.deleteCreatable({
          name_usage_field_type_usage_field_name: {
            usage_field_type: documentType,
            usage_field_name: fieldToSet || name,
            name: optionToDelete,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== optionToDelete)
    );
    setValue(name, "");
    setOptionToDelete("");
    setDeleteModalIsOpen(false);
    setTagIsUsed(false);
    setDocumentsWithMatchingTag([]);
    if (actionAfterDelete) {
      actionAfterDelete(optionToDelete);
    }
  };

  const openDeleteModalHandler = async (option) => {
    if (isTagCreatable) {
      let existingTags = [];
      switch (documentType) {
        case "order": {
          existingTags = await SalesService.getSalesOrderTags();
          break;
        }
        case "manufacture_order": {
          existingTags = await ManufactureService.getManufactureOrderTags();
          break;
        }
        case "contact": {
          existingTags = await ContactService.getContactTags();
          break;
        }
        default:
      }
      const documentsWithTag = existingTags.filter((document) =>
        document.tag_list.includes(option)
      );
      const matchingTagDocumentIds = documentsWithTag.map(
        (document) => document.unique_id
      );
      setDocumentsWithMatchingTag(matchingTagDocumentIds);
      if (matchingTagDocumentIds.length > 0) {
        setTagIsUsed(true);
      }
    }
    setOptionToDelete(option);
    setDeleteModalIsOpen(true);
  };

  const combinedOptions = [...defaultOptions, ...options];

  return (
    <>
      <Controller
        key={`${name}-creatable`}
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            fullWidth
            {...field}
            onChange={
              onChange
                ? (e, option) => {
                    if (option?.label?.includes("เพิ่ม ")) {
                      setOptionToAdd(option.inputValue);
                      setAddModalIsOpen(true);
                    }
                    onChange(e, option, field);
                    if (option?.value && actionAfterSelect) {
                      actionAfterSelect(option?.value);
                    }
                  }
                : (e, option) => {
                    if (option?.label?.includes("เพิ่ม ")) {
                      setOptionToAdd(option.inputValue);
                      setAddModalIsOpen(true);
                    }
                    if (!option) {
                      return field.onChange("");
                    }
                    if (option.value) {
                      if (actionAfterSelect) {
                        actionAfterSelect(option.value);
                      }
                      return field.onChange(option.value);
                    }
                    if (option.inputValue) {
                      return field.onChange(option.inputValue);
                    }
                    return field.onChange(option);
                  }
            }
            disabled={viewOnly}
            onInputChange={onInputChange}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.label
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `เพิ่ม "${inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disableClearable={endAdornment ? true : false}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={combinedOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.value || option.type;
            }}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  value={option.value ?? option}
                  key={option.label ?? option}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {option.label ?? option}
                  {!option?.label?.includes("เพิ่ม ") && option.isDeletable && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteModalHandler(option.value ?? option);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </li>
              );
            }}
            sx={sx}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label={title}
                size="small"
                name={name}
                error={error}
                onKeyDown={onKeyDown}
                helperText={helperText}
                required={required}
                disabled={viewOnly}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: endAdornment ?? (
                    <Fragment>{params.InputProps.endAdornment}</Fragment>
                  ),
                }}
              />
            )}
            readOnly={viewOnly}
          />
        )}
      />
      <ModalUI
        title="เพิ่มตัวเลือก"
        open={addModalIsOpen}
        handleClose={cancelAddHandler}
        maxWidth="sm"
      >
        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={12} md={6}>
            <CustomizedTextField
              label={title}
              fullWidth
              onChange={(e) => {
                setOptionToAdd(e.target.value);
              }}
              value={optionToAdd}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <CustomizedButton
            title={"ยกเลิก"}
            variant="outlined"
            size="medium"
            onClick={cancelAddHandler}
          />
          <CustomizedButton
            sx={{ ml: 1 }}
            title={"เพิ่ม"}
            variant="contained"
            size="medium"
            onClick={addOptionHandler}
          />
        </Box>
      </ModalUI>
      {(!isTagCreatable || !tagIsUsed) && (
        <ModalUI
          open={deleteModalIsOpen}
          title={`ยืนยันการลบตัวเลือก “${optionToDelete}”`}
          handleClose={cancelDeleteHandler}
          maxWidth="sm"
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            <CustomizedButton
              title={"ยกเลิก"}
              variant="outlined"
              size="medium"
              onClick={cancelDeleteHandler}
            />
            <CustomizedButton
              sx={{ ml: 1 }}
              title={"ยืนยัน"}
              variant="contained"
              size="medium"
              onClick={deleteOptionHandler}
            />
          </Box>
        </ModalUI>
      )}
      {isTagCreatable && tagIsUsed && (
        <ModalUI
          open={deleteModalIsOpen}
          title={`การจัดกลุ่ม ${optionToDelete} ถูกใช้อยู่ใน${t(
            `tag.${documentType}`
          )}`}
          handleClose={cancelDeleteHandler}
          maxWidth="sm"
        >
          <Typography sx={{ mt: 1 }}>{`${t(
            `tag.${documentType}`
          )}ที่ใช้การจัดกลุ่มนี้ คือ`}</Typography>
          {documentsWithMatchingTag?.map((unique_id) => (
            <ListItem
              key={`tag-doc-${unique_id}`}
              sx={{ display: "list-item" }}
            >
              {unique_id}
            </ListItem>
          ))}
          <Typography
            sx={{ mt: 1 }}
            fontWeight="bold"
          >{`หากลบการจัดกลุ่ม ${optionToDelete} การจัดกลุ่มที่อยู่ในแต่ละ${t(
            `tag.${documentType}`
          )}ตามที่แสดงตามด้านบนจะถูกลบด้วย`}</Typography>
          <Typography>กดยืนยันหากต้องการลบการจัดกลุ่ม</Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            <CustomizedButton
              title={"ยกเลิก"}
              variant="outlined"
              size="medium"
              onClick={cancelDeleteHandler}
            />
            <CustomizedButton
              sx={{ ml: 1 }}
              title={"ยืนยัน"}
              variant="contained"
              size="medium"
              onClick={deleteOptionHandler}
            />
          </Box>
        </ModalUI>
      )}
    </>
  );
};

export default ControlledServerSideCreatable;
