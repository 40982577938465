import { gql } from "graphql-request";
export const LOGIN = gql`
  mutation Mutation($loginInfo: LoginInfo) {
    login(loginInfo: $loginInfo) {
      user {
        id
        unique_id
        first_name
        last_name
        email
        phone
        department
        position
        is_user_active
        inactive_remarks
        last_login_date
        img_url
        role_list {
          id
          name
          permission_list {
            role_unique_id
            access_key_name
            access_value
          }
        }
      }
      token {
        access_token
        refresh_token
      }
    }
  }
`;

export const REVOKE = gql`
  mutation RevokeToken {
    revokeToken
  }
`;

export const REFRESH = gql`
  mutation Refresh {
    refreshToken
  }
`;
