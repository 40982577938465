import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";
import ExpiredConfirmation from "../components/UI/Confirmation/ExpiredConfirmation";

const AuthVerify = () => {
  const location = useLocation();
  const { isAuthenticated, logout, verify, refresh, isRefreshTokenExpired } =
    useAuth();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("เซสชันหมดอายุ");

  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(async () => {
        const isSamePermission = await verify();
        if (!isSamePermission) {
          setTitle("สิทธิ์การเข้าถึงมีการเปลี่ยนแปลง");
        }
      }, 3600000);
      return () => clearInterval(interval);
    }
  }, [isAuthenticated, verify]);

  useEffect(() => {
    if (
      isRefreshTokenExpired &&
      location.pathname !== "/login" &&
      location.pathname !== "/"
    ) {
      // setOpen(true);
    }
    const refreshToken = async () => {
      await refresh();
    };
    refreshToken();
  }, [isRefreshTokenExpired, location]);

  return (
    <ExpiredConfirmation
      open={open}
      title={title}
      logout={() => {
        logout();
        setOpen(false);
      }}
    />
  );
};

export default AuthVerify;
