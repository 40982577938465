import { v4 as uuidV4 } from "uuid";
import { formatDate, formatDateTimeNoAMPM } from "./date-converter";
import SalesService from "../services/Sales";
import { removeNullFromObj, formatFloat } from "./dataTransformer";
import { uploadFileToS3 } from "./s3";

export const formatSalesOrderPayload = (data, type, submitType) => {
  const formattedEmployeeList = data.employee_list.map((employee) => ({
    id: employee.id,
    unique_id: employee.unique_id,
    first_name: employee.first_name,
    last_name: employee.last_name,
    email: employee.email,
    phone: employee.phone,
    img_url: employee.img_url,
    department: employee.department,
    position: employee.position,
  }));

  const formattedItemListPayload = data.item_list.map((item) => ({
    id: type === "update" ? item.item_id ?? undefined : undefined,
    item_unique_id: item.item_unique_id,
    item_name: item.item_name,
    sku_name: item.sku_name,
    item_kind: item.item_kind,
    item_type: item.item_type,
    block: item.block,
    color: item.color,
    size: item.size,
    arm: item.arm,
    neck: item.neck,
    item_amount: parseInt(item.qty),
    item_remark: item.item_remark,
    price_per_unit: formatFloat(item.price_per_unit),
    other_cost: formatFloat(item.other_cost),
    uom: item.uom,
    process_type: item.process_type,
    item_discount: formatFloat(item.discount),
    item_total_price: formatFloat(
      parseInt(item.qty) *
        (formatFloat(item.price_per_unit) - formatFloat(item.discount))
    ),
    img_url: item.img_url ?? undefined,
  }));

  const totalItemDiscount = data.item_list?.reduce(
    (prev, curr) =>
      formatFloat(prev, 2) + parseInt(curr.qty) * formatFloat(curr.discount, 2),
    0
  );

  const totalDiscount =
    totalItemDiscount + formatFloat(data.additional_discount);

  const totalAmountBeforeDiscount = data.item_list?.reduce(
    (prev, curr) =>
      formatFloat(prev, 2) +
      parseInt(curr.qty || 0) * formatFloat(curr.price_per_unit),
    0
  );

  const totalAmountAfterDiscount =
    parseFloat(totalAmountBeforeDiscount) - parseFloat(totalDiscount);

  const calculateTotalAmountExcludedVat = () => {
    let vatAmount;
    if (!isNaN(parseFloat(data.vat_type))) {
      vatAmount = 100 + parseFloat(data.vat_type);
    } else {
      vatAmount = 100;
    }
    return formatFloat((totalAmountAfterDiscount * 100) / vatAmount, 2);
  };

  const totalAmountExcludedVat = calculateTotalAmountExcludedVat();

  const totalVatAmount = formatFloat(
    totalAmountAfterDiscount - totalAmountExcludedVat,
    2
  );

  const steps = ["draft", "waitConfirm", "waitPayment", "completed"];

  const formatSubmitStatus = () => {
    switch (submitType) {
      case "draft":
        return steps[0];
      case "sendToCustomer":
        return steps[1];
      default:
        return data.main_status;
    }
  };

  const payload = {
    ...data,
    main_status: formatSubmitStatus(),
    sub_status: data.sub_status ?? undefined,
    flag_status: data.flag_status ?? undefined,
    is_delivery: data.is_delivery === "จัดส่งแล้ว",
    is_open_manufacture: data.is_open_manufacture === "opened",
    employee_list: formattedEmployeeList,
    item_list: formattedItemListPayload,
    total_price: formatFloat(totalAmountBeforeDiscount),
    total_item_discount: formatFloat(totalItemDiscount),
    total_discount: formatFloat(totalDiscount),
    net_amount: formatFloat(totalAmountAfterDiscount),
    total_amount: formatFloat(totalAmountAfterDiscount),
    vat_amount: formatFloat(totalVatAmount),
    price_exclude_vat: formatFloat(totalAmountExcludedVat),
    order_payment_list: formatSalesOrderPaymentPayload(data).order_payment_list,
  };
  if (type === "update") {
    delete payload.unique_id;
  }
  delete payload.contact;
  delete payload.created_by;
  delete payload.summary_template;
  delete payload.creator_unique_id;
  delete payload.status;
  delete payload.isCopied;
  delete payload.pending_cancel;
  delete payload.manufacture_order_list;
  delete payload.tag_order;
  return payload;
};

export const formatSalesOrderQuery = (data) => {
  const payload = { ...data };
  delete payload.id;

  // wait for status
  delete payload.main_status;
  delete payload.sub_status;
  delete payload.flag_status;

  const formattedItemListQueryPayload = data.item_list
    .map((item) => {
      return {
        item_id: item.id,
        item_unique_id: item.item_unique_id,
        item_name: item.item_name,
        sku_name: item.sku_name,
        item_kind: item.item_kind,
        item_type: item.item_type,
        block: item.block,
        color: item.color,
        size: item.size,
        arm: item.arm,
        neck: item.neck,
        qty: item.item_amount,
        price_per_unit: item.price_per_unit,
        other_cost: item.other_cost,
        uom: item.uom,
        item_remark: item.item_remark,
        process_type: item.process_type,
        discount: item.item_discount,
        img_url: item.img_url ?? "",
        sort_index: item.sort_index ?? null,
      };
    })
    .sort((a, b) => {
      if (a.sort_index === null && b.sort_index === null) {
        return 0;
      } else if (a.sort_index === null) {
        return 1;
      } else if (b.sort_index === null) {
        return -1;
      } else {
        return a.sort_index - b.sort_index;
      }
    });

  const formattedEmployeeList = data.employee_list.map((employee) => ({
    id: employee.id,
    unique_id: employee.unique_id,
    first_name: employee.first_name,
    last_name: employee.last_name,
    email: employee.email,
    phone: employee.phone,
    img_url: employee.img_url,
    department: employee.department,
    position: employee.position,
  }));

  return {
    ...payload,
    sales_channel: payload.sales_channel ?? "",
    account_id: payload.account_id ?? "",
    brand_name: payload.brand_name ?? "",
    contact_name: payload.contact_name?.replaceAll("null", "") ?? "",
    phone_main: payload.phone_main ?? "",
    phone_sub: payload.phone_sub ?? "",
    email: payload.email ?? "",
    delivery_address: payload.delivery_address ?? "",
    tracking_number: payload.tracking_number ?? "",
    is_delivery: payload.is_delivery ? "จัดส่งแล้ว" : "ยังไม่จัดส่ง",
    remark: payload.remark ?? "",
    is_open_manufacture: payload.is_open_manufacture ? "opened" : "not_open",
    sale_cancel_remark: payload.sale_cancel_remark ?? "",
    delivery_cancel_remark: payload.delivery_cancel_remark ?? "",
    status: formattedStatus(data),
    item_list: formattedItemListQueryPayload,
    employee_list: formattedEmployeeList,
  };
};

export const formatSalesOrderPaymentPayload = async (data) => {
  const formatPaymentImgUrl = async () => {
    const result = await Promise.all(
      data.order_payment_list.map(async (payment) => {
        try {
          if (typeof payment.payment_img_url[0] === "object") {
            const { Location } = await uploadFileToS3(
              payment.payment_img_url[0],
              "order",
              data.unique_id
            );
            return Location;
          } else {
            if (Array.isArray(payment.payment_img_url)) {
              if (payment.payment_img_url?.length === 0) {
                return "";
              } else {
                return payment.payment_img_url[0];
              }
            }
            return payment.payment_img_url || "";
          }
        } catch (err) {
          return "";
        }
      })
    );
    return result;
  };
  const formattedPaymentImgUrl = await formatPaymentImgUrl();
  const formattedData = {
    order_payment_list: data.order_payment_list?.map((payment, index) => ({
      id: payment.id,
      bank_account: payment.bank_account?.toString().trim() ?? "",
      payment_amount: formatFloat(payment.payment_amount),
      payment_count: index + 1,
      payment_date_time: payment.payment_date_time,
      payment_img_url: formattedPaymentImgUrl[index],
      payment_method: payment.payment_method?.toString().trim() ?? "",
      remaining_amount: formatFloat(payment.remaining_amount),
      remark: payment.remark?.toString().trim() ?? "",
      total_amount: formatFloat(payment.total_amount),
      total_paid: formatFloat(payment.total_paid),
    })),
    payment_date:
      data.order_payment_list?.length > 0
        ? data.order_payment_list[0]?.payment_date_time
        : undefined,
  };
  return formattedData;
};

export const formattedStatus = (data) => {
  let status;
  if (data.flag_status) {
    return "cancelled";
  }
  switch (data.main_status) {
    case "draft":
      status = "draft";
      break;
    case "waitConfirm":
      status = "wait_customer_accept";
      break;
    case "waitPayment":
      switch (data.sub_status) {
        case "รอชำระเมื่อรับสินค้า":
          status = "wait_payment_when_receive";
          break;
        case "รอชำระCOD":
          status = "wait_payment_cod";
          break;
        case "ชำระแล้วบางส่วน":
          status = "partially_paid";
          break;
        case "ชำระแล้วเต็มจำนวน":
          status = "fully_paid";
          break;
        default:
          status = "wait_payment";
      }
      break;
    case "finished":
      status = "finished";
      break;
    default:
      status = "";
  }
  return status;
};

export const formatExportedStatus = (render_status) => {
  switch (render_status) {
    case "draft":
      return "ร่าง";
    case "wait_customer_accept":
      return "รอลูกค้ายืนยัน";
    case "wait_payment_when_receive":
      return "รอชำระเมื่อรับสินค้า";
    case "wait_payment_cod":
      return "รอชำระ COD";
    case "partially_paid":
      return "ชำระแล้วบางส่วน";
    case "fully_paid":
      return "ชำระแล้วเต็มจำนวน";
    case "finished":
      return "เสร็จสิ้น";
    case "cancelled":
      return "ยกเลิก";
    default:
      return "";
  }
};

export const formatDashboardStatus = (data) => {
  switch (data) {
    case "draft_paid":
    case "draft_count":
      return "ร่าง";
    case "wait_customer_accept_paid":
    case "wait_customer_accept_count":
      return "รอลูกค้ายืนยัน";
    case "wait_payment_when_receive_paid":
    case "wait_payment_when_receive_count":
      return "รอชำระเมื่อรับสินค้า";
    case "wait_payment_cod_paid":
    case "wait_payment_cod_count":
      return "รอชำระ COD";
    case "partially_paid_paid":
    case "partially_paid_count":
      return "ชำระแล้วบางส่วน";
    case "fully_paid_paid":
    case "fully_paid_count":
      return "ชำระแล้วเต็มจำนวน";
    case "finished_paid":
    case "finished_count":
      return "เสร็จสิ้น";
    case "cancelled_paid":
    case "cancelled_count":
      return "ยกเลิก";
    default:
      return "";
  }
};

export const salesOrderExportFormatter = (data) => {
  const result = [];

  const formatVatType = (vat_type) => {
    switch (vat_type) {
      case "7":
        return 1.07;
      case "3":
        return 1.03;
      default:
        return 1;
    }
  };

  data.forEach((order) => {
    const filteredDeliveryAddress = order.contact.address_list.filter(
      (address) => address.address_type === order.delivery_address
    )[0];
    // if (order.item_list.length > 0) {
    const item_price_sum_exclude_discount = order.item_list.reduce(
      (acc, currentItem) => {
        return (
          formatFloat(acc, 2) +
          currentItem.price_per_unit * currentItem.item_amount
        );
      },
      0
    );

    const totalDiscount =
      order.item_list.reduce((acc, currentItem) => {
        return (
          parseFloat(acc) + currentItem.item_discount * currentItem.item_amount
        );
      }, 0) + order.additional_discount;

    const itemTotalPriceExcludeVat = formatFloat(
      order.item_list.reduce((acc, currentItem) => {
        return (
          (parseFloat(acc) +
            (currentItem.price_per_unit * currentItem.item_amount -
              (order.additional_discount *
                (currentItem.price_per_unit * currentItem.item_amount)) /
                item_price_sum_exclude_discount)) /
          formatVatType(order.vat_type)
        );
      }, 0),
      2
    );

    const itemListLength = order.item_list?.length ?? 0;
    const manufactureOrderListLength =
      order.manufacture_order_list?.length ?? 0;
    const maxLength = Math.max(itemListLength, manufactureOrderListLength);

    for (let i = 0; i < maxLength; i++) {
      let item_list = {};
      if (i < itemListLength) {
        const item_additional_discount = formatFloat(
          (order.additional_discount *
            (order.item_list[i].price_per_unit *
              order.item_list[i].item_amount)) /
            item_price_sum_exclude_discount,
          2
        );
        const total_after_item_discount =
          order.item_list[i].item_amount * order.item_list[i].price_per_unit -
          order.item_list[i].item_discount;
        const item_total_price_additional_discount = formatFloat(
          total_after_item_discount - item_additional_discount,
          2
        );
        const item_price_exclude_vat = formatFloat(
          item_total_price_additional_discount / formatVatType(order.vat_type),
          2
        );
        const item_vat = formatFloat(
          item_total_price_additional_discount - item_price_exclude_vat,
          2
        );
        item_list = {
          item_unique_id: order.item_list[i].item_unique_id,
          sku_name: order.item_list[i].sku_name,
          item_name: order.item_list[i].item_name,
          color: order.item_list[i].color,
          size: order.item_list[i].size,
          arm: order.item_list[i].arm,
          neck: order.item_list[i].neck,
          item_remark: order.item_list[i].item_remark,
          item_amount: order.item_list[i].item_amount,
          price_per_unit: order.item_list[i].price_per_unit,
          uom: order.item_list[i].uom,
          item_discount: order.item_list[i].item_discount,
          total_after_item_discount,
          item_additional_discount: item_additional_discount,
          item_total_price_additional_discount:
            item_total_price_additional_discount,
          item_price_exclude_vat,
          item_vat: item_vat,
          total_price: order.total_price,
          additional_discount: order.additional_discount,
          total_discount: totalDiscount,
          total_amount: order.total_price - totalDiscount,
          vat_type: order.vat_type === "ไม่มี" ? "ไม่มี" : order.vat_type + "%",
          vat_amount: order.vat_amount,
          total_price_exclude_vat: itemTotalPriceExcludeVat,
        };
      }

      const manufacture_order_list =
        i < manufactureOrderListLength
          ? {
              mo_unique_id: order.manufacture_order_list[i].unique_id,
              mo_vendor_name: order.manufacture_order_list[
                i
              ].vendor_name?.replaceAll("null", ""),
              mo_customer_name: order.manufacture_order_list[
                i
              ].customer_name?.replaceAll("null", ""),
              mo_phone: order.manufacture_order_list[i].vendor_phone,
              mo_created_date: formatDate(
                order.manufacture_order_list[i].created_date
              ),
              mo_payment_date: formatDate(
                order.manufacture_order_list[i].payment_date
              ),
              mo_production_date: formatDate(
                order.manufacture_order_list[i].production_date
              ),
              mo_receive_date: formatDate(
                order.manufacture_order_list[i].receive_date
              ),
              mo_tag: order.manufacture_order_list[i].tag_list.join(", "),
              mo_created_by: `${
                order.manufacture_order_list[i].created_by?.first_name ?? ""
              }${
                order.manufacture_order_list[i].created_by?.last_name
                  ? ` ${order.manufacture_order_list[i].created_by?.last_name}`
                  : ""
              }`,
              mo_employee_list: order.manufacture_order_list[i]?.employee_list
                .map((employee) => {
                  return `${employee.first_name ?? ""}${
                    employee.last_name ? ` ${employee?.last_name}` : ""
                  }`;
                })
                .join(", "),
            }
          : {};

      result.push({
        unique_id: order.unique_id,
        created_by:
          order.created_by.first_name + " " + order.created_by.last_name,
        related_employees: order.employee_list
          .map((employee) => {
            return `${employee.first_name ?? ""}${
              employee.last_name ? ` ${employee?.last_name}` : ""
            }`;
          })
          .join(", "),
        render_status: formatExportedStatus(order.render_status),
        is_open_manufacture: order.is_open_manufacture ? "TRUE" : "FALSE",
        created_date: formatDate(order.created_date),
        payment_date: order.payment_date ? formatDate(order.payment_date) : "",
        due_date: order.due_date ? formatDate(order.due_date) : "",
        delivery_date: order.delivery_date
          ? formatDate(order.delivery_date)
          : "",
        receive_date: order.receive_date ? formatDate(order.receive_date) : "",
        contact_unique_id: order.contact_unique_id,
        contact_name: order.contact_name.replaceAll("null", ""),
        phone_main: order.phone_main,
        phone_sub: order.phone_sub,
        email: order.email,
        delivery_address: filteredDeliveryAddress?.address_number ?? "",
        sub_district: filteredDeliveryAddress?.sub_district ?? "",
        district: filteredDeliveryAddress?.district ?? "",
        province: filteredDeliveryAddress?.province ?? "",
        postal_code: filteredDeliveryAddress?.postal_code ?? "",
        zone: filteredDeliveryAddress?.zone ?? "",
        country: filteredDeliveryAddress?.country ?? "",
        ...item_list,
        remark: order.remark,
        sales_channel: order.sales_channel,
        account_id: order.account_id,
        brand_name: order.brand_name,
        tracking_number: order.tracking_number,
        is_delivery: order.is_delivery ? "จัดส่งแล้ว" : "ยังไม่จัดส่ง",
        tag_order: order.tag_list.join(", "),
        sale_cancel_remark: order.sale_cancel_remark,
        delivery_cancel_remark: order.delivery_cancel_remark,
        ...manufacture_order_list,
      });
    }
  });
  return result;
};

export const salesOrderPaymentExportFormatter = async (data) => {
  const result = [];
  data.forEach((order) => {
    order.order_payment_list.forEach((payment) => {
      const formattedPayment = {
        unique_id: order.unique_id,
        created_date: formatDate(order.created_date),
        payment_count: payment.payment_count,
        total_amount: payment.total_amount,
        total_paid: payment.total_paid,
        payment_amount: payment.payment_amount,
        remaining_amount: payment.remaining_amount,
        payment_method: payment.payment_method,
        payment_date_time: formatDateTimeNoAMPM(payment.payment_date_time),
        bank_account: payment.bank_account,
        remark: payment.remark,
      };
      result.push(formattedPayment);
    });
  });
  return result;
};
