import dayjs from "dayjs";

export const initialState = {
  isLoading: {
    allSalesOrders: false,
    allSalesOrdersExport: false,
    allSalesOrderPayments: false,
    allSalesOrderPaymentsExport: false,
    salesOrder: false,
  },
  allSalesOrders: [],
  allSalesOrdersExport: [],
  allSalesOrderPaymentsExport: [],
  salesOrder: {
    status: null,
    pending_cancel: false,
    main_status: null,
    sub_status: null,
    flag_status: null,
    unique_id: "",
    created_date: null,
    payment_date: null,
    due_date: null,
    delivery_date: null,
    receive_date: null,
    is_open_manufacture: "not_open",
    sales_channel: "",
    account_id: "",
    brand_name: "",
    contact_unique_id: "",
    contact: null,
    contact_name: "",
    phone_main: "",
    phone_sub: "",
    email: "",
    delivery_address: "",
    tag_list: [],
    tag_order: "",
    tracking_number: "",
    is_delivery: "",
    employee_list: [],
    creator_unique_id: null,
    created_by: null,
    item_list: [],
    remark: "",
    additional_discount: 0.0,
    vat_type: "7",
    sale_cancel_remark: "",
    delivery_cancel_remark: "",
    summary_template: "",
  },
  paymentDetail: {
    total_amount: 0,
    total_paid: 0,
    remaining_amount: 0,
    payment_amount: 0,
    payment_method: "",
    payment_date_time: dayjs().format(),
    bank_account: "",
    payment_remark: "",
    payment_img_url: [],
  },
  paymentList: [],
  error: null,
};
