import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoading: {
    allRoles: false,
    role: false,
  },
  allRoles: [],
  role: {
    unique_id: "",
    name: "",
    description: "",
    user_list: [],
    permission_list: [
      { access_key_name: "inventory", access_value: "" },
      { access_key_name: "contact", access_value: "" },
      { access_key_name: "sales", access_value: "" },
      { access_key_name: "manufacture", access_value: "" },
      { access_key_name: "config", access_value: "" },
      { access_key_name: "user", access_value: "" },
    ],
  },
  error: null,
};

const rbacSlice = createSlice({
  name: "rbac",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllRoles(state, action) {
      state.allRoles = action.payload;
      state.isLoading.allRoles = false;
    },
    appendedNewRole(state, action) {
      state.allRoles = [...state.allRoles, action.payload];
      state.isLoading.role = false;
    },
    resetRole(state) {
      state.role = initialState.role;
    },
    loadedRole(state, action) {
      state.role = { ...state.role, ...action.payload };
      state.isLoading.role = false;
    },
    updatedRole(state, action) {
      const updatedRole = action.payload;
      const updatedIndex = state.allRoles.findIndex(
        (role) => role.doument_id === updatedRole.document_id
      );
      state.allRoles[updatedIndex] = updatedRole;
      state.isLoading.allRoles = false;
    },
    deletedRole(state, action) {
      state.allRoles = state.allRoles.filter(
        (role) => role.document_id !== action.payload.document_id
      );
      state.isLoading.role = false;
    },
  },
});

export const rbacActions = rbacSlice.actions;

export default rbacSlice.reducer;
