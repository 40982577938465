import styled from '@emotion/styled'
import { AppBar } from '@mui/material'

const CustomizedDashboardNavbar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottomColor: theme.palette.divider,
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderLeftColor: theme.palette.divider,
  borderLeftStyle: 'solid',
  borderLeftWidth: 1,
  boxShadow: 'none',
  width: '100%',
  zIndex: 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: 'calc(100% - 259px)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

export default CustomizedDashboardNavbar
