import { gql } from "graphql-request";

export const CREATE_ITEM = gql`
  mutation ItemCreate($createInput: ItemCreateInput!) {
    itemCreate(createInput: $createInput) {
      id
      unique_id
      name
      description
      img_url
      is_active
      inactive_remark
      is_variation
      creator_unique_id
      created_date
      last_updated_date
      uom
      item_type
      collection_name
      design_code
      design_owner
      design_type
      block
      process
      process_type
      supplier
      type_name
      item_kind
      item_variant_key_list {
        item_variant_key_name
        item_variant_value_list {
          item_variant_value_name
        }
      }
      sku_list {
        sku_name
        item_unique_id
        item_variant_value {
          item_variant_value_name
        }
        sku_detail {
          sku_name
          sale_price
          other_cost
          shirt_cost
          is_active
          img_url
          item_kind
          process_type
          item_desc
          item_type
        }
      }
    }
  }
`;

export const GET_ITEMS = gql`
  query Items {
    items {
      unique_id
      name
      description
      img_url
      is_active
      inactive_remark
      is_variation
      creator_unique_id
      created_date
      last_updated_date
      uom
      item_type
      sale_price
      folded_cost
      printed_cost
      pressed_cost
      total_cost
    }
  }
`;

export const CREATE_ITEM_DIRECT = gql`
  mutation ItemCreateDirect($createInput: ItemCreateDirectInput!) {
    itemCreateDirect(createInput: $createInput) {
      id
      unique_id
      name
      description
      img_url
      is_active
      inactive_remark
      is_variation
      creator_unique_id
      created_date
      last_updated_date
      uom
      item_type
      collection_name
      design_code
      design_owner
      design_type
      block
      process
      process_type
      supplier
      type_name
      item_kind
      item_variant_key_list {
        item_variant_key_name
        item_variant_value_list {
          item_variant_value_name
        }
      }
      sku_list {
        sku_name
        item_unique_id
        item_variant_value {
          item_variant_value_name
        }
        sku_detail {
          sku_name
          sale_price
          other_cost
          shirt_cost
          is_active
          img_url
          item_kind
          process_type
          item_desc
          item_type
        }
      }
    }
  }
`;

export const CREATE_ITEMS = gql`
  mutation ItemCreateMany($createManyInput: [ItemCreateDirectInput!]) {
    itemCreateMany(createManyInput: $createManyInput) {
      errors {
        index
        unique_id
        errorMessage
        field
      }
      results {
        unique_id
        item_type
      }
    }
  }
`;

export const UPSERT_ITEMS = gql`
  mutation ItemUpsertMany($upsertManyInput: [ItemUpsertInput!]) {
    itemUpsertMany(upsertManyInput: $upsertManyInput) {
      errors {
        index
        unique_id
        errorMessage
        field
      }
      results {
        unique_id
        item_type
      }
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation ItemUpdateWithSku(
    $uniqueInput: ItemUniqueInput!
    $updateInput: ItemUpdateWithSkuInput!
  ) {
    itemUpdateWithSku(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      unique_id
      name
      description
      img_url
      is_active
      inactive_remark
      is_variation
      creator_unique_id
      created_by {
        id
        unique_id
        first_name
        last_name
        img_url
      }
      created_date
      last_updated_date
      uom
      process_type
      item_type
      item_kind
      collection_name
      design_type
      block
      type_name
      supplier
      process
      sku_list {
        sku_name
        item_unique_id
        item_variant_value {
          id
          item_variant_value_name
          item_variant_key_name
          item_unique_id
        }
        sku_detail {
          sku_name
          sale_price
          other_cost
          shirt_cost
          is_active
          img_url
          item_kind
          process_type
          item_desc
          item_type
        }
      }
      item_variant_key_list {
        id
        item_variant_key_name
        description
      }
      item_variant_value_list {
        id
        item_variant_value_name
        item_variant_key_name
        description
      }
    }
  }
`;

export const CREATE_ITEM_VARIANT_KEYS = gql`
  mutation ItemVariantKeysCreate($createInput: ItemVariantKeyCreateInput!) {
    itemVariantKeysCreate(createInput: $createInput) {
      id
      item_variant_key_name
      item_unique_id
      item_variant_value_list {
        id
        item_variant_value_name
        item_variant_key_name
        description
        item_unique_id
      }
    }
  }
`;

export const UPDATE_ITEM_SKU_DETAIL = gql`
  mutation ItemSkuDetailUpdate(
    $updateInput: ItemSkuDetailUpdateInput!
    $uniqueInput: ItemSkuDetailUniqueInput!
  ) {
    itemSkuDetailUpdate(updateInput: $updateInput, uniqueInput: $uniqueInput) {
      sku_name
      sale_price
      other_cost
      shirt_cost
      is_active
      img_url
      item_kind
      process_type
      item_desc
      item_type
    }
  }
`;

export const DISABLE_ITEM = gql`
  mutation ItemDisable($uniqueInput: ItemUniqueInput!) {
    itemDisable(uniqueInput: $uniqueInput) {
      unique_id
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation ItemDelete($uniqueInput: ItemUniqueInput!) {
    itemDelete(uniqueInput: $uniqueInput) {
      unique_id
    }
  }
`;
