import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../../hooks/use-auth";
import { schema, validation } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { useSnackbar } from "notistack";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginForm = ({ isMobile }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema,
    resolver: yupResolver(validation),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onLoginSubmit = async ({ email, password }) => {
    try {
      setEmailError(null);
      setPasswordError(null);
      await login(email, password);
      enqueueSnackbar("ยินดีต้อนรับสู่ AAL ERP", {
        variant: "success",
      });
      navigate(from, { replace: true });
    } catch (err) {
      console.log(err);
      if (err.message === "Network request failed") {
        enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้งภายหลัง", {
          variant: "error",
        });
      } else {
        if (
          err.message.includes(
            "Cannot read properties of null (reading 'unique_id')"
          )
        ) {
          setEmailError("อีเมลนี้ไม่มีในระบบ");
          enqueueSnackbar("ล็อคอินไม่สำเร็จ", {
            variant: "error",
          });
        } else if (err.message.includes("User is not active")) {
          enqueueSnackbar("ผู้ใช้งานนี้ถูกปิดการใช้งาน", {
            variant: "error",
          });
        } else {
          setPasswordError("รหัสผ่านไม่ถูกต้อง");
          enqueueSnackbar("ล็อคอินไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onLoginSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <CustomizedTextField
            testId="user-email-text-field"
            fullWidth
            error={Boolean(emailError) || Boolean(errors.email)}
            helperText={emailError || errors.email?.message}
            autoComplete="off"
            label="อีเมลผู้ใช้งาน"
            margin="dense"
            size={isMobile ? "medium" : "small"}
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <FormControl
            variant="outlined"
            fullWidth
            size={isMobile ? "medium" : "small"}
            sx={{ mt: 1 }}
            error={Boolean(passwordError) || errors.password}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              รหัสผ่าน
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              data-test-id="user-password-text-field"
              {...field}
            />
            {(passwordError || errors.password) && (
              <FormHelperText>
                {passwordError || errors?.password?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
      <CustomizedButton
        testId="login-button"
        type="submit"
        title="เข้าสู่ระบบ"
        variant="contained"
        fullWidth
        size="medium"
        sx={{ mt: 1 }}
      />
    </form>
  );
};

export default LoginForm;
