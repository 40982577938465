import * as XLSX from "xlsx";
import dot from "dot-object";
import dayjs from "dayjs";

const arrayHasObject = (arr) => {
  let objectFound = false;
  arr.forEach((obj) => {
    if (
      Object.values(obj).some(
        (val) => typeof val === "object" && !Array.isArray(val) && val !== null
      )
    ) {
      objectFound = true;
    }
  });
  return objectFound;
};

export const exportAsExcel = (
  data,
  fileName,
  sheetName,
  translation,
  documentType
) => {
  const workbook = XLSX.utils.book_new();
  dot.keepArray = true;
  let flattenedData = [...data];
  let counter = 0;
  while (arrayHasObject(flattenedData) || counter === 0) {
    flattenedData = flattenedData.map((record) => dot.dot(record));
    flattenedData = flattenedData
      .map((record) => {
        const tempArr = [];
        for (let key of Object.keys(record)) {
          if (Array.isArray(record[key])) {
            if (record[key].length === 0) {
              delete record[key];
              continue;
            }
            record[key].forEach((arrayItem, index) => {
              let recordToAssign;
              if (index === 0 && typeof tempArr[0] === "undefined") {
                recordToAssign = record;
              } else {
                recordToAssign = tempArr[index];
              }
              if (typeof arrayItem === "object") {
                tempArr[index] = {
                  ...recordToAssign,
                  [key]: { ...arrayItem },
                };
              } else {
                tempArr[index] = { ...recordToAssign, [key]: arrayItem };
              }
            });
          }
        }
        if (tempArr.length === 0) {
          tempArr[0] = { ...record };
        }
        return [...tempArr];
      })
      .flat();
    counter++;
  }
  const workSheet = XLSX.utils.json_to_sheet(flattenedData);

  function get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet["!ref"]);
    var C,
      R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell =
        sheet[
          XLSX.utils.encode_cell({ c: C, r: R })
        ]; /* find the cell in the first row */

      var hdr = "UNKNOWN " + C; // <-- replace with your desired default
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
      headers.push(hdr);
    }
    return headers;
  }

  const headerKeys = get_header_row(workSheet);

  const workSheetWithThai = XLSX.utils.json_to_sheet(flattenedData, {
    origin: translation ? "A2" : "A1",
  });

  const mapToThaiLanguage = translation
    ? headerKeys.map((key) => {
        if (key === "employee_list") {
          return "";
        } else {
          if (documentType) {
            return translation(`reports.${documentType}.${key}`);
          }
          return translation(`reports.${key}`);
        }
      })
    : undefined;

  if (translation) {
    XLSX.utils.sheet_add_aoa(workSheetWithThai, [mapToThaiLanguage]);
  }

  const timestamp = dayjs().format("YYYY-MM-DD_HHmmss");
  XLSX.utils.book_append_sheet(
    workbook,
    workSheetWithThai,
    sheetName || "Sheet1"
  );
  XLSX.writeFile(workbook, `${fileName}_${timestamp}.xlsx`);
};
