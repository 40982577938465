import { gql } from "graphql-request";

export const CREATE_ACTIVITY_LOGS = gql`
  mutation ActivityLogCreate($createInput: ActivityLogCreateInput) {
    activityLogCreate(createInput: $createInput) {
      id
      unique_id
      activity_details
      activity_type
      created_date
      created_by {
        id
        unique_id
        first_name
        last_name
      }
      model_type
      attachment_list
    }
  }
`;
