import { Controller } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import CustomizedTextField from "../Custom/CustomizedTextField";

const ControlledPhoneTextField = ({
  disabled,
  control,
  name,
  error,
  sx,
  helperText,
  label,
  inputProps,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <PatternFormat
          value={field.value}
          format={"0##-###-####"}
          mask={" "}
          onValueChange={(v) => {
            field.onChange(v.floatValue);
          }}
          label={label}
          disabled={disabled}
          customInput={CustomizedTextField}
          helperText={helperText}
          error={error}
          inputProps={inputProps}
          sx={sx}
        />
      )}
    />
  );
};

export default ControlledPhoneTextField;
