import { forwardRef } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CustomizedCheckboxes = forwardRef(
  ({ sx, value, isDisabled, onChange, label, error, testId }, ref) => {
    return (
      <FormControlLabel
        ref={ref}
        control={
          <Checkbox
            checked={value}
            value={value}
            disabled={isDisabled}
            onChange={onChange}
            sx={{ ...sx, color: error ? "error.main" : "primary.main" }}
            data-testid={testId}
          />
        }
        label={label}
      />
    );
  }
);

export default CustomizedCheckboxes;
