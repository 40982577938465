import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import HeaderForm from "../../../components/Form/ManufactureOrder/HeaderForm";
import RightDrawer from "../../../components/UI/RightDrawer";
import { updateManufactureOrderStatus } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ManufactureOrderPdf from "./Pdf";
import CancelConfirmation from "../../../components/UI/Confirmation/CancelConfirmation";
import StatusChangeConfirmation from "../../../components/UI/Confirmation/StatusChangeConfirmation";
import { useAuth } from "../../../hooks/use-auth";

const Header = ({
  control,
  errors,
  disabled,
  getValues,
  setValue,
  editClickHandler,
  scrollToCancelDetailForm,
  setIsInit,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { permissions } = useAuth();
  const modifyOptions = ["แก้ไข"];
  const statusOptions = [
    t("status.manufacturing"),
    t("status.finished"),
    t("status.cancelled"),
  ];
  const [statusChangeConfirmation, setStatusChangeConfirmation] =
    useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [tempTargetStatus, setTempTargetStatus] = useState(null);
  const [selectModifyOptions, setSelectModifyOptions] = useState(modifyOptions);
  const [selectStatusOptions, setSelectStatusOptions] = useState(statusOptions);
  const [openDrawer, setOpenDrawer] = useState(false);
  const manufacturePermission = permissions.manufacture;

  const watchStatus = useWatch({
    control,
    name: "status",
  });

  useEffect(() => {
    if (manufacturePermission === "VIEW__ONLY") {
      setSelectModifyOptions([]);
      setSelectStatusOptions([]);
      return;
    }
    switch (watchStatus) {
      case "draft":
        setSelectModifyOptions([]);
        setSelectStatusOptions(statusOptions);
        break;
      case "wait_manufacture":
        setSelectModifyOptions(modifyOptions);
        setSelectStatusOptions(statusOptions);
        break;
      case "manufacturing":
        setSelectModifyOptions(modifyOptions);
        setSelectStatusOptions([statusOptions[1], statusOptions[2]]);
        break;
      case "finished":
        setSelectModifyOptions([]);
        setSelectStatusOptions([statusOptions[2]]);
        break;
      case "cancelled":
        setSelectModifyOptions([]);
        setSelectStatusOptions([]);
        break;
      default:
        setSelectModifyOptions(modifyOptions);
    }
  }, [watchStatus]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => {
      const PrintElem = document.createElement("div");
      const header = `<div class="page-footer" />`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(componentRef.current);
      return PrintElem;
    },
  });

  const closeCancelConfirmationHandler = () => {
    setCancelConfirmation(false);
  };

  const cancelConfirmationAction = () => {
    setTempTargetStatus("ยกเลิก");
    statusOptionSelectHandler(t("status.cancelled"));
  };

  const onCancelStatusHandler = () => {
    setValue("pending_cancel", true);
    scrollToCancelDetailForm();
  };

  const openStatusChangeConfirmationHandler = (targetStatus) => {
    setStatusChangeConfirmation(true);
    setTempTargetStatus(targetStatus);
  };

  const closeStatusChangeConfirmationHandler = () => {
    setStatusChangeConfirmation(false);
    setTempTargetStatus(null);
  };

  const statusOptionSelectHandler = () => {
    const { unique_id, status } = getValues();
    dispatch(
      updateManufactureOrderStatus(
        unique_id,
        status,
        tempTargetStatus,
        enqueueSnackbar,
        setIsInit
      )
    );
    setStatusChangeConfirmation(false);
    setTempTargetStatus(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 3,
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            {t("manufacture.order.index")}
          </Typography>
          <CustomizedStatus status={watchStatus} />
        </Box>

        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <CustomizedButton
            title={t("print")}
            disabled={!id}
            variant="outlined"
            size="medium"
            onClick={handlePrint}
          />
          <CustomizedMenuOption
            fullWidth
            size="medium"
            label={"ตัวเลือก"}
            options={selectModifyOptions}
            onSelect={(e) => {
              switch (e.target.innerText) {
                case "แก้ไข":
                  editClickHandler();
                  break;
                default:
                  break;
              }
            }}
            disabled={!id || selectModifyOptions.length === 0}
          />
          <CustomizedMenuOption
            fullWidth
            size="medium"
            label={"สถานะ"}
            variant="contained"
            options={selectStatusOptions}
            onSelect={(e) => {
              if (e.target.innerText === "ยกเลิก") {
                onCancelStatusHandler();
              } else {
                openStatusChangeConfirmationHandler(e.target.innerText);
              }
            }}
            disabled={
              !id || watchStatus === "draft" || selectStatusOptions.length === 0
            }
          />
          <CustomizedTooltip
            title="ดูการเคลื่อนไหว"
            enterNextDelay={200}
            arrow
          >
            <IconButton
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => setOpenDrawer(true)}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
          <RightDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            title={t("inventory.activity")}
            uniqueId={id}
            modelType="manufacture_order"
          />
        </Box>
      </Box>
      <HeaderForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
      />
      <ManufactureOrderPdf
        ref={componentRef}
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
      />
      <CancelConfirmation
        openCancelConfirmation={cancelConfirmation}
        cancelConfirmationAction={cancelConfirmationAction}
        closeCancelConfirmationHandler={closeCancelConfirmationHandler}
      />
      <StatusChangeConfirmation
        currentStatus={watchStatus}
        targetStatus={tempTargetStatus}
        openStatusChangeConfirmation={statusChangeConfirmation}
        statusChangeConfirmationAction={statusOptionSelectHandler}
        closeStatusChangeConfirmationHandler={
          closeStatusChangeConfirmationHandler
        }
        translate={t}
      />
    </>
  );
};

export default Header;
