import { Box } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import {
  dateComparator,
  filterParamsOptions,
} from "../../../../utils/filterparams";

export const contactColumnDefs = (t, isImport, isModal) => {
  const contactChannelAction = (type, info) => {
    switch (type) {
      case "เบอร์โทรศัพท์":
      case "แฟกซ์":
      case "เบอร์บ้าน":
      case "เบอร์มือถือ":
        window.open(`tel:${info}`, "_self");
        return;
      case "Email":
      case "E-mail":
      case "E-Mail":
        window.open("mailto:" + info, "_self");
        return;
      case "Line":
        window.open(
          "https://line.me/R/ti/p/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "Facebook":
        window.open(
          "https://facebook.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "Website":
        window.open(`https://${info}`, "_blank", "noopener,noreferrer");
        return;
      case "Instagram":
        window.open(
          "https://instagram.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      default:
        return;
    }
  };

  return [
    {
      field: "unique_id",
      headerName: t("contact.document_id"),
      filter: "agTextColumnFilter",
      width: 175,
    },
    {
      field: "is_vendor",
      headerName: t("contact.contact_type.index"),
      filter: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "is_customer",
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "name",
      headerName: "ชื่อกิจการ/ชื่อ นามสกุล",
      cellRenderer: ({ data }) => {
        if (data.contact_type === "นิติบุคคล") {
          return data.name;
        }
        return `${data.name} ${data.last_name ? data.last_name : ""}`;
      },
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      field: "created_date",
      headerName: t("sales.order.created_date"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      // filterParams: {
      //   filterOptions: filterParamsOptions("date"),
      //   comparator: isImport ? dateComparator : undefined,
      // },
      width: 200,
    },
    {
      field: "contact_channel_list",
      headerName: t("contact.contact_channel.channel"),
      filter: false,
      // filterParams: {
      //   textMatcher: ({ data, filterText }) => {
      //     const includesText = data.contact_channel_list.some((channel) => {
      //       return (
      //         channel.contact_channel.includes(filterText) ||
      //         channel.contact_info_1.includes(filterText)
      //       );
      //     });
      //     return includesText;
      //   },
      // },
      cellRenderer: (params) => {
        let valueToRender = params.value;
        if (params.value?.length > 0 && !params.value[0].contact_channel_name) {
          valueToRender = valueToRender.slice(1);
        }
        return valueToRender?.map((channel, index) => (
          <Box
            sx={{ display: "inline-block", mr: 1 }}
            key={`${channel.contact_channel_type}-${index}`}
          >
            <CustomizedChips
              onClick={() =>
                contactChannelAction(
                  channel.contact_channel_type,
                  channel.contact_channel_name
                )
              }
              value={`${channel.contact_channel_type}: ${channel.contact_channel_name}`}
            />
          </Box>
        ));
      },
    },
    {
      field: "contact_type",
      headerName: t("contact.contact_type.index"),
      filter: "agTextColumnFilter",
      width: 160,
    },
    {
      field: "business_group_field",
      headerName: t("contact.business_group"),
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      field: "customer_quality",
      headerName: t("contact.customer_quality"),
      filter: "agTextColumnFilter",
      width: 150,
    },

    // {
    //   field: "tag_list",
    //   headerName: "การจัดกลุ่ม",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     values: ["active", "inactive"],
    //   },
    //   cellRenderer: (params) => {
    //     return params.value?.map((tag) => (
    //       <Box
    //         sx={{ display: "inline-block", mr: 1 }}
    //         key={`${tag.name}-${tag.id}`}
    //       >
    //         <CustomizedChips
    //           value={tag.name}
    //           onClick={() => tagListClickHandler(tag.name)}
    //         />
    //       </Box>
    //     ));
    //   },
    // },
    {
      field: "is_active",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ใช้งาน", "หยุดใช้งาน"],
      },
      cellRenderer: ({ value }) => {
        return <CustomizedStatus status={value ? "active" : "inactive"} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];
};
