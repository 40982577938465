import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "./Header";
import OpenManufactureForm from "../../../../components/Form/Order/OpenManufactureForm";
import SalesDetailForm from "../../../../components/Form/Order/SalesDetailForm";
import CustomerForm from "../../../../components/Form/Order/CustomerForm";
import DeliveryDetailForm from "../../../../components/Form/Order/DeliveryDetailForm";
import SummaryForm from "../../../../components/Form/Order/SummaryForm";
import CancelDetailForm from "../../../../components/Form/Order/CancelDetailForm";
import { Box } from "@mui/material";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import { useEffect, useState, useRef } from "react";
import SummaryTemplateForm from "../../../../components/Form/Order/SummaryTemplateForm";
import GlobalService from "../../../../services/Global";
import SOItemTable from "../../../../components/Table/SOItemTable";
import { formatSalesOrderQuery } from "../../../../utils/salesPayloadFormatter";
import { useAuth } from "../../../../hooks/use-auth";
import { useSnackbar } from "notistack";

const DocumentTab = ({
  documentTabUseForm,
  saveDraftHandler,
  sendToCustomer,
  saveDocumentHandler,
  existingItemIds,
  setIsInit,
  currentState,
}) => {
  const customerDetailFormRef = useRef();
  const cancelDetailFormRef = useRef();
  const { salesOrder, isLoading } = useSelector((state) => state.salesOrder);
  const [disabled, setDisabled] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [summaryTemplate, setSummaryTemplate] = useState("");
  const { permissions } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const salesPermission = permissions.sales;

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
    reset,
  } = documentTabUseForm;

  const watchStatus = useWatch({
    control,
    name: "status",
  });

  const watchPendingCancel = useWatch({
    control,
    name: "pending_cancel",
  });

  useEffect(() => {
    switch (watchStatus) {
      case "wait_customer_accept":
      case "wait_payment_when_receive":
      case "wait_payment_cod":
      case "partially_paid":
      case "fully_paid":
      case "finished":
      case "cancelled":
        setDisabled(true);
        break;
      default:
        setDisabled(false);
    }
  }, [watchStatus]);

  useEffect(() => {
    if (salesPermission === "VIEW__ONLY") {
      setDisabled(true);
    }
  }, [salesPermission]);

  const openSummaryModalHandler = () => {
    setOpenSummaryModal(true);
  };

  const closeSummaryModalHandler = () => {
    setOpenSummaryModal(false);
    setValue("summary_template", "");
    setSummaryTemplate("");
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    reset(formatSalesOrderQuery(salesOrder));
    setDisabled(true);
  };

  const onError = (data) => {
    const customerIsInvalid = data.contact_unique_id;
    const deliveryAddressIsInvalid = data.delivery_address;
    const itemListError = data.item_list;

    if (customerIsInvalid) {
      enqueueSnackbar(customerIsInvalid.message, {
        variant: "error",
      });
    }

    if (deliveryAddressIsInvalid) {
      enqueueSnackbar(deliveryAddressIsInvalid.message, {
        variant: "error",
      });
    }

    if (itemListError) {
      enqueueSnackbar("กรุณาระบุราคาขาย/หน่วย", {
        variant: "error",
      });
    }

    if (customerIsInvalid || deliveryAddressIsInvalid) {
      customerDetailFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToCancelDetailForm = () => {
    setTimeout(() =>
      cancelDetailFormRef.current.scrollIntoView({ behavior: "smooth" })
    ),
      100;
    trigger();
  };

  return (
    <form>
      <Header
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        editClickHandler={editClickHandler}
        openSummaryModalHandler={openSummaryModalHandler}
        setIsInit={setIsInit}
        currentState={currentState}
        documentType="order"
        scrollToCancelDetailForm={scrollToCancelDetailForm}
      />
      <OpenManufactureForm
        control={control}
        errors={errors}
        disabled={disabled || !watchStatus || watchStatus === "draft"}
      />
      <SalesDetailForm
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
      />
      <CustomerForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        ref={customerDetailFormRef}
      />
      <DeliveryDetailForm
        control={control}
        errors={errors}
        disabled={disabled}
      />
      <SOItemTable
        control={control}
        setValue={setValue}
        errors={errors}
        disabled={disabled}
        existingItemIds={existingItemIds}
      />
      <SummaryForm
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
      />
      <CancelDetailForm
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
        ref={cancelDetailFormRef}
      />
      {!disabled && !isEdit && (
        <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
          <CustomizedButton
            title="บันทึกร่าง"
            variant="outlined"
            onClick={handleSubmit(saveDraftHandler, onError)}
            disabled={isLoading.salesOrder}
          />
          <CustomizedButton
            title="บันทึก"
            variant="contained"
            onClick={handleSubmit(sendToCustomer, onError)}
            disabled={isLoading.salesOrder}
          />
        </Box>
      )}
      {(watchPendingCancel || (!disabled && isEdit)) && (
        <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={cancelEditHandler}
            disabled={isLoading.salesOrder}
          />
          <CustomizedButton
            title="บันทึก"
            variant="contained"
            onClick={handleSubmit(saveDocumentHandler, onError)}
            disabled={isLoading.salesOrder}
          />
        </Box>
      )}
      <SummaryTemplateForm
        openSummaryModal={openSummaryModal}
        closeSummaryModalHandler={closeSummaryModalHandler}
        control={control}
        errors={errors}
        disabled={disabled}
        summaryTemplate={summaryTemplate}
        setSummaryTemplate={setSummaryTemplate}
      />
    </form>
  );
};

export default DocumentTab;
