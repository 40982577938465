import { gql } from "graphql-request";

export const GET_SALES_ORDER = gql`
  query Order($uniqueInput: OrderUniqueInput!) {
    order(uniqueInput: $uniqueInput) {
      id
      unique_id
      created_date
      payment_date
      due_date
      delivery_date
      receive_date
      sales_channel
      account_id
      brand_name
      contact_unique_id
      contact_name
      creator_unique_id
      created_by {
        unique_id
        first_name
        last_name
        img_url
      }
      contact {
        id
        unique_id
        is_vendor
        is_customer
        is_active
        inactive_remark
        creator_unique_id
        created_date
        identity_no
        contact_type
        name
        last_name
        title
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          name
          phone
          is_default_address
          same_as_default_address
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
        }
        tag_list
      }
      phone_main
      phone_sub
      email
      delivery_address
      tag_list
      main_status
      sub_status
      flag_status
      render_status
      is_open_manufacture
      tracking_number
      is_delivery
      item_list {
        id
        process_type
        item_name
        item_unique_id
        sku_name
        item_kind
        item_type
        block
        color
        size
        arm
        neck
        item_amount
        price_per_unit
        other_cost
        uom
        item_discount
        item_total_price
        item_remark
        order_unique_id
        img_url
        sort_index
      }
      manufacture_order_list {
        unique_id
        external_ref_document_id
        render_status
        vendor_name
        customer_name
        vendor_phone
        created_date
        payment_date
        production_date
        receive_date
        tag_list
        created_by {
          first_name
          last_name
        }
        employee_list {
          first_name
          last_name
        }
      }
      remark
      total_amount
      total_price
      total_item_discount
      additional_discount
      total_discount
      net_amount
      vat_amount
      vat_type
      price_exclude_vat
      sale_cancel_remark
      delivery_cancel_remark
      order_payment_list {
        id
        payment_count
        total_amount
        total_paid
        remaining_amount
        payment_amount
        payment_method
        payment_date_time
        bank_account
        remark
        payment_img_url
        order_unique_id
      }
      employee_list {
        unique_id
        first_name
        last_name
        img_url
      }
    }
  }
`;

export const GET_SALES_ORDERS = gql`
  query Orders {
    orders {
      id
      unique_id
      created_by {
        unique_id
        first_name
        last_name
        img_url
      }
      created_date
      payment_date
      due_date
      delivery_date
      receive_date
      sales_channel
      account_id
      brand_name
      contact_unique_id
      contact_name
      contact {
        id
        unique_id
        is_vendor
        is_customer
        is_active
        inactive_remark
        creator_unique_id
        created_date
        identity_no
        contact_type
        name
        last_name
        title
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          name
          phone
          is_default_address
          same_as_default_address
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
        }
        tag_list
      }
      phone_main
      phone_sub
      email
      delivery_address
      tag_list
      main_status
      sub_status
      flag_status
      render_status
      is_open_manufacture
      tracking_number
      is_delivery
      item_list {
        id
        item_unique_id
        item_name
        sku_name
        item_kind
        item_type
        block
        process_type
        color
        size
        arm
        neck
        item_amount
        price_per_unit
        other_cost
        uom
        item_discount
        item_total_price
        item_remark
        order_unique_id
        img_url
        sort_index
      }
      remark
      total_amount
      total_price
      total_item_discount
      additional_discount
      total_discount
      net_amount
      vat_amount
      vat_type
      price_exclude_vat
      sale_cancel_remark
      delivery_cancel_remark
      order_payment_list {
        id
        payment_count
        total_amount
        total_paid
        remaining_amount
        payment_amount
        payment_method
        payment_date_time
        bank_account
        remark
        payment_img_url
        order_unique_id
      }
      employee_list {
        unique_id
        first_name
        last_name
        img_url
      }
    }
  }
`;

export const GET_SALES_ORDERS_ITEM_ID_LIST = gql`
  query Orders {
    orders {
      render_status
      item_list {
        item_unique_id
      }
    }
  }
`;

export const GET_SALES_ORDERS_AGGRID = gql`
  query OrdersAggrid($aggridInput: AggridOrderInput!) {
    ordersAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        created_by {
          unique_id
          first_name
          last_name
          img_url
        }
        payment_date
        due_date
        delivery_date
        receive_date
        sales_channel
        account_id
        brand_name
        contact_unique_id
        contact_name
        contact {
          id
          unique_id
          is_vendor
          is_customer
          is_active
          inactive_remark
          creator_unique_id
          created_date
          identity_no
          contact_type
          name
          last_name
          title
          gender
          main_interested_field
          secondary_interested_field
          business_group_field
          business_type_field
          customer_quality
          contact_remark
          contact_channel_list {
            contact_channel_type
            contact_channel_name
          }
          address_list {
            address_type
            name
            phone
            is_default_address
            same_as_default_address
            address_number
            building
            sub_district
            district
            province
            postal_code
            zone
            country
          }
          tag_list
        }
        phone_main
        phone_sub
        email
        delivery_address
        tag_list
        main_status
        sub_status
        flag_status
        render_status
        is_open_manufacture
        tracking_number
        is_delivery
        item_list {
          id
          item_unique_id
          process_type
          item_name
          sku_name
          item_kind
          item_type
          block
          color
          size
          arm
          neck
          item_amount
          price_per_unit
          other_cost
          uom
          item_discount
          item_total_price
          item_remark
          order_unique_id
          img_url
          sort_index
        }
        manufacture_order_list {
          unique_id
          vendor_name
          customer_name
          vendor_phone
          created_date
          payment_date
          production_date
          receive_date
          tag_list
          created_by {
            first_name
            last_name
          }
          employee_list {
            first_name
            last_name
          }
        }
        remark
        total_amount
        total_price
        total_item_discount
        additional_discount
        total_discount
        net_amount
        vat_amount
        vat_type
        price_exclude_vat
        sale_cancel_remark
        delivery_cancel_remark
        order_payment_list {
          id
          payment_count
          total_amount
          total_paid
          remaining_amount
          payment_amount
          payment_method
          payment_date_time
          bank_account
          remark
          payment_img_url
          order_unique_id
        }
        employee_list {
          unique_id
          first_name
          last_name
          img_url
        }
      }
    }
  }
`;

export const GET_SALES_ORDER_PAYMENT = gql`
  query OrderPayment($uniqueInput: OrderPaymentUniqueInput!) {
    orderPayment(uniqueInput: $uniqueInput) {
      id
      payment_count
      total_amount
      total_paid
      remaining_amount
      payment_amount
      payment_method
      payment_date_time
      bank_account
      remark
      payment_img_url
      order_unique_id
    }
  }
`;

export const GET_SALES_ORDER_PAYMENTS = gql`
  query OrderPayments {
    orderPayments {
      id
      payment_count
      total_amount
      total_paid
      remaining_amount
      payment_amount
      payment_method
      payment_date_time
      bank_account
      remark
      payment_img_url
      order_unique_id
    }
  }
`;

export const GET_SALES_ORDER_PAYMENTS_AGGRID = gql`
  query OrderPaymentsAggrid($aggridInput: AggridOrderPaymentInput!) {
    orderPaymentsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        payment_count
        total_amount
        total_paid
        remaining_amount
        payment_amount
        payment_method
        payment_date_time
        bank_account
        remark
        payment_img_url
        order_unique_id
      }
    }
  }
`;

export const GET_SALES_ORDER_PAYMENTS_EXPORT_AGGRID = gql`
  query OrdersAggrid($aggridInput: AggridOrderInput!) {
    ordersAggrid(aggridInput: $aggridInput) {
      results {
        created_date
        unique_id
        order_payment_list {
          id
          payment_count
          total_amount
          total_paid
          remaining_amount
          payment_amount
          payment_method
          payment_date_time
          bank_account
          remark
          payment_img_url
          order_unique_id
        }
      }
    }
  }
`;

export const GET_SALES_ORDER_TAGS = gql`
  query Orders {
    orders {
      unique_id
      tag_list
    }
  }
`;
