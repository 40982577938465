import { gql } from "graphql-request";

export const CREATE_USER = gql`
  mutation UserCreate($createInput: CreateUserInput!) {
    userCreate(createInput: $createInput) {
      unique_id
      title_name
      position
      phone
      nick_name
      last_name
      inactive_remarks
      img_url
      first_name
      email
      department
      created_date
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UserUpdate(
    $uniqueInput: UserWhereUniqueInput!
    $updateInput: UserUpdateInput!
  ) {
    userUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      unique_id
      title_name
      position
      phone
      nick_name
      last_name
      inactive_remarks
      img_url
      first_name
      email
      department
      last_updated_date
    }
  }
`;

export const DISABLE_USER = gql`
  mutation UserUpdate($uniqueInput: UserWhereUniqueInput!) {
    userDisable(uniqueInput: $uniqueInput) {
      unique_id
      is_user_active
      inactive_remarks
      last_updated_date
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation RoleCreate($createInput: RoleCreateInput!) {
    roleCreate(createInput: $createInput) {
      id
      unique_id
      name
      description
      permission_list {
        role_unique_id
        access_key_name
        access_value
        description
      }
      user_list {
        id
        unique_id
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation RoleUpdate(
    $uniqueInput: RoleWhereUniqueInput!
    $updateInput: RoleUpdateInput!
  ) {
    roleUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      unique_id
      name
      description
      permission_list {
        role_unique_id
        access_key_name
        access_value
        description
      }
      user_list {
        id
        unique_id
        first_name
        last_name
      }
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation RoleDelete($uniqueInput: RoleWhereUniqueInput!) {
    roleDelete(uniqueInput: $uniqueInput) {
      id
      unique_id
      name
      description
      permission_list {
        role_unique_id
        access_key_name
        access_value
        description
      }
      user_list {
        id
        unique_id
        first_name
        last_name
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation UserDelete($uniqueInput: UserWhereUniqueInput!) {
    userDelete(uniqueInput: $uniqueInput) {
      unique_id
    }
  }
`;
