import { Box, Typography, useMediaQuery, Link } from "@mui/material";
import LoginForm from "../components/Form/Login";
import { COMPANY_NAME, CURRENT_VERSION } from "../config/variables";

const Login = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return !isMobile ? (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        overflowY: "hidden",
        background:
          "linear-gradient(116.58deg, #36458C 10.99%, #C4CAE4 50.84%, #FFFFFF 60%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "inherit",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="login" src="/static/loginBg.svg" />
        </Box>
        <Box
          sx={{
            borderRadius: "40px 0 0 40px",
            background: "white",
            display: "flex",
            flex: 1,
            height: "inherit",
            alignItems: "center",
            flexDirection: "column",
            p: "2rem",
            justifyContent: "center",
            padding: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              flex: 15,
            }}
          >
            <img alt="logo" src="/static/logo.png" style={{ width: 300 }} />
            <Typography variant="h4" sx={{ mb: "2.5rem" }}>
              เข้าสู่ระบบ
            </Typography>
            <LoginForm />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 8,
            }}
          >
            <Link
              rel="noopener noreferrer"
              href="https://doc.clickup.com/3654689/p/h/3fh11-57162/7dccea7012a1e75"
              underline="hover"
              target="_blank"
              sx={{
                fontSize: 14,
              }}
            >
              นโยบายความเป็นส่วนตัว
            </Link>
          </Box>
          <Box sx={{ width: "100%", pt: "2rem", flex: 1 }}>
            <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
              Powered by {COMPANY_NAME}
            </Typography>
            <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
              Application version {CURRENT_VERSION}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        background: "white",
        display: "flex",
        height: "100vh",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        p: 4,
        justifyContent: "center",
      }}
    >
      <img alt="logo" src="/static/logo.png" style={{ width: 250 }} />
      <Typography variant="h4" sx={{ mb: "2.5rem" }}>
        เข้าสู่ระบบ
      </Typography>
      <LoginForm isMobile={isMobile} />
      <Box sx={{ width: "100%", pt: 4 }}>
        <Box
          sx={{
            width: "100%",
            py: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            rel="noopener noreferrer"
            href="https://docs.google.com/document/d/1pwTbjIgi2LL5SUfbnYYtQRBPBTp_Gb63iLD9jThoGPo/edit?usp=sharing"
            underline="hover"
            target="_blank"
            sx={{
              fontSize: 14,
            }}
          >
            นโยบายความเป็นส่วนตัว
          </Link>
        </Box>
        <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
          Powered by {COMPANY_NAME}
        </Typography>
        <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
          Application version {CURRENT_VERSION}
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
