import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, IconButton, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { StyledMenu } from "../../../Custom/CustomizedMenuOption";

const NewContactCard = ({
  onEdit,
  onRemove,
  index,
  name,
  position,
  phone,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const [anchorPos, setAnchorPos] = useState(null);
  const showActions = Boolean(anchorPos);

  const openActionsHandler = (event) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const editHandler = () => {
    setAnchorPos(null);
    onEdit(index);
  };

  return (
    <>
      <StyledMenu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{}}
      >
        {!viewOnly && (
          <MenuItem onClick={editHandler}>{viewOnly ? "ดู" : "แก้ไข"}</MenuItem>
        )}
        {!viewOnly && (
          <MenuItem onClick={() => onRemove(index)}>
            {t("contact.utility.delete")}
          </MenuItem>
        )}
      </StyledMenu>
      <CustomizedBox
        sx={{ py: "1.5rem", px: "2rem", border: "1px solid #E3E3E3" }}
        boxShadow="none"
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">{name}</Typography>
          </Box>
          {!viewOnly && (
            <IconButton
              onClick={openActionsHandler}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>

        <Box sx={{ display: "flex", mt: 2 }}>
          <Typography
            variant="body2"
            sx={{ mr: 2, color: (theme) => theme.palette.grey[700] }}
          >
            {t("contact.contact_person.department")}: {position || "-"}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{ my: 2, color: (theme) => theme.palette.grey[700] }}
        >
          {t("contact.contact_person.phone")}: {phone || "-"}
        </Typography>
      </CustomizedBox>
    </>
  );
};

export default NewContactCard;
