import { Box, Divider, Grid, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/dataTransformer";
import ControlledTextField from "../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../Custom/CustomizedBox";

const SummaryForm = ({ control, errors, disabled, isReport }) => {
  const { t } = useTranslation();

  const watchItemList = useWatch({
    control,
    name: "item_list",
    defaultValue: [],
  });

  const watchRemark = useWatch({ control, name: "remark" });

  const sumAllPrintingCost = () => {
    const sumAllPrintingCost = watchItemList?.reduce(
      (prev, curr) =>
        parseFloat(prev) +
        parseFloat(curr.printing_cost ?? 0) * parseInt(curr.amount ?? 0),
      0
    );
    if (isNaN(sumAllPrintingCost)) return 0;
    return sumAllPrintingCost;
  };

  const sumAllHotRolledCost = () => {
    const sumAllHotRolled = watchItemList?.reduce(
      (prev, curr) =>
        parseFloat(prev) +
        parseFloat(curr.hot_rolled_cost ?? 0) * parseInt(curr.amount ?? 0),
      0
    );
    if (isNaN(sumAllHotRolled)) return 0;
    return sumAllHotRolled;
  };

  const sumAllFoldCost = () => {
    const sumAllFoldCost = watchItemList?.reduce(
      (prev, curr) =>
        parseFloat(prev) +
        parseFloat(curr.fold_cost ?? 0) * parseInt(curr.amount ?? 0),
      0
    );
    if (isNaN(sumAllFoldCost)) return 0;
    return sumAllFoldCost;
  };

  const sumAllTotalCost = () => {
    const sumAllTotalCost =
      sumAllPrintingCost() + sumAllHotRolledCost() + sumAllFoldCost();
    return sumAllTotalCost;
  };

  const sumAllOtherCost = () => {
    const sumAllOtherCost = watchItemList?.reduce(
      (prev, curr) =>
        parseFloat(prev) +
        parseFloat(curr.other_cost || 0) * parseInt(curr.amount ?? 0),
      0
    );
    return sumAllOtherCost;
  };

  const sumAllCost = () => {
    const sumAllCost = sumAllTotalCost() + sumAllOtherCost();
    return sumAllCost;
  };

  return (
    <>
      {isReport ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 12,
                letterSpacing: "0.15px",
                color: "#333333",
              }}
            >
              {t("sales.remark")}
            </Typography>
            <Typography sx={{ fontSize: 12 }}>{watchRemark}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  my: 1,
                  mx: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  ราคาทุนพิมพ์รวม
                </Typography>
                <Typography
                  sx={{
                    width: "170px",
                    textAlign: "right",
                    mr: 4,
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  {formatNumber(sumAllPrintingCost())}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  บาท
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  my: 1,
                  mx: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  ราคาทุนทับร้อนรวม
                </Typography>
                <Typography
                  sx={{
                    width: "170px",
                    textAlign: "right",
                    mr: 4,
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  {formatNumber(sumAllHotRolledCost())}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  บาท
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  my: 1,
                  mx: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  ราคาทุนพับรวม
                </Typography>
                <Typography
                  sx={{
                    width: "170px",
                    textAlign: "right",
                    mr: 4,
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  {formatNumber(sumAllFoldCost())}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  บาท
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  my: 1,
                  mx: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  ราคาทุนผลิตรวม
                </Typography>
                <Typography
                  sx={{
                    width: "170px",
                    textAlign: "right",
                    mr: 4,
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  {formatNumber(sumAllTotalCost())}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  บาท
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  my: 1,
                  mx: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  ราคาทุนอื่นๆ รวม
                </Typography>
                <Typography
                  sx={{
                    width: "170px",
                    textAlign: "right",
                    mr: 4,
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  {formatNumber(sumAllOtherCost())}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  บาท
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  backgroundColor: "#EFF2FF",
                  my: 1,
                  p: 1,
                }}
              >
                <Typography
                  fontWeight={600}
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  จำนวนเงินรวมทั้งสิ้น
                </Typography>
                <Typography
                  sx={{
                    width: "170px",
                    textAlign: "right",
                    mr: 4,
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  {formatNumber(sumAllCost())}
                </Typography>
                <Typography
                  fontWeight={600}
                  sx={{
                    fontSize: 12,
                    letterSpacing: "0.15px",
                    color: "#333333",
                  }}
                >
                  บาท
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <CustomizedBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography ml={1} mb={2} fontWeight="bold">
                {t("sales.remark")}
              </Typography>
              <ControlledTextField
                name="remark"
                control={control}
                error={Boolean(errors?.remark)}
                helperText={errors?.remark && errors?.remark?.message}
                multiline
                rows={4}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    my: 3,
                    mx: 1,
                  }}
                >
                  <Typography>ราคาทุนพิมพ์รวม</Typography>
                  <Typography
                    sx={{ width: "170px", textAlign: "right", mr: 4 }}
                  >
                    {formatNumber(sumAllPrintingCost())}
                  </Typography>
                  <Typography>บาท</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    my: 3,
                    mx: 1,
                  }}
                >
                  <Typography>ราคาทุนทับร้อนรวม</Typography>
                  <Typography
                    sx={{ width: "170px", textAlign: "right", mr: 4 }}
                  >
                    {formatNumber(sumAllHotRolledCost())}
                  </Typography>
                  <Typography>บาท</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    my: 3,
                    mx: 1,
                  }}
                >
                  <Typography>ราคาทุนพับรวม</Typography>
                  <Typography
                    sx={{ width: "170px", textAlign: "right", mr: 4 }}
                  >
                    {formatNumber(sumAllFoldCost())}
                  </Typography>
                  <Typography>บาท</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    my: 3,
                    mx: 1,
                  }}
                >
                  <Typography>ราคาทุนผลิตรวม</Typography>
                  <Typography
                    sx={{ width: "170px", textAlign: "right", mr: 4 }}
                  >
                    {formatNumber(sumAllTotalCost())}
                  </Typography>
                  <Typography>บาท</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    my: 3,
                    mx: 1,
                  }}
                >
                  <Typography>ราคาทุนอื่นๆ รวม</Typography>
                  <Typography
                    sx={{ width: "170px", textAlign: "right", mr: 4 }}
                  >
                    {formatNumber(sumAllOtherCost())}
                  </Typography>
                  <Typography>บาท</Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    backgroundColor: "#EFF2FF",
                    my: 2,
                    p: 1,
                  }}
                >
                  <Typography fontWeight={600}>จำนวนเงินรวมทั้งสิ้น</Typography>
                  <Typography
                    sx={{
                      width: "170px",
                      textAlign: "right",
                      mr: 4,
                      fontWeight: 600,
                    }}
                  >
                    {formatNumber(sumAllCost())}
                  </Typography>
                  <Typography fontWeight={600}>บาท</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CustomizedBox>
      )}
    </>
  );
};

export default SummaryForm;
