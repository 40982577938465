import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Link,
} from "@mui/material";
import styled from "@emotion/styled";
import { useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { useCallback, useRef, useState } from "react";
import ModalUI from "../../UI/ModalUI";
import ControlledSelect from "../../Controlled/ControlledSelect";
import { useWatch } from "react-hook-form";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { contactColumnDefs } from "../../Table/ColumnDefs/Contact";
import CheckboxModalTable from "../../Table/CheckboxModalTable";
import ContactService from "../../../services/Contact";
import ControlledServerSideCreatable from "../../Controlled/ControlledSSCreatable";
import ControlledNumberTextField from "../../Controlled/ControlledNumberTextField";
import ContactFormModalUI from "../Order/ContactModalUI";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const CustomerForm = forwardRef(
  ({ control, errors, disabled, setValue, getValues, id, state }, ref) => {
    const selectCustomerGridRef = useRef();
    const { t } = useTranslation();

    const [contactFormModal, setContactFormModal] = useState(false);
    const [showSelectCustomer, setShowSelectCustomer] = useState(false);

    const [customerIds, setCustomerIds] = useState([]);
    const [customerIdsSnapshot, setCustomerIdsSnapshot] = useState([]);
    const [isInit, setIsInit] = useState(false);
    const [customerError, setCustomerError] = useState(false);

    const [addressOptions, setAddressOptions] = useState([
      {
        label: <em>เลือกที่อยู่</em>,
        value: "",
      },
      {
        label: t("sales.order.main_address"),
        value: t("sales.order.main_address"),
      },
      {
        label: t("sales.order.delivery_address"),
        value: t("sales.order.delivery_address"),
      },
    ]);

    const watchAddress = useWatch({
      control,
      name: "delivery_address",
    });

    const watchCustomerId = useWatch({ control, name: "customer_unique_id" });

    const [allAddress, setAllAddress] = useState([]);

    const openContactFormHandler = () => {
      if (!watchCustomerId) {
        setCustomerError(true);
        return;
      }
      setContactFormModal(true);
    };

    const closeContactFormHandler = () => {
      setContactFormModal(false);
    };

    const openCustomerTable = () => {
      setShowSelectCustomer(true);
    };

    const closeCustomerTable = () => {
      setShowSelectCustomer(false);
    };

    const datasource = {
      async getRows(params) {
        const request = params.request;
        const isActiveFilter = request.filterModel.is_active;
        const activeFilterValue =
          isActiveFilter?.values[0] === "active" ? "true" : "false";
        const agGridOptions = {
          startRow: request.startRow,
          endRow: request.endRow,
          filterModel: {
            ...request.filterModel,
            unique_id: {
              ...request.filterModel.unique_id,
              mode: "insensitive",
            },
            is_customer: {
              filterType: "boolean",
              type: "equals",
              filter: "true",
            },
            is_active:
              isActiveFilter && isActiveFilter?.values?.length === 1
                ? {
                    filterType: "boolean",
                    type: "equals",
                    filter: activeFilterValue,
                  }
                : undefined,
            tag_list: request.filterModel.tag_list
              ? {
                  filterType: "objectArray",
                  type: "some",
                  filter: {
                    name: {
                      filter: request.filterModel.tag_list.filter,
                      filterType: "text",
                      type: "contains",
                      mode: "insensitive",
                    },
                  },
                }
              : undefined,
            business_group_field: {
              ...request.filterModel.business_group_field,
              mode: "insensitive",
            },
            customer_quality: {
              ...request.filterModel.customer_quality,
              mode: "insensitive",
            },
          },
          sortModel: request.sortModel,
        };
        try {
          const allContacts = await ContactService.getAllContactsAgGrid(
            agGridOptions,
            params
          );
          params.successCallback(allContacts.results, allContacts.count);
        } catch (err) {
          console.log(err);
          params.failCallback();
        }
      },
    };

    const onGridReady = (params) => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
      params.api.setServerSideDatasource(datasource);
    };

    const setCustomerHandler = (data, isDataFirstLoad) => {
      if (data) {
        setValue("customer", data);
        const {
          unique_id,
          contact_type,
          name,
          last_name,
          contact_channel_list,
          address_list,
        } = data;

        const formatContactName = () => {
          if (contact_type === "นิติบุคคล" || !last_name) {
            return name;
          }
          return `${name} ${last_name}`;
        };

        if (!isDataFirstLoad) {
          const filteredPhoneList = contact_channel_list.filter(
            (list) => list.contact_channel_type === "เบอร์โทรศัพท์"
          );

          const foundEmail = contact_channel_list.find((list) =>
            ["Email", "E-mail", "E-Mail"].includes(list.contact_channel_type)
          );
          setValue("customer_unique_id", unique_id);
          setValue("customer_name", formatContactName());

          //TODO: Adjusted by requirement spec
          if (filteredPhoneList.length === 1) {
            setValue(
              "customer_phone_main",
              filteredPhoneList[0]?.contact_channel_name ?? ""
            );
            setValue("customer_phone_sub", "");
          } else if (filteredPhoneList.length >= 2) {
            setValue(
              "customer_phone_main",
              filteredPhoneList[0]?.contact_channel_name ?? ""
            );
            setValue(
              "customer_phone_sub",
              filteredPhoneList[1]?.contact_channel_name ?? ""
            );
          }

          if (foundEmail) {
            setValue("customer_email", foundEmail.contact_channel_name);
          } else {
            setValue("customer_email", "");
          }

          setValue("delivery_address", "");
        }

        if (address_list.length > 2) {
          setAddressOptions([
            {
              label: <em>เลือกที่อยู่</em>,
              value: "",
            },
            {
              label: t("sales.order.main_address"),
              value: t("sales.order.main_address"),
            },
            {
              label: t("sales.order.delivery_address"),
              value: t("sales.order.delivery_address"),
            },
            {
              label: address_list[2].address_type,
              value: address_list[2].address_type,
            },
          ]);
        } else {
          setAddressOptions([
            {
              label: <em>เลือกที่อยู่</em>,
              value: "",
            },
            {
              label: t("sales.order.main_address"),
              value: t("sales.order.main_address"),
            },
            {
              label: t("sales.order.delivery_address"),
              value: t("sales.order.delivery_address"),
            },
          ]);
        }
        setAllAddress(address_list);
      } else {
        setValue("customer_unique_id", "");
        setValue("customer_name", "");
        setValue("customer_phone_main", "");
        setValue("customer_phone_sub", "");
        setValue("customer_email", "");
        setValue("delivery_address", "");
        setAllAddress([]);
        setAddressOptions([
          {
            label: <em>เลือกที่อยู่</em>,
            value: "",
          },
          {
            label: t("sales.order.main_address"),
            value: t("sales.order.main_address"),
          },
          {
            label: t("sales.order.delivery_address"),
            value: t("sales.order.delivery_address"),
          },
        ]);
      }
      setShowSelectCustomer(false);
      setCustomerError(false);
    };

    const openAddNewCustomerModal = () => {
      setCustomerIds([]);
      setCustomerIdsSnapshot([]);
      setCustomerHandler();
      setShowSelectCustomer(false);
      setContactFormModal(true);
    };

    useEffect(() => {
      if (!isInit) {
        if (id || state) {
          const customer = getValues("customer");
          if (customer) {
            setCustomerHandler(customer, true);
            setCustomerIds([customer.unique_id]);
            setCustomerIdsSnapshot([customer.unique_id]);
            setIsInit(true);
          } else if (state?.contact) {
            setCustomerHandler(state.contact, true);
            setCustomerIds([state.contact.unique_id]);
            setCustomerIdsSnapshot([state.contact.unique_id]);
            setIsInit(true);
          }
        }
      }
    }, [id, state, watchCustomerId]);

    const getRowId = useCallback((params) => {
      return params.data.unique_id;
    }, []);

    const renderAddressString = (values) => {
      const orderedValues = {
        address_number: values?.address_number,
        building: values?.building,
        sub_district: values?.sub_district,
        district: values?.district,
        province: values?.province,
        postal_code: values?.postal_code,
        zone: values?.zone,
        country: values?.country,
      };
      let result = "";
      if (values) {
        const keys = Object.keys(orderedValues);
        keys.forEach((key) => {
          if (values[key]?.length > 0) {
            if (key === "address_type" || key === "name" || key === "phone") {
              return;
            } else {
              result = result + values[key] + ", ";
            }
          }
        });
      }

      if (result?.trim().length === 0) {
        return "-";
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
    };

    return (
      <CustomizedBox ref={ref}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography sx={{ ml: 1, fontWeight: "bold" }}>
            {t("sales.order.customer_detail")}
          </Typography>
          {!disabled && (
            <CustomizedTooltip title="เลือกลูกค้า">
              <IconButton
                onClick={openCustomerTable}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.contact_unique_id")}
              control={control}
              name="customer_unique_id"
              disabled={true}
              error={Boolean(errors?.customer_unique_id) || customerError}
              helperText={
                (errors?.customer_unique_id &&
                  errors?.customer_unique_id.message) ||
                (customerError && "กรุณาเลือกลูกค้า")
              }
              sx={{
                input: { visibility: disabled ? "hidden" : "visible" },
              }}
              InputProps={{
                startAdornment: disabled && (
                  <InputAdornment position="start">
                    <StyledLink onClick={openContactFormHandler}>
                      {watchCustomerId || "-"}
                    </StyledLink>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!disabled && (
                      <IconButton
                        onClick={openContactFormHandler}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.contact_name")}
              control={control}
              name="customer_name"
              disabled={true}
              error={Boolean(errors?.customer_name)}
              helperText={
                errors?.customer_name && errors?.customer_name.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.phone")}
              control={control}
              name="customer_phone_main"
              disabled={disabled}
              error={Boolean(errors?.customer_phone_main)}
              helperText={
                errors?.customer_phone_main &&
                errors?.customer_phone_main.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.phone")}
              control={control}
              name="customer_phone_sub"
              disabled={disabled}
              error={Boolean(errors?.customer_phone_sub)}
              helperText={
                errors?.customer_phone_sub && errors?.customer_phone_sub.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.email")}
              control={control}
              name="customer_email"
              disabled={disabled}
              error={Boolean(errors?.customer_email)}
              helperText={
                errors?.customer_email && errors?.customer_email.message
              }
            />
          </Grid>
        </Grid>
        <Typography sx={{ ml: 1, my: 2 }}>
          {t("manufacture.order.payment_detail")}
        </Typography>
        <Grid Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledNumberTextField
              label={t("manufacture.order.remaining_amount")}
              control={control}
              name="balance_cod"
              error={Boolean(errors?.balance_cod)}
              helperText={errors?.balance_cod && errors?.balance_cod?.message}
              inputProps={{
                style: { textAlign: "right" },
              }}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Typography sx={{ ml: 1, my: 2 }}>
          {t("manufacture.order.delivery_detail")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledServerSideCreatable
              name="delivery_channel"
              title={t("manufacture.order.delivery_channel")}
              control={control}
              error={Boolean(errors?.delivery_channel)}
              helperText={
                errors?.delivery_channel && errors?.delivery_channel.message
              }
              viewOnly={disabled}
              documentType="manufacture_order"
              defaultOptions={[]}
              setValue={setValue}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <ControlledSelect
                label={t("manufacture.order.delivery_address")}
                name="delivery_address"
                control={control}
                error={errors.delivery_address}
                options={addressOptions}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>
        {watchAddress && (
          <Typography sx={{ mt: 3, ml: 1 }}>
            {renderAddressString(
              allAddress.find(
                (address) => address.address_type === watchAddress
              )
            )}
          </Typography>
        )}
        <CheckboxModalTable
          getRowId={getRowId}
          modalTitle={t("contact.index")}
          btnTitle={t("button.add")}
          gridRef={selectCustomerGridRef}
          height={450}
          columnDefs={contactColumnDefs(t, false, true)}
          rowSelection="single"
          onFinishEditing={setCustomerHandler}
          modalIsOpen={showSelectCustomer}
          closeModal={closeCustomerTable}
          onGridReady={onGridReady}
          selectedIds={customerIds}
          setSelectedIds={setCustomerIds}
          idsSnapshot={customerIdsSnapshot}
          setIdsSnapshot={setCustomerIdsSnapshot}
          secondaryAction={openAddNewCustomerModal}
          secondaryActionTitle={"เพิ่มลูกค้าใหม่"}
        />
        <ModalUI
          title="ดูรายละเอียดลูกค้า"
          open={contactFormModal}
          handleClose={closeContactFormHandler}
          fullWidth
          maxWidth="md"
        >
          Contact data
        </ModalUI>
        <ContactFormModalUI
          contactId={watchCustomerId}
          modalIsOpen={contactFormModal}
          setModalIsOpen={setContactFormModal}
          closeContactForm={closeContactFormHandler}
          setCustomer={setCustomerHandler}
        />
      </CustomizedBox>
    );
  }
);

export default CustomerForm;
