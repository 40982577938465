import { gql } from "graphql-request";

export const CREATE_SALES_ORDER = gql`
  mutation OrderCreate($createInput: OrderCreateInput!) {
    orderCreate(createInput: $createInput) {
      id
      unique_id
      employee_list {
        unique_id
        first_name
        last_name
        img_url
      }
      created_date
      payment_date
      due_date
      delivery_date
      receive_date
      sales_channel
      account_id
      brand_name
      contact_unique_id
      contact_name
      contact {
        id
        unique_id
        is_vendor
        is_customer
        is_active
        inactive_remark
        creator_unique_id
        created_date
        identity_no
        contact_type
        name
        last_name
        title
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          name
          phone
          is_default_address
          same_as_default_address
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
        }
        tag_list
      }
      phone_main
      phone_sub
      email
      delivery_address
      tag_list
      main_status
      sub_status
      flag_status
      render_status
      is_open_manufacture
      tracking_number
      is_delivery
      item_list {
        id
        item_unique_id
        item_name
        sku_name
        item_kind
        item_type
        block
        process_type
        color
        size
        arm
        neck
        item_amount
        price_per_unit
        other_cost
        uom
        item_discount
        item_total_price
        item_remark
        order_unique_id
        sort_index
      }
      remark
      total_price
      total_item_discount
      additional_discount
      total_discount
      net_amount
      vat_amount
      vat_type
      price_exclude_vat
      sale_cancel_remark
      delivery_cancel_remark
      order_payment_list {
        id
        payment_count
        total_amount
        total_paid
        remaining_amount
        payment_amount
        payment_method
        payment_date_time
        bank_account
        remark
        payment_img_url
        order_unique_id
      }
      creator_unique_id
    }
  }
`;

export const UPDATE_SALES_ORDER = gql`
  mutation OrderUpdate(
    $uniqueInput: OrderUniqueInput!
    $updateInput: OrderUpdateInput!
  ) {
    orderUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      unique_id
      employee_list {
        unique_id
        first_name
        last_name
        img_url
      }
      created_date
      payment_date
      due_date
      delivery_date
      receive_date
      sales_channel
      account_id
      brand_name
      contact_unique_id
      contact_name
      contact {
        id
        unique_id
        is_vendor
        is_customer
        is_active
        inactive_remark
        creator_unique_id
        created_date
        identity_no
        contact_type
        name
        last_name
        title
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          name
          phone
          is_default_address
          same_as_default_address
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
        }
        tag_list
      }
      phone_main
      phone_sub
      email
      delivery_address
      tag_list
      main_status
      sub_status
      flag_status
      render_status
      is_open_manufacture
      tracking_number
      is_delivery
      item_list {
        id
        item_unique_id
        item_name
        sku_name
        item_kind
        item_type
        block
        process_type
        color
        size
        arm
        neck
        item_amount
        price_per_unit
        other_cost
        uom
        item_discount
        item_total_price
        item_remark
        order_unique_id
        sort_index
      }
      remark
      total_price
      total_item_discount
      additional_discount
      total_discount
      net_amount
      vat_amount
      vat_type
      price_exclude_vat
      sale_cancel_remark
      delivery_cancel_remark
      order_payment_list {
        id
        payment_count
        total_amount
        total_paid
        remaining_amount
        payment_amount
        payment_method
        payment_date_time
        bank_account
        remark
        payment_img_url
        order_unique_id
      }
    }
  }
`;
