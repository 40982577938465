import { formatFloat } from "./dataTransformer";

export const formatManufactureItemSettingsPayload = (data) => {
  return data.map((setting, index) => ({
    ...setting,
    vendor: undefined,
    hot_rolled_cost: formatFloat(setting.hot_rolled_cost),
    printing_cost: formatFloat(setting.printing_cost),
    fold_cost: formatFloat(setting.fold_cost),
    id: setting.setting_id,
    setting_index: index,
    setting_id: undefined,
  }));
};

export const formatManufactureItemSettingsQuery = (data) => {
  return data
    .map((setting) => ({
      ...setting,
      setting_id: setting.id,
    }))
    .sort((a, b) => a.setting_index - b.setting_index);
};
