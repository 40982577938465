import { gql } from "graphql-request";

export const CREATE_CONTACT = gql`
  mutation ContactCreate($createInput: CreateContactInput!) {
    contactCreate(createInput: $createInput) {
      id
      unique_id
      created_date
      # created_by {
      #   id
      #   document_id
      #   first_name
      #   last_name
      #   email
      #   department
      # }
    }
  }
`;

export const CREATE_CONTACTS = gql`
  mutation ContactsCreateMany($createManyInput: [CreateContactInput!]) {
    contactsCreateMany(createManyInput: $createManyInput) {
      errors {
        index
        unique_id
        errorMessage
        field
      }
      results {
        unique_id
      }
    }
  }
`;

export const UPSERT_CONTACTS = gql`
  mutation ContactsUpsertMany($upsertManyInput: [ContactUpsertInput!]) {
    contactsUpsertMany(upsertManyInput: $upsertManyInput) {
      errors {
        index
        unique_id
        errorMessage
        field
      }
      results {
        unique_id
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation ContactUpdate(
    $uniqueInput: ContactWhereUniqueInput!
    $updateInput: ContactUpdateInput!
  ) {
    contactUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      unique_id
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation ContactUpdate($uniqueInput: ContactWhereUniqueInput!) {
    contactDelete(uniqueInput: $uniqueInput)
  }
`;

export const CREATE_CONTACT_TAG = gql`
  mutation ContactTagCreate($createInput: ContactTagCreateInput!) {
    contactTagCreate(createInput: $createInput) {
      name
      id
    }
  }
`;

export const UPDATE_CONTACT_TAG = gql`
  mutation ContactTagUpdate(
    $uniqueInputL: ContactTagUniqueInput!
    $updateInput: ContactTagUpdateInput!
  ) {
    contactTagUpdate(uniqueInputL: $uniqueInputL, updateInput: $updateInput) {
      name
      id
    }
  }
`;

export const DELETE_CONTACT_TAG = gql`
  mutation ContactTagDelete($uniqueInput: ContactTagUniqueInput!) {
    contactTagDelete(uniqueInput: $uniqueInput) {
      id
      name
    }
  }
`;
