import CustomizedAddressBox from "../Custom/CustomizedAddressBox";
import LabelInput from "../UI/LabelInput";
import { useTranslation } from "react-i18next";

const ControlledAddressBox = ({
  name,
  type,
  disabled,
  getValues,
  setValue,
  addressValue,
  setAddressValue,
  viewMode,
  error,
  helperText,
  clearErrorsHandler,
  required,
}) => {
  const { t } = useTranslation();
  return viewMode ? (
    <LabelInput
      label={t(`contact.address.${type}`)}
      value={getValues(`${name}.${type}`)}
    />
  ) : (
    <CustomizedAddressBox
      value={addressValue}
      type={type}
      disabled={disabled}
      onChange={(_, data) => {
        setAddressValue(data);
        setValue(`${name}.sub_district`, data.sub_district);
        setValue(`${name}.district`, data.district);
        setValue(`${name}.province`, data.province);
        setValue(`${name}.postal_code`, data.postal_code);
        setValue(`${name}.zone`, data.zone);
        if (clearErrorsHandler) {
          clearErrorsHandler();
        }
      }}
      onInputChange={(value) => {
        if (value.length === 0) {
          setValue(`${name}.${type}`, value, { shouldValidate: true });
        }
      }}
      error={error}
      helperText={helperText}
      required={required}
    />
  );
};

export default ControlledAddressBox;
