import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import PermissionTab from "./PermissionTab";
import { useForm, useFieldArray } from "react-hook-form";
import { permissionValidator } from "./validator";
import { useAuth } from "../../../hooks/use-auth";
import {
  postNewRole,
  getRole,
  updateRole,
  deleteRole,
} from "../../../features/User/Rbac/rbac-action";
import { initialState } from "../../../features/User/Rbac/rbac-slice";

const RbacContainer = ({ viewOnly, addable }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions } = useAuth();
  const { roleId } = useParams();
  const { role } = useSelector((state) => state.rbac);
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const [option, setOption] = useState("");

  const userPermission = permissions.user;

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: role,
    resolver: yupResolver(permissionValidator),
  });

  const { fields, append, remove, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "user_list", // unique name for your Field Array
    keyName: "genId",
  });

  const admin = true;

  useEffect(() => {
    //get data of selected role
    if (roleId) {
      const getRolePayload = {
        unique_id: roleId,
      };
      dispatch(getRole(getRolePayload, enqueueSnackbar));
    } else {
      dispatch(getRole("init", enqueueSnackbar));
    }
  }, [dispatch, roleId]);

  useEffect(() => {
    if (role) {
      reset({
        ...role,
      });
    }
  }, [dispatch, role, setValue]);

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.rbac.index"),
      to: "/user/rbac",
    },
    {
      name: viewOnly ? role.name : t("user.rbac.addNewRole"),
    },
  ];

  const onResetForm = () => {
    if (role) {
      reset({
        ...role,
      });
    } else {
      reset({ ...initialState });
    }
  };

  const onRoleSubmit = (data, type) => {
    if (type !== "triggerRevalidate") {
      const payload = {
        name: data.name,
        description: data.description,
        permission_list: data.permission_list,
      };
      if (admin && viewOnly) {
        //case : update existing role
        const updateRolePayload = {
          unique_id: data.unique_id,
          ...payload,
        };

        if (type === "updateUser") {
          //update user before delete role
          dispatch(
            updateRole(
              { unique_id: data.unique_id },
              updateRolePayload,
              enqueueSnackbar
            )
          );
        } else {
          //normal update role details
          dispatch(
            updateRole(
              { unique_id: data.unique_id },
              updateRolePayload,
              enqueueSnackbar,
              navigate
            )
          );
        }
      } else {
        //case : create new role
        dispatch(postNewRole("role", payload, navigate, enqueueSnackbar));
      }
      setOption("");
    }
  };

  const onError = () => {
    enqueueSnackbar("กรุณาระบุข้อมูล", {
      variant: "error",
    });
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onRoleDelete = (deletedRoleId) => {
    dispatch(
      deleteRole({ unique_id: deletedRoleId }, navigate, enqueueSnackbar)
    );
  };

  return (
    <>
      {userPermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit(onRoleSubmit, onError)} noValidate>
        <PermissionTab
          control={control}
          errors={errors}
          trigger={trigger}
          viewOnly={viewOnly}
          admin={admin}
          fields={fields}
          onRoleDelete={onRoleDelete}
          getValues={getValues}
          setValue={setValue}
          addable={addable}
          replace={replace}
          append={append}
          remove={remove}
          option={option}
          setOption={setOption}
          onTriggerRevalidate={handleSubmit((data) =>
            onRoleSubmit(data, "triggerRevalidate")
          )}
          permission={userPermission}
          onReset={onResetForm}
          ref={formRef}
        />
      </form>
    </>
  );
};

export default RbacContainer;
