import { forwardRef } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";
import { FormControl, FormHelperText } from "@mui/material";
// import CreditTable from "../UI/DocumentTable/CreditTable";

const CustomizedRadioGroup = forwardRef(
  (
    { onChange, value, row, radioList, name, disabled, error, helperText },
    ref
  ) => {
    return (
      <FormControl error={error}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name={name}
          value={value}
          onChange={onChange}
          row={row}
          ref={ref}
        >
          {radioList &&
            radioList.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: item.tooltip ? "row" : "column",
                  alignItems: item.tooltip ? "center" : "flex-start",
                }}
              >
                <FormControlLabel
                  value={item.value}
                  control={<Radio disabled={disabled} />}
                  label={item.label}
                  disabled={disabled}
                />
                {/* {isCreditNote &&
                item.value === "credit" &&
                value === "credit" && (
                  <CreditTable
                    control={control}
                    disabled={disabled}
                    errors={errors}
                  /> */}
                {/* )} */}
                {/* {isCreditNote &&
                item.value === "payment" &&
                value === "payment" &&
                "payment"} */}
                {item.tooltip && (
                  <CustomizedTooltip title={item.tooltip} noMaxWidth>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
                  </CustomizedTooltip>
                )}
              </Box>
            ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);

export default CustomizedRadioGroup;
