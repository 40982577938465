import CustomizedStatus from "../../../../Custom/CustomizedStatus";
import { filterParamsOptions } from "../../../../../utils/filterparams";
import {
  dateComparator,
  dateComparatorWithTime,
} from "../../../../../utils/filterparams";
import { formatDate } from "../../../../../utils/date-converter";

export const itemSkuColumnDefs = (
  t,
  isImporterPage,
  isModal,
  isClientSide,
  isMainPage,
  isReport
) => {
  if (isClientSide) {
    return [
      {
        field: "sku_name",
        headerName: t("inventory.items.documentId"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 225,
      },
      {
        field: "item_name",
        headerName: t("inventory.items.name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "created_date",
        headerName: t("sales.order.created_date"),
        sort: "asc",
        // hide: true,
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
        // valueFormatter: (params) =>
        //   params.value ? params.value.substring(0, 10) : "-",
        comparator: isMainPage ? dateComparatorWithTime : dateComparator,
        width: 150,
        valueFormatter: ({ value }) => {
          if (isMainPage) {
            return value?.substring(0, 11) ?? "";
          }
          return value;
        },
      },
      {
        field: "item_desc",
        headerName: t("inventory.items.description"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 175,
      },
      {
        field: "process_type",
        headerName: t("inventory.items.processType"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "item_type",
        headerName: t("inventory.items.type"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "item_kind",
        headerName: t("inventory.items.kind"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "sale_price",
        headerName: t("inventory.items.pricePerUnit"),
        filter: false,
        // filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("number"),
        },
        valueFormatter: (params) => {
          return typeof params.value !== "undefined" ? params.value : "-";
        },
        width: 160,
      },
      {
        field: "is_active",
        headerName: t("contact.contact_status"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ใช้งาน", "หยุดใช้งาน"],
        },
        cellRenderer: (params) => {
          if (params.value === "ใช้งาน") {
            return <CustomizedStatus status="active" />;
          }
          return <CustomizedStatus status="inactive" />;
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    ];
  }
  return [
    {
      field: "sku_name",
      headerName: t("inventory.items.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 320,
    },
    {
      field: isImporterPage ? "name" : "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    !isImporterPage && {
      field: "created_date",
      headerName: t("sales.order.created_date"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      // filterParams: {
      //   filterOptions: filterParamsOptions("date"),
      //   comparator: undefined,
      // },
      width: 150,
    },
    {
      field: isImporterPage ? "item_desc" : "item_desc",
      headerName: t("inventory.items.description"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 175,
    },
    {
      field: isImporterPage ? "process_type" : "process_type",
      headerName: t("inventory.items.processType"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    {
      field: isImporterPage ? "item_type" : "item_type",
      headerName: t("inventory.items.type"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    {
      field: isImporterPage ? "item_kind" : "item_kind",
      headerName: t("inventory.items.kind"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    !isReport
      ? {
          field: isImporterPage ? "sale_price" : "sale_price",
          headerName: t("inventory.items.pricePerUnit"),
          filter: false,
          // filter: "agTextColumnFilter",
          // filterParams: {
          //   filterOptions: filterParamsOptions("string"),
          // },
          width: 160,
        }
      : undefined,
    {
      field: "is_active",
      headerName: isReport
        ? "สถานะการใช้งานสินค้า"
        : t("contact.contact_status"),
      filter: !isModal ? "agSetColumnFilter" : false,
      filterParams: {
        // valueFormatter: (params) => {
        //   if (params.value === "active") {
        //     return "ใช้งาน";
        //   }
        //   return "หยุดใช้งาน";
        // },
        values: ["ใช้งาน", "หยุดใช้งาน"],
      },
      cellRenderer: ({ value }) => {
        return <CustomizedStatus status={value ? "active" : "inactive"} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    isReport
      ? {
          field: isImporterPage ? "sale_price" : "sale_price",
          headerName: t("inventory.items.pricePerUnit"),
          // filter: false,
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
          width: 160,
        }
      : undefined,
  ].filter((column) => column);
};
