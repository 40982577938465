import dayjs from "dayjs";
import { Box } from "@mui/system";
import { useCallback } from "react";
import CustomizedSelect from "../Custom/CustomizedSelect";
import ControlledDatePicker from "../Controlled/ControlledDatePicker";
import { Controller, useWatch } from "react-hook-form";
import { useMemo } from "react";
import { useEffect } from "react";

export default function NewReportDateFilter({
  control,
  setValue,
  getValues,
  t,
}) {
  const watchDateType = useWatch({ control, name: "dateType" });

  const filterChangedDate = useCallback(
    (startDate, endDate) => {
      setValue("date", startDate);
      setValue("dateTo", endDate);
    },
    [setValue]
  );

  const dateOption = useMemo(
    () => [
      {
        label: "วันนี้",
        value: "today",
      },
      { label: "เมื่อวาน", value: "yesterday" },
      { label: "7 วันที่แล้ว", value: "lastWeek" },
      { label: "14 วันที่แล้ว", value: "lastTwoWeek" },
      { label: "30 วันที่แล้ว", value: "last30Days" },
      { label: "เดือนนี้", value: "currentMonth" },
      { label: "เดือนที่แล้ว", value: "lastMonth" },
      { label: "3 เดือนที่แล้ว", value: "lastThreeMonth" },
      { label: "ปีนี้", value: "currentYear" },
      { label: "ปีที่แล้ว", value: "lastYear" },
    ],
    []
  );

  const onChangeDateHandle = useCallback(() => {
    const dateType = getValues("dateType");
    let startDate = new Date(dayjs().startOf("day").subtract(1, "day"));
    let endDate = new Date(dayjs().endOf("day"));
    if (dateType === "today") {
      startDate = new Date(dayjs().startOf("day"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "yesterday") {
      startDate = new Date(dayjs().startOf("day").subtract(1, "day"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "lastWeek") {
      startDate = new Date(dayjs().startOf("day").subtract(6, "day"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "lastTwoWeek") {
      startDate = new Date(dayjs().startOf("day").subtract(13, "day"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "last30Days") {
      startDate = new Date(dayjs().startOf("day").subtract(29, "day"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "currentMonth") {
      startDate = new Date(dayjs().startOf("month"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "lastMonth") {
      startDate = new Date(dayjs().startOf("month").subtract(1, "month"));
      endDate = new Date(dayjs().endOf("month").subtract(1, "month"));
    } else if (dateType === "lastThreeMonth") {
      startDate = new Date(dayjs().startOf("month").subtract(2, "month"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "currentYear") {
      startDate = new Date(dayjs().startOf("year"));
      endDate = new Date(dayjs().endOf("day"));
    } else if (dateType === "lastYear") {
      startDate = new Date(dayjs().startOf("year").subtract(1, "year"));
      endDate = new Date(dayjs().endOf("year").subtract(1, "year"));
    }
    return filterChangedDate(startDate, endDate);
  }, [filterChangedDate, getValues]);

  useEffect(() => {
    if (watchDateType) onChangeDateHandle();
  }, [onChangeDateHandle, watchDateType]);

  return (
    <Box display="flex" gap={2}>
      <Controller
        control={control}
        name={"dateType"}
        render={({ field }) => (
          <CustomizedSelect
            {...field}
            options={dateOption}
            label={t("date.range")}
          />
        )}
      />
      <ControlledDatePicker
        control={control}
        name="date"
        label={t("date.from")}
      />
      <ControlledDatePicker
        control={control}
        name="dateTo"
        label={t("date.to")}
      />
    </Box>
  );
}
