import { Controller } from "react-hook-form";
import CustomizedTextField from "../Custom/CustomizedTextField";

const ControlledTextField = ({
  name,
  label,
  control,
  multiline,
  rows,
  error,
  onChange,
  onBlur,
  helperText,
  required,
  disabled,
  type,
  testId,
  autoFocus,
  autoComplete,
  InputProps,
  sx,
}) => {
  return (
    <Controller
      key={`${name}-text-field`}
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedTextField
          sx={sx}
          fullWidth
          error={Boolean(error)}
          helperText={helperText || error?.message}
          label={label}
          {...field}
          onChange={onChange ? (e) => onChange(e, field) : field.onChange}
          type={type}
          onBlur={onBlur}
          disabled={disabled}
          required={required}
          testId={testId}
          multiline={multiline}
          rows={rows}
          autoFocus={autoFocus}
          InputProps={InputProps}
          autoComplete={autoComplete}
        />
      )}
    />
  );
};

export default ControlledTextField;
