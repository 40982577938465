import { useState, useEffect, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Grid, Box, Typography } from "@mui/material";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import CustomizedTextField from "../../components/Custom/CustomizedTextField";
import ControlledSelect from "../../components/Controlled/ControlledSelect";
import { useTranslation } from "react-i18next";
import DropzoneUI from "../../components/UI/DropzoneUI";
import { uploadFileToS3 } from "../../utils/s3";
import { useSnackbar } from "notistack";
import { useAuth } from "../../hooks/use-auth";
import { exportAsExcel } from "../../utils/exporter";
import AgGrid from "../../components/Table/AgGrid";

const ImageMassUploadPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const gridRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isExportable, setIsExportable] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesToExport, setImagesToExport] = useState([]);
  const { control, setValue, getValues } = useForm({
    defaultValues: { module: "item" },
  });

  useEffect(() => {
    if (images.length > 0) {
      setIsExportable(false);
      const imagesToSet = [];
      images.forEach((image, index) => {
        imagesToSet.push({
          no: index + 1,
          name: image.name,
          url: "",
        });
      });
      setImagesToExport(imagesToSet);
    }
  }, [images]);

  const watchModule = useWatch({ control, name: "module" });

  const uploadImages = async () => {
    const currentUploadedImages = [];
    for (const image of images) {
      try {
        const { Location } = await uploadFileToS3(
          image,
          watchModule,
          user.unique_id
        );
        currentUploadedImages.push(Location);
      } catch (err) {
        console.log("Could not upload image");
        currentUploadedImages.push("");
      }
    }
    setImagesToExport((prevImages) =>
      prevImages.map((image, index) => {
        return { ...image, url: currentUploadedImages[index] };
      })
    );
    setImages([]);
    setValue("module", "");
    setIsExportable(true);
    enqueueSnackbar("นำเข้ารูปภาพสำเร็จ", { variant: "success" });
  };

  const exportHandler = () => {
    exportAsExcel(
      imagesToExport,
      `images-exported-${watchModule}`,
      null,
      t,
      "image"
    );
  };

  const moduleOptions = [
    {
      id: 1,
      label: t("inventory.index"),
      value: "item",
    },
  ];

  const columnDefs = [
    {
      field: "no",
      headerName: "ลำดับ",
      filter: "agTextColumnFilter",
    },
    {
      field: "name",
      headerName: "ชื่อรูป",
      filter: "agTextColumnFilter",
      width: 350,
    },
    {
      field: "url",
      headerName: "URL รูป",
      filter: "agTextColumnFilter",
      width: 700,
    },
  ];

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้ารูปภาพ"}</Typography>
      </Box>
      <Grid container mb={4} spacing={2} alignItems="center">
        <Grid item xs={12} sm={8} md={3} lg={3} xl={3}>
          <ControlledSelect
            name="module"
            control={control}
            options={moduleOptions}
            label="เมนู"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: "flex" }}>
            <CustomizedButton
              sx={{ mr: 2 }}
              title="นำเข้า"
              variant="contained"
              onClick={uploadImages}
              disabled={!watchModule || images.length === 0}
            />
            <CustomizedButton
              title="ดาวน์โหลด Excel"
              variant="contained"
              onClick={exportHandler}
              disabled={!isExportable}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <DropzoneUI
            control={control}
            files={images}
            setFiles={setImages}
            setValue={setValue}
            getValues={getValues}
            noPreview
            acceptedFileType="image"
          />
        </Grid>
      </Grid>
      {imagesToExport.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            height={649}
            rowData={imagesToExport}
            groupDefaultExpanded={1}
            disableFloatingFilter={true}
          />
        </Box>
      )}
    </>
  );
};

export default ImageMassUploadPage;
