import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ControlledComboBox from "../../../Controlled/ControlledComboBox";
import ControlledSSCreatable from "../../../Controlled/ControlledSSCreatable";

const SpecificTab = ({ control, setValue, errors, viewOnly }) => {
  const { t } = useTranslation();

  const genderOptions = ["ชาย", "หญิง", "ไม่ระบุ"];

  const interestGroupOptions = [
    "แนวครอบครัว",
    "แนววิชาการ",
    "แนวช้อปปิ้ง",
    "แนวสุขภาพ",
    "แนวแฟชั่น",
  ];

  const businessGroupOptions = ["B2C", "B2B"];

  const businessTypeOptions = [
    "Broker",
    "อาหารและวัตถุดิบ",
    "ร้านค้าทั่วไป",
    "บริหารการเงิน",
    "ราชการ",
  ];

  const customerQualityOptions = [
    "VIP",
    "ทั่วไป",
    "Blacklist-ยกเลิก",
    "Blacklist-ไม่รับของ",
    "อารมณ์ร้อน",
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledComboBox
            control={control}
            name="gender"
            error={errors.gender}
            options={genderOptions}
            label={t("contact.gender")}
            disabled={viewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSSCreatable
            control={control}
            name="main_interested_field"
            documentType="contact"
            error={errors.main_interested_field}
            defaultOptions={[]}
            title={t("contact.main_interest")}
            setValue={setValue}
            viewOnly={viewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSSCreatable
            control={control}
            name="secondary_interested_field"
            documentType="contact"
            error={errors.secondary_interested_field}
            defaultOptions={[]}
            title={t("contact.secondary_interest")}
            setValue={setValue}
            viewOnly={viewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSSCreatable
            control={control}
            name="business_group_field"
            documentType="contact"
            error={errors.business_group}
            defaultOptions={[]}
            title={t("contact.business_group")}
            setValue={setValue}
            viewOnly={viewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSSCreatable
            control={control}
            name="business_type_field"
            documentType="contact"
            error={errors.business_type}
            defaultOptions={[]}
            title={t("contact.business_type")}
            setValue={setValue}
            viewOnly={viewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSSCreatable
            control={control}
            name="customer_quality"
            documentType="contact"
            error={errors.customer_quality}
            defaultOptions={[]}
            title={t("contact.customer_quality")}
            setValue={setValue}
            viewOnly={viewOnly}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SpecificTab;
