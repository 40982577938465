import { forwardRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { departmentEngToThai } from "../../utils/dataTransformer";

const CustomizedComboBox = forwardRef(
  (
    {
      options,
      label,
      sx,
      name,
      onChange,
      onInputChange,
      value,
      disabled,
      error,
      helperText,
      InputProps,
      required,
      isOptionEqualToValue,
    },
    ref
  ) => {
    return (
      <Autocomplete
        value={departmentEngToThai(value) || null}
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={(option) => option.label || option}
        isOptionEqualToValue={
          isOptionEqualToValue
            ? isOptionEqualToValue
            : (option, value) => {
                if (option.label)
                  return option.id === value.id || option.label === value;
                else return option === value;
              }
        }
        sx={sx}
        ref={ref}
        renderOption={(props, option) => {
          if (option.id || option.label) {
            return (
              <li {...props} value={option.value} key={option.id}>
                {option.label}
              </li>
            );
          } else {
            return (
              <li {...props} value={option} key={option}>
                {option}
              </li>
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={name}
            error={error}
            InputProps={{ ...params.InputProps, ...InputProps }}
            size="small"
            required={required}
            helperText={helperText}
          />
        )}
        autoComplete
        includeInputInList
        disabled={disabled}
      />
    );
  }
);

export default CustomizedComboBox;
