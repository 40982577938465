import { useCallback, useRef, useEffect, useState } from "react";
import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import NewReportDateFilter from "../../../components/UI/newReportDateFilter";
import { useTranslation } from "react-i18next";
import {
  getAllItemsExport,
  getAllItems,
} from "../../../features/Inventory/inventory-actions";
import { ItemColumnDefs } from "../../../components/Table/ColumnDefs/Inventory/Items";
import { itemSkuColumnDefs } from "../../../components/Table/ColumnDefs/Inventory/Items/sku";
import AgGrid from "../../../components/Table/AgGrid";
import { exportAsExcel } from "../../../utils/exporter";
import { useAuth } from "../../../hooks/use-auth";
import { getAllItemsSku } from "../../../features/Inventory/inventory-actions";

const Report = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allItemsExport } = useSelector((state) => state.inventory);
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef();
  const { permissions } = useAuth();
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inventoryPermission = permissions.inventory;

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(
        dayjs().endOf("day").subtract(7, "day").format("DD/MM/YYYY")
      ),
      dateTo: new Date(dayjs().endOf("day")),
    },
  });

  useEffect(() => {
    if (!isInit) {
      getFilteredItems();
      setIsInit(true);
    }
  }, [isInit]);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.report"),
    },
  ];

  const exportHandler = async () => {
    try {
      setIsLoading(true);
      const exportCallback = (dataToExport) => {
        if (dataToExport.length == 0) {
          setIsLoading(false);
          return;
        }
        exportAsExcel(
          dataToExport,
          t("inventory.items.index"),
          null,
          t,
          "item"
        );
        setIsLoading(false);
      };
      dispatch(
        getAllItemsExport(
          {
            startRow: 0,
            endRow: Math.pow(10, 9),
            filterModel: formatFilterModel(
              gridRef.current.api.getFilterModel()
            ),
          },
          enqueueSnackbar,
          exportCallback
        )
      );
    } catch (err) {
      console.log("ไม่สามารถนำออกสินค้าได้");
    }
  };

  const getFilteredItems = useCallback(() => {
    gridRef.current?.api?.onFilterChanged();
  }, [dispatch, enqueueSnackbar, getValues]);

  const formatFilterModel = (filterModel) => {
    let startDate = getValues("date");
    let endDate = getValues("dateTo");
    const isActiveFilter = filterModel?.["is_active"];
    const activeFilterValue =
      isActiveFilter?.values[0] === "ใช้งาน" ? "true" : "false";
    const formattedFilterModel = {
      created_date: {
        filter: startDate ? startDate.toISOString() : undefined,
        filterTo: endDate ? endDate.toISOString() : undefined,
        filterType: "date",
        type: "inRange",
      },
      sku_name: {
        ...filterModel.sku_name,
        mode: "insensitive",
      },
      item_name: {
        ...filterModel.item_name,
        mode: "insensitive",
      },
      item_desc: {
        ...filterModel.item_desc,
        mode: "insensitive",
      },
      process_type: {
        ...filterModel.process_type,
        mode: "insensitive",
      },
      item_kind: {
        ...filterModel.item_kind,
        mode: "insensitive",
      },
      item_type: {
        ...filterModel.item_type,
        mode: "insensitive",
      },
      is_active:
        isActiveFilter && isActiveFilter?.values?.length === 1
          ? {
              filterType: "boolean",
              type: "equals",
              filter: activeFilterValue,
            }
          : undefined,
      sale_price: { ...filterModel.sale_price },
    };
    return formattedFilterModel;
  };

  const datasource = {
    async getRows(params) {
      setIsLoading(true);
      const request = params.request;
      const filterModel = formatFilterModel(request.filterModel);
      const skuNameSortModel = request.sortModel?.find(
        (column) => column.colId === "sku_name"
      );
      const sortModel = [
        ...request.sortModel,
        {
          colId: "sku_name",
          sort: skuNameSortModel ? skuNameSortModel.sort : "desc",
        },
      ];
      dispatch(
        getAllItemsSku(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterModel,
            sortModel: sortModel,
          },
          params,
          enqueueSnackbar,
          setIsLoading
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <>
      {inventoryPermission === "NONE" && <Navigate to="/no-permission" />}
      {isLoading && <LinearProgress />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        sx={{
          mt: 3,
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{t("inventory.items.report")}</Typography>
        <CustomizedButton
          title="นำออกสินค้าด้วยไฟล์ Excel"
          variant="outlined"
          onClick={exportHandler}
          testId="order-export-btn"
          disabled={allItemsExport?.length == 0}
        />
      </Box>
      <CustomizedBox margin="2rem 0">
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFilteredItems}
                disabled={isLoading.allItemsExport}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      <AgGrid
        ref={gridRef}
        columnDefs={itemSkuColumnDefs(t, false, false, false, true, true)}
        height={649}
        onGridReady={onGridReady}
        // rowData={allItemsExport}
      />
    </>
  );
};

export default Report;
