import * as Yup from "yup";

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

export const orderValidation = Yup.object().shape({
  contact_unique_id: Yup.string().required("กรุณาเลือกลูกค้า"),
  // delivery_address: Yup.string().required("กรุณาเลือกที่อยู่จัดส่ง"),
  created_date: Yup.date()
    .required("กรุณาระบุวันที่สร้างเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  // due_date: Yup.date()
  //   .required("กรุณาระบุวันที่กำหนดรับ")
  //   .nullable()
  //   .transform((value) => dateIsValid(value)),
  // delivery_date: Yup.date()
  //   .required("กรุณาระบุวันที่ส่ง")
  //   .nullable()
  //   .transform((value) => dateIsValid(value)),
  sale_cancel_remark: Yup.string().when("pending_cancel", {
    is: (pending_cancel) => pending_cancel === true,
    then: Yup.string().required("กรุณาระบุ"),
    otherwise: Yup.string(),
  }),
  delivery_cancel_remark: Yup.string().when("pending_cancel", {
    is: (pending_cancel) => pending_cancel === true,
    then: Yup.string().required("กรุณาระบุ"),
    otherwise: Yup.string(),
  }),
  item_list: Yup.array().of(
    Yup.object().shape({
      price_per_unit: Yup.number("กรุณาระบุ").min(1).required("กรุณาระบุ"),
    })
  ),
});
