import { Divider, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import ControlledDateTimePicker from "../../../components/Controlled/ControlledDateTimePricker";
import ControlledServerSideCreatable from "../../../components/Controlled/ControlledSSCreatable";
import ControlledTextField from "../../../components/Controlled/ControlledTextField";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import DropzoneUI from "../../../components/UI/DropzoneUI";
import ModalUI from "../../../components/UI/ModalUI";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { Box } from "@mui/system";
import PaymentTable from "../../../components/Table/PaymentTable";
import { updateSalesOrderPayment } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { useAuth } from "../../../hooks/use-auth";

const paymentMethodOptions = [
  "COD",
  "โอนเงิน",
  "ชำระหน้าร้าน",
  "จ่ายมัดจำ",
  "จ่ายยอดคงเหลือ",
];

const completedStatuses = ["finished", "cancelled"];

const PaymentTab = ({ documentTabUseForm, uniqueId, setMainIsInit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const { paymentDetail, paymentList, salesOrder } = useSelector(
    (state) => state.salesOrder
  );
  const { permissions } = useAuth();
  const [files, setFiles] = useState([]);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const { t } = useTranslation();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openConfirmDeletePaymentModal, setOpenConfirmDeletePaymentModal] =
    useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [indexToDelete, setindexToDelete] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tempAmount, setTempAmount] = useState(0);
  const [paymentAmountError, setPaymentAmountError] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const salesPermission = permissions.sales;

  const { control: documentTabControl, setValue: setDocumentTabValue } =
    documentTabUseForm;

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: paymentDetail,
  });

  const {
    control: paymentListControl,
    getValues: getPaymentListValues,
    reset: resetPaymentList,
  } = useForm({
    defaultValues: {
      payment_list: paymentList,
    },
  });

  const { fields, append, update, replace, remove } = useFieldArray({
    control: paymentListControl,
    name: "payment_list",
  });

  const watchPaymentList = useWatch({
    control: paymentListControl,
    name: "payment_list",
  });

  const watchStatus = useWatch({ control: documentTabControl, name: "status" });

  const watchItemList = useWatch({
    control: documentTabControl,
    name: "item_list",
  });

  const watchAdditionalDiscount = useWatch({
    control: documentTabControl,
    name: "additional_discount",
  });

  const watchPaymentAmount = useWatch({
    control: control,
    name: "payment_amount",
  });

  const isEditable =
    watchStatus &&
    !completedStatuses.includes(watchStatus) &&
    salesPermission === "EDITABLE";

  useEffect(() => {
    if (!isInit) {
      resetPaymentList({ payment_list: salesOrder.order_payment_list });
      setIsInit(true);
    }
  }, [isInit]);

  useEffect(() => {
    const totalItemDiscount = watchItemList?.reduce((prev, curr) => {
      return prev + curr.discount * curr.qty;
    }, 0);

    console.log(totalItemDiscount, watchAdditionalDiscount);

    const totalDiscount = totalItemDiscount + watchAdditionalDiscount;

    const totalAmountBeforeDiscount = watchItemList.reduce(
      (prev, curr) => prev + curr.qty * curr.price_per_unit,
      0
    );

    const totalAmountAfterDiscount =
      parseFloat(totalAmountBeforeDiscount) - parseFloat(totalDiscount);
    setTotalAmount(totalAmountAfterDiscount);
    setValue("total_amount", totalAmountAfterDiscount);
  }, [watchItemList]);

  useEffect(() => {
    if (openPaymentModal) {
      setPaymentAmountError(false);
      const formattedPaymentAmount = isNaN(parseFloat(watchPaymentAmount))
        ? 0
        : parseFloat(watchPaymentAmount);
      const sumExistingPayments = watchPaymentList?.reduce(
        (prev, curr) => parseFloat(prev + parseFloat(curr.payment_amount)),
        0
      );
      const sumAllPayments = parseFloat(
        sumExistingPayments + formattedPaymentAmount
      );
      setValue("total_paid", sumAllPayments - tempAmount);
      setValue(
        "remaining_amount",
        parseFloat(totalAmount - sumAllPayments) + parseFloat(tempAmount)
      );
    }
  }, [watchPaymentAmount, openPaymentModal]);

  const openPaymentModalHandler = () => {
    setOpenPaymentModal(true);
  };

  const newPaymentHandler = () => {
    setTempAmount(0);
    setFiles([]);
    openPaymentModalHandler();
  };

  const closePaymentModalHandler = () => {
    setOpenPaymentModal(false);
    setIsEdit(false);
    reset({ ...paymentDetail, total_amount: totalAmount });
  };

  const submitPaymentHandler = (data) => {
    const {
      total_amount,
      total_paid,
      remaining_amount,
      payment_amount,
      payment_method,
      payment_date_time,
      bank_account,
      remark,
      payment_img_url,
    } = data;

    if (remaining_amount < 0) {
      enqueueSnackbar("กรุณากรอกยอดชำระให้ถูกต้อง", { variant: "error" });
      setPaymentAmountError(true);
      return;
    }
    if (isNaN(payment_amount)) {
      enqueueSnackbar("กรุณากรอกยอดชำระให้ถูกต้อง", { variant: "error" });
      setPaymentAmountError(true);
      return;
    }

    const formatData = {
      id: watchPaymentList[currentIndex]?.id,
      total_amount: total_amount ?? 0,
      payment_count: watchPaymentList.length + 1,
      total_paid,
      remaining_amount,
      payment_amount,
      payment_method,
      payment_date_time,
      bank_account,
      remark,
      payment_img_url,
    };

    append(formatData);

    const paymentList = getPaymentListValues("payment_list");
    const formattedPaymentList = paymentList.map((payment, index) => {
      const totalPaid = paymentList
        .slice(0, index + 1)
        .reduce(
          (prev, curr) => parseFloat(prev) + parseFloat(curr.payment_amount),
          0
        );
      return {
        ...payment,
        total_paid: totalPaid,
        remaining_amount: payment.total_amount - totalPaid,
      };
    });
    replace(formattedPaymentList);
    dispatch(
      updateSalesOrderPayment(
        {
          order_payment_list: formattedPaymentList,
          unique_id: uniqueId,
        },
        enqueueSnackbar,
        "บันทึกการชำระเงิน",
        setMainIsInit
      )
    );

    if (watchPaymentList.length === 0) {
      setDocumentTabValue("payment_date", payment_date_time);
    }
    closePaymentModalHandler();
  };

  const editPaymentHandler = (data, index) => {
    setIsEdit(true);
    reset(data);
    setTempAmount(data.payment_amount);
    setFiles(data.payment_img_url ? [data.payment_img_url] : []);
    setCurrentIndex(index);
    openPaymentModalHandler();
  };

  const pendingDeletePaymentHandler = (currentIndex) => {
    setindexToDelete(currentIndex);
    closePaymentModalHandler();
    setOpenConfirmDeletePaymentModal(true);
  };

  const deletePaymentHandler = () => {
    remove(indexToDelete);
    const paymentList = getPaymentListValues("payment_list");
    const formattedPaymentList = paymentList.map((payment, index) => {
      const totalPaid = paymentList
        .slice(0, index + 1)
        .reduce(
          (prev, curr) => parseFloat(prev) + parseFloat(curr.payment_amount),
          0
        );
      return {
        ...payment,
        total_paid: totalPaid,
        remaining_amount: payment.total_amount - totalPaid,
      };
    });
    replace(formattedPaymentList);
    dispatch(
      updateSalesOrderPayment(
        {
          order_payment_list: formattedPaymentList,
          unique_id: uniqueId,
        },
        enqueueSnackbar,
        "ลบการชำระเงิน",
        setMainIsInit
      )
    );
    setOpenConfirmDeletePaymentModal(false);
    setindexToDelete(null);
  };

  const submitEditPaymentHandler = (data) => {
    const {
      total_amount,
      total_paid,
      payment_count,
      remaining_amount,
      payment_amount,
      payment_method,
      payment_date_time,
      bank_account,
      remark,
      payment_img_url,
    } = data;

    if (remaining_amount < 0) {
      enqueueSnackbar("กรุณากรอกยอดชำระให้ถูกต้อง", { variant: "error" });
      setPaymentAmountError(true);
      return;
    }

    const formatData = {
      id: watchPaymentList[currentIndex]?.id,
      payment_count: payment_count,
      total_amount: total_amount ?? 0,
      total_paid,
      remaining_amount,
      payment_amount,
      payment_method,
      payment_date_time,
      bank_account,
      remark,
      payment_img_url,
    };
    update(currentIndex, formatData);

    const paymentList = getPaymentListValues("payment_list");
    const formattedPaymentList = paymentList.map((payment, index) => {
      const totalPaid = paymentList
        .slice(0, index + 1)
        .reduce(
          (prev, curr) => parseFloat(prev) + parseFloat(curr.payment_amount),
          0
        );
      return {
        ...payment,
        total_paid: totalPaid,
        remaining_amount: payment.total_amount - totalPaid,
      };
    });
    replace(formattedPaymentList);
    dispatch(
      updateSalesOrderPayment(
        {
          order_payment_list: formattedPaymentList,
          unique_id: uniqueId,
        },
        enqueueSnackbar,
        "แก้ไขการชำระเงิน",
        setMainIsInit
      )
    );

    if (currentIndex === 0) {
      setDocumentTabValue("payment_date", payment_date_time);
    }
    closePaymentModalHandler();
  };

  const closeDeletePaymentModalHandler = () => {
    setOpenConfirmDeletePaymentModal(false);
    setindexToDelete(null);
  };

  return (
    <form>
      <Typography variant="h5">{t("sales.payment")}</Typography>
      {isEditable && (
        <CustomizedButton
          sx={{ width: 150, mt: 4 }}
          title={"เพิ่ม" + t("sales.payment")}
          variant="outlined"
          onClick={newPaymentHandler}
        />
      )}
      <ModalUI
        title={"เพิ่ม" + t("sales.payment")}
        open={openPaymentModal}
        maxWidth="md"
        handleClose={closePaymentModalHandler}
      >
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("sales.order.total_amount")}
              control={control}
              name="total_amount"
              error={Boolean(errors?.total_amount)}
              helperText={errors?.total_amount && errors?.total_amount?.message}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("sales.order.total_paid")}
              control={control}
              name="total_paid"
              error={Boolean(errors?.total_paid)}
              helperText={errors?.total_paid && errors?.total_paid?.message}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("sales.order.remaining_amount")}
              control={control}
              name="remaining_amount"
              error={Boolean(errors?.remaining_amount)}
              helperText={
                errors?.remaining_amount && errors?.remaining_amount?.message
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("sales.order.payment_amount")}
              control={control}
              name="payment_amount"
              error={Boolean(errors?.payment_amount) || paymentAmountError}
              helperText={
                errors?.payment_amount && errors?.payment_amount?.message
              }
              disabled={false}
            />
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ my: 3 }} />
        <Typography sx={{ mb: 2 }}>{t("sales.detail")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledServerSideCreatable
              title={t("sales.order.payment_method")}
              control={control}
              name="payment_method"
              documentType="order"
              error={Boolean(errors?.payment_method)}
              helperText={
                errors?.payment_method && errors?.payment_method?.message
              }
              setValue={setValue}
              defaultOptions={paymentMethodOptions}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledDateTimePicker
              label={t("sales.order.payment_datetime")}
              name="payment_date_time"
              control={control}
              error={errors.payment_date_time?.error}
              isOpen={datePickerIsOpen}
              onClose={() => setDatePickerIsOpen(false)}
              onOpen={() => setDatePickerIsOpen(true)}
              required={true}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledServerSideCreatable
              title={t("sales.order.bank_account")}
              control={control}
              name="bank_account"
              documentType="order"
              error={Boolean(errors?.bank_account)}
              helperText={errors?.bank_account && errors?.bank_account?.message}
              defaultOptions={[]}
              setValue={setValue}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("sales.order.payment_remark")}
              control={control}
              name="remark"
              error={Boolean(errors?.remark)}
              helperText={errors?.remark && errors?.remark?.message}
              disabled={false}
            />
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ my: 3 }} />
        <Typography sx={{ mb: 2 }}>
          {t("sales.order.payment_attachment")}
        </Typography>
        <DropzoneUI
          valueToSet="payment_img_url"
          setValue={setValue}
          files={files}
          setFiles={setFiles}
          filesLimit={1}
          manualRevoke
          acceptedFileType="image"
        />
        {!isEdit && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={closePaymentModalHandler}
            />
            <CustomizedButton
              title="บันทึก"
              variant="contained"
              onClick={handleSubmit(submitPaymentHandler)}
            />
          </Box>
        )}
        {isEdit && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title="ลบ"
              variant="outlined"
              onClick={() => pendingDeletePaymentHandler(currentIndex)}
            />
            <CustomizedButton
              title="บันทึก"
              variant="contained"
              onClick={handleSubmit(submitEditPaymentHandler)}
            />
          </Box>
        )}
      </ModalUI>
      <PaymentTable
        fields={fields}
        editPaymentHandler={editPaymentHandler}
        defaultTotalAmount={totalAmount}
        disabled={!isEditable}
      />
      <ModalUI
        title="ยืนยันการลบรายการชำระเงิน"
        open={openConfirmDeletePaymentModal}
        close={closeDeletePaymentModalHandler}
        maxWidth="sm"
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={closeDeletePaymentModalHandler}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={deletePaymentHandler}
          />
        </Box>
      </ModalUI>
    </form>
  );
};

export default PaymentTab;
