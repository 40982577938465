import dayjs from "dayjs";
import { dateToUnix } from "./date-converter";

export const filterParamsOptions = (type) => {
  if (type === "string") {
    return ["contains", "equals", "startsWith", "endsWith"];
  } else if (type === "date") {
    return [
      "equals",
      "greaterThan",
      "lessThan",
      {
        displayKey: "inRange",
        displayName: "inRange",
        predicate: ([fv1, fv2], cellValue) => {
          const dateParts = cellValue.split("/");
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );
          const dateValue = new Date(cellDate);
          const formatFv2 = new Date(dayjs(fv2).endOf("day"));
          return (
            dateValue == null || (dateValue >= fv1 && dateValue <= formatFv2)
          );
        },
        numberOfInputs: 2,
      },
    ];
  } else if (type === "number") {
    return [
      "equals",
      "lessThan",
      "lessThanOrEqual",
      "greaterThan",
      "greaterThanOrEqual",
      "inRange",
    ];
  }
};

export const filterDateWithUnix = (date) => {
  switch (date?.type) {
    case "equals":
      return {
        filterType: "number",
        type: "inRange",
        filter: dateToUnix(date.dateFrom),
        filterTo: dateToUnix(dayjs(date.dateFrom).endOf("day")),
      };
    case "greaterThan":
      return {
        filterType: "number",
        type: "greaterThan",
        filter: dateToUnix(dayjs(date.dateFrom).add(1, "day")),
      };
    case "lessThan":
      return {
        filterType: "number",
        type: "lessThan",
        filter: dateToUnix(dayjs(date.dateFrom).subtract(1, "minute")),
      };
    case "inRange":
      return {
        filter: dateToUnix(date.dateFrom),
        filterTo: dateToUnix(dayjs(date.dateTo).endOf("day")),
        filterType: "number",
        type: "inRange",
      };
    default:
  }
};

export const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  const dateAsString = cellValue;
  if (!dateAsString) {
    return -1;
  }
  const dateParts = dateAsString.split("/");
  const cellDate = new Date(
    Number(dateParts[2].substring(0, 4)),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );
  let localCellDate = filterLocalDateAtMidnight;

  if (typeof filterLocalDateAtMidnight === "string") {
    const localDateParts = filterLocalDateAtMidnight.split("/");
    localCellDate = new Date(
      Number(localDateParts[2].substring(0, 4)),
      Number(localDateParts[1]) - 1,
      Number(localDateParts[0])
    );
  }
  if (localCellDate.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate.getTime() < localCellDate.getTime()) {
    return -1;
  }
  if (cellDate.getTime() > localCellDate.getTime()) {
    return 1;
  }
};

export const dateComparatorWithTime = (
  filterLocalDateAtMidnight,
  cellValue
) => {
  const dateAsString = cellValue;
  if (!dateAsString) {
    return -1;
  }
  const dateParts = dateAsString.split("/");
  const cellDate = new Date(
    Number(dateParts[2]?.substring(0, 4)),
    Number(dateParts[1]) - 1,
    Number(dateParts[0]),
    Number(dateParts[2]?.substring(5, 7)),
    Number(dateParts[2]?.substring(8, 10)),
    Number(dateParts[2]?.substring(11, 13))
  );
  
  let localCellDate = filterLocalDateAtMidnight;

  if (typeof filterLocalDateAtMidnight === "string") {
    const localDateParts = filterLocalDateAtMidnight.split("/");
    localCellDate = new Date(
      Number(localDateParts[2]?.substring(0, 4)),
      Number(localDateParts[1]) - 1,
      Number(localDateParts[0]),
      Number(localDateParts[2]?.substring(5, 7)),
      Number(localDateParts[2]?.substring(8, 10)),
      Number(localDateParts[2]?.substring(11, 13))
    );
  }
  if (dayjs(cellDate).isSame(dayjs(localCellDate))) {
    return 0;
  }
  if (dayjs(cellDate).isBefore(dayjs(localCellDate))) {
    return -1;
  }
  if (dayjs(cellDate).isAfter(dayjs(localCellDate))) {
    return 1;
  }
};

export const filterStatusValueFormatter = (status, t) => {
  switch (status) {
    case "draft":
      return t("status.draft");
    case "wait_customer_accept":
      return t("status.wait_customer_accept");
    case "wait_payment_when_receive":
      return t("status.wait_payment_when_receive");
    case "wait_manufature":
      return t("status.wait_manufature");
    case "wait_payment_cod":
      return t("status.wait_payment_cod");
    case "wait_manufacture":
      return t("status.wait_manufacture");
    case "manufacturing":
      return t("status.manufacturing");
    case "partially_paid":
      return t("status.partially_paid");
    case "fully_paid":
      return t("status.fully_paid");
    case "finished":
      return t("status.finished");
    case "cancelled":
      return t("status.cancelled");
    default:
  }
};

export const exportCSVParams = (fileName, t) => {
  return {
    fileName,
    processCellCallback: (params) => {
      if (params.column.colId === "render_status") {
        return filterStatusValueFormatter(params.value, t);
      } else {
        return params.value;
      }
    },
  };
};
