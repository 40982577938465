import { Box, IconButton, Typography, Grid } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/use-auth";
import ControlledDatePicker from "../../Controlled/ControlledDatePicker";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import RelatedEmployeeIcon from "../../UI/RelatedEmployeeIcon";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CheckboxModalTable from "../../Table/CheckboxModalTable";
import { userColumnDefs } from "../../Table/ColumnDefs/User";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { getAllUsers } from "../../../features/User/Account/account-actions";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ControlledTextField from "../../Controlled/ControlledTextField";
import GlobalService from "../../../services/Global";

const HeaderForm = ({ control, errors, getValues, setValue, disabled }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectEmployeesGridRef = useRef();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const { t } = useTranslation();

  const watchCreatorUniqueId = useWatch({
    control,
    name: "creator_unique_id",
  });

  const watchSalesOrderUniqueId = useWatch({
    control,
    name: "unique_id",
  });

  const watchRelatedEmployees = useWatch({
    control,
    name: "employee_list",
  });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "employee_list",
  });

  const creatorRow =
    pathname.split("/").at(-1) === "add"
      ? [user?.unique_id]
      : [watchCreatorUniqueId];

  const [showSelectEmployees, setShowSelectEmployees] = useState(false);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState([]);

  const [createdAtDateisOpen, setCreatedAtDateisOpen] = useState(false);
  const [paymentDateIsOpen, setPaymentDateIsOpen] = useState(false);
  const [dueDateIsOpen, setDueDateIsOpen] = useState(false);
  const [deliveryDateIsOpen, setDeliveryDateIsOpen] = useState(false);
  const [receiveDateIsOpen, setReceiveDateIsOpen] = useState(false);

  const openEmployeeTable = () => {
    setShowSelectEmployees(true);
  };

  const closeEmployeeTable = () => {
    setShowSelectEmployees(false);
  };

  const getRowId = useCallback((params) => {
    return params.data.unique_id;
  }, []);

  useEffect(() => {
    if (
      pathname.split("/").at(-1) === "add" &&
      watchRelatedEmployees &&
      watchRelatedEmployees.length === 0
    ) {
      // setValue("employee_list", [{ ...user }]);
      setEmployeeIds([user?.unique_id]);
      setEmployeeIdsSnapshot([user?.unique_id]);
    } else {
      if (watchCreatorUniqueId) {
        const mappedEmployeeIds =
          getValues("employee_list")?.map((employee) => employee?.unique_id) ??
          [];
        setEmployeeIds([watchCreatorUniqueId, ...mappedEmployeeIds]);
        setEmployeeIdsSnapshot([watchCreatorUniqueId, ...mappedEmployeeIds]);
      }
    }
  }, [
    pathname,
    watchRelatedEmployees,
    watchCreatorUniqueId,
    user,
    setValue,
    getValues,
  ]);

  const generateDocumentId = useCallback(async () => {
    if (disabled) {
      return;
    }
    try {
      const so_id = await GlobalService.getUniqueId("order");
      setValue("unique_id", so_id);
    } catch (err) {
      console.log("Could not generate order ID");
    }
  }, [setValue, disabled]);

  useEffect(() => {
    if (pathname === "/sales/order/add" && !watchSalesOrderUniqueId) {
      generateDocumentId();
    }
  }, [pathname, generateDocumentId]);

  const finishEmployeesSelect = (data) => {
    // filter out employees not selected in current modal session
    let filteredEmployees = fields.filter((employee) =>
      employeeIds.includes(employee.unique_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.unique_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.unique_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        employeeIds.indexOf(a.unique_id) - employeeIds.indexOf(b.unique_id)
      );
    });
    setValue(
      "employee_list",
      filteredEmployees.filter(
        (employee) => employee.unique_id !== watchCreatorUniqueId
      )
    );
    setShowSelectEmployees(false);
  };

  const removeEmployee = (index) => {
    remove(index);
    setEmployeeIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setEmployeeIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const renderAvatars = () => {
    return fields.map((item, index) => {
      return (
        <RelatedEmployeeIcon
          key={item.id}
          first_name={item.first_name}
          last_name={item.last_name}
          index={index}
          avatar={item.img_url}
          remove={removeEmployee}
          viewOnly={disabled}
        />
      );
    });
  };

  const renderCreatorAvatar = () => {
    if (watchCreatorUniqueId) {
      return (
        <RelatedEmployeeIcon
          isMain={watchCreatedBy && fields.length > 0}
          first_name={watchCreatedBy?.first_name}
          last_name={watchCreatedBy?.last_name}
          avatar={watchCreatedBy?.img_url}
        />
      );
    } else {
      return null;
    }
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const filterModel = {
        ...request.filterModel,
        unique_id: {
          ...request.filterModel.unique_id,
          mode: "insensitive",
        },
        first_name: {
          ...request.filterModel.first_name,
          mode: "insensitive",
        },
        last_name: {
          ...request.filterModel.last_name,
          mode: "insensitive",
        },
      };
      dispatch(
        getAllUsers(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ mr: 2, fontWeight: "bold" }}>
            {t("sales.documentId")}
          </Typography>
          <Box sx={{ display: "flex", ml: 1 }}>
            <ControlledTextField
              name="unique_id"
              label={t("sales.documentId")}
              control={control}
              error={errors.unique_id}
              disabled={pathname !== "/sales/order/add" || disabled}
              required
            />
            {pathname === "/sales/order/add" && !disabled && (
              <Box>
                <CustomizedTooltip title="เรียกเลขที่เอกสารใหม่">
                  <IconButton
                    onClick={generateDocumentId}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="created_date"
              control={control}
              error={errors.created_date}
              isOpen={createdAtDateisOpen}
              onClose={() => setCreatedAtDateisOpen(false)}
              onOpen={() => setCreatedAtDateisOpen(true)}
              label={t("sales.order.created_date")}
              disabled={disabled}
            />
          </Box>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="payment_date"
              control={control}
              error={errors.payment_date}
              isOpen={paymentDateIsOpen}
              onClose={() => setPaymentDateIsOpen(false)}
              onOpen={() => setPaymentDateIsOpen(true)}
              label={t("sales.order.payment_date")}
              disabled={true}
            />
          </Box>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="due_date"
              control={control}
              error={errors.due_date}
              isOpen={dueDateIsOpen}
              onClose={() => setDueDateIsOpen(false)}
              onOpen={() => setDueDateIsOpen(true)}
              label={t("sales.order.due_date")}
              disabled={disabled}
            />
          </Box>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="delivery_date"
              control={control}
              error={errors.delivery_date}
              isOpen={deliveryDateIsOpen}
              onClose={() => setDeliveryDateIsOpen(false)}
              onOpen={() => setDeliveryDateIsOpen(true)}
              label={t("sales.order.delivery_date")}
              disabled={disabled}
            />
          </Box>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="receive_date"
              control={control}
              error={errors.receive_date}
              isOpen={receiveDateIsOpen}
              onClose={() => setReceiveDateIsOpen(false)}
              onOpen={() => setReceiveDateIsOpen(true)}
              label={t("sales.order.receive_date")}
              disabled={disabled}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ mr: 4, fontWeight: "bold" }}>
          {t("sales.order.related_employee")}
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {renderCreatorAvatar()}
          {renderAvatars()}
          {!disabled && (
            <CustomizedTooltip title="เพิ่มผู้เกี่ยวข้อง" enterNextDelay={200}>
              <IconButton
                onClick={openEmployeeTable}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ControlPointOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
      </Box>
      <CheckboxModalTable
        modalTitle="ผู้เกี่ยวข้อง"
        btnTitle={t("button.add")}
        gridRef={selectEmployeesGridRef}
        height={450}
        columnDefs={userColumnDefs(t, false, true, true)}
        rowSelection="multiple"
        onFinishEditing={finishEmployeesSelect}
        modalIsOpen={showSelectEmployees}
        getRowId={getRowId}
        closeModal={closeEmployeeTable}
        onGridReady={onGridReady}
        selectedIds={employeeIds}
        setSelectedIds={setEmployeeIds}
        idsSnapshot={employeeIdsSnapshot}
        setIdsSnapshot={setEmployeeIdsSnapshot}
        lockRows={creatorRow}
      />
    </Box>
  );
};

export default HeaderForm;
