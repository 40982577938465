import { gql } from "graphql-request";

export const CREATE_CREATABLE = gql`
  mutation CreatableCreate($createInput: CreatableCreateInput!) {
    creatableCreate(createInput: $createInput) {
      name
      usage_field_type
      usage_field_name
    }
  }
`;

export const CREATE_CREATABLES = gql`
  mutation CreatableCreateMany($createManyInput: [CreatableCreateInput!]!) {
    creatableCreateMany(createManyInput: $createManyInput) {
      count
    }
  }
`;

export const DELETE_CREATABLE = gql`
  mutation CreatableDelete($uniqueInput: CreatableUniqueInput!) {
    creatableDelete(uniqueInput: $uniqueInput) {
      name
      usage_field_type
      usage_field_name
    }
  }
`;

export const DELETE_CREATABLE_WITH_TAGS = gql`
  mutation CreatableDeleteWithTags($uniqueInput: CreatableUniqueInput!) {
    creatableDeleteWithTags(uniqueInput: $uniqueInput) {
      name
      usage_field_type
      usage_field_name
    }
  }
`;

export const DOCUMENT_NEXT_STATUS = gql`
  mutation DocumentNextStatus($documentInput: DocumentInput!) {
    documentNextStatus(documentInput: $documentInput) {
      id
      reference_unique_id
      changed_date
      reference_document_type
      status
      user_unique_iq
      workflow_step_number
      workflow_document_type
      workflow {
        step_number
        document_type
        status_type
        initial_status
        condition
        target_status
        remark
      }
    }
  }
`;

export const DOCUMENT_CANCEL = gql`
  mutation DocumentCancel($documentCancelDocumentInput2: DocumentInput!) {
    documentCancel(documentInput: $documentCancelDocumentInput2) {
      unique_id
      document_type
    }
  }
`;

export const DOCUMENT_SET_STEP = gql`
  mutation DocumentSetStep($documentSetStepInput: DocumentSetStepInput!) {
    documentSetStep(documentSetStepInput: $documentSetStepInput) {
      status
      step_number
      unique_id
    }
  }
`;
