import { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ContactCard from "./ContactCard";
import ModalUI from "../../../UI/ModalUI";

const defaultNewContactState = {
  name: "",
  position: "",
  phone: "",
};

const NewContactTab = ({ control, errors, getValues, viewOnly }) => {
  // const { isReset, contactPersonListIsInit, contactSnapshot } = useSelector(
  //   (state) => state.contact
  // );
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isInit, setIsInit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  const {
    append: appendContactPerson,
    update: updatedContactPerson,
    remove: removeContactPerson,
  } = useFieldArray({
    control,
    name: "contact_person_list",
  });

  const {
    control: newContactControl,
    getValues: getNewContactValues,
    setValue: setNewContactValue,
    reset: resetNewContactForm,
  } = useForm({
    defaultValues: { ...defaultNewContactState },
  });

  const {
    control: displayCardsControl,
    setValue: setDisplayCardsValue,
    getValues: getDisplayCardsValues,
    watch: watchDisplayCards,
    formState: { errors: displayCardsErrors },
    reset: resetDisplayCards,
  } = useForm({
    defaultValues: { contact_person_list: getValues("contact_person_list") },
  });

  const {
    fields: displayCardsFields,
    append: appendDisplayCard,
    update: updateDisplayCard,
    remove: removeDisplayCard,
  } = useFieldArray({
    control: displayCardsControl,
    name: "contact_person_list",
  });

  const watchedDisplayCards = watchDisplayCards("contact_person_list");

  const controlledDisplayCards = displayCardsFields.map((field, index) => {
    return {
      ...field,
      ...watchedDisplayCards[index],
    };
  });

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const updateDisplayCards = (contactPersons) => {
    const updatedDisplayCards = contactPersons?.map((item) => {
      const updatedItem = { ...item };
      return updatedItem;
    });
    return updatedDisplayCards;
  };

  useEffect(() => {
    if (!isInit) {
      const updatedDisplayCards = updateDisplayCards(
        getDisplayCardsValues("contact_person_list")
      );
      setDisplayCardsValue("contact_person_list", updatedDisplayCards);
      setIsInit(true);
    }
    // eslint-disable-next-line
  }, []);

  const stopEditingHandler = () => {
    setIsEditing(false);
    setEditingIndex(null);
  };

  const startEditingHandler = () => {
    resetNewContactForm({ ...defaultNewContactState });
    setIsEditing(true);
  };

  const editContactCardHandler = (index) => {
    const contactPerson = getValues("contact_person_list")[index];
    setIsEditing(true);
    resetNewContactForm(contactPerson);
    setEditingIndex(index);
  };

  const submitContactHandler = () => {
    const newContactValue = getNewContactValues();
    if (newContactValue.name) {
      if (editingIndex !== null) {
        updateDisplayCard(editingIndex, newContactValue);
        updatedContactPerson(editingIndex, newContactValue);
      } else {
        appendDisplayCard(newContactValue);
        appendContactPerson(newContactValue);
      }
    }
    setIsEditing(false);
    setEditingIndex(null);
    resetNewContactForm({ ...defaultNewContactState });
  };

  const removeContactPersonHandler = (index) => {
    removeDisplayCard(index);
    removeContactPerson(index);
  };

  const renderContactsList = () => {
    if (!controlledDisplayCards) {
      return null;
    }
    return controlledDisplayCards
      .filter((contact) => {
        return contact.name;
      })
      .map((item, index) => {
        return (
          <Grid key={item.id} item xs={12} sm={6} md={3} lg={3} xl={2}>
            <ContactCard
              control={control}
              errors={displayCardsErrors}
              name={item.name}
              position={item.position}
              phone={item.phone}
              index={index}
              onEdit={editContactCardHandler}
              onRemove={removeContactPersonHandler}
              viewOnly={viewOnly}
            />
          </Grid>
        );
      });
  };

  return (
    <>
      <CustomizedButton
        title={"เพิ่มบุคคลติดต่อ"}
        variant="outlined"
        size="medium"
        onClick={startEditingHandler}
        disabled={viewOnly}
      />
      <Grid container spacing={2}>
        {isInit && renderContactsList()}
      </Grid>

      <ModalUI
        open={isEditing}
        handleClose={stopEditingHandler}
        fullWidth
        maxWidth="sm"
      >
        <Typography fontWeight={"bold"} sx={{ ml: 1, my: 2 }}>
          {t("contact.contact_person.index")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ControlledTextField
              name="name"
              label={t("contact.contact_person.name.index")}
              control={newContactControl}
              error={errors.name}
              disabled={viewOnly}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ControlledTextField
              name="position"
              label={t("contact.contact_person.department")}
              control={newContactControl}
              error={errors.position}
              disabled={viewOnly}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ControlledTextField
              name="phone"
              label={t("contact.contact_person.phone")}
              control={newContactControl}
              error={errors.phone}
              disabled={viewOnly}
              onChange={(e, field) => {
                return field.onChange(e.target.value.replace(/[^0-9.]+/g, ""));
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ my: 2 }}>
          <CustomizedButton
            title={"บันทึก"}
            variant="contained"
            size="medium"
            onClick={submitContactHandler}
            disabled={viewOnly}
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default NewContactTab;
