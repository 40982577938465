import {
  Box,
  IconButton,
  Toolbar,
  Badge,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneAllIcon from "@mui/icons-material/DoneAll";
// import { alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CustomizedButton from "../Custom/CustomizedButton";
import CustomizedDashboardNavbar from "../Custom/CustomizedDashboardNavbar";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useNavbar } from "../../hooks/use-navbar";
import { useAuth } from "../../hooks/use-auth";
import { useSelector } from "react-redux";
import UserBadge from "../UI/UserBadge";

const TopNavbar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sidebar } = useNavbar();

  // TODO please remove these after connnecting API
  const { user, logout } = useAuth();
  // const { isLoading: quotationLoading } = useSelector(
  //   (state) => state.quotation
  // );
  // const { isLoading: salesOrderLoading } = useSelector(
  //   (state) => state.salesOrder
  // );
  // const { isLoading: salesInvoiceLoading } = useSelector(
  //   (state) => state.salesInvoice
  // );
  // const { isLoading: depositInvoiceLoading } = useSelector(
  //   (state) => state.depositInvoice
  // );
  // const { isLoading: salesReturnLoading } = useSelector(
  //   (state) => state.salesReturn
  // );
  // const { isLoading: creditNoteLoading } = useSelector(
  //   (state) => state.creditNote
  // );
  // const { isLoading: itemLoading } = useSelector((state) => state.itemMaster);
  // const { isLoading: rbacLoading } = useSelector((state) => state.rbac);
  const { isLoading: accountLoading } = useSelector((state) => state.account);
  // const { isLoading: salesReportLoading } = useSelector(
  //   (state) => state.salesReport
  // );

  const logoutHandler = () => {
    logout();
    navigate("/", { replace: true });
  };

  return (
    <>
      <CustomizedDashboardNavbar open={!isSmallScreen && sidebar.isSidebarOpen}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          {!sidebar.isSidebarOpen && (
            <IconButton
              onClick={() => {
                sidebar.setIsSidebarOpen(true);
              }}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          )}
          <Box sx={{ ml: sidebar.isSidebarOpen ? 0 : 4 }}>
            {!isSmallScreen && (
              <CustomizedButton
                variant="outlined"
                title={t("button.back")}
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIcon fontSize="small" />}
              />
            )}
            {isSmallScreen && (
              <Box
                sx={{
                  border: "1px solid rgba(50, 143, 53, 0.6)",
                  borderRadius: "4px",
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon
                    sx={{
                      color: "#328F35",
                      fontSize: "0.75rem",
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {user && <UserBadge {...user} onLogout={logoutHandler} />}
        </Toolbar>
        {/* {(quotationLoading.allQuotations ||
          quotationLoading.quotation ||
          salesOrderLoading.allSalesOrders ||
          salesOrderLoading.salesOrder ||
          salesInvoiceLoading.allSalesInvoices ||
          salesInvoiceLoading.salesInvoice ||
          depositInvoiceLoading.allDepositInvoices ||
          depositInvoiceLoading.depositInvoice ||
          salesReturnLoading.allSalesReturns ||
          salesReturnLoading.salesReturn ||
          creditNoteLoading.allCreditNotes ||
          creditNoteLoading.creditNote ||
          itemLoading.item ||
          rbacLoading.allRoles ||
          rbacLoading.role ||
          rbacLoading.priviledgeList ||
          accountLoading.allUsers ||
          accountLoading.user ||
          salesReportLoading.salesReportByCustomer ||
          salesReportLoading.salesReportByProduct) && <LinearProgress />} */}
        {(accountLoading.allUsers || accountLoading.user) && <LinearProgress />}
      </CustomizedDashboardNavbar>
    </>
  );
};

export default TopNavbar;
