import { v4 as uuidv4 } from "uuid";
import { uploadFileToS3 } from "./s3";
import { positionType } from "./userInfo";

export const mapObjectToEnum = (object) => {
  const permissions = [];
  for (const [key1, value1] of Object.entries(object)) {
    for (const [key2, value2] of Object.entries(value1)) {
      for (const [key3, value3] of Object.entries(value2)) {
        if (typeof value3 === "string" && value3 !== "CLOSE") {
          permissions.push(`${key1}__${key2}__${value3}`.toUpperCase());
        } else if (value3 === true) {
          permissions.push(`${key1}__${key2}__${key3}`.toUpperCase());
        }
      }
    }
  }
  return permissions;
};

export const filteredRow = (initialrows, priviledgeObject, resource) => {
  if (initialrows && priviledgeObject && resource) {
    const deDuplicatedFilteredRows = [];

    for (const [, priviledgeList] of Object.entries(priviledgeObject)) {
      const filteredPriviledgesBySource = priviledgeList.filter(
        (priviledge) => priviledge.split("__")[0] === resource
      );
      const extractedFunctions = filteredPriviledgesBySource.map(
        (priviledge) => priviledge.split("__")[1]
      );

      if (extractedFunctions !== []) {
        initialrows
          .filter((row) => extractedFunctions.includes(row.functionName))
          .forEach((row) => {
            if (
              deDuplicatedFilteredRows.some(
                (item) => item.functionName === row.functionName
              ) === false
            ) {
              deDuplicatedFilteredRows.push(row);
            }
          });
      }
    }
    return deDuplicatedFilteredRows;
  }
  return initialrows;
};

export const extractRoleNames = (allRoles) => allRoles.map((role) => role.name);

export const mapActivityToIconName = (activityType, attachment_list) => {
  if (activityType === "comment") {
    if (attachment_list?.length !== 0 && attachment_list?.length !== 0) {
      return "mixedFiles";
    }
  }
  return activityType;
};

export const filterPositionByDepartment = (department, setPosition) => {
  switch (department) {
    case "วางแผน":
      setPosition([
        {
          id: uuidv4(),
          label: "super",
          value: "super",
        },
      ]);
      break;
    case "ขาย":
      setPosition([
        {
          id: uuidv4(),
          label: "แอดมิน",
          value: "แอดมิน",
        },
      ]);
      break;
    case "ฝ่ายผลิต":
      setPosition([
        {
          id: uuidv4(),
          label: "ฝ่ายผลิต",
          value: "ฝ่ายผลิต",
        },
      ]);
      break;
    case "บัญชี":
      setPosition([
        {
          id: uuidv4(),
          label: "พนักงานบัญชี",
          value: "พนักงานบัญชี",
        },
      ]);
      break;
    case "การตลาด":
      setPosition([
        {
          id: uuidv4(),
          label: "ฝ่ายขาย",
          value: "ฝ่ายขาย",
        },
      ]);
      break;
    default:
      setPosition(positionType);
      break;
  }
};

export const departmentEngToThai = (department) => {
  switch (department) {
    case "management":
      return "บริหาร";
    case "inventory":
      return "คลังสินค้า";
    case "sales":
      return "รายรับ";
    case "purchase":
      return "จัดซื้อ";
    case "account":
      return "บัญชี";
    case "logistic":
      return "ขนส่ง";
    case "manufacture":
      return "ผลิต";
    case "admin":
      return "ดูแลระบบ";
    default:
      return department || "";
  }
};

export const departmentThaiToEnum = (department) => {
  switch (department) {
    case "บริหาร":
      return "management";
    case "คลังสินค้า":
      return "inventory";
    case "รายรับ":
      return "sales";
    case "จัดซื้อ":
      return "purchase";
    case "บัญชี":
      return "account";
    case "ขนส่ง":
      return "logistic";
    case "ผลิต":
      return "manufacture";
    case "ดูแลระบบ":
      return "admin";
    default:
      return department;
  }
};

export const formatVatOption = (value) => {
  if (value === "ไม่มี") return "NO_VAT";
  if (value === "0 %") return "VAT_0";
  if (value === "7 %") return "VAT_7";
  if (value === "NO_VAT") return "ไม่มี";
  if (value === "VAT_0") return "0 %";
  if (value === "VAT_7") return "7 %";
};

export const formatUpdateItemPayload = async (data, allUoms, user) => {
  const serializedData = Object.assign({}, data);
  const formatList = [
    "base_uom",
    "purchase_uom",
    "deliver_uom",
    "sales_uom",
    "weight_uom",
    "width_uom",
    "length_uom",
    "thickness_uom",
    "height_uom",
    "volume_uom",
  ];

  // loop formating various Uoms to return only id
  for (const [key, value] of Object.entries(serializedData)) {
    if (formatList.includes(key)) {
      serializedData[key] = allUoms.find((uom) => {
        return uom.name === value.name;
      }).document_id;
    }
  }

  // loop for upload pic
  for (const [index, value] of serializedData.attachment_list.entries()) {
    if (value && !value.url && typeof value === "object") {
      const { Location } = await uploadFileToS3(value, "item", user.unique_id);
      serializedData.attachment_list[index].url = Location;
    }
  }

  serializedData.attachment_list = serializedData.attachment_list.map(
    (file) => {
      return {
        name: file.name,
        url: file.url,
      };
    }
  );

  serializedData.tag_list = serializedData.tag_list.map((tag) => {
    return tag.name;
  });

  // serializedData.base_uom_document_id = serializedData.stock_uom.document_id;
  // serializedData.sales_uom_document_id = serializedData.sales_uom.document_id;
  // serializedData.purchase_uom_document_id =
  //   serializedData.purchase_uom.document_id;
  // serializedData.deliver_uom_document_id =
  //   serializedData.deliver_uom.document_id;

  // ================= dimention uom=================
  serializedData.volume_uom_document_id = serializedData.volume_uom.document_id;
  serializedData.length_uom_document_id = serializedData.length_uom.document_id;
  serializedData.width_uom_document_id = serializedData.width_uom.document_id;
  serializedData.height_uom_document_id = serializedData.height_uom.document_id;
  serializedData.thickness_uom_document_id =
    serializedData.thickness_uom.document_id;
  serializedData.weight_uom_document_id = serializedData.weight_uom.document_id;
  serializedData.thickness_uom_document_id =
    serializedData.thickness_uom.document_id;
  serializedData.weight_uom_document_id = serializedData.weight_uom.document_id;
  //= ==================================================

  // ================= value to float =================
  serializedData.sales_standard_price = parseFloat(
    serializedData.sales_standard_price
  );
  serializedData.purchase_standard_price = parseFloat(
    serializedData.purchase_standard_price
  );
  serializedData.purchase_minimum_qty = parseFloat(
    serializedData.purchase_minimum_qty
  );
  serializedData.sales_maximum_discount = parseFloat(
    serializedData.sales_maximum_discount
  );

  //= ==================================================

  // ================= warehouse uom =================
  serializedData.base_uom_document_id = serializedData.base_uom;
  serializedData.sales_uom_document_id = serializedData.sales_uom;
  serializedData.deliver_uom_document_id = serializedData.deliver_uom;
  serializedData.purchase_uom_document_id = serializedData.purchase_uom;
  serializedData.uom_group = {
    ...serializedData.uom_group,
    uom_conversion_list: serializedData.uom_conversion_list.map(
      (conversion) => {
        return {
          base_uom_rate: conversion.base_uom_rate,
          target_uom_document_id: conversion.target_uom_document_id,
          target_uom_rate: conversion.target_uom_rate,
        };
      }
    ),
  };

  // ===================================================
  if (
    !serializedData.is_active ||
    serializedData.is_active === "false" ||
    serializedData.is_active === "true"
  ) {
    serializedData.is_active = serializedData.is_active === "true" && true;
  }

  serializedData.item_group_sub_level_1_document_id =
    serializedData.item_group_sub_level_1.document_id;
  if (!serializedData.item_group_sub_level_2_document_id) {
    delete serializedData.item_group_sub_level_2_document_id;
  }
  if (!serializedData.item_group_sub_level_3_document_id) {
    delete serializedData.item_group_sub_level_3_document_id;
  }
  serializedData.sales_vat_type = formatVatOption(data.sales_vat_type);

  // ================= attribute_list  =================
  serializedData.attribute_list = serializedData.attribute_list.map(
    (attribute) => {
      return {
        attribute_id: attribute.id,
        attribute_value: attribute.attribute_value,
      };
    }
  );
  // ===================================================
  // uom group set up payload for update
  delete serializedData.uom_group.convertable_uom_list;
  delete serializedData.uom_group.id;
  delete serializedData.uom_group.base_uom;
  // delete not ready field (have to change)
  delete serializedData.id;
  delete serializedData.img_url;
  delete serializedData.vendor_item_id;
  delete serializedData.item_group_sub_level_1;
  delete serializedData.sales_uom;
  delete serializedData.base_uom;
  delete serializedData.purchase_uom;
  delete serializedData.deliver_uom;
  delete serializedData.volume_uom;
  delete serializedData.length_uom;
  delete serializedData.width_uom;
  delete serializedData.height_uom;
  delete serializedData.thickness_uom;
  delete serializedData.weight_uom;
  delete serializedData.created_date;
  delete serializedData.created_by;
  delete serializedData.uom_group_document_id;
  delete serializedData.uom_conversion_list;
  // delete serializedData.item_group_sub_level;
  delete serializedData.item_group_sub_level_2;
  delete serializedData.item_group_sub_level_3;
  // delete serializedData.tag_list;
  delete serializedData.item_group;
  // will be in BE but not this time
  delete serializedData.mrp_default_method;
  delete serializedData.manufacture_type;
  delete serializedData.manufacture_minimum_qty;
  delete serializedData.manufacture_bom_document_id;
  return serializedData;
};

export const filterUom = (allUoms, selectedUom) => {
  return allUoms
    .filter((uom) => selectedUom.includes(uom.name))
    .map((uom) => ({ ...uom, label: uom.name }));
};

export const extractMentionData = (message) => {
  const idRegex = /[^(]+(?=\))/g;
  return message.match(idRegex);
};

export const formatComment = (message) => {
  const idRegex = /[^(]+(?=\))/g;
  const nameRegex = /[^[]+(?=\])/g;
  const mentionList = [];

  const nameList = message.match(nameRegex);
  const idList = message.match(idRegex);

  if (nameList && idList) {
    nameList.forEach((name, index) => {
      mentionList.push({ name, id: idList[index] });
    });

    // replace each mention with anchor tag
    mentionList.forEach((mention) => {
      message = message.replace(
        `@[${mention.name}](${mention.id})`,
        ` <a href="/user/account/${mention.id}" target="_blank" class="mention">
          @${mention.name}
        </a>`
      );
    });
  }
  return message;
};

export const entryTypeToThai = (input) => {
  if (input === "transfer") return "โอนย้าย";
  else if (input === "receive") return "นำเข้า";
  else if (input === "issue") return "นำออก";
  else if (input === "adjustment") return "ปรับสต๊อก";
  else if (input === "deduct") return "ลด";
  else if (input === "add") return "เพิ่ม";
};

export const formatNumber = (number, noDecimal) => {
  return (
    Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100
  )?.toLocaleString(undefined, {
    minimumFractionDigits: noDecimal ? 0 : 2,
    maximumFractionDigits: noDecimal ? 0 : 2,
  });
};

export const roundDecimal = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const calcSummary = (
  itemList,
  shippingCost,
  additionalDiscount,
  type,
  withholdingTaxType
) => {
  const summary = {
    pre_vat_amount: 0,
    vat_exempted_amount: 0,
    vat_0_amount: 0,
    vat_7_amount: 0,
    vat_amount: 0,
    net_amount: 0,
    withholding_tax_amount: 0,
    total_amount: 0,
  };

  if (type === "di") {
    summary.pre_vat_amount = itemList.reduce(
      (acc, item) => acc + parseFloat(item?.pre_vat_amount || 0),
      0
    );
  } else if (type === "rt") {
    summary.pre_vat_amount = itemList.reduce(
      (acc, item) => acc + parseFloat(item?.payment_amount || 0),
      0
    );
  } else {
    summary.pre_vat_amount = itemList.reduce(
      (acc, item) =>
        acc +
        (parseFloat(item?.qty || 0) * parseFloat(item?.price_per_unit || 0) -
          parseFloat(item?.discount_amount || 0)),
      0
    );
  }

  summary.vat_exempted_amount = itemList
    .filter((item) => item?.vat_type === "ไม่มี")
    .reduce((acc, item) => acc + parseFloat(item?.pre_vat_amount || 0), 0);

  summary.vat_0_amount = itemList
    .filter((item) => item?.vat_type === "0")
    .reduce((acc, item) => {
      return acc + parseFloat(item?.pre_vat_amount || 0);
    }, 0);

  summary.vat_7_amount = itemList
    .filter((item) => item?.vat_type === "7")
    .reduce((acc, item) => acc + parseFloat(item?.pre_vat_amount || 0), 0);

  summary.vat_amount = parseFloat(summary.vat_7_amount) * 0.07;

  if (type === "di") {
    summary.net_amount =
      parseFloat(summary.pre_vat_amount) + parseFloat(summary.vat_amount);
  } else {
    summary.net_amount =
      parseFloat(summary.pre_vat_amount) +
      parseFloat(summary.vat_amount) +
      parseFloat(shippingCost ?? 0) -
      parseFloat(additionalDiscount ?? 0);
  }

  if (type !== "rt") {
    summary.withholding_tax_amount = itemList
      .filter(
        (item) =>
          !["ยังไม่ระบุ", "ไม่มี", ""].includes(item?.withholding_tax?.type)
      )
      .reduce(
        (acc, item) =>
          acc +
          (parseFloat(item?.pre_vat_amount) *
            parseFloat(item?.withholding_tax?.type)) /
            100,
        0
      );
  } else {
    if (
      ["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(withholdingTaxType)
    ) {
      summary.withholding_tax_amount = 0;
    } else {
      summary.withholding_tax_amount = itemList.reduce((acc, item) => {
        const paymentAmount = parseFloat(item?.payment_amount || 0);
        return (
          acc +
          (paymentAmount * parseFloat(withholdingTaxType?.replace("%", ""))) /
            100
        );
      }, 0);
    }
  }

  if (type === "di") {
    summary.total_amount = parseFloat(summary.net_amount);
  } else {
    summary.total_amount =
      parseFloat(summary.net_amount) +
      parseFloat(summary.withholding_tax_amount);
  }

  // loop formating result in comma form
  for (const [key, value] of Object.entries(summary)) {
    summary[key] = formatNumber(value);
  }

  return summary;
};

export const localeStringToFloat = (string) => {
  return parseFloat(string.replace(/,/g, ""));
};

export const getDocumentName = (t, document) => {
  switch (document) {
    case "quotation":
      return t("sales.quotation.index");
    case "order":
    case "sales_order":
      return t("sales.order.index");
    case "deposit-invoice":
    case "deposit_invoice":
      return t("sales.depositInvoice.index");
    case "invoice":
    case "sales_invoice":
      return t("sales.invoice.index");
    case "payment-receipt":
    case "payment_receipt":
      return t("sales.paymentReceipt.index");
    case "return":
    case "sales_return":
      return t("sales.return.index");
    case "credit-note":
    case "credit_note":
      return t("sales.creditNote.index");
    case "delivery-order":
    case "delivery_order":
      return t("logistic.deliveryOrder.index");
    default:
      return "";
  }
};

export const formatUserAccount = (user) => {
  return {
    title_name: user.title_name?.toString().trim() ?? "",
    first_name: user.first_name?.toString().trim() ?? "",
    last_name: user.last_name?.toString().trim() ?? "",
    nick_name: user.nick_name?.toString().trim() ?? "",
    img_url: user.img_url ?? [],
    email: user.email?.toString().trim() ?? "",
    phone: user.phone?.toString().trim() ?? "",
    organization: user.organization?.toString().trim() ?? "",
    department: user.department?.toString().trim() ?? "",
    position: user.position?.toString().trim() ?? "",
    password: "",
    confirm_password: "",
    is_user_active: user.is_user_active ?? true,
    inactive_remarks: user.inactive_remarks?.toString().trim() ?? "",
    current_role: user?.role_list[0]?.name,
    created_date: user.created_date,
  };
};

export const formatItem = (item) => {
  const colors = formatVariantKeyValues(item, "color");
  const sizes = formatVariantKeyValues(item, "size");
  const arms = formatVariantKeyValues(item, "arm");
  const colorImages = formatVariantKeyValues(item, "color_images", true);

  const variationList = item.is_variation
    ? item.item_variant_key_list[0].item_variant_value_list.map((color) => {
        const currentColor = color.item_variant_value_name;
        return {
          key: "color",
          name: currentColor,
          value: item.item_variant_key_list[1].item_variant_value_list.map(
            (size) => {
              const currentSize = size.item_variant_value_name;
              return {
                key: "size",
                name: currentSize,
                value:
                  item.item_variant_key_list[2].item_variant_value_list.map(
                    (arm) => {
                      const currentArm = arm.item_variant_value_name;
                      const skuItem = item.sku_list.find((sku) => {
                        const color = sku.item_variant_value.find(
                          (variant) => variant.item_variant_key_name === "color"
                        )?.item_variant_value_name;
                        const size = sku.item_variant_value.find(
                          (variant) => variant.item_variant_key_name === "size"
                        )?.item_variant_value_name;
                        const arm = sku.item_variant_value.find(
                          (variant) => variant.item_variant_key_name === "arm"
                        )?.item_variant_value_name;
                        return (
                          color === currentColor &&
                          size === currentSize &&
                          arm === currentArm
                        );
                      });
                      try {
                        return {
                          key: "arm",
                          name: currentArm,
                          other_cost: skuItem?.sku_detail?.other_cost,
                          shirt_cost: skuItem?.sku_detail?.shirt_cost,
                          sale_price: skuItem?.sku_detail?.sale_price,
                          sku_name: skuItem?.sku_detail?.sku_name,
                          is_active: skuItem?.sku_detail?.is_active,
                        };
                      } catch (err) {
                        console.log(err);
                        return {};
                      }
                    }
                  ),
              };
            }
          ),
        };
      })
    : [];

  return {
    ...item,
    img_url: item.img_url && item.img_url[0] ? item.img_url : [],
    sale_price:
      !item.is_variation && item.sku_list?.length > 0
        ? item?.sku_list[0]?.sku_detail?.sale_price
        : 0,
    shirt_cost:
      !item.is_variation && item.sku_list?.length > 0
        ? item?.sku_list[0]?.sku_detail?.shirt_cost
        : 0,
    other_cost:
      !item.is_variation && item.sku_list?.length > 0
        ? item?.sku_list[0]?.sku_detail?.other_cost
        : 0,
    variation_color: colors,
    variation_size: sizes,
    variation_arm: arms,
    variation_color_images: colorImages,
    variation_list: variationList,
  };
};

export const variationConvert = (items, name, notObject) => {
  return {
    item_variant_key_name: name,
    item_variant_value_list:
      items?.map((item) => {
        return notObject
          ? item
          : { item_variant_value_name: item?.value || item || "" };
      }) ?? [],
  };
};

export const formatVariantKeyValues = (item, name, isNotObject) => {
  if (!item.is_variation) {
    return undefined;
  }
  return item.item_variant_key_list
    ?.find((key) => key.item_variant_key_name === name)
    .item_variant_value_list.map((value) => {
      if (isNotObject) {
        return !value.item_variant_value_name.includes("placeholder-") &&
          value.item_variant_value_name
          ? [value.item_variant_value_name]
          : [];
      }
      return { value: value.item_variant_value_name };
    });
};

export const formatItemPayload = async (item, user) => {
  let img_url = "";
  if (item.img_url && typeof item.img_url[0] === "object") {
    try {
      const { Location } = await uploadFileToS3(
        item.img_url[0],
        "item",
        user.unique_id
      );
      img_url = Location;
    } catch (err) {
      console.log(err);
    }
  }

  const formattedVariationColorImages = [];
  if (item.variation_color_images) {
    for (let i = 0; i < item.variation_color_images.length; i++) {
      formattedVariationColorImages.push(item.variation_color_images[i]);
    }
  }
  const colorImages = formattedVariationColorImages
    ? await Promise.all(
        formattedVariationColorImages.map(async (image, index) => {
          if (image && typeof image[0] === "object") {
            try {
              const { Location } = await uploadFileToS3(
                image[0],
                "item",
                user.unique_i,
                index,
              );
              if (!Location) {
                return `placeholder-${index}`;
              }
              return Location;
            } catch (err) {
              console.log("Could not upload image");
              return null;
            }
          } else {
            return image && image[0]?.trim().length > 0
              ? image[0]
              : `placeholder-${index}`;
          }
        })
      )
    : undefined;

  // const dupes = [];
  // const foundDuplicate = colorImages.some(function (item, idx) {
  //   if (colorImages.indexOf(item) != idx) {
  //     dupes.push(item);
  //   }
  //   return colorImages.indexOf(item) != idx;
  // });

  // console.log("dupes", dupes);
  // console.log("has dupe?", foundDuplicate);

  const is_variation =
    item.is_variation === "true"
      ? true
      : item.is_variation === "false"
      ? false
      : item.is_variation;

  return {
    ...item,
    img_url:
      item.img_url && item.img_url.length > 0
        ? typeof item.img_url[0] === "object"
          ? img_url
          : item.img_url[0]
        : "",
    sale_price: undefined,
    shirt_cost: undefined,
    other_cost: undefined,
    variation_list: undefined,
    variation_color: undefined,
    variation_size: undefined,
    variation_arm: undefined,
    variation_color_images: undefined,
    creator_unique_id: undefined,
    created_date: undefined,
    last_updated_date: undefined,
    is_active:
      item.is_active === "true"
        ? true
        : item.is_active === "false"
        ? false
        : item.is_active,
    is_variation,
    item_variant_key_list: is_variation
      ? [
          variationConvert(item.variation_color, "color"),
          variationConvert(item.variation_size, "size"),
          variationConvert(item.variation_arm, "arm"),
          variationConvert(colorImages, "color_images"),
        ]
      : undefined,
    sku_list: is_variation ? getSkuList(item, false, colorImages) : undefined,
  };
};

export const getItemImporterSkuList = (item) => {
  if (!item.is_variation) {
    return [
      {
        name: item.name,
        is_active: item.is_active,
        item_kind: item.item_kind,
        item_type: item.item_type,
        process_type: item.process_type,
        sku_name: item.unique_id,
        sale_price: item.sale_price,
        shirt_cost: item.shirt_cost,
        other_cost: item.other_cost,
        item_variant_value: [],
      },
    ];
  }
  const formattedColorImages =
    item.colorImages.filter((img) => img).length > 0
      ? item.colorImages
      : undefined;
  return getSkuList(item, true, formattedColorImages);
};

export const getSkuList = (item, isDisplayed, colorImages) => {
  let colorIndex;
  const result = item.variation_list
    ?.map((color, index) => {
      colorIndex = index;
      return color.value.map((size) => {
        return size.value.map((arm) => {
          return {
            ...arm,
            color_name: color.name,
            size_name: size.name,
            img_url: item.variation_color_images
              ? colorImages[colorIndex]
              : undefined,
          };
        });
      });
    })
    .flat()
    .flat()
    .map((sku) => {
      return {
        name: isDisplayed ? item.name : undefined,
        item_kind: item.item_kind,
        item_type: item.item_type,
        item_desc: item.description,
        process_type: item.process_type,
        is_active:
          !item.is_active || item.is_active === "false"
            ? false
            : typeof sku.is_active === "boolean"
            ? sku.is_active
            : sku.is_active === "true"
            ? true
            : sku.is_active === "false"
            ? false
            : sku.is_active,
        img_url: sku.img_url,
        sale_price: parseFloat(sku.sale_price),
        shirt_cost: parseFloat(sku.shirt_cost),
        other_cost: parseFloat(sku.other_cost),
        sku_name: sku.sku_name,
        item_variant_value: [
          {
            item_variant_key_name: "color",
            item_variant_value_name: sku.color_name,
          },
          {
            item_variant_key_name: "size",
            item_variant_value_name: sku.size_name,
          },
          {
            item_variant_key_name: "arm",
            item_variant_value_name: sku.name,
          },
        ],
      };
    });
  return result;
};

export const formatItemUpdatePayload = async (item, user) => {
  const formattedPayload = await formatItemPayload(item, user);

  delete formattedPayload.creator_unique_id;
  delete formattedPayload.created_date;
  delete formattedPayload.last_updated_date;
  delete formattedPayload.last_updator_unique_id;

  return formattedPayload;
};

export const formatSkuDetailPayload = (item) => {
  return {
    uniqueInput: {
      sku_name: item.unique_id,
    },
    updateInput: {
      img_url: item.img_url ? item.img_url[0] : undefined,
      is_active:
        item.is_active === "true"
          ? true
          : item.is_active === "false"
          ? false
          : item.is_active,
      item_desc: item.description,
      item_kind: item.item_kind,
      item_type: item.item_type,
      sale_price: item.sale_price,
      shirt_cost: item.shirt_cost,
      other_cost: item.other_cost,
      process_type: item.process_type,
    },
  };
};

export const errorMessageHandler = (error, message) => {
  const formatError = JSON.stringify(error);
  if (formatError.includes("Unique constraint failed")) {
    return message || "หมายเลขเอกสารซ้ำกรุณาระบุใหม่";
  } else {
    return;
  }
};

export const formatRequiredString = (value) => {
  return value.toString().trim();
};

export const formatString = (value) => {
  return value?.toString().trim() ?? "";
};

export const formatFloat = (input, points) => {
  return !isNaN(parseFloat(input))
    ? parseFloat(parseFloat(input).toFixed(points || 2))
    : 0;
};

export const formatBoolean = (value, defaultValue = false) => {
  if ((typeof value === "boolean" && value) || value === "TRUE") {
    return true;
  }
  return defaultValue;
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index && value !== null;
};

export const uniqueArray = (arr) => {
  return arr?.filter(onlyUnique);
};

export const removeNullFromObj = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export const uniqueArrayOfObject = (arr, keyProps) => {
  const kvArray = arr.map((entry) => {
    const key = keyProps.map((k) => entry[k]).join("|");
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
};

export const dateFilterModel = (filterObj) => {
  if (!filterObj) {
    return undefined;
  }
  if (filterObj.type === "equals") {
    const nextDay = new Date(filterObj.dateFrom);
    nextDay.setDate(nextDay.getDate() + 1);
    return {
      filterType: "date",
      type: "inRange",
      filter: filterObj.dateFrom
        ? new Date(filterObj.dateFrom).toISOString()
        : undefined,
      filterTo: nextDay ? nextDay.toISOString() : undefined,
    };
  }
  return {
    filterType: "date",
    type: filterObj.type,
    filter: filterObj.dateFrom
      ? new Date(filterObj.dateFrom).toISOString()
      : undefined,
    filterTo: filterObj.dateTo
      ? new Date(filterObj.dateTo).toISOString()
      : undefined,
  };
};
