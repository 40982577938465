import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/dataTransformer";
import ControlledNumberTextField from "../../Controlled/ControlledNumberTextField";
import ControlledServerSideCreatable from "../../Controlled/ControlledSSCreatable";
import ControlledTextField from "../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../Custom/CustomizedBox";

const vatTypeOptions = ["ไม่มี", "0", "7"];

const SummaryForm = ({ control, errors, setValue, disabled }) => {
  const { t } = useTranslation();

  const watchItemList = useWatch({
    control,
    name: "item_list",
    defaultValue: [],
  });

  const watchAdditionalDiscount = useWatch({
    control,
    name: "additional_discount",
  });

  const watchVatType = useWatch({
    control,
    name: "vat_type",
  });

  const totalAmountBeforeDiscount = () => {
    const totalAmountBeforeDiscount = watchItemList?.reduce(
      (prev, curr) => parseFloat(prev) + curr.qty * curr.price_per_unit,
      0
    );
    return totalAmountBeforeDiscount;
  };

  const sumAllDiscount = () => {
    const sumAllDiscount = watchItemList?.reduce((prev, curr) => {
      if (!curr.discount) {
        return parseFloat(prev);
      }
      return parseFloat(prev) + parseInt(curr.qty) * parseFloat(curr.discount);
    }, 0);
    return sumAllDiscount;
  };

  const totalDiscountAmount = () => {
    return parseFloat(sumAllDiscount()) + watchAdditionalDiscount;
  };

  const totalAmountAfterDiscount = () => {
    return (
      parseFloat(totalAmountBeforeDiscount()) -
      parseFloat(totalDiscountAmount())
    );
  };

  const totalAmountExcludedVat = () => {
    let vatAmount;
    if (!isNaN(parseFloat(watchVatType))) {
      vatAmount = 100 + parseFloat(watchVatType);
    } else {
      vatAmount = 100;
    }
    return (totalAmountAfterDiscount() * 100) / vatAmount;
  };

  const totalVatAmount = () => {
    return totalAmountAfterDiscount() - totalAmountExcludedVat();
  };

  return (
    <CustomizedBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography ml={1} mb={2} fontWeight="bold">
            {t("sales.remark")}
          </Typography>
          <ControlledTextField
            name="remark"
            control={control}
            error={Boolean(errors?.remark)}
            helperText={errors?.remark && errors?.remark?.message}
            multiline
            rows={4}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                my: 2,
                mx: 1,
              }}
            >
              <Typography>รวมทั้งหมด</Typography>
              <Typography sx={{ width: "170px", textAlign: "right", mr: 4 }}>
                {formatNumber(totalAmountBeforeDiscount())}
              </Typography>
              <Typography>บาท</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                my: 2,
                mx: 1,
              }}
            >
              <Typography>รวมส่วนลดรายสินค้า</Typography>
              <Typography sx={{ width: "170px", textAlign: "right", mr: 4 }}>
                {formatNumber(sumAllDiscount())}
              </Typography>
              <Typography>บาท</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                my: 2,
                mx: 1,
              }}
            >
              <Typography>ส่วนลดเพิ่มเติม</Typography>
              <Box sx={{ width: "170px", textAlign: "right", mx: 2 }}>
                <ControlledNumberTextField
                  name="additional_discount"
                  control={control}
                  error={Boolean(errors?.additional_discount)}
                  helperText={
                    errors?.additional_discount &&
                    errors?.additional_discount?.message
                  }
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  disabled={disabled}
                />
              </Box>
              <Typography>บาท</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                my: 2,
                mx: 1,
              }}
            >
              <Typography>รวมส่วนลดทั้งหมด</Typography>
              <Typography sx={{ width: "170px", textAlign: "right", mr: 4 }}>
                {formatNumber(totalDiscountAmount())}
              </Typography>
              <Typography>บาท</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                backgroundColor: "#EFF2FF",
                my: 2,
                p: 1,
              }}
            >
              <Typography fontWeight={600}>ยอดรวมทั้งสิ้น</Typography>
              <Typography
                sx={{
                  width: "170px",
                  textAlign: "right",
                  mr: 4,
                  fontWeight: 600,
                }}
              >
                {formatNumber(totalAmountAfterDiscount())}
              </Typography>
              <Typography fontWeight={600}>บาท</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                my: 2,
                mx: 1,
              }}
            >
              <Typography sx={{ mx: 2 }}>
                {t("sales.order.vat_type")}
              </Typography>
              <Box width={100}>
                <ControlledServerSideCreatable
                  name="vat_type"
                  control={control}
                  error={Boolean(errors?.vat_type)}
                  helperText={errors?.vat_type && errors?.vat_type.message}
                  viewOnly={disabled}
                  documentType="order"
                  defaultOptions={vatTypeOptions}
                  setValue={setValue}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                />
              </Box>
              <Typography sx={{ width: "170px", textAlign: "right", mr: 4 }}>
                {formatNumber(totalVatAmount())}
              </Typography>
              <Typography>บาท</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                my: 2,
                mx: 1,
              }}
            >
              <Typography>ราคาไม่รวมภาษีมูลค่าเพิ่ม</Typography>
              <Typography sx={{ width: "170px", textAlign: "right", mr: 4 }}>
                {formatNumber(totalAmountExcludedVat())}
              </Typography>
              <Typography>บาท</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default SummaryForm;
