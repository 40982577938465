import AgGrid from "./AgGrid";
import { Typography } from "@mui/material";
import { CustomizedBox } from "../Custom/CustomizedBox";
import ControlledSelect from "../Controlled/ControlledSelect";

const mockData = [
  {
    access_key_name: "inventory",
    label: "คลังสินค้า",
    permission: "NONE",
  },
  { access_key_name: "contact", label: "ผู้ติดต่อ", permission: "VIEW__ONLY" },
  { access_key_name: "sales", label: "การขาย", permission: "NONE" },
  { access_key_name: "manufacture", label: "การผลิต", permission: "EDITABLE" },
  { access_key_name: "config", label: "ต้นทุนการผลิต", permission: "EDITABLE" },
  { access_key_name: "user", label: "ผู้ใช้งาน", permission: "EDITABLE" },
];

const permissionOptions = [
  { id: 1, label: "ดูเท่านั้น", value: "VIEW__ONLY" },
  { id: 2, label: "แก้ไขได้", value: "EDITABLE" },
  { id: 3, label: "ไม่มีสิทธิ์เข้าถึง", value: "NONE" },
];

const PermissionTable = ({
  gridRef,
  t,
  rowData,
  control,
  getValues,
  viewOnly,
  errors,
}) => {
  const columnDefs = [
    {
      field: "access_key_name",
      headerName: t("user.rbac.function"),
      filter: "agTextColumnFilter",
      flex: 1,
      valueFormatter: (params) => {
        return t(`user.rbac.permissions.${params.value}`);
      },
    },
    {
      field: "access_value",
      headerName: t("user.rbac.permission"),
      filter: "agTextColumnFilter",
      flex: 1,
      autoHeight: true,
      cellRenderer: ({ value, node }) => {
        const index = node.rowIndex;
        if (!viewOnly && control) {
          return (
            <ControlledSelect
              name={`permission_list.${index}.access_value`}
              error={errors?.permission_list?.[index]?.access_value}
              control={control}
              options={permissionOptions}
              disabled={viewOnly}
              required
            />
          );
        } else {
          const accessValue = getValues
            ? getValues(`permission_list.${index}.access_value`)
            : value;
          let content = accessValue;
          switch (accessValue) {
            case "VIEW__ONLY":
              content = (
                <Typography
                  type="body2"
                  sx={{ color: (theme) => theme.palette.info.main }}
                >
                  ดูเท่านั้น
                </Typography>
              );
              break;
            case "EDITABLE":
              content = (
                <Typography
                  type="body2"
                  sx={{ color: (theme) => theme.palette.success.main }}
                >
                  แก้ไขได้
                </Typography>
              );
              break;
            case "NONE":
              content = (
                <Typography
                  type="body2"
                  sx={{ color: (theme) => theme.palette.error.main }}
                >
                  ไม่มีสิทธิ์เข้าถึง
                </Typography>
              );
              break;
            default:
              content = accessValue;
          }
          return content;
        }
      },
      cellStyle: {
        padding: "8px",
      },
    },
  ];

  return (
    <CustomizedBox>
      <Typography sx={{ fontWeight: "bold", ml: 1, mb: 2 }}>
        {t("user.rbac.access")}
      </Typography>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        autoHeight
        rowData={rowData || mockData}
        disableFloatingFilter
        disabledSidebar
      />
    </CustomizedBox>
  );
};

export default PermissionTable;
