import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import { useRef, useState, useEffect } from "react";
// import React from "react";
// import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedTotalBox from "../../../components/Custom/CustomizedTotalBox";
import AgGrid from "../../../components/Table/AgGrid";
import { useAuth } from "../../../hooks/use-auth";
import { exportAsExcel } from "../../../utils/exporter";
import { itemExportFormatter } from "../../../utils/inventoryPayloadFormatter";
import { itemSkuColumnDefs } from "../../../components/Table/ColumnDefs/Inventory/Items/sku";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  getAllItemsSku,
  getAllItemsExport,
} from "../../../features/Inventory/inventory-actions";
import { inventoryActions } from "../../../features/Inventory/inventory-slice";

const Items = () => {
  const gridRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("type");
  const navigate = useNavigate();
  const { permissions } = useAuth();
  const { allItemsCount, allItemsExport, isLoading } = useSelector(
    (state) => state.inventory
  );
  const [isInit, setIsInit] = useState(false);

  const inventoryPermission = permissions.inventory;

  const tabs = [
    {
      label: "ทั้งหมด",
      path: `${pathname}`,
    },
  ];

  const currentTab = tab ? pathname + `?type=${tab}` : pathname;

  useEffect(() => {
    return () => {
      dispatch(inventoryActions.resetAllItems());
    };
  }, [dispatch]);

  const formatFilterModel = (filterModel) => {
    const isActiveFilter = filterModel?.["is_active"];
    const activeFilterValue =
      isActiveFilter?.values[0] === "ใช้งาน" ? "true" : "false";
    const formattedFilterModel = {
      sku_name: {
        ...filterModel.sku_name,
        mode: "insensitive",
      },
      item_name: {
        ...filterModel.item_name,
        mode: "insensitive",
      },
      item_desc: {
        ...filterModel.item_desc,
        mode: "insensitive",
      },
      process_type: {
        ...filterModel.process_type,
        mode: "insensitive",
      },
      item_kind: {
        ...filterModel.item_kind,
        mode: "insensitive",
      },
      item_type: {
        ...filterModel.item_type,
        mode: "insensitive",
      },
      is_active:
        isActiveFilter && isActiveFilter?.values?.length === 1
          ? {
              filterType: "boolean",
              type: "equals",
              filter: activeFilterValue,
            }
          : undefined,
    };
    return formattedFilterModel;
  };

  const exportHandler = async () => {
    try {
      const exportCallback = (dataToExport) => {
        exportAsExcel(
          dataToExport,
          t("inventory.items.index"),
          null,
          t,
          "item"
        );
      };
      dispatch(
        getAllItemsExport(
          {
            startRow: 0,
            endRow: Math.pow(10, 9),
            filterModel: formatFilterModel(
              gridRef.current.api.getFilterModel()
            ),
          },
          enqueueSnackbar,
          exportCallback
        )
      );
    } catch (err) {
      console.log("ไม่สามารถนำออกสินค้าได้");
    }
  };

  const datasource = {
    async getRows(params) {
      const request = params.request;
      const filterModel = formatFilterModel(request.filterModel);
      const skuNameSortModel = request.sortModel?.find(
        (column) => column.colId === "sku_name"
      );
      const sortModel = [
        ...request.sortModel,
        {
          colId: "sku_name",
          sort: skuNameSortModel ? skuNameSortModel.sort : "desc",
        },
      ];
      dispatch(
        getAllItemsSku(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterModel,
            sortModel: sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const rowSelectHandler = (params) => {
    const item_unique_id = params.data.sku.item_unique_id;
    navigate(`/inventory/items/${encodeURIComponent(item_unique_id)}`);
  };

  const onFirstDataRendered = () => {
    const instance = gridRef.current.api.getFilterInstance("is_active");
    if (instance) {
      instance.setModel({ filterType: "set", values: ["ใช้งาน"] });
    }
    gridRef.current.api.onFilterChanged();
  };

  const onAddItem = () => {
    navigate(`${pathname}/add`);
  };

  return (
    <>
      {inventoryPermission === "NONE" && <Navigate to="/no-permission" />}
      {isLoading.allItemsExport && <LinearProgress />}
      <Box
        sx={{
          mt: 3,
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{t("inventory.items.index")}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: { xs: 2, md: 0 },
          }}
        >
          {inventoryPermission !== "VIEW__ONLY" && (
            <Box mx={1}>
              <CustomizedButton
                title={t("inventory.items.importExcel")}
                variant="outlined"
                onClick={() => navigate(`${pathname}/import`)}
                testId="contact-import-btn"
              />
            </Box>
          )}
          <Box mx={1}>
            <CustomizedButton
              title={t("inventory.items.exportExcel")}
              variant="outlined"
              onClick={exportHandler}
              testId="contact-export-btn"
              disabled={isLoading.allItemsExport}
            />
          </Box>
          {inventoryPermission !== "VIEW__ONLY" && (
            <Box xl={1}>
              <CustomizedButton
                sx={{ mr: 2 }}
                title={t("inventory.items.add")}
                variant="contained"
                onClick={onAddItem}
                testId="item-create-btn"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <CustomizedTotalBox
            title={t("inventory.items.all")}
            total={allItemsCount}
          />
        </Grid>
      </Grid>
      <Box sx={{ maxWidth: "100%", overflow: "hidden" }}>
        <CustomizedTab tabs={tabs} currentTab={currentTab} scrollable table />
      </Box>
      <AgGrid
        ref={gridRef}
        columnDefs={itemSkuColumnDefs(t, false, false, false, true)}
        height={649}
        onRowDoubleClicked={rowSelectHandler}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        testId={"items-table"}
        path="/inventory/items"
      />
    </>
  );
};

export default Items;
