import { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Typography, Box, IconButton } from "@mui/material";
import ActivityLog from "./ActivityLog";
import { useForm, Controller } from "react-hook-form";
import CustomizedButton from "../Custom/CustomizedButton";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";
import CustomizedMentionsInput from "../Custom/CustomizedMentionsInput";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import { extractMentionData } from "../../utils/dataTransformer";
import { useDispatch, useSelector } from "react-redux";
import {
  createActivityLogs,
  getActivityLogsById,
} from "../../features/ActivityLogs/activityLogs-actions";
import { useAuth } from "../../hooks/use-auth";
import { uploadFileToS3 } from "../../utils/s3";
import DropzoneUIActivitylogs from "./DropzoneUIActivitylogs";
import { useSnackbar } from "notistack";
import { activityLogsActions } from "../../features/ActivityLogs/activityLogs-slice";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { getAllUsersNoAggrid } from "../../features/User/Account/account-actions";

const RightDrawer = ({
  open,
  onClose,
  title,
  uniqueId,
  documentIdToShow,
  modelType,
  isApproval,
  approvalLogs,
  viewOnly,
}) => {
  const { id } = useParams();
  const { user } = useAuth();
  const [files, setFiles] = useState([]);
  const { control, setValue, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      comment: "",
      attachment_list: [],
    },
  });
  const dispatch = useDispatch();
  const { logs } = useSelector((state) => state.activityLogs);
  const { allUsers } = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (open && !isApproval) {
      dispatch(
        getActivityLogsById({
          uniqueIdInput: documentIdToShow || id,
        })
      );
      dispatch(getAllUsersNoAggrid({}));
    }
    return () => {
      dispatch(activityLogsActions.resetActivity());
    };
  }, [isApproval, dispatch, id, open]);

  const onCommentSubmit = async (data) => {
    // extract mention list from comment
    for (const [index, value] of data.attachment_list.entries()) {
      if (value && typeof value === "object") {
        const { Location } = await uploadFileToS3(
          value,
          "user",
          user.unique_id
        );
        data.attachment_list[index].url = Location;
      }
    }
    const finalData = {
      unique_id: uniqueId,
      activity_details: { message: data.comment || "" },
      activity_type: "comment",
      created_date: new Date(),
      attachment_list: data.attachment_list?.map((file) => {
        return {
          name: file?.fileName,
          url: file?.url,
        };
      }),
      model_type: modelType,
    };
    dispatch(
      createActivityLogs({ createInput: finalData }, reset, enqueueSnackbar)
    );
  };

  const users =
    allUsers?.map((user) => {
      return {
        ...user,
        id: user.unique_id,
        display: user.first_name + " " + user.last_name,
      };
    }) || [];

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          width: fullScreen || 570,
          borderRadius: "10px 0px 0px 10px",
          padding: "16px",
        },
      }}
      ModalProps={{ sx: { zIndex: "rightDrawer" } }}
    >
      <CustomizedScrollbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>{title}</Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ height: "auto" }}
              size="small"
            >
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
          {!isApproval && !viewOnly && (
            <>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <CustomizedMentionsInput data={users} {...field} />
                )}
              />
              <DropzoneUIActivitylogs
                files={files}
                setFiles={setFiles}
                setValue={setValue}
                valueToSet={"attachment_list"}
                getValues={getValues}
                mainControl={control}
                reset={reset}
              />
              <CustomizedButton
                title={"บันทึก"}
                variant="contained"
                sx={{ mt: 3, mb: 1, width: "20%" }}
                onClick={handleSubmit(onCommentSubmit)}
              />
            </>
          )}
          <ActivityLog
            logData={isApproval ? approvalLogs : logs}
            isApproval={isApproval}
            viewOnly={viewOnly}
          />
        </Box>
      </CustomizedScrollbar>
    </Drawer>
  );
};

export default RightDrawer;
