import { forwardRef } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import ControlledServerSideCreatable from "../../Controlled/ControlledSSCreatable";
import { CustomizedBox } from "../../Custom/CustomizedBox";

const CancelDetailForm = forwardRef(
  ({ control, errors, disabled, setValue }, ref) => {
    const { t } = useTranslation();

    const watchPendingCancel = useWatch({
      control,
      name: "pending_cancel",
    });

    return (
      <CustomizedBox ref={ref}>
        <Typography ml={1} mb={2} fontWeight="bold">
          {t("sales.order.cancel_detail")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledServerSideCreatable
              name="reject_remark"
              title={t("manufacture.order.reject_remark")}
              control={control}
              error={Boolean(errors?.reject_remark)}
              helperText={
                errors?.reject_remark && errors?.reject_remark.message
              }
              viewOnly={!watchPendingCancel && disabled}
              documentType="manufacture_order"
              defaultOptions={[]}
              setValue={setValue}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
    );
  }
);

export default CancelDetailForm;
