import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import CustomizedDashboardContainer from '../Custom/CustomizedDashboardContainer'
import { useNavbar } from '../../hooks/use-navbar'
import LeftNavbar from './LeftNavbar'
import TopNavbar from './TopNavbar.js'

const DashboardLayout = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { sidebar } = useNavbar()
  return (
    <>
      <CustomizedDashboardContainer
        open={!isSmallScreen && sidebar.isSidebarOpen}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            height: '100%',
            maxWidth: '1440px',
            p: 3
          }}
        >
          <Outlet />
        </Box>
      </CustomizedDashboardContainer>
      <TopNavbar />
      <LeftNavbar />
    </>
  )
}

export default DashboardLayout
