import { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import ContactService from "../../services/Contact";
import ContactForm from "../../components/Form/Contact/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema, validation } from "../../components/Form/Contact/schema";
import { useAuth } from "../../hooks/use-auth";
import { useContact } from "../../hooks/use-contact";
import ActivityLogsService from "../../services/ActivityLogs";
import {
  createActivityLogEditPayload,
  createActivityLogPayload,
} from "../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../utils/dataTransformer";

const NewContactContainer = ({ isTest }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { contactSnapshot },
  } = useContact();
  const { user, permissions } = useAuth();
  const generalSectionRef = useRef();
  const [isInit, setIsInit] = useState(false);
  const [contactTypeError, setContactTypeError] = useState(false);
  const [contactTags, setContactTags] = useState([]);
  const [mode, setMode] = useState("สร้าง");
  const [addressList, setAddressList] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    reset,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues: { ...schema },
    resolver: yupResolver(validation),
    mode: "onBlur",
  });
  const isEditPage = useMemo(
    () => pathname.split("/")[2] !== "add",
    [pathname]
  );

  const contactPermission = permissions.contact;

  const isVendorChecked = watch("is_vendor");
  const isCustomerChecked = watch("is_customer");

  useEffect(() => {
    if (isVendorChecked || isCustomerChecked) {
      setContactTypeError(false);
    }
  }, [isVendorChecked, isCustomerChecked]);

  useEffect(() => {
    if (!isInit) {
      if (isEditPage) {
        const unique_id = decodeURIComponent(pathname.split("/")[2]);
        const getLoadedContact = async () => {
          try {
            const loadedContact = await ContactService.getContact({
              unique_id,
            });
            const formattedContactChannelList =
              loadedContact.contact_channel_list.map((channel) => {
                return {
                  ...channel,
                  contact_channel_name:
                    channel.contact_channel_type === "เบอร์โทรศัพท์"
                      ? channel.contact_channel_name?.substring(1) ?? ""
                      : channel.contact_channel_name,
                };
              });
            setAddressList(loadedContact.address_list);
            reset({
              ...loadedContact,
              created_date: dayjs.unix(loadedContact.created_date).toDate(),
              contact_channel_list: formattedContactChannelList,
            });
            dispatch({ type: "initialize", payload: loadedContact });
            setIsInit(true);
          } catch (err) {
            console.log(err);
            enqueueSnackbar("ไม่พบข้อมูลผู้ติดต่อ", {
              variant: "error",
            });
            navigate("/contact", { replace: true });
          }
        };
        getLoadedContact();
        setMode("ดูข้อมูล");
      } else {
        dispatch({ type: "initialize", payload: null });
      }
      const getContactTags = async () => {
        try {
          const contactTags = await ContactService.getContactTags();
          setContactTags(contactTags);
        } catch (err) {
          console.log("Could not get contact tags");
        }
      };
      getContactTags();
      setIsInit(true);
    }
  }, [
    isInit,
    reset,
    pathname,
    dispatch,
    setValue,
    isEditPage,
    enqueueSnackbar,
    navigate,
  ]);

  const formatSubmittedData = async (data) => {
    // const unixCreatedDate = dayjs(data.created_date).unix();
    const formattedAddressList = data.address_list.map((address) => {
      return {
        ...address,
        postal_code: address.postal_code?.toString().trim() ?? undefined,
      };
    });
    const formattedContactChannelList = data.contact_channel_list.map(
      (channel) => {
        return {
          ...channel,
          contact_channel_name:
            channel.contact_channel_type === "เบอร์โทรศัพท์" &&
            channel.contact_channel_name?.length > 0
              ? `0${channel.contact_channel_name?.toString().trim()}` ?? ""
              : channel.contact_channel_name?.toString().trim() ?? "",
        };
      }
    );
    const updatedContactData = {
      ...data,
      id: undefined,
      unique_id: isEditPage ? undefined : data.unique_id,
      // change these later
      created_date: undefined,
      created_by: undefined,
      tag_contact: undefined,
      contact_channel_list: formattedContactChannelList,
      address_list: formattedAddressList,
      is_active:
        typeof data.is_active === "boolean"
          ? data.is_active
          : data.is_active === "true"
          ? true
          : false,
      creator_unique_id: data.creator_unique_id
        ? data.creator_unique_id
        : user.unique_id,
    };
    return updatedContactData;
  };

  const onValidate = () => {
    const contactIdError = getFieldState("unique_id").error;
    const contactTypeError = getFieldState("contact_type").error;
    const contactNameError = getFieldState("name").error;
    const IdentityNoError = getFieldState("identity_no").error;
    const isVendorError = getFieldState("is_vendor").error;
    const isCustomerError = getFieldState("is_customer").error;
    const contactChannelEmailError =
      getFieldState("contact_channel_list").error?.filter((channel) => {
        return ["email", "Email", "E-mail", "E-Mail"].includes(
          channel.contact_channel_name.type
        );
      }).length ?? false;
    const contactChannelPhoneError =
      getFieldState("contact_channel_list").error?.filter((channel) => {
        return channel.contact_channel_name.type === "phone";
      }).length ?? false;
    const errorsCount =
      (contactIdError ? 1 : 0) +
      (contactTypeError ? 1 : 0) +
      (contactNameError ? 1 : 0) +
      (IdentityNoError ? 1 : 0) +
      (isVendorError || isCustomerError ? 1 : 0) +
      (contactChannelEmailError ? 1 : 0) +
      (contactChannelPhoneError ? 1 : 0);
    if (errorsCount > 1) {
      enqueueSnackbar("กรุณาระบุข้อมูล", {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(contactIdError)) {
      enqueueSnackbar(contactIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(contactTypeError)) {
      enqueueSnackbar(contactTypeError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(contactNameError)) {
      enqueueSnackbar(contactNameError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(IdentityNoError)) {
      enqueueSnackbar(IdentityNoError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(contactChannelEmailError)) {
      enqueueSnackbar("กรุณากรอกอีเมลให้ถูกต้อง", {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(contactChannelPhoneError)) {
      enqueueSnackbar("กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง", {
        variant: "error",
      });
    }
    if (Boolean(isVendorError) || Boolean(isCustomerError)) {
      setContactTypeError(true);
      if (errorsCount === 1) {
        enqueueSnackbar("กรุณาเลือกประเภทผู้ติดต่อ", {
          variant: "error",
        });
      }
    }
    if (errorsCount > 0) {
      generalSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const submitCreateContactHandler = async (data) => {
    onValidate();
    const formattedFormData = await formatSubmittedData(data);
    const { unique_id } = formattedFormData;
    delete formattedFormData.last_updator_unique_id;
    try {
      const createdContact = await ContactService.createContact(
        formattedFormData
      );
      const createActivityLog = createActivityLogPayload(
        formattedFormData,
        "contact",
        "สร้างผู้ติดต่อ"
      );
      await ActivityLogsService.createActivityLogs({
        createInput: createActivityLog,
      });
      enqueueSnackbar("บันทึกสำเร็จ", {
        variant: "success",
      });
      dispatch({ type: "uninitialize" });
      dispatch({ type: "initialize", payload: createdContact });
      navigate(`/contact/${unique_id}`);
      setMode("ดูข้อมูล");
    } catch (err) {
      console.log(err);
      const uniqueIdError = errorMessageHandler(
        err,
        "รหัสผู้ติดต่อนี้มีในระบบแล้วกรุณาระบุรหัสผู้ติดต่อใหม่"
      );
      if (uniqueIdError) {
        enqueueSnackbar(uniqueIdError, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("ไม่สามารถบันทึกได้", {
          variant: "error",
        });
      }
    }
  };

  const submitEditContactHandler = async (data) => {
    const { unique_id } = data;
    const formattedFormData = await formatSubmittedData(data);
    delete formattedFormData.creator_unique_id;
    delete formattedFormData.last_updator_unique_id;
    delete formattedFormData.last_updated_date;

    try {
      await ContactService.updateContact({ unique_id }, formattedFormData);
      enqueueSnackbar("แก้ไขผู้ติดต่อสำเร็จ", {
        variant: "success",
      });
      try {
        const logPayload = createActivityLogEditPayload(
          { unique_id },
          "contact",
          "แก้ไขผู้ติดต่อ"
        );
        await ActivityLogsService.createActivityLogs({
          createInput: logPayload,
        });
        navigate(`/contact/${unique_id}`);
        setMode("ดูข้อมูล");
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("ไม่สามารถแก้ไขผู้ติดต่อ", {
        variant: "error",
      });
    }
  };

  const resetFormHandler = async () => {
    if (contactSnapshot) {
      reset({
        ...contactSnapshot,
        created_date: dayjs.unix(contactSnapshot.created_date).toDate(),
      });
    } else {
      const unique_id = getValues("unique_id");
      reset({ ...schema, unique_id });
    }
    generalSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const errorHandler = () => {
    onValidate();
  };

  const tagOptions = contactTags.map((tag) => tag.name);

  return (
    <>
      {contactPermission === "NONE" && <Navigate to="/no-permission" />}
      <ContactForm
        ref={generalSectionRef}
        control={control}
        onSubmit={handleSubmit(submitCreateContactHandler, errorHandler)}
        onSubmitEdit={handleSubmit(submitEditContactHandler, errorHandler)}
        onCancel={resetFormHandler}
        setValue={setValue}
        getValues={getValues}
        trigger={trigger}
        watch={watch}
        reset={reset}
        errors={errors}
        id={pathname.split("/")[2]?.replaceAll("%20", " ")}
        contactTypeError={contactTypeError}
        contactTags={tagOptions}
        isContactPage
        isTest={isTest}
        isEditPage={isEditPage}
        mode={mode}
        setMode={setMode}
        addressList={addressList}
      />
      {/* )} */}
    </>
  );
};

export default NewContactContainer;
