import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const StatusChangeConfirmation = ({
  currentStatus,
  targetStatus,
  openStatusChangeConfirmation,
  statusChangeConfirmationAction,
  closeStatusChangeConfirmationHandler,
  translate,
}) => {
  return (
    <ModalUI
      title={`ยืนยันการเปลี่ยนสถานะจาก ${translate(
        `status.${currentStatus}`
      )} เป็น ${targetStatus ?? "-"}`}
      open={openStatusChangeConfirmation}
      handleClose={closeStatusChangeConfirmationHandler}
      maxWidth="sm"
      fullWidth
    >
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeStatusChangeConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={statusChangeConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default StatusChangeConfirmation;
