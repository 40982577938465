import * as Yup from "yup";

export const schema = {
  created_date: "",
  is_customer: true,
  is_vendor: false,
  unique_id: "",
  created_by: null,
  creator_unique_id: "",
  last_updator_unique_id: "",
  contact_type: "บุคคลธรรมดา",
  name: "",
  last_name: "",
  title: "",
  identity_no: "",
  contact_channel_list: [
    { contact_channel_type: "เบอร์โทรศัพท์", contact_channel_name: "" },
  ],
  is_active: true,
  inactive_remark: "",
  tag_list: [],
  tag_contact: "",
  address_list: [
    {
      address_type: "ที่อยู่หลัก",
      is_default_address: true,
      same_as_default_address: null,
      name: "",
      phone: "",
      address_number: "",
      building: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      zone: "",
      country: "",
    },
    {
      address_type: "ที่อยู่จัดส่ง",
      is_default_address: false,
      same_as_default_address: false,
      name: "",
      phone: "",
      address_number: "",
      building: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      zone: "",
      country: "",
    },
  ],
  contact_person_list: [],
  contact_remark: "",
  gender: "ไม่ระบุ",
  main_interested_field: "",
  secondary_interested_field: "",
  business_group_field: "",
  business_type_field: "",
  customer_quality: "",
};

export const validation = Yup.object().shape(
  {
    unique_id: Yup.string().required("กรุณาระบุรหัสผู้ติดต่อ"),
    is_customer: Yup.boolean().when("is_vendor", {
      is: (is_vendor) => is_vendor === false,
      then: Yup.boolean()
        .required("กรุณาเลือกประเภทผู้ติดต่อ")
        .oneOf([true], "กรุณาเลือกประเภทผู้ติดต่อ"),
      otherwise: Yup.boolean(),
    }),
    is_vendor: Yup.boolean().when("is_customer", {
      is: (is_customer) => is_customer === false,
      then: Yup.boolean()
        .required("กรุณาเลือกประเภทผู้ติดต่อ")
        .oneOf([true], "กรุณาเลือกประเภทผู้ติดต่อ"),
      otherwise: Yup.boolean(),
    }),
    contact_type: Yup.string().required("กรุณาเลือกประเภทกิจการ"),
    name: Yup.string().when("contact_type", {
      is: (contact_type) => contact_type !== "",
      then: Yup.string().when("contact_type", {
        is: (contact_type) => contact_type === "นิติบุคคล",
        then: Yup.string().required("กรุณาระบุชื่อกิจการ"),
        otherwise: Yup.string().required("กรุณาระบุชื่อ"),
      }),
      otherwise: Yup.string().required("กรุณาระบุชื่อ"),
    }),
    last_name: Yup.string(),
    identity_no: Yup.string()
      .nullable()
      .test(
        "len",
        'เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"',
        (val) => !val || (val && val.length <= 13)
      ),
    contact_channel_list: Yup.array().of(
      Yup.object().shape({
        contact_channel_type: Yup.string().nullable(),
        contact_channel_name: Yup.string().test("is-email", (value, ctx) => {
          if (value) {
            switch (ctx.parent.contact_channel_type) {
              case "Email":
              case "E-Mail":
              case "E-mail": {
                const emailIsValid = Yup.string()
                  .nullable()
                  .email("Email invalid")
                  .isValidSync(value);
                return !emailIsValid
                  ? ctx.createError({
                      message: "กรุณากรอกอีเมลให้ถูกต้อง",
                      path: ctx.path,
                      type: "email",
                    })
                  : true;
              }
              case "เบอร์โทรศัพท์": {
                const phoneIsValid = Yup.number().nullable().isValidSync(value);
                return !phoneIsValid
                  ? ctx.createError({
                      message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
                      path: ctx.path,
                      type: "phone",
                    })
                  : true;
              }
              default:
                return Yup.string().nullable();
            }
          }
          return true;
        }),
      })
    ),
    address_list: Yup.array().of(
      Yup.object().shape({
        is_default_address: Yup.boolean(),
        same_as_default_address: Yup.boolean().nullable(),
        address_number: Yup.string().nullable(),
        building: Yup.string().nullable(),
        name: Yup.string().nullable(),
        phone: Yup.string().nullable(),
        address_type: Yup.string().nullable(),
        country: Yup.string().nullable(),
        sub_district: Yup.string().nullable(),
        district: Yup.string().nullable(),
        province: Yup.string().nullable(),
        zone: Yup.string().nullable(),
        postal_code: Yup.lazy((value) =>
          value === "" ? Yup.string() : Yup.number()
        ),
      })
    ),
  },
  ["is_customer", "is_vendor"]
);
