import { Box, InputLabel, Typography } from "@mui/material";

const LabelInput = ({ label, value, endAdornment }) => {
  return (
    <Box mx={2}>
      <InputLabel sx={{ fontSize: 11.11 }}>{label}</InputLabel>
      {endAdornment ? (
        <Box display="flex" justifyContent="space-between">
          <Typography>{value || "-"}</Typography>
          <InputLabel>{endAdornment}</InputLabel>
        </Box>
      ) : (
        <Typography>{value || "-"}</Typography>
      )}
    </Box>
  );
};

export default LabelInput;
