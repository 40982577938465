import { Controller } from "react-hook-form";
import CustomizedComboBox from "../Custom/CustomizedComboBox";

const ControlledComboBox = ({
  name,
  label,
  control,
  error,
  helperText,
  disabled,
  required,
  options,
  onChange,
  isOptionEqualToValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedComboBox
          {...field}
          error={Boolean(error)}
          helperText={helperText || error?.message}
          label={label}
          options={options}
          onChange={
            onChange
              ? (_, newValue) => onChange(_, newValue, field)
              : (_, newValue) => {
                  if (newValue !== null) {
                    field.onChange(newValue.label || newValue);
                  } else {
                    field.onChange(null);
                  }
                }
          }
          disabled={disabled}
          required={required}
          isOptionEqualToValue={isOptionEqualToValue}
        />
      )}
    />
  );
};

export default ControlledComboBox;
