import { graphQLClient, graphQLClientWithHeader } from "../graphQL";
import { LOGIN, REFRESH, REVOKE } from "./mutations";
import { VERIFY } from "./queries";

class AuthService {
  async postLogin(loginInfo) {
    const { login } = await graphQLClient.request(LOGIN, { loginInfo });
    return login;
  }
  async postRevoke() {
    await graphQLClient.request(REVOKE);
  }

  async postRefreshToken() {
    const graphQLClient = graphQLClientWithHeader();
    const { refreshToken } = await graphQLClient.request(REFRESH);
    return refreshToken;
  }
  async verifyToken() {
    const graphQLClient = graphQLClientWithHeader();
    const { verifyJwtToken } = await graphQLClient.request(VERIFY);
    return verifyJwtToken;
  }
}

export default new AuthService();
