import * as Yup from "yup";

export const validation = Yup.object().shape({
  item_cost_setting_list: Yup.array().of(
    Yup.object().shape({
      brand_name: Yup.string().required("กรุณาระบุ"),
      vendor_unique_id: Yup.string().required("กรุณาระบุ"),
      item_type: Yup.string().required("กรุณาระบุ"),
      // process_type: Yup.string().required("กรุณาระบุ"),
      block: Yup.string().required("กรุณาระบุ"),
      // printing_cost: Yup.number().required("กรุณาระบุ"),
      // hot_rolled_cost: Yup.number().required("กรุณาระบุ"),
      // fold_cost: Yup.number().required("กรุณาระบุ"),
    })
  ),
});
