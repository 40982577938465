import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  Switch,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controlled/ControlledTextField";
import ControlledSelect from "../../Controlled/ControlledSelect";
// import ControlledComboBox from "../../Controlled/ControlledComboBox";
import ControlledSSCreatable from "../../Controlled/ControlledSSCreatable";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedChips from "../../Custom/CustomizedChips";
import DropzoneUI from "../../UI/DropzoneUI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { schema } from "./schema";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import { formatDate } from "../../../utils/date-converter";
// import { filterPositionByDepartment } from "../../../utils/dataTransformer";
// import { positionType } from "../../../utils/userInfo";
import { useAuth } from "../../../hooks/use-auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const UserAccountForm = ({
  control,
  errors,
  setValue,
  trigger,
  viewOnly,
  option,
  onTriggerRevalidate,
  onSubmit,
  setting,
  getValues,
  addable,
  onReset,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formDisableState = option === "แก้ไข" ? false : viewOnly;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [position, setPosition] = useState(positionType);
  const { user } = useSelector((state) => state.account);
  const { user: editor } = useAuth();
  const [files, setFiles] = useState([]);
  const { employeeId } = useParams();

  const currentRole = useWatch({ control, name: "current_role" });
  const watchChangePassword = useWatch({ control, name: "change_password" });

  const chipData = [
    `ผู้สร้าง: ${
      user?.created_by
        ? `${user?.created_by.first_name}${
            user?.created_by.last_name ? " " + user?.created_by.last_name : ""
          }`
        : "-"
    }`,
    `วันที่สร้าง: ${formatDate(getValues("created_date")) ?? "-"}`,
    `วันที่ใช้งานล่าสุด:  ${
      user?.last_login_date ? formatDate(user?.last_login_date) : "-"
    }`,
    `ผู้แก้ไขล่าสุด: ${
      user?.last_updated_by
        ? `${user?.last_updated_by.first_name}${
            user?.last_updated_by.last_name
              ? " " + user?.last_updated_by.last_name
              : ""
          }`
        : "-"
    }`,
  ];

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const radioList = [
    { value: true, label: "ใช้งาน" },
    { value: false, label: "หยุดใช้งาน" },
  ];

  const onButtonClicked = async () => {
    if (!currentRole) {
      onTriggerRevalidate();
      const validInput = await trigger(Object.keys(schema));
      if (validInput) navigate(`${pathname}?tab=permission`);
    } else {
      onSubmit();
    }
  };

  // const onDepartmentChange = (value) => {
  //   filterPositionByDepartment(value, setPosition);
  // };

  useEffect(() => {
    if (user?.img_url) {
      setFiles([user.img_url]);
    } else {
      setFiles([]);
    }
  }, [user]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <CustomizedBox margin={"0"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
              ข้อมูลทั่วไป
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledSelect
                  name="title_name"
                  label={t("contact.contact_person.prefix.index")}
                  control={control}
                  error={errors.title_name}
                  options={prefixes}
                  disabled={formDisableState}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledTextField
                  name="nick_name"
                  label={t("user.account.nickname")}
                  control={control}
                  error={errors.nick_name}
                  disabled={formDisableState}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledTextField
                  name="first_name"
                  label={t("user.account.firstname")}
                  control={control}
                  error={errors.first_name}
                  disabled={formDisableState}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledTextField
                  name="last_name"
                  label={t("user.account.lastname")}
                  control={control}
                  error={errors.last_name}
                  disabled={formDisableState}
                  required
                />
              </Grid>
            </Grid>
            <Typography sx={{ mb: 2, mt: 3, ml: 1 }}>
              ข้อมูลการติดต่อ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledTextField
                  name="phone"
                  label={t("user.account.phone")}
                  control={control}
                  error={errors.phone}
                  disabled={formDisableState}
                  onBlur={(e) => {
                    setValue(`phone`, e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledTextField
                  name="email"
                  label={t("user.account.email")}
                  control={control}
                  error={errors.email}
                  disabled={formDisableState}
                  required
                />
              </Grid>
            </Grid>
            <Typography sx={{ mb: 2, mt: 3, ml: 1 }}>จัดการหน้าที่</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledSSCreatable
                  control={control}
                  name="department"
                  documentType="user"
                  error={errors.department}
                  defaultOptions={[]}
                  title={t("user.account.department")}
                  setValue={setValue}
                  viewOnly={formDisableState}
                  onChange={(_, newValue, field) => {
                    if (newValue !== null) {
                      field.onChange(newValue.label);
                    } else {
                      field.onChange(null);
                    }
                    setValue("position", null);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledSSCreatable
                  control={control}
                  name="position"
                  documentType="user"
                  error={errors.position}
                  defaultOptions={[]}
                  title={t("user.account.position")}
                  setValue={setValue}
                  onChange={(_, newValue, field) => {
                    if (newValue !== null) {
                      field.onChange(newValue.label);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  viewOnly={formDisableState}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledSSCreatable
                  control={control}
                  name="organization"
                  documentType="user"
                  error={errors.organization}
                  defaultOptions={[]}
                  title={t("user.account.organization")}
                  setValue={setValue}
                  onChange={(_, newValue, field) => {
                    if (newValue !== null) {
                      field.onChange(newValue.label);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  viewOnly={formDisableState}
                />
              </Grid>
            </Grid>
            {(addable || option === "แก้ไข") && (
              <>
                <Typography sx={{ mb: 2, mt: 3, ml: 1 }}>
                  ความปลอดภัย
                </Typography>
                {option === "แก้ไข" && (
                  <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
                    <Typography sx={{ ml: 1 }}>แก้ไขรหัสผ่าน</Typography>
                    <CustomizedTooltip
                      title={"กดเปิดเมื่อต้องการเปลี่ยนรหัสผ่าน"}
                      noMaxWidth
                    >
                      <ErrorOutlineOutlinedIcon
                        sx={{ fontSize: "14px", ml: 1, mr: 2 }}
                      />
                    </CustomizedTooltip>
                    <Controller
                      control={control}
                      name={"change_password"}
                      render={({ field }) => (
                        <Switch
                          checked={field.value}
                          onChange={() => {
                            field.onChange(!field.value);
                          }}
                          disabled={formDisableState}
                        />
                      )}
                    />
                  </Box>
                )}
                {watchChangePassword && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            disabled={formDisableState}
                            variant="outlined"
                            fullWidth
                            size="small"
                            error={errors.password}
                            required={option !== "แก้ไข"}
                          >
                            <InputLabel htmlFor="outlined-adornment-password1">
                              {t("user.account.password")}
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password1"
                              autoComplete="new-password"
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    disabled={formDisableState}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t("user.account.password")}
                              {...field}
                            />
                            <FormHelperText
                              id="helper-text-password1"
                              sx={{ whiteSpace: "pre-wrap" }}
                            >
                              {errors.password?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Controller
                        name="confirm_password"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            disabled={formDisableState}
                            variant="outlined"
                            fullWidth
                            size="small"
                            error={errors.confirm_password}
                            required={option !== "แก้ไข"}
                          >
                            <InputLabel
                              htmlFor="outlined-adornment-password1"
                              sx={{ backgroundColor: "#FFFFFF" }}
                            >
                              {t("user.account.confirm_password")}
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password1"
                              autoComplete="new-password"
                              type={showConfirmPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    disabled={formDisableState}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t("user.account.password")}
                              {...field}
                            />
                            <FormHelperText
                              id="helper-text-password2"
                              sx={{ whiteSpace: "pre-wrap" }}
                            >
                              {errors.confirm_password?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <ControlledTextField
                        name="confirm_password"
                        label={t("user.account.confirm_password")}
                        control={control}
                        error={errors.confirm_password}
                        disabled={formDisableState}
                        required={option !== "แก้ไข"}
                      />
                    </Grid> */}
                  </Grid>
                )}
              </>
            )}
            <>
              <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                สถานะ
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="is_user_active"
                    control={control}
                    render={({ field }) => (
                      <CustomizedRadioGroup
                        {...field}
                        radioList={radioList}
                        row
                        disabled={
                          formDisableState || employeeId === editor.unique_id
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="inactive_remarks"
                    control={control}
                    render={({ field }) => (
                      <CustomizedTextField
                        fullWidth
                        error={Boolean(errors.inactive_remarks)}
                        label={t("user.account.remark")}
                        {...field}
                        disabled={formDisableState}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1.5} xl={1.5} />
          {(user?.img_url || option === "แก้ไข" || setting || addable) && (
            <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2.5}>
              <Typography fontWeight="bold" sx={{ mb: 2, ml: 1 }}>
                รูปภาพ
              </Typography>
              {option !== "แก้ไข" && !pathname.includes("/add") ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: 200,
                    p: 2,
                    border: "1px solid #C8C8C8",
                    borderRadius: "5px",
                    objectFit: "contain",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={user?.img_url}
                    alt="user profile"
                    style={{ height: "100%" }}
                  />
                </Box>
              ) : (
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ErrorOutlineOutlinedIcon
                      sx={{ fontSize: "14px", mr: 1 }}
                    />
                    <Typography variant="caption" sx={{ color: "#333333" }}>
                      File size limit 10MB
                    </Typography>
                  </Box>
                  <DropzoneUI
                    valueToSet="img_url"
                    setValue={setValue}
                    files={files}
                    setFiles={setFiles}
                    filesLimit={1}
                    acceptedFileType="image"
                  />
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </CustomizedBox>
      {(viewOnly || setting) && (
        <Box sx={{ my: 4 }}>
          <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
            ข้อมูลอื่นๆ
          </Typography>
          {chipData.map((chip) => (
            <CustomizedChips
              sx={{ mr: 1 }}
              key={chip}
              value={chip}
              color="secondary"
              variant="outlined"
              spacing={1}
            />
          ))}
        </Box>
      )}
      <Box mt={4}>
        {(!viewOnly || option === "แก้ไข") && (
          <CustomizedButton
            title={"ยกเลิก"}
            variant="outlined"
            onClick={onReset}
          />
        )}
        {(!viewOnly || option === "แก้ไข") && (
          <CustomizedButton
            sx={{ ml: 1 }}
            title={currentRole ? "บันทึก" : "ดำเนินการต่อ"}
            variant="contained"
            onClick={onButtonClicked}
          />
        )}
      </Box>
    </>
  );
};

export default UserAccountForm;
