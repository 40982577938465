import { GraphQLClient } from "graphql-request";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const devAPIEndpoint = "https://aal-dev-api.npr.digital/graphql";
const prodAPIEndpoint = "https://aal-api.npr.digital/graphql";

let endpoint;
if (process.env.REACT_APP_ENVIRONMENT?.trim() === "development") {
  endpoint = devAPIEndpoint;
} else if (process.env.REACT_APP_ENVIRONMENT?.trim() === "production") {
  endpoint = prodAPIEndpoint;
} else {
  endpoint = devAPIEndpoint;
}
export const graphQLClient = new GraphQLClient(endpoint, {
  credentials: "include",
  mode: "cors",
});

export const graphQLClientWithHeader = () => {
  const token = cookies.get("access_token");
  graphQLClient.setHeader("authorization", `Bearer ${token}`);
  return graphQLClient;
};
