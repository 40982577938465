import styled from "@emotion/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const CustomizedTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ noMaxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#EBEDFF",
    color: "#505050",
    boxShadow: "0px 2px 10px #E5E5E5",
    padding: "8px 16px",
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 500,
    maxWidth: noMaxWidth && "none",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#EBEDFF",
  },
}));
