import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import CustomizedTextField from "../Custom/CustomizedTextField";

const ControlledNumberTextField = ({
  disabled,
  control,
  name,
  error,
  sx,
  helperText,
  label,
  inputProps,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <NumericFormat
          value={field.value}
          allowNegative={false}
          thousandSeparator=","
          decimalScale={2}
          onValueChange={(v) => {
            field.onChange(v.floatValue ?? 0);
          }}
          label={label}
          disabled={disabled}
          customInput={CustomizedTextField}
          helperText={helperText}
          error={error}
          inputProps={inputProps}
          sx={sx}
        />
      )}
    />
  );
};

export default ControlledNumberTextField;
