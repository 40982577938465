import { Box, Typography } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";

export const userColumnDefs = (
  t,
  isRbacPage,
  enableCheckbox,
  headerCheckboxSelection
) => {
  const phoneAction = (number) => {
    window.open(`tel:${number}`, "_self");
    return;
  };

  const columnDefs = [
    {
      field: "unique_id",
      headerName: t("user.account.employeeId"),
      filter: "agTextColumnFilter",
      checkboxSelection: enableCheckbox,
      headerCheckboxSelection: headerCheckboxSelection,
    },
    {
      field: "title_name",
      headerName: t("user.account.title"),
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value && value !== "ไม่มี" ? value : "-";
      },
      width: 125,
    },
    {
      field: "first_name",
      headerName: t("user.account.firstname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.lastname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "nick_name",
      headerName: t("user.account.nickname"),
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value?.toString().trim() ? value : "-";
      },
      width: 125,
    },
    !isRbacPage
      ? {
          field: "email",
          headerName: t("user.account.email"),
          filter: "agTextColumnFilter",
        }
      : undefined,
    !isRbacPage
      ? {
          field: "phone",
          headerName: t("user.account.phone"),
          filter: "agTextColumnFilter",
          width: 250,
          cellRenderer: ({ value }) => {
            return (
              <CustomizedChips
                onClick={() => phoneAction(value)}
                value={`เบอร์โทรศัพท์: ${value}`}
              />
            );
          },
        }
      : undefined,
    !isRbacPage
      ? {
          field: "organization",
          headerName: t("user.account.organization"),
          filter: "agTextColumnFilter",
          valueFormatter: ({ value }) => {
            return value?.toString().trim() ? value : "-";
          },
          width: 125,
        }
      : undefined,
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "sales",
          "inventory",
          "account",
          "purchase",
          "admin",
          "manufacture",
          "logistic",
        ],
      },
      valueFormatter: ({ value }) => {
        return value?.toString().trim() ?? "-";
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value?.toString().trim() ? value : "-";
      },
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">{params.value}</Typography>
            {/* {!disabled && removeSelectedEmployeeHandler && (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{
                  ml: 1,
                  color: "rgba(0, 0, 0, 0.54)",
                  justifySelf: "flex-end",
                }}
                onClick={() =>
                  removeSelectedEmployeeHandler(params.node.rowIndex)
                }
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )} */}
          </Box>
        );
      },
    },
    // {
    //   field: "is_user_active",
    //   headerName: t("user.account.status"),
    //   filter: "agTextColumnFilter",
    //   cellRenderer: (params) => {
    //     const status = params.data.is_user_active ? "active" : "inactive";
    //     return (
    //       <>
    //         <CustomizedStatus status={status} />
    //         {!disabled && removeSelectedEmployeeHandler && (
    //           <IconButton
    //             aria-label="delete"
    //             size="small"
    //             sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
    //             onClick={() =>
    //               removeSelectedEmployeeHandler(params.node.rowIndex)
    //             }
    //           >
    //             <CloseIcon fontSize="small" />
    //           </IconButton>
    //         )}
    //       </>
    //     );
    //   },
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    // },
  ].filter((column) => column !== undefined);

  return columnDefs;
};
