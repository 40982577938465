import { gql } from "graphql-request";

export const GET_ALL_CONTACTS_AGGRID = gql`
  query ContactsAggrid($aggridInput: AggridContactInput!) {
    contactsAggrid(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        is_vendor
        is_customer
        contact_type
        identity_no
        title
        name
        last_name
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        created_by {
          first_name
          last_name
        }
        last_updated_by {
          first_name
          last_name
        }
        is_active
        inactive_remark
        tag_list
        creator_unique_id
        last_updator_unique_id
        created_date
        last_updated_date
        address_list {
          is_default_address
          address_type
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
          same_as_default_address
          name
          phone
        }
        contact_person_list {
          name
          position
          phone
        }
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
      }
      count
    }
  }
`;

export const GET_ALL_CONTACTS = gql`
  query Contacts {
    contacts {
      id
      unique_id
      is_vendor
      is_customer
      is_active
      inactive_remark
      creator_unique_id
      last_updator_unique_id
      last_updated_date
      created_date
      identity_no
      contact_type
      name
      last_name
      title
      contact_remark
      contact_person_list {
        name
        position
        phone
      }
      contact_channel_list {
        contact_channel_type
        contact_channel_name
      }
      address_list {
        address_type
        name
        phone
        is_default_address
        same_as_default_address
        address_number
        building
        sub_district
        district
        province
        postal_code
        zone
        country
      }
      tag_list
    }
  }
`;

export const GET_ALL_CONTACT_IDS = gql`
  query Contacts {
    contacts {
      unique_id
    }
  }
`;

export const GET_CONTACT = gql`
  query Contact($uniqueInput: ContactWhereUniqueInput!) {
    contact(uniqueInput: $uniqueInput) {
      id
      unique_id
      is_vendor
      is_customer
      is_active
      inactive_remark
      creator_unique_id
      last_updator_unique_id
      last_updated_date
      created_date
      identity_no
      contact_type
      name
      last_name
      title
      contact_remark
      contact_person_list {
        name
        position
        phone
      }
      contact_channel_list {
        contact_channel_type
        contact_channel_name
      }
      address_list {
        address_type
        name
        phone
        is_default_address
        same_as_default_address
        address_number
        building
        sub_district
        district
        province
        postal_code
        zone
        country
      }
      tag_list
      gender
      main_interested_field
      secondary_interested_field
      business_group_field
      business_type_field
      customer_quality
    }
  }
`;

export const GET_CONTACT_TAGS = gql`
  query Contacts {
    contacts {
      unique_id
      tag_list
    }
  }
`;

export const GET_ALL_CONTACT_NAMES = gql`
  query Contacts {
    contacts {
      unique_id
      name
      last_name
      is_vendor
      is_customer
    }
  }
`;
