import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { filterParamsOptions } from "../../../../utils/filterparams";

export const contactReportColumnDefs = (t) => {
  return [
    {
      field: "unique_id",
      headerName: t("reports.contact.unique_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    {
      field: "is_vendor",
      headerName: t("reports.contact.is_vendor"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "is_customer",
      headerName: t("reports.contact.is_customer"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "contact_type",
      headerName: t("reports.contact.contact_type"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 160,
    },
    {
      field: "identity_no",
      headerName: t("reports.contact.identity_no"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "title",
      headerName: t("reports.contact.title"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "name",
      headerName: t("reports.contact.name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 200,
    },
    {
      field: "last_name",
      headerName: t("reports.contact.last_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 125,
    },
    {
      field: "contact_channel_type",
      headerName: t("reports.contact.contact_channel_type"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 200,
    },
    {
      field: "contact_channel_name",
      headerName: t("reports.contact.contact_channel_name"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "is_active",
      headerName: t("status.index"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          if (params.value === "TRUE") {
            return "ใช้งาน";
          } else if (params.value === "FALSE") {
            return "ไม่ใช้งาน";
          }
        },
        values: ["TRUE", "FALSE"],
      },
      cellRenderer: ({ value }) => {
        return <CustomizedStatus status={value} />;
      },
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "inactive_remark",
      headerName: t("reports.contact.inactive_remark"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "tag_list",
      headerName: t("reports.contact.tag_list"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "is_default_address",
      headerName: t("reports.contact.is_default_address"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "address_type",
      headerName: t("reports.contact.address_type"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "address_number",
      headerName: t("reports.contact.address_number"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "building",
      headerName: t("reports.contact.building"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "sub_district",
      headerName: t("reports.contact.sub_district"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "district",
      headerName: t("reports.contact.district"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "province",
      headerName: t("reports.contact.province"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "postal_code",
      headerName: t("reports.contact.postal_code"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "zone",
      headerName: t("reports.contact.zone"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "country",
      headerName: t("reports.contact.country"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "same_as_default_address",
      headerName: t("reports.contact.same_as_default_address"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "address_name",
      headerName: t("reports.contact.address_name"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "address_phone",
      headerName: t("reports.contact.address_phone"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "contact_person_name",
      headerName: t("reports.contact.contact_person_name"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "contact_person_phone",
      headerName: t("reports.contact.phone"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "contact_person_position",
      headerName: t("reports.contact.contact_person_position"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "gender",
      headerName: t("reports.contact.gender"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "main_interested_field",
      headerName: t("reports.contact.main_interested_field"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "secondary_interested_field",
      headerName: t("reports.contact.secondary_interested_field"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "business_group_field",
      headerName: t("reports.contact.business_group_field"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 125,
    },
    {
      field: "business_type_field",
      headerName: t("reports.contact.business_type_field"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "customer_quality",
      headerName: t("reports.contact.customer_quality"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    {
      field: "contact_remark",
      headerName: t("reports.contact.contact_remark"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "created_by",
      headerName: t("reports.creator_name"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "created_date",
      headerName: t("reports.created_date"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "last_updated_by",
      headerName: t("reports.last_updator_name"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      field: "last_updated_date",
      headerName: t("reports.updated_date"),
      filter: false,
      hide: true,
      sortable: false,
      suppressColumnsToolPanel: true,
    },
  ];
};
