import { gql } from "graphql-request";

export const GET_ITEM = gql`
  query Item($uniqueInput: ItemUniqueInput!) {
    item(uniqueInput: $uniqueInput) {
      unique_id
      name
      description
      img_url
      is_active
      inactive_remark
      is_variation
      creator_unique_id
      created_date
      last_updated_date
      uom
      item_type
      collection_name
      design_code
      design_owner
      design_type
      block
      process
      process_type
      supplier
      type_name
      item_kind
      item_variant_key_list {
        item_variant_key_name
        item_variant_value_list {
          item_variant_value_name
        }
      }
      sku_list {
        sku_name
        item_unique_id
        item_variant_value {
          item_variant_value_name
          item_variant_key_name
        }
        sku_detail {
          sku_name
          sale_price
          other_cost
          shirt_cost
          is_active
          img_url
          item_kind
          process_type
          item_desc
          item_type
        }
      }
    }
  }
`;

export const GET_ITEMS = gql`
  query Items {
    items {
      unique_id
      name
      description
      img_url
      is_active
      inactive_remark
      is_variation
      creator_unique_id
      created_date
      last_updated_date
      uom
      item_type
      sale_price
      folded_cost
      printed_cost
      pressed_cost
      total_cost
    }
  }
`;

export const GET_ITEM_IDS = gql`
  query ItemIds {
    itemIds
  }
`;

export const GET_ITEM_SKUS = gql`
  query ItemSkus {
    itemSkus {
      sku_name
      item_unique_id
      created_date
      sku_detail {
        sku_name
        sale_price
        other_cost
        shirt_cost
        is_active
        img_url
        item_kind
        process_type
        item_desc
        item_type
      }
      item {
        name
        item_type
        block
        uom
        img_url
        is_variation
        process_type
        last_updated_date
      }
    }
  }
`;

export const GET_ITEMS_SKU_AGGRID = gql`
  query ItemSkusAggrid($aggridInput: AnyAggridInput) {
    itemSkusAggrid(aggridInput: $aggridInput) {
      count_all
      count
      results {
        sku_name
        item_unique_id
        created_date
        sku_detail {
          sku_name
          sale_price
          other_cost
          shirt_cost
          is_active
          img_url
          item_kind
          process_type
          item_desc
          item_type
        }
        item {
          name
          item_type
          block
          uom
          img_url
          is_variation
          process_type
          last_updated_date
        }
      }
    }
  }
`;

export const GET_ITEMS_SKU_AGGRID_EXPORT = gql`
  query ItemSkusAggrid($aggridInput: AnyAggridInput) {
    itemSkusAggrid(aggridInput: $aggridInput) {
      count
      results {
        sku_name
        item_unique_id
        created_date
        sku_detail {
          sku_name
          sale_price
          other_cost
          shirt_cost
          is_active
          img_url
          item_kind
          process_type
          item_desc
          item_type
        }
        item_variant_value {
          item_variant_key_name
          item_variant_value_name
        }
        item {
          id
          unique_id
          name
          created_date
          created_by {
            first_name
            last_name
          }
          last_updated_by {
            first_name
            last_name
          }
          description
          img_url
          is_active
          inactive_remark
          is_variation
          creator_unique_id
          created_date
          last_updated_date
          uom
          process_type
          item_type
          item_kind
          collection_name
          design_owner
          design_code
          design_type
          block
          type_name
          supplier
          process
        }
      }
    }
  }
`;

export const GET_ITEMS_SKU_FLAT_AGGRID = gql`
  query ItemsSkusReportAggrid($aggridInput: AnyAggridInput) {
    itemSkusReportAggrid(aggridInput: $aggridInput) {
      count
      count_all
      results {
        sku_name
        item_name
        item_type
        item_kind
        item_desc
        is_active
        created_date
        sale_price
        process_type
        sku {
          sku_name
          item_unique_id
          sku_detail {
            img_url
            sale_price
            other_cost
            shirt_cost
            is_active
          }
          item_variant_value {
            item_variant_value_name
          }
          item {
            created_by {
              first_name
              last_name
            }
            last_updated_by {
              first_name
              last_name
            }
            item_variant_key_list {
              item_variant_key_name
              item_variant_value_list {
                item_variant_value_name
              }
            }
            is_variation
            is_active
            created_date
            last_updated_date
            name
            description
            img_url
            process_type
            item_type
            item_kind
            collection_name
            design_type
            type_name
            block
            design_owner
            design_code
            uom
            supplier
            process
            inactive_remark
          }
        }
      }
    }
  }
`;

export const GET_ITEMS_AGGRID = gql`
  query ItemsAggrid($aggridInput: AnyAggridInput) {
    itemsAggrid(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        name
        description
        img_url
        is_active
        inactive_remark
        is_variation
        creator_unique_id
        created_date
        last_updated_date
        uom
        item_type
        item_kind
        process_type
      }
    }
  }
`;

export const GENERATE_ITEM_SKU_LIST = gql`
  query ItemsGenerateSkuList($parameters: ItemGenerateSkuInput) {
    itemsGenerateSkuList(parameters: $parameters) {
      sku_name
      variation_list {
        key
        value
      }
    }
  }
`;

export const GET_SKU_BY_ITEM_ID = gql`
  query ItemSkuByItem($itemUniqueId: String!) {
    itemSkuByItem(itemUniqueId: $itemUniqueId) {
      sku_name
      item_unique_id
      item_variant_value {
        id
        item_variant_value_name
        item_variant_key_name
        description
        item_unique_id
      }
      sku_detail {
        sku_name
        sale_price
        other_cost
        shirt_cost
        is_active
        img_url
        item_kind
        process_type
        item_desc
        item_type
      }
    }
  }
`;

export const GET_ITEM_VARIANT_VALUES_BY_ITEM_ID = gql`
  query ItemVariantValuesByItem($findByItemInput: ItemVariantKeyByItemInput!) {
    itemVariantKeysByItem(findByItemInput: $findByItemInput) {
      id
      item_variant_key_name
      description
      item_unique_id
      item_variant_value_list {
        id
        item_variant_value_name
        item_variant_key_name
        description
        item_variant_key {
          id
          item_variant_key_name
          description
          item_unique_id
          item_variant_value_list {
            id
            item_variant_value_name
            item_variant_key_name
            description
            item_unique_id
            item_variant_key {
              id
              item_variant_key_name
              description
              item_unique_id
              item_variant_value_list {
                id
                item_variant_value_name
                item_variant_key_name
                description
              }
            }
          }
        }
      }
    }
  }
`;
