import { Box, DialogContentText } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const CancelConfirmation = ({
  openCancelConfirmation,
  cancelConfirmationAction,
  closeCancelConfirmationHandler,
}) => {
  return (
    <ModalUI
      title="ยืนยันการยกเลิก"
      open={openCancelConfirmation}
      handleClose={closeCancelConfirmationHandler}
      maxWidth="sm"
    >
      <DialogContentText>
        หากยกเลิกแล้วจะไม่สามารถเปลี่ยนแปลงรายการได้
      </DialogContentText>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeCancelConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={cancelConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default CancelConfirmation;
