import * as Yup from "yup";

export const schema = {
  title_name: "",
  first_name: "",
  last_name: "",
  nick_name: "",
  img_url: [],
  email: "",
  phone: "",
  organization: "",
  department: "",
  position: "",
  password: "",
  confirm_password: "",
  is_user_active: true,
  inactive_remarks: "",
  current_role: "",
  change_password: true,
};

export const validation = Yup.object().shape({
  first_name: Yup.string().required("กรุณาระบุชื่อ"),
  last_name: Yup.string().required("กรุณาระบุนามสกุล"),
  email: Yup.string()
    .email("กรุณากรอกอีเมลให้ถูกต้อง")
    .required("กรุณากรอกอีเมล"),
  department: Yup.string().nullable(),
  position: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  // img_url: Yup.string().required("กรุณากรอก"),
  // password: Yup.string().when("change_password", {
  //   is: (change_password) => change_password,
  //   then: Yup.string().required("กรุณาระบุรหัสผ่าน"),
  //   otherwise: Yup.string(),
  // }),
  password: Yup.string().when("change_password", {
    is: (change_password) => change_password,
    then: Yup.string()
      .required("กรุณาระบุรหัสผ่าน")
      .min(8, "กรุณาระบุมากกว่า 8 ตัว")
      .matches(/[a-z]+/, "ตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัว")
      .matches(/[A-Z]+/, "ตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว")
      .matches(
        // eslint-disable-next-line no-useless-escape
        /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
        `อักขระพิเศษ (!@#$%^&*()_+|~-=\`{}[]:";'<>?,./) อย่างน้อย 1 ตัว`
      )
      .matches(/\d+/, "ตัวเลขอย่างน้อย 1 ตัว"),
    otherwise: Yup.string(),
  }),
  // is_active: Yup.boolean().required("กรุณากรอก"),
  confirm_password: Yup.string().when("change_password", {
    is: (change_password) => change_password,
    then: Yup.string().oneOf([Yup.ref("password"), null], "รหัสผ่านไม่ตรงกัน"),
    otherwise: Yup.string(),
  }),
});
