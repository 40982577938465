import SalesService from "../../../services/Sales";
import { salesOrderActions } from "./sales-order-slice";
import { initialState } from "./sales-order-initial";
import {
  formatSalesOrderPayload,
  formatSalesOrderPaymentPayload,
  formattedStatus,
  salesOrderExportFormatter,
  salesOrderPaymentExportFormatter,
} from "../../../utils/salesPayloadFormatter";
import {
  createActivityLogPayload,
  createActivityLogEditPayload,
  createActivityLogStatusPayload,
} from "../../../utils/activityLogsPayloadFormatter";
import ActivityLogsService from "../../../services/ActivityLogs";
import GlobalService from "../../../services/Global";

export const getAllSalesOrders =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("allSalesOrders"));
    try {
      const allSalesOrders = await SalesService.getSalesOrdersAgGrid(input);
      if (params) {
        params.successCallback(allSalesOrders.results, allSalesOrders.count);
      }
      dispatch(
        salesOrderActions.loadedAllSalesOrders({
          results: allSalesOrders.results,
          count: allSalesOrders.count,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "allSalesOrders" })
      );
      if (params) {
        params.failCallback();
      }
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllSalesOrdersWithoutAgGrid =
  (enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("allSalesOrders"));
    try {
      const allSalesOrders = await SalesService.getAllSalesOrders();
      dispatch(
        salesOrderActions.loadedAllSalesOrders({
          results: allSalesOrders,
          count: allSalesOrders?.length ?? 0,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "allSalesOrders" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createSalesOrder =
  (createSalesOrderInput, submitType, navigate, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const formattedPayload = formatSalesOrderPayload(
        createSalesOrderInput,
        "create",
        submitType
      );
      const result = await SalesService.createSalesOrder({
        ...formattedPayload,
        render_status:
          submitType === "sendToCustomer" ? "wait_customer_accept" : "draft",
      });
      await GlobalService.documentSetStep({
        unique_id: createSalesOrderInput.unique_id,
        document_type: "order",
        step: 1,
      });
      if (submitType === "sendToCustomer") {
        await GlobalService.postNextStatus({
          unique_id: createSalesOrderInput.unique_id,
          document_type: "order",
        });
      }
      dispatch(salesOrderActions.loadedSalesOrder(result));
      const createActivityLog = createActivityLogPayload(
        formattedPayload,
        "order",
        "สร้างใบสั่งขาย"
      );
      const statusLogPayload = createActivityLogStatusPayload(
        { unique_id: createSalesOrderInput.unique_id },
        "order",
        "",
        submitType === "sendToCustomer" ? "รอลูกค้ายืนยัน" : "ร่าง"
      );
      try {
        await ActivityLogsService.createActivityLogs({
          createInput: createActivityLog,
        });
        await ActivityLogsService.createActivityLogs({
          createInput: statusLogPayload,
        });
      } catch (err) {
        console.log("Cannot create sales order activity log");
      }
      enqueueSnackbar("บันทึกสำเร็จ", {
        variant: "success",
      });
      navigate(`/sales/order/${createSalesOrderInput.unique_id}`);
    } catch (err) {
      console.log(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      if (JSON.stringify(err).includes("Unique constraint failed")) {
        enqueueSnackbar(
          "เลขที่เอกสารนี้มีอยู่ในระบบแล้ว กรุณาระบุเลขที่เอกสารใหม่",
          {
            variant: "error",
          }
        );
        return;
      }
      enqueueSnackbar("บันทึกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const updateSalesOrder =
  (updateInput, submitType, enqueueSnackbar, setIsInit) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const uniqueInput = { unique_id: updateInput.unique_id };
      const formattedUpdatePayload = await formatSalesOrderPayload(
        updateInput,
        "update",
        submitType
      );
      if (submitType === "sendToCustomer") {
        await SalesService.updateSalesOrder(uniqueInput, {
          ...formattedUpdatePayload,
          render_status: "wait_customer_accept",
        });
        await GlobalService.postNextStatus({
          unique_id: updateInput.unique_id,
          document_type: "order",
        });
      } else {
        await SalesService.updateSalesOrder(
          uniqueInput,
          formattedUpdatePayload
        );
      }
      const editLogPayload = createActivityLogEditPayload(
        uniqueInput,
        "order",
        "แก้ไขใบสั่งขาย"
      );
      let statusLogPayload;
      if (submitType === "sendToCustomer") {
        statusLogPayload = createActivityLogStatusPayload(
          uniqueInput,
          "order",
          updateInput.status,
          "รอลูกค้ายืนยัน"
        );
      }
      try {
        await ActivityLogsService.createActivityLogs({
          createInput: editLogPayload,
        });
        await ActivityLogsService.createActivityLogs({
          createInput: statusLogPayload,
        });
      } catch (err) {
        console.log("Cannot update sales order activity log");
      }
      enqueueSnackbar("บันทึกสำเร็จ", {
        variant: "success",
      });
      setIsInit(false);
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("บันทึกไม่สำเร็จ", {
        variant: "error",
      });
      console.log(err);
    }
  };

export const updateSalesOrderStatus =
  (uniqueId, status, targetStatus, enqueueSnackbar, setIsInit) =>
  async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));

    const currentPaymentOptions = [
      "wait_payment_when_receive",
      "wait_payment_cod",
      "partially_paid",
      "fully_paid",
      "wait_payment",
    ];
    const paymentStatusOptions = [
      "รอชำระเมื่อรับสินค้า",
      "รอชำระCOD",
      "ชำระแล้วบางส่วน",
      "ชำระแล้วเต็มจำนวน",
    ];
    const uniqueInput = { unique_id: uniqueId };

    try {
      if (paymentStatusOptions.includes(targetStatus)) {
        if (!currentPaymentOptions.includes(status)) {
          await GlobalService.documentSetStep({
            unique_id: uniqueId,
            document_type: "order",
            step: 3,
          });
        }
        await SalesService.updateSalesOrder(uniqueInput, {
          main_status: "waitPayment",
          sub_status: targetStatus,
          render_status: formattedStatus({
            main_status: "waitPayment",
            sub_status: targetStatus,
          }),
        });
      }
      if (targetStatus === "เสร็จสิ้น") {
        await GlobalService.documentSetStep({
          unique_id: uniqueId,
          document_type: "order",
          step: 4,
        });
        await SalesService.updateSalesOrder(uniqueInput, {
          main_status: "finished",
          render_status: formattedStatus({
            main_status: "finished",
          }),
        });
      }
      if (targetStatus === "ยกเลิก") {
        await SalesService.updateSalesOrder(uniqueInput, {
          flag_status: "cancelled",
          render_status: formattedStatus({
            flag_status: "cancelled",
          }),
        });
      }
      try {
        const statusLogPayload = createActivityLogStatusPayload(
          { unique_id: uniqueId },
          "order",
          status,
          targetStatus
        );
        await ActivityLogsService.createActivityLogs({
          createInput: statusLogPayload,
        });
      } catch (err) {
        console.log("Cannot create sales order status activity log");
      }
      const salesOrder = await SalesService.getSalesOrder(uniqueInput);
      dispatch(salesOrderActions.loadedSalesOrder(salesOrder));
      enqueueSnackbar("เปลี่ยนสถานะสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.log(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
    setIsInit(false);
  };

export const cancelSalesOrder =
  (updateInput, enqueueSnackbar, setIsInit) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const uniqueInput = { unique_id: updateInput.unique_id };
      const formattedUpdatePayload = await formatSalesOrderPayload(
        updateInput,
        "update",
        "submit"
      );
      await SalesService.updateSalesOrder(uniqueInput, {
        ...formattedUpdatePayload,
        flag_status: "cancelled",
        render_status: formattedStatus({
          flag_status: "cancelled",
        }),
      });
      try {
        const statusLogPayload = createActivityLogStatusPayload(
          { unique_id: updateInput.unique_id },
          "order",
          updateInput.status,
          "cancelled"
        );
        await ActivityLogsService.createActivityLogs({
          createInput: statusLogPayload,
        });
      } catch (err) {
        console.log("Cannot create sales order status activity log");
      }
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      setIsInit(false);
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
      console.log(err);
    }
  };

export const updateSalesOrderPayment =
  (updateInput, enqueueSnackbar, type, setIsInit) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const formattedPaymentPayload = await formatSalesOrderPaymentPayload(
        updateInput
      );
      const result = await SalesService.updateSalesOrder(
        { unique_id: updateInput.unique_id },
        formattedPaymentPayload
      );
      const editLogPayload = createActivityLogEditPayload(
        { unique_id: updateInput.unique_id },
        "order",
        "แก้ไขใบสั่งขาย"
      );
      try {
        await ActivityLogsService.createActivityLogs({
          createInput: editLogPayload,
        });
      } catch (err) {
        console.log("Cannot update sales order activity log");
      }
      dispatch(salesOrderActions.loadedSalesOrder(result));
      setIsInit(false);
      enqueueSnackbar(`${type}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar(`${type}ไม่สำเร็จ`, {
        variant: "error",
      });
      console.log(err);
    }
  };

export const getSalesOrder =
  (uniqueInput, enqueueSnackbar, navigate) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      let salesOrder;
      if (uniqueInput === "init") {
        dispatch(salesOrderActions.loadedSalesOrder(initialState.salesOrder));
      } else {
        salesOrder = await SalesService.getSalesOrder(uniqueInput);
        dispatch(salesOrderActions.loadedSalesOrder(salesOrder));
        return salesOrder;
      }
    } catch (err) {
      console.log(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
      navigate("/sales/order");
    }
  };

export const getAllSalesOrdersExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("allSalesOrdersExport"));
    try {
      const { results } = await SalesService.getSalesOrdersAgGrid(input);
      let salesOrders = await salesOrderExportFormatter(results);
      dispatch(salesOrderActions.loadedAllSalesOrdersExport(salesOrders));
    } catch (err) {
      console.log(err);
      dispatch(
        salesOrderActions.rejectedActions({
          ...err,
          name: "allSalesOrdersExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllSalesOrderPaymentsExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("allSalesOrderPaymentsExport"));
    try {
      const { results } = await SalesService.getSalesOrderPaymentExportAgGrid(
        input
      );
      let payments = await salesOrderPaymentExportFormatter(results);
      dispatch(salesOrderActions.loadedAllSalesOrderPaymentsExport(payments));
    } catch (err) {
      console.log(err);
      dispatch(
        salesOrderActions.rejectedActions({
          ...err,
          name: "allSalesOrderPaymentsExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
