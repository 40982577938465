import { forwardRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

const CustomizedCreatable = forwardRef(
  (
    {
      title,
      options,
      sx,
      name,
      value,
      onInputChange,
      onChange,
      onKeyDown,
      readOnly,
      error,
      helperText,
      required,
      placeholder,
      disabled,
    },
    ref
  ) => {
    return (
      <Autocomplete
        fullWidth
        disabled={disabled}
        value={value}
        onInputChange={onInputChange}
        onChange={onChange}
        ref={ref}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.label
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              label: `เพิ่ม "${inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.value || option.type;
        }}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              value={option.value ?? option}
              key={option.label ?? option}
            >
              {option.label ?? option}
            </li>
          );
        }}
        sx={sx}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            size="small"
            name={name}
            error={error}
            onKeyDown={onKeyDown}
            helperText={helperText}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
        readOnly={readOnly}
      />
    );
  }
);

export default CustomizedCreatable;
