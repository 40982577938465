import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Link,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { useCallback, useRef, useState, useEffect, forwardRef } from "react";
import ControlledSelect from "../../Controlled/ControlledSelect";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import CustomizedChips from "../../Custom/CustomizedChips";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import ControlledSSCreatable from "../../Controlled/ControlledSSCreatable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { contactColumnDefs } from "../../Table/ColumnDefs/Contact";
import CheckboxModalTable from "../../Table/CheckboxModalTable";
import ContactService from "../../../services/Contact";
import ContactFormModalUI from "./ContactModalUI";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const CustomerForm = forwardRef(
  ({ control, errors, disabled, setValue, getValues }, ref) => {
    const selectCustomerGridRef = useRef();
    const { t } = useTranslation();

    const [contactFormModal, setContactFormModal] = useState(false);
    const [showSelectCustomer, setShowSelectCustomer] = useState(false);

    const [customerIds, setCustomerIds] = useState([]);
    const [customerIdsSnapshot, setCustomerIdsSnapshot] = useState([]);
    const [contactError, setContactError] = useState(false);

    const [addressOptions, setAddressOptions] = useState([
      {
        label: <em>เลือกที่อยู่</em>,
        value: "",
      },
      {
        label: t("sales.order.main_address"),
        value: t("sales.order.main_address"),
      },
      {
        label: t("sales.order.delivery_address"),
        value: t("sales.order.delivery_address"),
      },
    ]);

    const watchContactId = useWatch({
      control,
      name: "contact_unique_id",
    });

    const watchAddress = useWatch({
      control,
      name: "delivery_address",
    });

    const watchContact = useWatch({
      control,
      name: "contact",
    });

    const [allAddress, setAllAddress] = useState([]);

    const openContactFormHandler = () => {
      if (!watchContactId) {
        setContactError(true);
        return;
      }
      setContactFormModal(true);
    };

    const closeContactFormHandler = () => {
      setContactFormModal(false);
    };

    const { fields, append, remove } = useFieldArray({
      control,
      name: "tag_list",
    });

    const renderGroupChips = () => {
      return fields.map((item, index) => (
        <Controller
          key={item.id}
          name={`tag_list.${index}`}
          control={control}
          render={({ field }) => (
            <Box sx={{ display: "inline-block", mr: 1 }}>
              <CustomizedChips
                {...field}
                onDelete={!disabled ? () => remove(index) : null}
              />
            </Box>
          )}
        />
      ));
    };

    const openCustomerTable = () => {
      setShowSelectCustomer(true);
    };

    const closeCustomerTable = () => {
      setShowSelectCustomer(false);
    };

    const appendTag = (value) => {
      const tagList = getValues("tag_list");
      if (!tagList.includes(value)) {
        append(value);
      }
    };

    const removeTag = (value) => {
      const tagList = getValues("tag_list");
      const tagIndex = tagList.indexOf(value);
      if (tagIndex !== -1) {
        remove(tagIndex);
      }
    };

    const datasource = {
      async getRows(params) {
        const request = params.request;
        const isActiveFilter = request.filterModel.is_active;
        const activeFilterValue =
          isActiveFilter?.values[0] === "active" ? "true" : "false";
        const agGridOptions = {
          startRow: request.startRow,
          endRow: request.endRow,
          filterModel: {
            ...request.filterModel,
            unique_id: {
              ...request.filterModel.unique_id,
              mode: "insensitive",
            },
            is_customer: {
              filterType: "boolean",
              type: "equals",
              filter: "true",
            },
            is_active:
              isActiveFilter && isActiveFilter?.values?.length === 1
                ? {
                    filterType: "boolean",
                    type: "equals",
                    filter: activeFilterValue,
                  }
                : undefined,
            tag_list: request.filterModel.tag_list
              ? {
                  filterType: "objectArray",
                  type: "some",
                  filter: {
                    name: {
                      filter: request.filterModel.tag_list.filter,
                      filterType: "text",
                      type: "contains",
                      mode: "insensitive",
                    },
                  },
                }
              : undefined,
            business_group_field: {
              ...request.filterModel.business_group_field,
              mode: "insensitive",
            },
            customer_quality: {
              ...request.filterModel.customer_quality,
              mode: "insensitive",
            },
          },
          sortModel: request.sortModel,
        };
        try {
          const allContacts = await ContactService.getAllContactsAgGrid(
            agGridOptions,
            params
          );
          params.successCallback(allContacts.results, allContacts.count);
        } catch (err) {
          console.log(err);
          params.failCallback();
        }
      },
    };

    const onGridReady = (params) => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
      params.api.setServerSideDatasource(datasource);
    };

    const setCustomerHandler = (data, isDataFirstLoad) => {
      if (data) {
        setValue("contact", data);
        const {
          unique_id,
          contact_type,
          name,
          last_name,
          contact_channel_list,
          address_list,
        } = data;

        const formatContactName = () => {
          if (contact_type === "นิติบุคคล" || !last_name) {
            return name;
          }
          return `${name} ${last_name}`;
        };

        if (!isDataFirstLoad) {
          const filteredPhoneList = contact_channel_list.filter(
            (list) => list.contact_channel_type === "เบอร์โทรศัพท์"
          );

          const foundEmail = contact_channel_list.find((list) =>
            ["Email", "E-mail", "E-Mail"].includes(list.contact_channel_type)
          );
          setValue("contact_unique_id", unique_id);
          setValue("contact_name", formatContactName());

          //TODO: Adjusted by requirement spec
          if (filteredPhoneList.length === 1) {
            setValue(
              "phone_main",
              filteredPhoneList[0]?.contact_channel_name ?? ""
            );
            setValue("phone_sub", "");
          } else if (filteredPhoneList.length >= 2) {
            setValue(
              "phone_main",
              filteredPhoneList[0]?.contact_channel_name ?? ""
            );
            setValue(
              "phone_sub",
              filteredPhoneList[1]?.contact_channel_name ?? ""
            );
          }

          if (foundEmail) {
            setValue("email", foundEmail.contact_channel_name);
          } else {
            setValue("email", "");
          }

          setValue("delivery_address", "");
        }

        if (address_list.length > 2) {
          setAddressOptions([
            {
              label: <em>เลือกที่อยู่</em>,
              value: "",
            },
            {
              label: t("sales.order.main_address"),
              value: t("sales.order.main_address"),
            },
            {
              label: t("sales.order.delivery_address"),
              value: t("sales.order.delivery_address"),
            },
            {
              label: address_list[2].address_type,
              value: address_list[2].address_type,
            },
          ]);
        } else {
          setAddressOptions([
            {
              label: <em>เลือกที่อยู่</em>,
              value: "",
            },
            {
              label: t("sales.order.main_address"),
              value: t("sales.order.main_address"),
            },
            {
              label: t("sales.order.delivery_address"),
              value: t("sales.order.delivery_address"),
            },
          ]);
        }
        setAllAddress(address_list);
      } else {
        setValue("contact_unique_id", "");
        setValue("contact_name", "");
        setValue("phone_main", "");
        setValue("phone_sub", "");
        setValue("email", "");
        setValue("delivery_address", "");
        setAllAddress([]);
        setAddressOptions([
          {
            label: <em>เลือกที่อยู่</em>,
            value: "",
          },
          {
            label: t("sales.order.main_address"),
            value: t("sales.order.main_address"),
          },
          {
            label: t("sales.order.delivery_address"),
            value: t("sales.order.delivery_address"),
          },
        ]);
      }
      setShowSelectCustomer(false);
      setContactError(false);
    };

    const openAddNewCustomerModal = () => {
      setCustomerIds([]);
      setCustomerIdsSnapshot([]);
      setCustomerHandler();
      setShowSelectCustomer(false);
      setContactFormModal(true);
    };

    useEffect(() => {
      const contact = getValues("contact");
      if (contact) {
        setCustomerHandler(contact, true);
        setCustomerIds([contact.unique_id]);
        setCustomerIdsSnapshot([contact.unique_id]);
      }
    }, []);

    const getRowId = useCallback((params) => {
      return params.data.unique_id;
    }, []);

    const renderAddressString = (values) => {
      const orderedValues = {
        address_number: values?.address_number,
        building: values?.building,
        sub_district: values?.sub_district,
        district: values?.district,
        province: values?.province,
        postal_code: values?.postal_code,
        zone: values?.zone,
        country: values?.country,
      };
      let result = "";
      if (values) {
        const keys = Object.keys(orderedValues);
        keys.forEach((key) => {
          if (values[key]?.length > 0) {
            if (key === "address_type" || key === "name" || key === "phone") {
              return;
            } else {
              result = result + values[key] + ", ";
            }
          }
        });
      }

      if (result?.trim().length === 0) {
        return "-";
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
    };

    return (
      <CustomizedBox ref={ref}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography sx={{ ml: 1, fontWeight: "bold" }}>
            {t("sales.order.customer_detail")}
          </Typography>
          {!disabled && (
            <CustomizedTooltip title="เลือกลูกค้า">
              <IconButton
                onClick={openCustomerTable}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.contact_unique_id")}
              control={control}
              name="contact_unique_id"
              disabled={true}
              error={Boolean(errors?.contact_unique_id) || contactError}
              helperText={
                (errors?.contact_unique_id &&
                  errors?.contact_unique_id.message) ||
                (contactError && "กรุณาเลือกลูกค้า")
              }
              sx={{
                input: { visibility: disabled ? "hidden" : "visible" },
              }}
              InputProps={{
                startAdornment: disabled && (
                  <InputAdornment position="start">
                    <StyledLink onClick={openContactFormHandler}>
                      {watchContactId || "-"}
                    </StyledLink>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!disabled && (
                      <IconButton
                        onClick={openContactFormHandler}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.contact_name")}
              control={control}
              name="contact_name"
              disabled={true}
              error={Boolean(errors?.contact_name)}
              helperText={errors?.contact_name && errors?.contact_name.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.phone")}
              control={control}
              name="phone_main"
              disabled={disabled}
              error={Boolean(errors?.phone_main)}
              helperText={errors?.phone_main && errors?.phone_main.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.phone")}
              control={control}
              name="phone_sub"
              disabled={disabled}
              error={Boolean(errors?.phone)}
              helperText={errors?.phone_sub && errors?.phone_sub.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("sales.order.email")}
              control={control}
              name="email"
              disabled={disabled}
              error={Boolean(errors?.email)}
              helperText={errors?.email && errors?.email.message}
            />
          </Grid>
        </Grid>
        <Typography sx={{ ml: 1, my: 2 }}>
          {t("sales.order.delivery_address")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledSelect
              name="delivery_address"
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <Typography sx={{ opacity: 0.42 }}>เลือกที่อยู่</Typography>
                  );
                }
                return selected;
              }}
              control={control}
              error={errors.delivery_address}
              options={addressOptions}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        {watchAddress && (
          <Typography sx={{ mt: 3, ml: 1 }}>
            {renderAddressString(
              watchContact?.address_list?.find(
                (address) => address.address_type === watchAddress
              )
            )}
          </Typography>
        )}
        <Typography sx={{ ml: 1, my: 2, fontWeight: 450 }}>
          Tag Order
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledSSCreatable
              control={control}
              name="tag_order"
              documentType="order"
              defaultOptions={[]}
              setValue={setValue}
              viewOnly={disabled}
              actionAfterAdd={appendTag}
              actionAfterSelect={appendTag}
              actionAfterDelete={removeTag}
              isTagCreatable
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={8} alignSelf="center">
            {renderGroupChips()}
          </Grid>
        </Grid>
        <CheckboxModalTable
          getRowId={getRowId}
          modalTitle={t("contact.index")}
          btnTitle={t("button.add")}
          gridRef={selectCustomerGridRef}
          height={450}
          columnDefs={contactColumnDefs(t, false, true)}
          rowSelection="single"
          onFinishEditing={setCustomerHandler}
          modalIsOpen={showSelectCustomer}
          closeModal={closeCustomerTable}
          onGridReady={onGridReady}
          selectedIds={customerIds}
          setSelectedIds={setCustomerIds}
          idsSnapshot={customerIdsSnapshot}
          setIdsSnapshot={setCustomerIdsSnapshot}
          secondaryAction={openAddNewCustomerModal}
          secondaryActionTitle={"เพิ่มลูกค้าใหม่"}
          // onFirstDataRendered={onFirstDataRendered}
        />
        <ContactFormModalUI
          contactId={watchContactId}
          modalIsOpen={contactFormModal}
          setModalIsOpen={setContactFormModal}
          closeContactForm={closeContactFormHandler}
          setCustomer={setCustomerHandler}
        />
      </CustomizedBox>
    );
  }
);

export default CustomerForm;
