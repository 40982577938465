import { Box } from "@mui/material";
import CustomizedChips from "../../../../Custom/CustomizedChips";
import { formatDate } from "../../../../../utils/date-converter";
import { formatNumber } from "../../../../../utils/dataTransformer";
import {
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../../../utils/filterparams";
import CustomizedStatus from "../../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../../Custom/CustomizedAvatar";
import { dateComparator } from "../../../../../utils/filterparams";

export const manufactureOrderColumnDef = (t, isReport, tagOptions) => {
  if (isReport) {
    return [
      {
        field: "unique_id",
        headerName: t("document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "external_ref_document_id",
        headerName: t("reports.manufacture_order.external_ref_document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "created_by",
        headerName: t("reports.manufacture_order.created_by"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 125,
      },
      {
        field: "related_employees",
        headerName: t("reports.manufacture_order.related_employees"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 125,
      },
      {
        field: "payment_date",
        headerName: t("reports.manufacture_order.payment_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "created_date",
        headerName: t("reports.manufacture_order.created_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "production_date",
        headerName: t("reports.manufacture_order.production_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "receive_date",
        headerName: t("reports.manufacture_order.receive_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "vendor_unique_id",
        headerName: t("reports.manufacture_order.vendor_unique_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "vendor_name",
        headerName: t("reports.manufacture_order.vendor_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 125,
      },
      {
        field: "tag_mfg",
        headerName: t("reports.manufacture_order.tag_mfg"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 125,
      },
      {
        field: "customer_unique_id",
        headerName: t("reports.manufacture_order.customer_unique_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "customer_name",
        headerName: t("reports.manufacture_order.customer_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 125,
      },
      {
        field: "customer_phone_main",
        headerName: t("reports.manufacture_order.customer_phone_main"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 175,
      },
      {
        field: "customer_phone_sub",
        headerName: t("reports.manufacture_order.customer_phone_sub"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 175,
      },
      {
        field: "render_status",
        headerName: t("status.index"),
        sortable: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ร่าง", "รอผลิต", "กำลังผลิต", "เสร็จสิ้น", "ยกเลิก"],
          suppressSorting: true,
        },
        cellRenderer: ({ value }) => {
          return <CustomizedStatus status={value} />;
        },
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
      },
      {
        field: "item_unique_id",
        headerName: t("reports.manufacture_order.item_unique_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "item_amount",
        headerName: t("reports.manufacture_order.item_amount"),
        filter: false,
        disableFloatingFilter: true,
        sortable: false,
        width: 125,
      },
      {
        field: "uom",
        headerName: t("reports.manufacture_order.uom"),
        filter: false,
        disableFloatingFilter: true,
        sortable: false,
        width: 125,
      },
    ];
  }

  const columnDefs = [
    {
      field: "unique_id",
      headerName: t("document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    {
      field: "external_ref_document_id",
      headerName: t("reference_document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    {
      field: "vendor_name",
      headerName: t("manufacture.order.manufacturer_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 125,
    },
    {
      field: "customer_name",
      headerName: t("manufacture.order.customer_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 125,
    },
    {
      field: "vendor_phone",
      headerName: t("manufacture.order.phone"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    {
      field: "created_date",
      headerName: t("manufacture.order.created_date"),
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        params.data.created_date ? formatDate(params.data.created_date) : "-",
      width: 150,
    },
    {
      field: "payment_date",
      headerName: t("manufacture.order.payment_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        params.data.payment_date ? formatDate(params.data.payment_date) : "-",
      width: 150,
    },
    {
      field: "production_date",
      headerName: t("manufacture.order.production_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        params.data.production_date
          ? formatDate(params.data.production_date)
          : "-",
      width: 150,
    },
    {
      field: "receive_date",
      headerName: t("manufacture.order.due_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        params.data.receive_date ? formatDate(params.data.receive_date) : "-",
      width: 150,
    },
    {
      field: "tag_list",
      headerName: t("manufacture.order.tag_mfg"),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          if (params.value) {
            return params.value;
          }
          return "-";
        },
        values: tagOptions,
      },
      cellRenderer: ({ value }) => {
        if (value.length === 0) {
          return "";
        }
        return value.map((tag, index) => (
          <Box sx={{ display: "inline-block", mr: 1 }} key={`${tag}-${index}`}>
            <CustomizedChips value={tag} />
          </Box>
        ));
      },
      width: 125,
    },
    {
      field: "created_by",
      headerName: t("manufacture.order.created_by"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        return <CustomizedAvatar avatars={[params.data.created_by]} />;
      },
      width: 125,
    },
    {
      field: "employee_list",
      headerName: t("manufacture.order.related_employee"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        return <CustomizedAvatar avatars={params.data.employee_list} />;
      },
      width: 150,
    },
    {
      field: "render_status",
      headerName: t("status.index"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value, t);
        },
        values: [
          "draft",
          "wait_manufacture",
          "manufacturing",
          "finished",
          "cancelled",
        ],
        suppressSorting: true,
      },
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
  ];
  return columnDefs;
};
