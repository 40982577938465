import {
  Grid,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  IconButton,
  Divider,
} from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedCreatable from "../../Custom/CustomizedCreatable";
import CustomizedChips from "../../Custom/CustomizedChips";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Confirmation from "../../UI/Confirmation/Confirmation";
import ControlledTextField from "../../Controlled/ControlledTextField";
import CustomizedButton from "../../Custom/CustomizedButton";
import InventoryService from "../../../services/Inventory";
import ColorImageDropzoneUI from "../../UI/ColorImageDropzoneUI";
import { useSnackbar } from "notistack";

const VariationTab = ({
  tab,
  control,
  setValue,
  isEditPage,
  isItemPage,
  watchItemUniqueId,
  watchVariantList,
  watchVariantColorImages,
  colorImages,
  setColorImages,
  mode,
  isInit,
  viewOnly,
  setIsEditVariant,
  hasVariation,
}) => {
  const { t } = useTranslation();
  // const [colorImages, setColorImages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDisabledSkuModal, setOpenDisabledSkuModal] = useState(false);
  const [selectedChip, setSelectedChip] = useState(null);
  const [selectedSku, setSelectedSku] = useState(null);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [arm, setArm] = useState("");

  const watchItemIsActive = useWatch({ control, name: "is_active" });

  const {
    fields: variantColor,
    append: appendVariantColor,
    remove: removeVariantColor,
  } = useFieldArray({ control, name: "variation_color" });

  const {
    fields: variantSize,
    append: appendVariantSize,
    remove: removeVariantSize,
  } = useFieldArray({ control, name: "variation_size" });

  const {
    fields: variantArm,
    append: appendVariantArm,
    remove: removeVariantArm,
  } = useFieldArray({ control, name: "variation_arm" });

  const formatPayload = () => {
    const apiPayload = [
      { key: "color", value: [] },
      { key: "size", value: [] },
      { key: "arm", value: [] },
    ];
    variantColor.forEach((color) => apiPayload[0].value.push(color.value));
    variantSize.forEach((size) => apiPayload[1].value.push(size.value));
    variantArm.forEach((arm) => apiPayload[2].value.push(arm.value));

    // const getVariant = (variant) => {
    //   return variant.forEach((value) => {
    //     return apiPayload.push({
    //       key: i === 0 ? "color" : i === 1 ? "size" : "arm",
    //       value: value,
    //     });
    //   });
    // };
    // if (i === 0) getVariant(variantColor);
    // else if (i === 1) getVariant(variantSize);
    // else getVariant(variantArm);

    return apiPayload;
  };

  const submitVariant = async () => {
    const parameters = {
      unique_id: watchItemUniqueId,
      variation_list: formatPayload(),
    };
    const result = await InventoryService.generateItemSkuList(parameters);
    const initResult = transformVairiation(result);
    setValue("variation_list", initResult);
    setIsEditVariant(false);
  };

  const transformVairiation = (data) => {
    const result = [];
    for (const item of data) {
      // Find the variation for the current item
      const colorVariation = item.variation_list.find((v) => v.key === "color");
      // Check if the variation has already been added to the result
      let obj = result.find((o) => o.name === colorVariation.value);
      if (!obj) {
        // If it has not been added, create a new object for it
        obj = {
          key: colorVariation.key,
          name: colorVariation.value,
          value: [],
        };
        result.push(obj);
      }
      // Find the size variation for the current item
      const sizeVariation = item.variation_list.find((v) => v.key === "size");
      // Check if the size variation has already been added to the value array
      let sizeObj = obj.value.find((o) => o.name === sizeVariation.value);
      if (!sizeObj) {
        // If it has not been added, create a new object for it
        sizeObj = {
          key: sizeVariation.key,
          name: sizeVariation.value,
          value: [],
        };
        obj.value.push(sizeObj);
      }
      // Find the arm variation for the current item
      const armVariation = item.variation_list.find((v) => v.key === "arm");
      // Add the arm variation to the value array

      let foundVariant;
      if (colorVariation && sizeVariation && armVariation) {
        foundVariant = watchVariantList
          .find((color) => color.name === colorVariation?.value)
          ?.value?.find((size) => size.name === sizeVariation?.value)
          ?.value?.find((arm) => arm.name === armVariation?.value);
      }

      sizeObj.value.push({
        key: foundVariant ? foundVariant.key : armVariation.key,
        name: foundVariant ? foundVariant.name : armVariation.value,
        sale_price: foundVariant?.sale_price ?? 0,
        other_cost: foundVariant?.other_cost ?? 0,
        shirt_cost: foundVariant?.shirt_cost ?? 0,
        sku_name: item.sku_name,
        is_active: foundVariant ? foundVariant.is_active : true,
      });
    }
    return result;
  };

  // const buildVariations = (variations, sku) => {
  //   let result = [];
  //   for (let i = 0; i < variations.length; i++) {
  //     const key = variations[i].key;
  //     const value = variations[i].value;
  //     if (variations[i].key === "color")
  //       result.push({ key, name: value, value: [] });
  //     else if (variations[i].key === "size")
  //       result.forEach((color) =>
  //         color.value.push({ key, name: value, value: [] })
  //       );
  //     else
  //       result.forEach((color) =>
  //         color.value.forEach((size) =>
  //           size.value.push({
  //             key,
  //             name: value,
  //             sale_price: 0,
  //             other_cost: 0,
  //             shirt_cost: 0,
  //             sku_name: item.sku_name,
  //             is_active: false,
  //           })
  //         )
  //       );
  //   }
  //   return result;
  // };

  const calColorCount = (arrColor) => {
    let count = 0;
    arrColor.value.forEach((size) => {
      count += 1;
      size.value.forEach(() => (count += 1));
    });
    return count + 1;
  };

  const tableHeader = [
    t("inventory.variant.color"),
    t("inventory.variant.size"),
    t("inventory.variant.arm"),
    t("inventory.items.otherCost"),
    t("inventory.items.shirtCost"),
    t("inventory.items.pricePerUnit"),
    t("inventory.items.itemInternalName"),
    t("inventory.active"),
  ];

  const handleDelete = (chipToDelete, removeVariant) => () => {
    removeVariant(chipToDelete);
  };

  const getTitle = (type, value) => {
    if (type === "color") return `ยืนยันการลบตัวเลือกที่ 1 : สี ${value}`;
    else if (type === "size") return `ยืนยันการลบตัวเลือกที่ 2 : ไซส์ ${value}`;
    else return `ยืนยันการลบตัวเลือกที่ 3 : แขน ${value}`;
  };

  const getTextContent = (type, value) => {
    const baseText = (text) => {
      return `หากลบแล้ว ตัวเลือก${text} จะหายไป`;
    };
    if (type === "color") return baseText(`สี ${value}`);
    else if (type === "size") return baseText(`ไซส์ ${value}`);
    else return baseText(`แขน ${value}`);
  };

  const handleOpen = (chip, type, index) => {
    setOpenDeleteModal(true);
    setSelectedChip({
      type: type,
      index,
      title: getTitle(type, chip),
      textContent: getTextContent(type, chip),
    });
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setSelectedChip(null);
  };

  const handleDeleteVariant = () => {
    if (selectedChip.type === "color") removeVariantColor(selectedChip.index);
    else if (selectedChip.type === "size")
      removeVariantSize(selectedChip.index);
    else removeVariantArm(selectedChip.index);
    setOpenDeleteModal(false);
    setSelectedChip(null);
    setIsEditVariant(true);
  };

  const handleSubmitDisabledSku = () => {
    setValue(selectedSku.position, !selectedSku.current_value);
    setOpenDisabledSkuModal(false);
  };

  useEffect(() => {
    if (isInit || !isItemPage) {
      if (watchVariantColorImages) {
        setColorImages(watchVariantColorImages);
        return;
      }
      if (
        (watchVariantList.length > 0 && colorImages.length === 0) ||
        watchVariantList.length !== colorImages.length
      ) {
        const initColorImages = watchVariantList.map(() => null);
        setColorImages(initColorImages);
      }
    } else {
      setColorImages([]);
    }
  }, [watchVariantList, isInit, isItemPage]);

  const setColorImagesHandler = (file, index) => {
    setColorImages((prevImgs) => {
      const newImgs = [...prevImgs];
      newImgs[index] = file;
      setValue("variation_color_images", newImgs);
      return newImgs;
    });
  };

  const renderConditionChip = (chip, index, removeVariant, type) => {
    let typeLength;
    switch (type) {
      case "color":
        typeLength = variantColor.length;
        break;
      case "size":
        typeLength = variantSize.length;
        break;
      case "arm":
        typeLength = variantArm.length;
        break;
      default:
        typeLength = "";
    }
    if (watchVariantList.length === 0)
      return (
        <CustomizedChips
          value={chip}
          sx={{ marginRight: "0.5rem" }}
          handleDelete={handleDelete(index, removeVariant)}
        />
      );
    else if ((watchVariantList.length > 0 && viewOnly) || typeLength === 1)
      return <CustomizedChips value={chip} sx={{ marginRight: "0.5rem" }} />;
    else
      return (
        <CustomizedChips
          value={chip}
          sx={{ marginRight: "0.5rem" }}
          handleDelete={() => handleOpen(chip, type, index)}
        />
      );
  };

  const renderColorImages = watchVariantList.map((variant, index) => {
    return (
      <Grid item xs={6} sm={4} md={3} lg={2} key={variant.name}>
        <Typography variant="subtitle1">{variant.name}</Typography>
        {viewOnly &&
          colorImages[index] &&
          !colorImages[index].includes("placeholder-") &&
          colorImages[index][0] && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: 140,
                width: 149,
                p: 0.2,
                border: "1px solid #BEBEBE",
                borderRadius: "5px",
                objectFit: "contain",
                overflow: "hidden",
              }}
            >
              <img
                src={colorImages[index] || colorImages[index][0] || ""}
                alt="color reference image"
                style={{ height: "100%" }}
              />
            </Box>
          )}
        {!viewOnly && (
          <ColorImageDropzoneUI
            setFiles={(file) => setColorImagesHandler(file, index)}
            files={colorImages[index] ?? []}
            filesLimit={1}
            manualRevoke
            acceptedFileType="image"
          />
        )}
      </Grid>
    );
  });

  return (
    <Fragment>
      <CustomizedBox margin={"0"} boxShadow={!isItemPage ? "none" : undefined}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">
            {t("inventory.variant.option") +
              t("inventory.variant.no1") +
              " : " +
              t("inventory.variant.color")}
          </Typography>
          <Confirmation
            width={600}
            open={openDeleteModal}
            title={selectedChip?.title}
            textContent={selectedChip?.textContent}
            handleCancel={handleClose}
            handleSubmit={handleDeleteVariant}
          />
          <Confirmation
            width={600}
            open={openDisabledSkuModal}
            title={t("inventory.sentence.diabledSKU") + " " + selectedSku?.name}
            textContent={t("inventory.sentence.diabledSKUDetail")}
            handleCancel={() => setOpenDisabledSkuModal(false)}
            handleSubmit={handleSubmitDisabledSku}
          />
        </Box>
        <Box mt={1} mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomizedCreatable
                title={t("inventory.variant.option")}
                options={[]}
                value={color}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    appendVariantColor({ value: newValue });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    appendVariantColor({ value: newValue.inputValue });
                  } else {
                    if (newValue) {
                      appendVariantColor({ value: newValue.value });
                    }
                  }
                  setColor("");
                  setIsEditVariant(true);
                }}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={8} alignSelf="center">
              {variantColor.map((chip, index) => (
                <Fragment key={chip.id}>
                  {renderConditionChip(
                    chip.value,
                    index,
                    removeVariantColor,
                    "color"
                  )}
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Typography variant="subtitle1">
          {t("inventory.variant.option") +
            t("inventory.variant.no2") +
            " : " +
            t("inventory.variant.size")}
        </Typography>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomizedCreatable
                title={t("inventory.variant.option")}
                options={[]}
                value={size}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    appendVariantSize({ value: newValue });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    appendVariantSize({ value: newValue.inputValue });
                  } else {
                    if (newValue) appendVariantSize({ value: newValue.value });
                  }
                  setSize("");
                  setIsEditVariant(true);
                }}
                disabled={viewOnly || variantColor.length === 0}
              />
            </Grid>
            <Grid item xs={8} alignSelf="center">
              {variantSize.map((chip, index) => (
                <Fragment key={chip.id}>
                  {renderConditionChip(
                    chip.value,
                    index,
                    removeVariantSize,
                    "size"
                  )}
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Typography variant="subtitle1">
          {t("inventory.variant.option") +
            t("inventory.variant.no3") +
            " : " +
            t("inventory.variant.arm")}
        </Typography>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomizedCreatable
                title={t("inventory.variant.option")}
                options={[]}
                value={arm}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    appendVariantArm({ value: newValue });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    appendVariantArm({ value: newValue.inputValue });
                  } else {
                    if (newValue)
                      appendVariantArm({
                        value: newValue.value,
                      });
                  }
                  setArm("");
                  setIsEditVariant(true);
                }}
                disabled={viewOnly || variantSize.length === 0}
              />
            </Grid>
            <Grid item xs={8} alignSelf="center">
              {variantArm.map((chip, index) => (
                <Fragment key={chip.id}>
                  {renderConditionChip(
                    chip.value,
                    index,
                    removeVariantArm,
                    "arm"
                  )}
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
        {!viewOnly && (
          <CustomizedButton
            title={"บันทึก"}
            variant="contained"
            testId="item-submit-btn"
            onClick={submitVariant}
          />
        )}
      </CustomizedBox>
      {!isItemPage && <Divider />}
      {watchVariantList && watchVariantList.length >= 1 && (
        <Fragment>
          <CustomizedBox boxShadow={!isItemPage ? "none" : undefined}>
            <Typography fontWeight="bold" mt={isItemPage ? 2 : -2} mb={2}>
              ตารางตัวเลือก
            </Typography>
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeader.map((header) => (
                    <TableCell sx={{ textAlign: "center" }} key={header}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {watchVariantList.map((color, colorInd) => (
                  <Fragment key={color.name + colorInd}>
                    <TableRow sx={{ textAlign: "center" }}>
                      <TableCell
                        rowSpan={calColorCount(color)}
                        sx={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                          // paddingBottom: "10px",
                          marginBottom: "10px",
                          textAlign: "center",
                        }}
                      >
                        {color.name}
                      </TableCell>
                    </TableRow>
                    {color.value.map((size, sizeInd) => (
                      <Fragment key={size.name + sizeInd}>
                        <TableRow>
                          <TableCell
                            rowSpan={size.value.length + 1}
                            sx={{
                              textAlign: "center",
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {size.name}
                          </TableCell>
                        </TableRow>
                        {size.value.map((arm, armInd) => {
                          return (
                            <Fragment key={arm.name + armInd}>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  {arm.name}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  <ControlledTextField
                                    control={control}
                                    name={`variation_list[${colorInd}].value[${sizeInd}].value[${armInd}].other_cost`}
                                    type="number"
                                    disabled={
                                      !arm.is_active ||
                                      (isEditPage && !(mode === "แก้ไข"))
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  <ControlledTextField
                                    control={control}
                                    name={`variation_list[${colorInd}].value[${sizeInd}].value[${armInd}].shirt_cost`}
                                    type="number"
                                    disabled={
                                      !arm.is_active ||
                                      (isEditPage && !(mode === "แก้ไข"))
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  <ControlledTextField
                                    control={control}
                                    name={`variation_list[${colorInd}].value[${sizeInd}].value[${armInd}].sale_price`}
                                    type="number"
                                    disabled={
                                      !arm.is_active ||
                                      (isEditPage && !(mode === "แก้ไข"))
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  {arm.sku_name}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  <Controller
                                    control={control}
                                    name={`variation_list[${colorInd}].value[${sizeInd}].value[${armInd}].is_active`}
                                    render={({ field }) => (
                                      <Box
                                        sx={{
                                          cursor:
                                            isEditPage &&
                                            mode === "แก้ไข" &&
                                            hasVariation &&
                                            (!watchItemIsActive ||
                                              watchItemIsActive === "false")
                                              ? "pointer"
                                              : "default",
                                        }}
                                        onClick={
                                          isEditPage &&
                                          mode === "แก้ไข" &&
                                          hasVariation &&
                                          (!watchItemIsActive ||
                                            watchItemIsActive === "false")
                                            ? () => {
                                                enqueueSnackbar(
                                                  "หากต้องการเปิดการใช้งานของตัวเลือก\nกรุณาเปิดการใช้งานสถานะในหน้าทั่วไปของสินค้าก่อน",
                                                  {
                                                    variant: "error",
                                                    style: {
                                                      whiteSpace: "pre-line",
                                                    },
                                                  }
                                                );
                                              }
                                            : () => {}
                                        }
                                      >
                                        <Switch
                                          checked={field.value}
                                          onChange={() => {
                                            if (!field.value)
                                              return field.onChange(
                                                !field.value
                                              );
                                            setOpenDisabledSkuModal(true);
                                            setSelectedSku({
                                              name: arm.sku_name,
                                              position: `variation_list[${colorInd}].value[${sizeInd}].value[${armInd}].is_active`,
                                              current_value: field.value,
                                            });
                                          }}
                                          disabled={
                                            (isEditPage &&
                                              !(mode === "แก้ไข")) ||
                                            (hasVariation &&
                                              (!watchItemIsActive ||
                                                watchItemIsActive === "false"))
                                          }
                                        />
                                      </Box>
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        })}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </CustomizedBox>
          <CustomizedBox boxShadow={!isItemPage ? "none" : undefined}>
            <Typography fontWeight="bold" mt={isItemPage ? 0 : -6} mb={4}>
              อัปโหลดรูปสี
            </Typography>
            <Grid container spacing={4}>
              {renderColorImages}
            </Grid>
          </CustomizedBox>
        </Fragment>
      )}
    </Fragment>
  );
};

export default VariationTab;
