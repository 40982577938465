import React from 'react'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined'
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
// import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined'

const CustomizedIcon = ({ name }) => {
  switch (name) {
  case 'image':
    return <PanoramaOutlinedIcon fontSize="small" />
  case 'mixedFiles':
    return <AttachFileOutlinedIcon fontSize="small" />
  case 'comment':
    return <InsertCommentOutlinedIcon fontSize="small" />
  case 'related_document':
    return <StickyNote2OutlinedIcon fontSize="small" />
  case 'edit':
    return <CreateOutlinedIcon fontSize="small" />
  case 'create':
    return <InsertDriveFileOutlinedIcon fontSize="small" />
  case 'cancel':
    return <CloseOutlinedIcon fontSize="small" />
  case 'delete_related_employee':
    return <DeleteOutlinedIcon fontSize="small" />
  case 'status_change':
  case 'PENDING':
    return <EastOutlinedIcon fontSize="small" />
  case 'add_related_employee':
    return <ControlPointOutlinedIcon fontSize="small" />
  case 'copy':
    return <ContentCopyOutlinedIcon fontSize="small" />
  case 'approve':
  case 'APPROVED':
    return <DoneAllOutlinedIcon fontSize="small" />
  default:
  }
}

export default CustomizedIcon
