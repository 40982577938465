import { Box, Typography, Grid } from "@mui/material";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";
import { useAuth } from "../../../hooks/use-auth";

const SalesReportPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { permissions } = useAuth();
  const salesPermission = permissions.sales;

  const menuList = [
    { title: t("sales.report.order"), navigateTo: "order" },
    { title: t("sales.report.payment"), navigateTo: "payment" },
  ];

  const renderMenuList = () =>
    menuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          onClick={() => navigate(`${pathname}/${menu.navigateTo}`)}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      {salesPermission === "NONE" && <Navigate to="/no-permission" />}
      <Box>
        <Typography variant="h5">{t("sales.report.index")}</Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default SalesReportPage;
