import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./sales-order-initial";

const salesOrderSlice = createSlice({
  name: "salesOrder",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllSalesOrders(state, action) {
      state.allSalesOrders = action.payload;
      state.isLoading.allSalesOrders = false;
    },
    loadedAllSalesOrdersExport(state, action) {
      state.allSalesOrdersExport = action.payload;
      state.isLoading.allSalesOrdersExport = false;
    },
    loadedAllSalesOrderPaymentsExport(state, action) {
      state.allSalesOrderPaymentsExport = action.payload;
      state.isLoading.allSalesOrderPaymentsExport = false;
    },
    loadedSalesOrder(state, action) {
      state.salesOrder = action.payload;
      state.isLoading.salesOrder = false;
    },
    resetSalesOrder(state) {
      state.salesOrder = initialState.salesOrder;
      state.error = initialState.error;
    },
    resetAllSalesOrders(state) {
      state.allSalesOrders = initialState.allSalesOrders;
      state.error = initialState.error;
    },
  },
});

export const salesOrderActions = salesOrderSlice.actions;

export default salesOrderSlice.reducer;
