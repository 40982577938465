import { graphQLClientWithHeader } from "../graphQL";
import {
  GENERATE_ITEM_SKU_LIST,
  GET_ITEM,
  GET_ITEMS,
  GET_ITEMS_AGGRID,
  GET_ITEM_IDS,
  GET_ITEM_SKUS,
  GET_ITEMS_SKU_AGGRID,
  GET_ITEMS_SKU_AGGRID_EXPORT,
  GET_ITEM_VARIANT_VALUES_BY_ITEM_ID,
  GET_SKU_BY_ITEM_ID,
  GET_ITEMS_SKU_FLAT_AGGRID,
} from "./queries";
import {
  CREATE_ITEM,
  CREATE_ITEM_DIRECT,
  CREATE_ITEMS,
  UPDATE_ITEM,
  UPDATE_ITEM_SKU_DETAIL,
  DISABLE_ITEM,
  DELETE_ITEM,
  UPSERT_ITEMS,
} from "./mutations";

class InventoryService {
  async getAllItemsAgGrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemsAggrid } = await graphQLClient.request(GET_ITEMS_AGGRID, {
      aggridInput,
    });
    return itemsAggrid;
  }

  async getAllitemsSkus() {
    const graphQLClient = graphQLClientWithHeader();
    const { itemSkus } = await graphQLClient.request(GET_ITEM_SKUS);
    return itemSkus;
  }

  async getAllitemsSkuAgGrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemSkusAggrid } = await graphQLClient.request(
      GET_ITEMS_SKU_AGGRID,
      {
        aggridInput,
      }
    );
    return itemSkusAggrid;
  }

  async getAllItemsSkuFlatAgGrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemSkusReportAggrid } = await graphQLClient.request(
      GET_ITEMS_SKU_FLAT_AGGRID,
      {
        aggridInput,
      }
    );
    return itemSkusReportAggrid;
  }

  async getAllItemsSkuAgGridExport(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemSkusAggrid } = await graphQLClient.request(
      GET_ITEMS_SKU_AGGRID_EXPORT,
      {
        aggridInput,
      }
    );
    return itemSkusAggrid;
  }

  async getAllItems() {
    const graphQLClient = graphQLClientWithHeader();
    const { items } = await graphQLClient.request(GET_ITEMS);
    return items;
  }

  async getItem(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { item } = await graphQLClient.request(GET_ITEM, {
      uniqueInput,
    });
    return item;
  }

  async getItemIds() {
    const graphQLClient = graphQLClientWithHeader();
    const { itemIds } = await graphQLClient.request(GET_ITEM_IDS);
    return itemIds;
  }

  async createItem(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCreate } = await graphQLClient.request(CREATE_ITEM, {
      createInput,
    });
    return itemCreate;
  }

  async createItemDirect(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCreateDirect } = await graphQLClient.request(
      CREATE_ITEM_DIRECT,
      {
        createInput,
      }
    );
    return itemCreateDirect;
  }

  async createItems(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCreateMany } = await graphQLClient.request(CREATE_ITEMS, {
      createManyInput,
    });
    return itemCreateMany;
  }

  async createOrUpdateItems(upsertManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemUpsertMany } = await graphQLClient.request(UPSERT_ITEMS, {
      upsertManyInput,
    });
    return itemUpsertMany;
  }

  async updateItem(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemUpdate } = await graphQLClient.request(UPDATE_ITEM, {
      uniqueInput,
      updateInput,
    });
    return itemUpdate;
  }
  async getItemVariantByItemId(itemId) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemVariantKeysByItem } = await graphQLClient.request(
      GET_ITEM_VARIANT_VALUES_BY_ITEM_ID,
      {
        findByItemInput: { item_unique_id: itemId },
      }
    );
    return itemVariantKeysByItem;
  }
  async getItemSkuByItemId(itemId) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemSkuByItem } = await graphQLClient.request(GET_SKU_BY_ITEM_ID, {
      item_unique_id: itemId,
    });
    return itemSkuByItem;
  }
  async generateItemSkuList(parameters) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemsGenerateSkuList } = await graphQLClient.request(
      GENERATE_ITEM_SKU_LIST,
      {
        parameters,
      }
    );
    return itemsGenerateSkuList;
  }
  async updateItemSkuDetail(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemSkuDetailUpdate } = await graphQLClient.request(
      UPDATE_ITEM_SKU_DETAIL,
      {
        uniqueInput,
        updateInput,
      }
    );
    return itemSkuDetailUpdate;
  }
  async disableItem(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemDisable } = await graphQLClient.request(DISABLE_ITEM, {
      uniqueInput,
    });
    return itemDisable;
  }
  async deleteItem(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemDelete } = await graphQLClient.request(DELETE_ITEM, {
      uniqueInput,
    });
    return itemDelete;
  }
}

export default new InventoryService();
