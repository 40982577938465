import { graphQLClientWithHeader } from "../graphQL";
import { CREATE_ACTIVITY_LOGS } from "./mutation";
import { GET_ACTIVITY_LOGS } from "./queries";

class ActivityLogsService {
  async getActivityLogs(uniqueIdInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { activityLogsByUniqueId } = await graphQLClient.request(
      GET_ACTIVITY_LOGS,
      uniqueIdInput
    );
    return activityLogsByUniqueId;
  }
  async createActivityLogs(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { activityLogCreate } = await graphQLClient.request(
      CREATE_ACTIVITY_LOGS,
      input
    );
    return activityLogCreate;
  }
}

export default new ActivityLogsService();
