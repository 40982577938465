import { Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";

const OpenManufactureForm = ({ control, errors, disabled }) => {
  const { t } = useTranslation();

  const openManufactureList = [
    { value: "opened", label: "เปิดแล้ว" },
    { value: "not_open", label: "ยังไม่เปิด" },
  ];

  return (
    <CustomizedBox>
      <Typography sx={{ ml: 1, fontWeight: "bold" }}>
        {t("sales.order.is_open_manufacture")}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Controller
          name="is_open_manufacture"
          control={control}
          render={({ field }) => (
            <CustomizedRadioGroup
              {...field}
              radioList={openManufactureList}
              row
              disabled={disabled}
              error={Boolean(errors?.is_open_manufacture)}
              helperText={
                errors?.is_open_manufacture &&
                errors?.is_open_manufacture?.message
              }
            />
          )}
        />
      </Box>
    </CustomizedBox>
  );
};

export default OpenManufactureForm;
