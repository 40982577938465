import { Box, DialogContentText } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const Confirmation = ({
  open,
  title,
  handleCancel,
  handleSubmit,
  textContent,
  width,
}) => {
  return (
    <ModalUI
      title={title}
      open={open}
      handleClose={handleCancel}
      width={width}
      centered
    >
      {textContent && (
        <DialogContentText sx={{ color: "black" }} minHeight="40px">
          {textContent}
        </DialogContentText>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 3,
        }}
        gap={1}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={handleCancel}
          size="medium"
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={handleSubmit}
          size="medium"
        />
      </Box>
    </ModalUI>
  );
};

export default Confirmation;
