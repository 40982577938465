import AgGrid from "./AgGrid";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography } from "@mui/material";
import { departmentEngToThai } from "../../utils/dataTransformer";
import CustomizedChips from "../Custom/CustomizedChips";
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from "../../utils/date-converter";
import CustomizedStatus from "../Custom/CustomizedStatus";

const UserTable = ({
  gridRef,
  onGridReady,
  onFirstDataRendered,
  rowSelection,
  height,
  searchBox,
  enableCheckbox,
  rowData,
  onRowDoubleClicked,
  headerCheckboxSelection,
  enableRangeSelection,
  autoHeight,
  removeSelectedEmployeeHandler,
  getRowId,
  disabled,
  isRbacPage,
  departmentOptions,
}) => {
  const { t } = useTranslation();

  const phoneAction = (number) => {
    window.open(`tel:${number}`, "_self");
    return;
  };

  const columnDefs = [
    !isRbacPage
      ? {
          field: "unique_id",
          headerName: t("user.account.employeeId"),
          filter: "agTextColumnFilter",
          checkboxSelection: enableCheckbox,
          headerCheckboxSelection: headerCheckboxSelection,
        }
      : undefined,
    {
      field: "created_date",
      headerName: t("user.account.created_date"),
      sort: "desc",
      hide: true,
      valueFormatter: ({ value }) => (value ? formatDate(value) : "-"),
      filter: "agDateColumnFilter",
      width: 200,
    },
    // {
    //   field: "last_updated_date",
    //   sort: "desc",
    //   hide: true,
    //   filter: "agDateColumnFilter",
    //   width: 200,
    // },
    {
      field: "title_name",
      headerName: t("user.account.title"),
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value && value !== "ไม่มี" ? value : "-";
      },
      width: 125,
    },
    {
      field: "first_name",
      headerName: t("user.account.firstname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.lastname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "nick_name",
      headerName: t("user.account.nickname"),
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value?.toString().trim() ? value : "-";
      },
      width: 125,
    },
    !isRbacPage
      ? {
          field: "email",
          headerName: t("user.account.email"),
          filter: "agTextColumnFilter",
        }
      : undefined,
    !isRbacPage
      ? {
          field: "phone",
          headerName: t("user.account.phone"),
          filter: "agTextColumnFilter",
          width: 250,
          cellRenderer: ({ value }) => {
            return (
              <CustomizedChips
                onClick={() => phoneAction(value)}
                value={`เบอร์โทรศัพท์: ${value}`}
              />
            );
          },
        }
      : undefined,
    !isRbacPage
      ? {
          field: "organization",
          headerName: t("user.account.organization"),
          filter: "agTextColumnFilter",
          valueFormatter: ({ value }) => {
            return value?.toString().trim() ? value : "-";
          },
          width: 125,
        }
      : undefined,
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: departmentOptions,
      },
      valueFormatter: ({ value }) => {
        return value?.toString().trim() ? departmentEngToThai(value) : "-";
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value?.toString().trim() ? value : "-";
      },
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">{params.value}</Typography>
            {!disabled && removeSelectedEmployeeHandler && (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{
                  ml: 1,
                  color: "rgba(0, 0, 0, 0.54)",
                  justifySelf: "flex-end",
                }}
                onClick={() =>
                  removeSelectedEmployeeHandler(params.node.rowIndex)
                }
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        );
      },
    },
    {
      field: "is_user_active",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: isRbacPage ? [true, false] : ["active", "inactive"],
        valueFormatter: (params) => {
          if (!isRbacPage && params.value === "active") {
            return "ใช้งาน";
          }
          if (!isRbacPage) {
            return "หยุดใช้งาน";
          }
          if (isRbacPage && params.value === "true") {
            return "ใช้งาน";
          }
          return "หยุดใช้งาน";
        },
      },
      hide: true,
      cellRenderer: (params) => {
        if (params.value) {
          return <CustomizedStatus status="active" />;
        }
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    // {
    //   field: "is_user_active",
    //   headerName: t("user.account.status"),
    //   filter: "agTextColumnFilter",
    //   cellRenderer: (params) => {
    //     const status = params.data.is_user_active ? "active" : "inactive";
    //     return (
    //       <>
    //         <CustomizedStatus status={status} />
    //         {!disabled && removeSelectedEmployeeHandler && (
    //           <IconButton
    //             aria-label="delete"
    //             size="small"
    //             sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
    //             onClick={() =>
    //               removeSelectedEmployeeHandler(params.node.rowIndex)
    //             }
    //           >
    //             <CloseIcon fontSize="small" />
    //           </IconButton>
    //         )}
    //       </>
    //     );
    //   },
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    // },
  ].filter((column) => column !== undefined);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={height}
      autoHeight={autoHeight}
      onGridReady={onGridReady}
      onFirstDataRendered={onFirstDataRendered}
      rowSelection={rowSelection}
      searchBox={searchBox}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
      enableRangeSelection={enableRangeSelection}
      getRowId={getRowId}
      path="/user/account"
    />
  );
};

export default UserTable;
