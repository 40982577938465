import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllRolesNoAggrid } from "../../../features/User/Rbac/rbac-action";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ControlledComboBox from "../../../components/Controlled/ControlledComboBox";
import { useWatch } from "react-hook-form";
import PermissionTable from "../../../components/Table/PermissionTable";

const PermissionTab = ({
  control,
  setValue,
  viewOnly,
  onSubmit,
  onReset,
  option,
  currentRoleError,
  setCurrentRoleError,
}) => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { role, allRoles } = useSelector((state) => state.rbac);

  const chipOptions = allRoles.map((role) => role.name);
  const currentRole = useWatch({ control, name: "current_role" });
  const firstName = useWatch({ control, name: "first_name" });
  const lastName = useWatch({ control, name: "last_name" });
  const email = useWatch({ control, name: "email" });
  const password = useWatch({ control, name: "password" });
  const currentPassword = useWatch({ control, name: "current_password" });
  const changePassword = useWatch({ control, name: "change_password" });

  const hasEmptyRequiredField =
    firstName === "" ||
    lastName === "" ||
    email === "" ||
    (changePassword && password === "") ||
    (changePassword && currentPassword === "");

  useEffect(() => {
    dispatch(getAllRolesNoAggrid({}, enqueueSnackbar));
  }, []);

  const permissionList =
    allRoles.find((role) => role.name === currentRole)?.permission_list ?? [];

  const handleCancel = () => {
    onReset();
    navigate(pathname);
  };

  const handleSubmit = () => {
    if (currentRole === "") {
      setCurrentRoleError({ message: "กรุณาเลือกกลุ่มการเข้าถึง" });
    } else {
      setCurrentRoleError(undefined);
    }
    onSubmit();
    if (hasEmptyRequiredField) {
      navigate(pathname);
    }
  };

  useEffect(() => {
    //set new default value for inputs in the table if role change
    if (role) {
      Object.entries(role).forEach(([key, value]) => setValue(key, value));
    }
  }, [role, setValue]);

  return (
    <>
      <CustomizedBox margin={"0"}>
        <Typography fontWeight="bold" ml={1} mb={2}>
          {t("user.rbac.index")}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <ControlledComboBox
              control={control}
              name="current_role"
              label={t("user.rbac.permission")}
              error={currentRoleError}
              options={chipOptions}
              onChange={(_, newValue, field) => {
                return field.onChange(newValue);
              }}
              disabled={viewOnly && option !== "แก้ไข"}
              required
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <PermissionTable
        gridRef={gridRef}
        t={t}
        rowData={permissionList}
        viewOnly
      />
      <Box mt={4}>
        {(!viewOnly || option === "แก้ไข") && (
          <CustomizedButton
            title={"ยกเลิก"}
            variant="outlined"
            onClick={handleCancel}
          />
        )}
        {(!viewOnly || option === "แก้ไข") && (
          <CustomizedButton
            sx={{ ml: 1 }}
            title={hasEmptyRequiredField ? "ดำเนินการต่อ" : "บันทึก"}
            variant="contained"
            onClick={handleSubmit}
          />
        )}
      </Box>
    </>
  );
};

export default PermissionTab;
