import { useState, useEffect, useCallback, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useWatch } from "react-hook-form";
import ControlledDatePicker from "../../Controlled/ControlledDatePicker";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedTab from "../../Custom/CustomizedTab";
import CustomizedBreadcrumbs from "../../Custom/CustomizedBreadcrumbs";
import CustomizedMenuOption from "../../Custom/CustomizedMenuOption";
import HistoryIcon from "@mui/icons-material/History";
import RightDrawer from "../../UI/RightDrawer";
import GeneralTab from "./GeneralTab";
import VariationTab from "./VariationTab";
import GlobalService from "../../../services/Global";
import { useDispatch } from "react-redux";
import DeleteItemConfirmation from "../../UI/Confirmation/DeleteItemConfirmation";
import { deleteItem } from "../../../features/Inventory/inventory-actions";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../hooks/use-auth";
import SalesService from "../../../services/Sales";

const ItemForm = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      onSubmit,
      onReset,
      viewOnly,
      isEditPage,
      isItemPage,
      mode,
      setMode,
      isInit,
      id,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const tab = searchParams.get(isItemPage ? "tab" : "itemTab");
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [files, setFiles] = useState([]);
    const [colorImages, setColorImages] = useState([]);
    const [isEditVariant, setIsEditVariant] = useState(false);
    const [showDeleteItemConfirmation, setShowDeleteItemConfirmation] =
      useState(false);
    const [itemExistInDocument, setItemExistInDocument] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { permissions } = useAuth();
    const inventoryPermission = permissions?.inventory;

    const watchVariation = useWatch({ control, name: "is_variation" });
    const hasVariation = watchVariation && watchVariation !== "false";
    // const actionIsSubmit =
    //   !hasVariation || (hasVariation && tab === "variation");

    const watchItemUniqueId = useWatch({ control, name: "unique_id" });
    const watchItemName = useWatch({ control, name: "name" });
    const watchVariantList = useWatch({ control, name: "variation_list" });
    const watchVariantColorImages = useWatch({
      control,
      name: "variation_color_images",
    });

    const requiredFieldsAreFilled = watchItemUniqueId && watchItemName;

    useEffect(() => {
      if (mode === "ลบ" || mode === "แก้ไข") {
        const verifyItemUsage = async () => {
          const itemList = await SalesService.getSalesOrderItemIdList();
          const mappedItemIdList = itemList
            .map((order) =>
              order.item_list.map((item) => {
                return {
                  item_unique_id: item.item_unique_id,
                  render_status: order.render_status,
                };
              })
            )
            .flat();
          const formattedItemIdList = mappedItemIdList.reduce(
            (acc, currentItem) => {
              acc[currentItem.render_status] =
                acc[currentItem.render_status] || [];
              acc[currentItem.render_status].push(currentItem.item_unique_id);
              acc[currentItem.render_status] = [
                ...new Set(acc[currentItem.render_status]),
              ];
              return acc;
            },
            Object.create(null)
          );
          const nonDeletableStatuses = [
            "wait_customer_accept",
            "wait_payment_when_receive",
            "wait_payment_cod",
            "partially_paid",
            "fully_paid",
            "wait_payment",
            "wait_manufacture",
            "manufacturing",
            // "cancelled",
          ];
          for (const status of nonDeletableStatuses) {
            if (formattedItemIdList[status]?.includes(watchItemUniqueId)) {
              if (mode === "ลบ") {
                enqueueSnackbar("ไม่สามารถลบได้ เนื่องจากสินค้าถูกใช้งานอยู่", {
                  variant: "error",
                });
                setMode("");
              }
              return;
            }
          }
          if (
            formattedItemIdList["draft"]?.includes(watchItemUniqueId) ||
            formattedItemIdList["finished"]?.includes(watchItemUniqueId)
          ) {
            setItemExistInDocument(true);
          } else {
            setItemExistInDocument(false);
          }
          if (mode === "ลบ") {
            setShowDeleteItemConfirmation(true);
          }
        };
        verifyItemUsage();
      }
    }, [mode]);

    const tabs = [
      {
        label: "ทั่วไป",
        path: `${pathname}`,
      },
      {
        label: "ตัวเลือกสินค้า",
        path: `${pathname}?${isItemPage ? "tab" : "itemTab"}=variation`,
        disabled: !requiredFieldsAreFilled || !hasVariation,
      },
    ];

    const breadcrumbs = [
      {
        name: t("inventory.index"),
        to: "/inventory",
      },
      {
        name: t("inventory.items.index"),
        to: "/inventory/items",
      },
      {
        name: isEditPage
          ? watchItemName || "แก้ไขสินค้า"
          : t("inventory.items.add"),
      },
    ];

    const submitHandler = () => {
      onSubmit();
    };

    const generateDocumentId = useCallback(async () => {
      if (viewOnly) {
        return;
      }
      try {
        const item_id = await GlobalService.getUniqueId("item");
        setValue("unique_id", item_id);
      } catch (err) {
        console.log("Could not generate item ID");
      }
    }, [viewOnly]);

    useEffect(() => {
      if (pathname === "/inventory/items/add") {
        generateDocumentId();
      }
    }, [pathname, generateDocumentId]);

    const closeDeleteItemConfirmationHandler = () => {
      setShowDeleteItemConfirmation(false);
      setMode("");
    };

    const deleteItemConfirmationAction = () => {
      dispatch(
        deleteItem({ unique_id: watchItemUniqueId }, enqueueSnackbar, navigate)
      );
    };

    const currentTab = tab
      ? pathname + `?${isItemPage ? "tab" : "itemTab"}=${tab}`
      : pathname;
    return (
      <>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={6}>
            {isItemPage && <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />}
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", mb: isItemPage ? 0 : -1 }}>
          <Grid
            container
            alignItems={"flex-end"}
            sx={{ mt: isItemPage ? 2 : 0 }}
          >
            <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
              {isItemPage && (
                <Typography variant="h5">
                  {isEditPage ? watchItemName : t("inventory.items.add")}
                </Typography>
              )}
              {!isItemPage && (
                <Typography variant="h6">{watchItemName}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Grid item xs={5} sm={5} md={6} lg={6} xl={5}>
                  <ControlledDatePicker
                    name="created_date"
                    control={control}
                    error={errors.created_date?.error}
                    isOpen={datePickerIsOpen}
                    onClose={() => setDatePickerIsOpen(false)}
                    onOpen={() => setDatePickerIsOpen(true)}
                    label="วันที่สร้าง"
                    disabled
                  />
                </Grid>
                {isItemPage && inventoryPermission === "EDITABLE" && (
                  <Grid item xs={5} sm={5} md={4} lg={4} xl={3}>
                    <CustomizedMenuOption
                      size="medium"
                      label={"ตัวเลือก"}
                      options={["แก้ไข", "ลบ"]}
                      onSelect={(e) => {
                        setMode(e.target.innerText);
                      }}
                      disabled={!isEditPage}
                      btnTestId="item-option-btn"
                      menuTestId="item-option-menu"
                    />
                  </Grid>
                )}
                {isItemPage && (
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => setOpenDrawer(true)}
                      disabled={!isEditPage}
                    >
                      <HistoryIcon
                        fontSize="small"
                        color=" rgba(0, 0, 0, 0.54)"
                      />
                    </IconButton>
                    <RightDrawer
                      open={openDrawer}
                      onClose={() => setOpenDrawer(false)}
                      title={t("inventory.activity")}
                      uniqueId={watchItemUniqueId}
                      modelType="item"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {(hasVariation || isItemPage) && (
          <Box sx={{ mb: !isItemPage ? -5 : 0 }}>
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
          </Box>
        )}
        {!tab && (
          <GeneralTab
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            viewOnly={viewOnly}
            isEditPage={isEditPage}
            isItemPage={isItemPage}
            hasVariation={hasVariation}
            watchVariantList={watchVariantList}
            ref={ref}
            generateDocumentId={generateDocumentId}
            files={files}
            setFiles={setFiles}
          />
        )}
        {tab && (
          <VariationTab
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            viewOnly={viewOnly}
            isEditPage={isEditPage}
            isItemPage={isItemPage}
            hasVariation={hasVariation}
            watchItemUniqueId={watchItemUniqueId}
            watchVariantList={watchVariantList}
            watchVariantColorImages={watchVariantColorImages}
            mode={mode}
            tab={tab}
            isInit={isInit}
            colorImages={colorImages}
            setColorImages={setColorImages}
            isEditVariant={isEditVariant}
            setIsEditVariant={setIsEditVariant}
          />
        )}
        {!viewOnly && (
          <Box sx={{ display: "flex" }} mt={2}>
            <Box sx={{ mr: 1 }}>
              <CustomizedButton
                title={"ยกเลิก"}
                variant="outlined"
                type="reset"
                onClick={onReset}
                testId="item-reset-btn"
              />
            </Box>
            <CustomizedButton
              title={"บันทึก"}
              variant="contained"
              testId="item-submit-btn"
              onClick={submitHandler}
              disabled={
                !requiredFieldsAreFilled ||
                (hasVariation && watchVariantList?.length === 0) ||
                isEditVariant
              }
            />
          </Box>
        )}
        <DeleteItemConfirmation
          openDeleteItemConfirmation={showDeleteItemConfirmation}
          deleteItemConfirmationAction={deleteItemConfirmationAction}
          closeDeleteItemConfirmationHandler={
            closeDeleteItemConfirmationHandler
          }
          isUsed={itemExistInDocument}
        />
      </>
    );
  }
);
export default ItemForm;
