import { createContext, useEffect, useReducer } from "react";
import AuthService from "../services/Auth";
import jwt from "jwt-decode";
import dayjs from "dayjs";
import Cookies from "universal-cookie";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["LOGIN"] = "LOGIN";
  ActionType["LOGOUT"] = "LOGOUT";
  ActionType["VERIFY"] = "VERIFY";
  ActionType["REFRESH"] = "REFRESH";
})(ActionType || (ActionType = {}));

const mapPermissionsToObject = (permissions) => {
  if (!permissions || permissions.length === 0) return null;
  const permissionsObj = {};
  permissions.forEach((permission) => {
    permissionsObj[permission.access_key_name] = permission.access_value;
  });
  permissionsObj.role_unique_id = permissions[0].role_unique_id;
  return permissionsObj;
};

const cookies = new Cookies();

const defaultPermissions = {
  inventory: "NONE",
  contact: "NONE",
  sales: "NONE",
  manufacture: "NONE",
  config: "NONE",
  user: "NONE",
  role_unique_id: "",
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isAccessTokenExpired: false,
  isRefreshTokenExpired: false,
  user: null,
  role: null,
  permissions: defaultPermissions,
};

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
});

const handlers = {
  INITIALIZE: (state, action) => {
    const {
      isAuthenticated,
      isAccessTokenExpired,
      isRefreshTokenExpired,
      user,
      role,
      permissions,
    } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isAccessTokenExpired,
      isRefreshTokenExpired,
      user,
      role,
      permissions,
    };
  },
  LOGIN: (state, action) => {
    const { user, role, permissions } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isAccessTokenExpired: false,
      isRefreshTokenExpired: false,
      user,
      role,
      permissions,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isAccessTokenExpired: true,
    isRefreshTokenExpired: true,
    user: null,
    role: null,
    permissions: { ...defaultPermissions },
  }),
  VERIFY: (state, action) => {
    const { permissions, role } = action.payload;
    return {
      ...state,
      permissions,
      role,
    };
  },
  REFRESH: (state, action) => {
    const { isAccessTokenExpired, isRefreshTokenExpired } = action.payload;
    return {
      ...state,
      isAccessTokenExpired,
      isRefreshTokenExpired,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initialize = async () => {
      const accessToken = await cookies.get("access_token");
      const refreshToken = await cookies.get("refresh_token");
      try {
        if (accessToken) {
          // verify token
          const user = await AuthService.verifyToken();
          Sentry.setUser({ id: user.document_id, email: user.email });

          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isInitialized: true,
              isAuthenticated: true,
              user,
              role: user.role_list[0]?.name,
              permissions: user?.role_list[0]?.permission_list
                ? mapPermissionsToObject(user.role_list[0].permission_list)
                : { ...defaultPermissions },
              isAccessTokenExpired: false,
              isRefreshTokenExpired: refreshToken ? false : true,
            },
          });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              role: null,
              permissions: null,
              isAccessTokenExpired: true,
              isRefreshTokenExpired: refreshToken ? false : true,
            },
          });
        }
      } catch (err) {
        enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองอีกครั้งภายหลัง", {
          variant: "error",
        });
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            role: null,
            permissions: null,
            isAccessTokenExpired: accessToken ? false : true,
            isRefreshTokenExpired: refreshToken ? false : true,
          },
        });
      }
    };
    initialize();
  }, [enqueueSnackbar]);

  const login = async (email, password) => {
    const auth = await AuthService.postLogin({
      email: email.toLowerCase(),
      password,
    });
    const { exp } = await jwt(auth.token.access_token);
    const { exp: refreshTokenExp } = await jwt(auth.token.refresh_token);
    const formatExpireDate = dayjs.unix(exp).format();
    const formatRefreshExpireDate = dayjs.unix(refreshTokenExp).format();

    cookies.set("access_token", auth.token.access_token, {
      expires: new Date(formatExpireDate),
      path: "/",
    });

    cookies.set("refresh_token", auth.token.refresh_token, {
      expires: new Date(formatRefreshExpireDate),
      path: "/",
    });

    const user = auth.user;

    Sentry.setUser({ id: user.document_id, email: user.email });

    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user,
        role: user?.role_list[0]?.name,
        permissions: user?.role_list[0]?.permission_list
          ? mapPermissionsToObject(user.role_list[0].permission_list)
          : { ...defaultPermissions },
      },
    });
  };

  const logout = async () => {
    cookies.remove("access_token", { path: "/" });
    cookies.remove("refresh_token", { path: "/" });
    dispatch({
      type: ActionType.LOGOUT,
    });
    navigate("/login");
    Sentry.setUser(null);
  };

  const verify = async () => {
    try {
      const user = await AuthService.verifyToken();

      Sentry.setUser({ id: user.document_id, email: user.email });

      const permissions = mapPermissionsToObject(
        user?.role_list[0]?.permission_list
      );
      let samePermission = true;
      if (JSON.stringify(permissions) !== JSON.stringify(state.permissions)) {
        samePermission = false;
      }
      dispatch({
        type: ActionType.VERIFY,
        payload: {
          user,
          role: user?.role_list[0]?.name,
          permissions: user?.role_list[0]?.permission_list
            ? mapPermissionsToObject(user.role_list[0].permission_list)
            : { ...defaultPermissions },
        },
      });
      return samePermission;
    } catch (err) {
      dispatch({
        type: ActionType.VERIFY,
        payload: {
          role: null,
          permissions: null,
        },
      });
      return false;
    }
  };

  const refresh = async () => {
    const accessToken = await cookies.get("access_token");
    if (!accessToken) {
      const refreshToken = await cookies.get("refresh_token");
      if (refreshToken) {
        dispatch({
          type: ActionType.REFRESH,
          payload: {
            isAccessTokenExpired: true,
            isRefreshTokenExpired: false,
          },
        });
        try {
          const accessToken = await AuthService.postRefreshToken();
          const { exp } = await jwt(accessToken);
          const formatExpireDate = dayjs.unix(exp).format();
          cookies.set("access_token", accessToken, {
            expires: new Date(formatExpireDate),
            path: "/",
          });
          dispatch({
            type: ActionType.REFRESH,
            payload: {
              isAccessTokenExpired: false,
              isRefreshTokenExpired: false,
            },
          });
        } catch (err) {
          dispatch({
            type: ActionType.REFRESH,
            payload: {
              isAccessTokenExpired: true,
              isRefreshTokenExpired: true,
            },
          });
        }
      } else {
        dispatch({
          type: ActionType.REFRESH,
          payload: {
            isAccessTokenExpired: true,
            isRefreshTokenExpired: true,
          },
        });
      }
    } else {
      dispatch({
        type: ActionType.REFRESH,
        payload: {
          isAccessTokenExpired: false,
          isRefreshTokenExpired: false,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        verify,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
