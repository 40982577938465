import { createContext, useReducer } from "react";

const initialState = {
  contactSnapshot: null,
  isInit: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "initialize":
      return { ...state, contactSnapshot: action.payload, isInit: true };
    case "uninitialize":
      return { ...initialState };
    default:
      return state;
  }
};

const ContactContext = createContext({
  state: {
    contactSnapshot: null,
    isInit: false,
  },
  dispatch: () => {},
});

export const ContactContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ContactContext.Provider value={{ state, dispatch }}>
      {children}
    </ContactContext.Provider>
  );
};

export default ContactContext;
