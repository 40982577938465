import { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  List,
  ListItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  useNavigate,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useForm, Controller, useWatch } from "react-hook-form";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ImporterDropzoneUI from "../../../components/UI/ImporterDropzoneUI";
import ModalUI from "../../../components/UI/ModalUI";
import AgGrid from "../../../components/Table/AgGrid";
import CustomizedCheckboxes from "../../../components/Custom/CustomizedCheckboxes";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import { useAuth } from "../../../hooks/use-auth";
import {
  formatString,
  formatBoolean,
  formatFloat,
  getItemImporterSkuList,
  variationConvert,
  uniqueArray,
} from "../../../utils/dataTransformer";
import { itemSkuColumnDefs } from "../../../components/Table/ColumnDefs/Inventory/Items/sku";
import InventoryService from "../../../services/Inventory";
import GlobalService from "../../../services/Global";

const keys = [
  "attribute_1_value",
  "attribute_2_value",
  "attribute_3_value",
  "sale_price",
  "other_cost",
  "shirt_cost",
  "variation_is_active",
  "img_url",
];

const columns = [
  "unique_id",
  "is_variation",
  "name",
  "description",
  "img_url",
  "process_type",
  "item_type",
  "item_kind",
  "collection_name",
  "design_type",
  "block",
  "type_name",
  "design_owner",
  "design_code",
  "uom",
  "supplier",
  "process",
  "is_item_active",
  "inactive_remark",
  "attribute_1_value",
  "attribute_2_value",
  "attribute_3_value",
  "sale_price",
  "other_cost",
  "shirt_cost",
  "variation_is_active",
];

const requiredKeys = ["unique_id", "name"];

const ItemImporterPage = () => {
  //keys need to be defined in order
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, permissions } = useAuth();
  const gridRef = useRef();
  const { t } = useTranslation();
  const [displayedSkuList, setDisplayedSkuList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createErrors, setCreateErrors] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [formattedCreateOnlyData, setFormattedCreateOnlyData] = useState([]);
  const [createData, setCreateData] = useState([]);
  const [updateOrSkipData, setUpdateOrSkipData] = useState([]);

  const { control } = useForm({
    defaultValues: { is_upsert: true },
  });
  const watchIsUpsert = useWatch({ control, name: "is_upsert" });

  const inventoryPermission = permissions.inventory;

  const columnDefs = itemSkuColumnDefs(t, true);

  const breadcrumbs = [
    {
      name: t("inventory.items.index"),
      to: "/inventory/items",
    },
    {
      name: "นำเข้าสินค้า",
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "updates":
        if (watchIsUpsert) {
          setDisplayedSkuList(updateOrSkipData);
        } else {
          setDisplayedSkuList([]);
        }
        break;
      case "skips":
        if (!watchIsUpsert) {
          setDisplayedSkuList(updateOrSkipData);
        } else {
          setDisplayedSkuList([]);
        }
        break;
      default:
        setDisplayedSkuList(createData);
    }
    // setDisplayedSkuList(flattenedSkuList);
  }, [watchIsUpsert, tab, createData, updateOrSkipData]);

  const tabs = [
    {
      label: `สร้าง (${createData.length})`,
      path: `${pathname}`,
    },
    {
      label: `แก้ไข (${watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=updates`,
    },
    {
      label: `ข้าม (${!watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=skips`,
    },
  ];

  const formatItemData = async (data) => {
    const tempData = structuredClone(data);

    const tempSkuList = [];

    const formatVariationList = (item) => {
      if (!item.is_variation) {
        return undefined;
      }
      let currentIndex = -1;
      const result =
        item.attribute_1_value?.map((color) => {
          return {
            key: "color",
            name: color,
            value: item.attribute_2_value.map((size) => {
              return {
                key: "size",
                name: size,
                value: item.attribute_3_value.map((arm) => {
                  currentIndex++;
                  return {
                    key: "arm",
                    name: arm,
                    other_cost: !isNaN(item.other_cost?.[currentIndex])
                      ? formatFloat(item.other_cost[currentIndex])
                      : 0,
                    sale_price: !isNaN(item.sale_price?.[currentIndex])
                      ? formatFloat(item.sale_price[currentIndex])
                      : 0,
                    shirt_cost: !isNaN(item.shirt_cost?.[currentIndex])
                      ? formatFloat(item.shirt_cost[currentIndex])
                      : 0,
                    sku_name: `${item.unique_id}-${color}-${size}-${arm}`,
                    is_active: formatBoolean(
                      item.variation_is_active?.[currentIndex + 1],
                      true
                    ),
                  };
                }),
              };
            }),
          };
        }) ?? undefined;
      return result;
    };

    let missingCols;

    try {
      // find missing columns
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      tempData.forEach((item) => {
        if (
          (typeof item.is_variation !== "boolean" &&
            !["TRUE", "FALSE"].includes(item.is_variation)) ||
          (typeof item.is_item_active !== "boolean" &&
            !["TRUE", "FALSE"].includes(item.is_item_active)) ||
          (item.is_variation && !item.variation_is_active) ||
          (item.is_variation &&
            item.variation_is_active?.some(
              (status) =>
                typeof status !== "boolean" &&
                !["TRUE", "FALSE"].includes(status)
            ))
        ) {
          throw new Error("boolean");
        }
      });

      const formattedData = tempData.map((item) => {
        const is_variation = formatBoolean(item.is_variation);
        const attribute_1_value = is_variation
          ? uniqueArray(item.attribute_1_value)
          : undefined;
        const attribute_2_value = is_variation
          ? uniqueArray(item.attribute_2_value)
          : undefined;
        const attribute_3_value = is_variation
          ? uniqueArray(item.attribute_3_value)
          : undefined;
        const other_cost = is_variation
          ? item.other_cost
          : !isNaN(parseFloat(item.other_cost?.[0]))
          ? parseFloat(item.other_cost[0])
          : 0;
        const sale_price = is_variation
          ? item.sale_price
          : !isNaN(parseFloat(item.sale_price?.[0]))
          ? parseFloat(item.sale_price[0])
          : 0;
        const shirt_cost = is_variation
          ? item.shirt_cost
          : !isNaN(parseFloat(item.shirt_cost?.[0]))
          ? parseFloat(item.shirt_cost[0])
          : 0;
        const sku_name = is_variation ? uniqueArray(item.sku_name) : undefined;
        const is_active = formatBoolean(item.is_item_active, true);
        const variation_color_images = item.img_url ?? [];
        item.variation_list = formatVariationList({
          ...item,
          is_variation,
          attribute_1_value,
          attribute_2_value,
          attribute_3_value,
          other_cost,
          sale_price,
          shirt_cost,
          sku_name,
          is_active,
        });

        const skuList = getItemImporterSkuList({
          ...item,
          is_variation,
          attribute_1_value,
          attribute_2_value,
          attribute_3_value,
          other_cost,
          sale_price,
          shirt_cost,
          sku_name,
          is_active,
          variation_color_images:
            variation_color_images.filter((img) => img).length > 0,
          colorImages: variation_color_images,
        });
        tempSkuList.push(
          skuList.map((sku) => {
            return { ...sku, unique_id: formatString(item.unique_id) };
          })
        );

        return {
          unique_id: formatString(item.unique_id),
          is_variation,
          name: formatString(item.name),
          description: formatString(item.description),
          img_url: formatString(item.img_url?.[0]),
          process_type: formatString(item.process_type),
          item_type: formatString(item.item_type),
          item_kind: formatString(item.item_kind),
          collection_name: formatString(item.collection_name),
          design_type: formatString(item.design_type),
          block: formatString(item.block),
          type_name: formatString(item.type_name),
          design_owner: formatString(item.design_owner),
          design_code: formatString(item.design_code),
          uom: formatString(item.uom),
          supplier: formatString(item.supplier),
          process: formatString(item.process),
          inactive_remark: formatString(item.inactive_remark),
          sku_list: skuList.map((sku) => {
            return { ...sku, name: undefined };
          }),
          item_variant_key_list: item.is_variation
            ? [
                variationConvert(attribute_1_value, "color"),
                variationConvert(attribute_2_value, "size"),
                variationConvert(attribute_3_value, "arm"),
                variationConvert(
                  [
                    ...new Set(
                      variation_color_images.map((data, index) => {
                        if (!data) {
                          return `placeholder-${index}`;
                        }
                        return data;
                      })
                    ),
                  ],
                  "color_images"
                ),
              ]
            : undefined,
          is_active,
        };
      });
      const existingItemIds = await InventoryService.getItemIds();
      const itemsToCreate = [];
      const itemsToSkipOrUpdate = [];

      const flattenedSkuList = tempSkuList.flat();

      flattenedSkuList.forEach((sku) => {
        if (existingItemIds.includes(sku.unique_id)) {
          itemsToSkipOrUpdate.push(sku);
        } else {
          itemsToCreate.push(sku);
        }
      });
      setCreateData(itemsToCreate);
      setUpdateOrSkipData(itemsToSkipOrUpdate);
      setFormattedCreateOnlyData(
        formattedData.filter(
          (item) => !existingItemIds.includes(item.unique_id)
        )
      );
      return formattedData;
    } catch (err) {
      console.log(err);
      if (err.message === "template") {
        enqueueSnackbar(
          `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`,
          {
            variant: "error",
          }
        );
      } else if (err.message === "boolean") {
        enqueueSnackbar(
          `ข้อมูลใน column ไม่ตรงตามรูปแบบ\nกรุณาระบุ TRUE/FALSE`,
          {
            variant: "error",
            style: {
              whiteSpace: "pre-line",
            },
          }
        );
      } else {
        enqueueSnackbar(`ข้อมูลใน column ไม่ตรงตาม Template`, {
          variant: "error",
        });
      }
      return [];
    }
  };

  const importFormattedDocuments = async () => {
    if (formattedData.length === 0) {
      return;
    }
    setIsLoading(true);
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (Array.isArray(formattedDataSnapshot[i][requiredKeys[j]])) {
          let hasError;
          for (
            let k = 0;
            k < formattedDataSnapshot[i][requiredKeys[j]].length;
            k++
          ) {
            if (
              Object.values(formattedDataSnapshot[i][requiredKeys[j]][k]).some(
                (item) => item === null || typeof item === "undefined"
              )
            ) {
              hasError = true;
              break;
            }
          }
          if (hasError) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j],
            });
            formattedDataSnapshot.splice(i, 1);
            i--;
            break;
          }
        }
        if (
          formattedDataSnapshot[i][requiredKeys[j]] !== 0 &&
          !formattedDataSnapshot[i][requiredKeys[j]]
        ) {
          requiredFieldErrors.push({
            unique_id: formattedDataSnapshot[i].unique_id,
            type: "required",
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setHasCreateError(true);
    } else {
      let idsWithSpace = [];
      try {
        let data;
        if (watchIsUpsert) {
          const dataWithFormattedId = formattedData.filter((data) => {
            if (data.unique_id.includes(" ")) {
              idsWithSpace.push(data.unique_id);
              return false;
            }
            return true;
          });
          data = await InventoryService.createOrUpdateItems(
            dataWithFormattedId
          );
        } else {
          const dataWithFormattedId = formattedCreateOnlyData.filter((data) => {
            if (data.unique_id.includes(" ")) {
              idsWithSpace.push(data.unique_id);
              return false;
            }
            return true;
          });
          data = await InventoryService.createItems(dataWithFormattedId);
        }
        const loadedProcessTypes = await GlobalService.getCreatables({
          usage_field_type: "item",
          usage_field_name: "item_type",
        });
        const existingProcessTypes = loadedProcessTypes.map(
          (creatable) => creatable.name
        );
        const processTypesToCreate = [
          ...new Set(data.results.map((item) => item.item_type)),
        ];
        const filteredProcessTypesToCreate = processTypesToCreate.filter(
          (item) => item && !existingProcessTypes.includes(item)
        );
        const mappedProcessTypesToCreate = filteredProcessTypesToCreate.map(
          (item) => {
            return {
              usage_field_type: "item",
              usage_field_name: "item_type",
              name: item,
            };
          }
        );
        if (mappedProcessTypesToCreate.length > 0) {
          try {
            await GlobalService.createCreatables(mappedProcessTypesToCreate);
          } catch (err) {
            console.log(err);
          }
        }
        if (idsWithSpace.length > 0) {
          throw new Error("id_whitespace");
        }

        if (data.errors?.length === 0) {
          let message = "";
          if (
            watchIsUpsert &&
            createData.length > 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "นำเข้าและแก้ไขสินค้าสำเร็จ";
          } else if (
            watchIsUpsert &&
            createData.length === 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "แก้ไขสินค้าสำเร็จ";
          } else if (
            (watchIsUpsert &&
              createData.length > 0 &&
              updateOrSkipData.length === 0) ||
            (!watchIsUpsert && createData.length > 0)
          ) {
            message = "นำเข้าสำเร็จ";
          }
          enqueueSnackbar(message, {
            variant: "success",
          });
          navigate("/inventory/items");
        } else {
          setCreateErrors((prevErrors) => [...prevErrors, ...data.errors]);
          setHasCreateError(true);
          throw new Error();
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        if (err.message === "id_whitespace") {
          setHasCreateError(true);
          setCreateErrors((prevErrors) => [
            ...prevErrors,
            { type: "id_whitespace", ids: idsWithSpace },
          ]);
        }
        setIsLoading(false);
      }
    }
  };

  const renderCreateErrors = () => {
    if (createErrors.find((error) => error.type === "template_from_importer")) {
      enqueueSnackbar("Template ไม่ตรง", {
        variant: "error",
      });
    }
    const requiredFieldErrors = [
      ...new Set(
        createErrors
          .filter((error) => error.type === "required")
          .map((error) => error.field)
      ),
    ];
    if (requiredFieldErrors.length > 0) {
      enqueueSnackbar(
        `ไม่พบข้อมูลใน column\n${requiredFieldErrors.join(", ")}`,
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
    const whiteSpaceError = createErrors.find(
      (error) => error.type === "id_whitespace"
    );
    if (whiteSpaceError) {
      return (
        <ModalUI
          open={hasCreateError}
          handleClose={() => setHasCreateError(false)}
          fullWidth
          title="ไม่สามารถสร้างได้ เนื่องจากรหัสสินค้ามีการเว้นวรรค"
          titleSize="16px"
          maxWidth="xs"
        >
          <Typography fontWeight="bold">รายการที่ไม่สามารถสร้างได้:</Typography>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {whiteSpaceError.ids.map((id) => (
              <ListItem
                key={id}
                sx={{
                  display: "list-item",
                }}
              >
                <Typography
                  sx={{
                    display: "inline",
                    color: (theme) => theme.palette.warning.main,
                  }}
                >
                  {id}
                </Typography>
              </ListItem>
            ))}
          </List>
        </ModalUI>
      );
    }
    return <></>;
  };

  const currentTab = tab ? pathname + `?tab=${tab}` : pathname;

  return (
    <>
      {inventoryPermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้าสินค้า"}</Typography>
      </Box>
      <Box
        sx={{ mt: 1, mb: 2, display: "flex", justifyContent: "space-between" }}
      >
        <CustomizedButton
          title="ดาวน์โหลด Template"
          variant="outlined"
          onClick={() =>
            window.open("/static/item_import_template.xlsx", "_blank")
          }
        />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatItemData}
            setLoading={setIsLoading}
            setErrors={setCreateErrors}
            setHasError={setHasCreateError}
            hasLabelHeader
          />
        </Grid>
      </Grid>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Controller
              name={"is_upsert"}
              control={control}
              render={({ field }) => (
                <CustomizedCheckboxes
                  label="อัปเดตรายการปัจจุบัน"
                  {...field}
                  isDisabled={formattedData.length === 0}
                />
              )}
            />
            <CustomizedTooltip
              title={
                "กรณีที่มีรหัสสินค้าเดิมอยู่แล้ว จะเป็นการเลือกเพื่ออัปเดตข้อมูลใหม่ในรายการสินค้าเดิม"
              }
              noMaxWidth
            >
              <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomizedButton
              title="นำเข้า"
              variant="contained"
              onClick={importFormattedDocuments}
              disabled={
                formattedData.length === 0 ||
                isLoading ||
                (!watchIsUpsert && formattedCreateOnlyData.length === 0)
              }
            />
          </Box>
        </Grid>
      </Grid>

      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box>
          <Box sx={{ maxWidth: "100%", overflow: "scroll", mt: 4 }}>
            <CustomizedTab
              tabs={tabs}
              currentTab={currentTab}
              scrollable
              table
            />
          </Box>
          <Box>
            <AgGrid
              ref={gridRef}
              columnDefs={columnDefs}
              height={649}
              rowData={displayedSkuList}
              groupDefaultExpanded={1}
              disableFloatingFilter={true}
            />
          </Box>
        </Box>
      )}
      {hasCreateError && renderCreateErrors()}
    </>
  );
};

export default ItemImporterPage;
