import { formatDate, formatDateTimeWithSeconds } from "./date-converter";

export const itemExportFormatter = (data) => {
  const itemSkus = structuredClone(data);

  const formattedItems = itemSkus.map((flat_sku) => {
    const sku = flat_sku.sku;
    const is_variation = sku.item.is_variation;
    const item_is_active = sku.item.is_active ? "TRUE" : "FALSE";
    const created_by = `${sku.item.created_by?.first_name ?? ""}${
      sku.item.created_by?.last_name ? ` ${sku.item.created_by?.last_name}` : ""
    }`;
    const last_updated_by = `${sku.item.last_updated_by?.first_name ?? ""}${
      sku.item.last_updated_by?.last_name
        ? ` ${sku.item.last_updated_by?.last_name}`
        : ""
    }`;
    return {
      unique_id: sku.item_unique_id,
      is_variation: is_variation ? "TRUE" : "FALSE",
      name: sku.item.name,
      description: sku.item.description,
      img_url: is_variation
        ? sku.sku_detail.img_url
        : sku.item.img_url[0] ?? "",
      process_type: sku.item.process_type,
      item_type: sku.item.item_type,
      item_kind: sku.item.item_kind,
      collection_name: sku.item.collection_name,
      design_type: sku.item.design_type,
      type_name: sku.item.type_name,
      block: sku.item.block,
      design_owner: sku.item.design_owner,
      design_code: sku.item.design_code,
      uom: sku.item.uom,
      supplier: sku.item.supplier,
      process: sku.item.process,
      is_active: item_is_active,
      inactive_remark: sku.item.inactive_remark,
      attribute_1_name: is_variation ? "สี" : "",
      attribute_1_value: is_variation
        ? sku.item_variant_value?.[0].item_variant_value_name
        : "",
      attribute_2_name: is_variation ? "Size" : "",
      attribute_2_value: is_variation
        ? sku.item_variant_value?.[1].item_variant_value_name
        : "",
      attribute_3_name: is_variation ? "แขน" : "",
      attribute_3_value: is_variation
        ? sku.item_variant_value?.[2].item_variant_value_name
        : "",
      sale_price: sku.sku_detail.sale_price,
      other_cost: sku.sku_detail.other_cost,
      shirt_cost: sku.sku_detail.shirt_cost,
      sku_name: is_variation ? sku.sku_name : sku.item.name,
      variation_is_active: is_variation
        ? sku.sku_detail.is_active
          ? "TRUE"
          : "FALSE"
        : "",
      created_by,
      created_date: formatDate(sku.item.created_date),
      last_updated_by,
      last_updated_date: formatDate(sku.item.last_updated_date),
    };
  });

  return formattedItems;
};

export const allItemsFormatterClientSide = (data, isMainPage) => {
  const formatted = data.map((sku) => ({
    created_date: isMainPage
      ? formatDateTimeWithSeconds(sku.created_date)
      : formatDate(sku.created_date),
    item_unique_id: sku.item_unique_id,
    sku_name: sku.sku_name,
    item_name: sku.item.name,
    item_desc: sku.sku_detail.item_desc,
    process_type: sku.sku_detail.process_type,
    item_type: sku.sku_detail.item_type,
    item_kind: sku.sku_detail.item_kind,
    sale_price: sku.sku_detail.sale_price,
    is_active: sku.sku_detail.is_active ? "ใช้งาน" : "ไม่ใช้งาน",
  }));
  return formatted;
};
