import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Link,
} from "@mui/material";
import styled from "@emotion/styled";
import { useCallback, useRef, useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckboxModalTable from "../../Table/CheckboxModalTable";
import { contactColumnDefs } from "../../Table/ColumnDefs/Contact";
import ContactService from "../../../services/Contact";
import ControlledTextField from "../../Controlled/ControlledTextField";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import ControlledServerSideCreatable from "../../Controlled/ControlledSSCreatable";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import CustomizedChips from "../../Custom/CustomizedChips";
import ContactFormModalUI from "../Order/ContactModalUI";
import ManufactureItemUpdateConfirmation from "../../UI/Confirmation/ManufactureItemUpdateConfirmation";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const brandOptions = ["Am A Love", "I Am Premium"];

const ManufacturerForm = forwardRef(
  ({ control, errors, disabled, setValue, getValues, id }, ref) => {
    const { t } = useTranslation();
    const { manufactureItemSettings } = useSelector(
      (state) => state.manufactureOrder
    );
    const selectVendorGridRef = useRef();

    const [contactFormModal, setContactFormModal] = useState(false);
    const [showSelectVendor, setShowSelectVendor] = useState(false);
    const [openItemUpdateConfirmation, setOpenItemUpdateConfirmation] =
      useState(false);
    const [tempUpdateFieldName, setTempUpdateFieldName] = useState(null);
    const [tempUpdateFieldValue, setTempUpdateFieldValue] = useState(null);

    const [vendorIds, setVendorIds] = useState([]);
    const [vendorIdsSnapshot, setVendorIdsSnapshot] = useState([]);
    const [vendorError, setVendorError] = useState(false);

    const watchVendorId = useWatch({ control, name: "vendor_unique_id" });
    const watchBrandName = useWatch({ control, name: "brand_name" });
    const watchStatus = useWatch({ control, name: "status" });

    const updateItemList = (fieldToUpdateItem, valueToUpdateItem) => {
      const itemList = getValues("item_list").map((item) => {
        const matchingItemPriceSetting = manufactureItemSettings.find(
          (setting) => {
            return (
              setting.brand_name ===
                (fieldToUpdateItem === "brand_name"
                  ? valueToUpdateItem
                  : watchBrandName) &&
              setting.vendor_unique_id ===
                (fieldToUpdateItem === "vendor_unique_id"
                  ? valueToUpdateItem
                  : watchVendorId) &&
              setting.item_type === item.item_type &&
              setting.block === item.block
            );
          }
        );
        const formattedItemList = {
          ...item,
          printing_cost: matchingItemPriceSetting?.printing_cost || 0,
          hot_rolled_cost: matchingItemPriceSetting?.hot_rolled_cost || 0,
          fold_cost: matchingItemPriceSetting?.fold_cost || 0,
        };
        return formattedItemList;
      });
      setTempUpdateFieldName(null);
      setTempUpdateFieldValue(null);
      setValue("item_list", itemList);
    };

    useEffect(() => {
      if (id) {
        const vendor = getValues("vendor");
        if (vendor) {
          vendorSelectHandler(vendor, true);
          setVendorIds([vendor.unique_id]);
          setVendorIdsSnapshot([vendor.unique_id]);
        }
      }
    }, [id]);

    const openContactFormHandler = () => {
      if (!watchVendorId) {
        setVendorError(true);
        return;
      }
      setContactFormModal(true);
    };

    const closeContactFormHandler = () => {
      setContactFormModal(false);
    };

    const openVendorTable = () => {
      setShowSelectVendor(true);
    };

    const closeVendorTable = () => {
      setShowSelectVendor(false);
    };

    const updateItemConfirmHandler = () => {
      setOpenItemUpdateConfirmation(false);
      updateItemList(tempUpdateFieldName, tempUpdateFieldValue);
    };

    const triggerItemUpdateHandler = (fieldName, value) => {
      if (watchStatus) {
        setTempUpdateFieldName(fieldName);
        setTempUpdateFieldValue(value);
        setOpenItemUpdateConfirmation(true);
      } else {
        updateItemList(fieldName, value);
      }
    };

    const getRowId = useCallback((params) => {
      return params.data.unique_id;
    }, []);

    const vendorSelectHandler = (data, isDataFirstLoad) => {
      if (data) {
        const {
          unique_id,
          contact_type,
          name,
          last_name,
          contact_channel_list,
        } = data;

        const formatContactName = () => {
          if (contact_type === "นิติบุคคล" || !last_name) {
            return name;
          }
          return `${name} ${last_name}`;
        };

        setValue("vendor_unique_id", unique_id);
        setValue("vendor_name", formatContactName());

        if (!isDataFirstLoad) {
          const filteredPhoneList = contact_channel_list.filter(
            (list) => list.contact_channel_type === "เบอร์โทรศัพท์"
          );

          setValue(
            "vendor_phone",
            filteredPhoneList[0]?.contact_channel_name ?? ""
          );
          triggerItemUpdateHandler("vendor_unique_id", unique_id);
        }
      } else {
        if (!isDataFirstLoad) {
          triggerItemUpdateHandler("vendor_unique_id", "");
          setValue("vendor_unique_id", "");
          setValue("vendor_name", "");
          setValue("vendor_phone", "");
        }
      }
      setShowSelectVendor(false);
      setVendorError(false);
    };

    const appendTag = (value) => {
      const tagList = getValues("tag_list");
      if (!tagList.includes(value)) {
        append(value);
      }
    };

    const removeTag = (value) => {
      const tagList = getValues("tag_list");
      const tagIndex = tagList.indexOf(value);
      if (tagIndex !== -1) {
        remove(tagIndex);
      }
    };

    const datasource = {
      async getRows(params) {
        const request = params.request;
        const isActiveFilter = request.filterModel.is_active;
        const activeFilterValue =
          isActiveFilter?.values[0] === "active" ? "true" : "false";
        const agGridOptions = {
          startRow: request.startRow,
          endRow: request.endRow,
          filterModel: {
            ...request.filterModel,
            unique_id: {
              ...request.filterModel.unique_id,
              mode: "insensitive",
            },
            is_vendor: {
              filterType: "boolean",
              type: "equals",
              filter: "true",
            },
            is_active:
              isActiveFilter && isActiveFilter?.values?.length === 1
                ? {
                    filterType: "boolean",
                    type: "equals",
                    filter: activeFilterValue,
                  }
                : undefined,
            tag_list: request.filterModel.tag_list
              ? {
                  filterType: "objectArray",
                  type: "some",
                  filter: {
                    name: {
                      filter: request.filterModel.tag_list.filter,
                      filterType: "text",
                      type: "contains",
                      mode: "insensitive",
                    },
                  },
                }
              : undefined,
            business_group_field: {
              ...request.filterModel.business_group_field,
              mode: "insensitive",
            },
            customer_quality: {
              ...request.filterModel.customer_quality,
              mode: "insensitive",
            },
          },
          sortModel: request.sortModel,
        };
        try {
          const allContacts = await ContactService.getAllContactsAgGrid(
            agGridOptions,
            params
          );
          params.successCallback(allContacts.results, allContacts.count);
        } catch (err) {
          console.log(err);
          params.failCallback();
        }
      },
    };

    const onGridReady = (params) => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
      params.api.setServerSideDatasource(datasource);
    };

    const { fields, append, remove } = useFieldArray({
      control,
      name: "tag_list",
    });

    const renderGroupChips = () => {
      return fields.map((item, index) => (
        <Controller
          key={item.id}
          name={`tag_list.${index}`}
          control={control}
          render={({ field }) => (
            <Box sx={{ display: "inline-block", mr: 1 }}>
              <CustomizedChips
                {...field}
                onDelete={!disabled ? () => remove(index) : null}
              />
            </Box>
          )}
        />
      ));
    };

    return (
      <CustomizedBox ref={ref}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography sx={{ ml: 1, fontWeight: "bold" }}>
            {t("manufacture.order.vendor_detail")}
          </Typography>
          {!disabled && (
            <CustomizedTooltip title="เลือกผู้ผลิต">
              <IconButton
                onClick={openVendorTable}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("manufacture.order.manufacturer_unique_id")}
              control={control}
              name="vendor_unique_id"
              disabled={true}
              error={Boolean(errors?.vendor_unique_id) || vendorError}
              helperText={
                (errors?.vendor_unique_id &&
                  errors?.vendor_unique_id.message) ||
                (vendorError && "กรุณาเลือกผู้ผลิต")
              }
              sx={{
                input: { visibility: disabled ? "hidden" : "visible" },
              }}
              InputProps={{
                startAdornment: disabled && (
                  <InputAdornment position="start">
                    <StyledLink onClick={openContactFormHandler}>
                      {watchVendorId || "-"}
                    </StyledLink>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!disabled && (
                      <IconButton
                        onClick={openContactFormHandler}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("manufacture.order.manufacturer_name")}
              control={control}
              name="vendor_name"
              disabled={true}
              error={Boolean(errors?.vendor_name)}
              helperText={errors?.vendor_name && errors?.vendor_name.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("manufacture.order.phone")}
              control={control}
              name="vendor_phone"
              disabled={disabled}
              error={Boolean(errors?.vendor_phone)}
              helperText={errors?.vendor_phone && errors?.vendor_phone.message}
            />
          </Grid>
        </Grid>
        <Typography sx={{ ml: 1, my: 2 }}>
          {t("sales.order.sales_detail")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledServerSideCreatable
              name="brand_name"
              title={t("sales.order.brand_name")}
              control={control}
              error={Boolean(errors?.brand_name)}
              helperText={errors?.brand_name && errors?.brand_name.message}
              viewOnly={disabled}
              documentType="order"
              defaultOptions={[]}
              setValue={setValue}
              actionAfterAdd={triggerItemUpdateHandler}
              actionAfterDelete={triggerItemUpdateHandler}
              actionAfterSelect={
                watchStatus ? triggerItemUpdateHandler : () => {}
              }
              onChange={(e, option, field) => {
                setTempUpdateFieldName("brand_name");
                if (!option) {
                  if (watchStatus) {
                    setTempUpdateFieldValue("");
                  } else {
                    triggerItemUpdateHandler("brand_name", "");
                  }
                  return field.onChange("");
                }
                if (option.value) {
                  if (watchStatus) {
                    setTempUpdateFieldValue(option.value);
                  } else {
                    triggerItemUpdateHandler("brand_name", option.value);
                  }
                  return field.onChange(option.value);
                }
                if (option.inputValue) {
                  setTempUpdateFieldName("brand_name");
                  setTempUpdateFieldValue(option.inputValue);
                  return field.onChange(option.inputValue);
                }
                return field.onChange(option);
              }}
            />
          </Grid>
        </Grid>
        <Typography sx={{ ml: 1, my: 2, fontWeight: 500 }}>
          {t("manufacture.order.tag_mfg")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <ControlledServerSideCreatable
              control={control}
              name="tag_mfg"
              documentType="manufacture_order"
              defaultOptions={[]}
              setValue={setValue}
              getValues={getValues}
              viewOnly={disabled}
              actionAfterAdd={appendTag}
              actionAfterSelect={appendTag}
              actionAfterDelete={removeTag}
              isTagCreatable
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={8} alignSelf="center">
            {renderGroupChips()}
          </Grid>
        </Grid>
        <CheckboxModalTable
          getRowId={getRowId}
          modalTitle={t("contact.index")}
          btnTitle={t("button.add")}
          gridRef={selectVendorGridRef}
          height={450}
          columnDefs={contactColumnDefs(t, false, true)}
          rowSelection="single"
          onFinishEditing={vendorSelectHandler}
          modalIsOpen={showSelectVendor}
          closeModal={closeVendorTable}
          onGridReady={onGridReady}
          selectedIds={vendorIds}
          setSelectedIds={setVendorIds}
          idsSnapshot={vendorIdsSnapshot}
          setIdsSnapshot={setVendorIdsSnapshot}
        />
        <ContactFormModalUI
          contactId={watchVendorId}
          modalIsOpen={contactFormModal}
          setModalIsOpen={setContactFormModal}
          closeContactForm={closeContactFormHandler}
          setCustomer={vendorSelectHandler}
          isVendor
        />
        <ManufactureItemUpdateConfirmation
          open={openItemUpdateConfirmation}
          handleClose={() => setOpenItemUpdateConfirmation(false)}
          onUpdate={updateItemConfirmHandler}
        />
      </CustomizedBox>
    );
  }
);

export default ManufacturerForm;
