import { v4 as uuidV4 } from "uuid";
import { formatDate, formatDateTime } from "./date-converter";
import SalesService from "../services/Sales";
import { formatFloat } from "./dataTransformer";
import { uploadFileToS3 } from "./s3";

export const formatCreateManufactureOrderFromSalesOrder = (data) => {
  return { ...data };
};

export const formatManufactureOrderPayload = (data, type, submitType) => {
  const formattedEmployeeList = data.employee_list.map((employee) => ({
    id: employee.id,
    unique_id: employee.unique_id,
    first_name: employee.first_name,
    last_name: employee.last_name,
    email: employee.email,
    phone: employee.phone,
    img_url: employee.img_url,
    department: employee.department,
    position: employee.position,
  }));

  const formattedItemListPayload = data.item_list.map((item) => {
    const other_cost = formatFloat(item.other_cost);
    const printing_cost = formatFloat(item.printing_cost);
    const hot_rolled_cost = formatFloat(item.hot_rolled_cost);
    const fold_cost = formatFloat(item.fold_cost);
    const amount = item.amount ? parseInt(item.amount) : 0;
    return {
      id: type === "update" ? item.item_id ?? undefined : undefined,
      item_unique_id: item.item_unique_id,
      item_name: item.item_name,
      process_type: item.process_type,
      sku_name: item.sku_name,
      color: item.color,
      size: item.size,
      arm: item.arm,
      neck: item.neck,
      amount: amount,
      item_kind: item.item_kind,
      item_type: item.item_type,
      block: item.block,
      source_ready_shirt: item.source_ready_shirt,
      other_cost: other_cost,
      printing_cost: printing_cost,
      hot_rolled_cost: hot_rolled_cost,
      fold_cost: fold_cost,
      all_cost:
        (other_cost + printing_cost + hot_rolled_cost + fold_cost) * amount,
      uom: item.uom,
      img_url: item.img_url ?? undefined,
      item_remark: item.item_remark,
    };
  });

  const total_printing_cost = data.item_list.reduce(
    (acc, currentItem) =>
      formatFloat(acc) +
      formatFloat(currentItem.printing_cost) *
        parseInt(currentItem.amount || 0),
    0
  );

  const total_hot_rolled_cost = data.item_list.reduce(
    (acc, currentItem) =>
      formatFloat(acc) +
      formatFloat(currentItem.hot_rolled_cost) *
        parseInt(currentItem.amount || 0),
    0
  );

  const total_fold_cost = data.item_list.reduce(
    (acc, currentItem) =>
      formatFloat(acc) +
      formatFloat(currentItem.fold_cost) * parseInt(currentItem.amount || 0),
    0
  );

  const total_other_cost = data.item_list.reduce(
    (acc, currentItem) =>
      formatFloat(acc) +
      formatFloat(currentItem.other_cost) * parseInt(currentItem.amount || 0),
    0
  );

  const total_all_cost =
    total_printing_cost + total_hot_rolled_cost + total_fold_cost;

  const total_amount = total_all_cost + total_other_cost;

  const steps = ["draft", "manufacturing", "completed"];

  const formatSubmitStatus = () => {
    switch (submitType) {
      case "draft":
        return steps[0];
      case "sendToManufacture":
        return steps[1];
      default:
        return data.main_status;
    }
  };

  const payload = {
    ...data,
    main_status: formatSubmitStatus(),
    sub_status: data.sub_status ?? undefined,
    flag_status: data.flag_status ?? undefined,
    employee_list: formattedEmployeeList,
    item_list: formattedItemListPayload,
    total_printing_cost,
    total_hot_rolled_cost,
    total_fold_cost,
    total_other_cost,
    total_all_cost,
    total_amount,
  };

  if (type === "update") {
    delete payload.unique_id;
  }

  delete payload.id;
  delete payload.created_date;
  delete payload.customer;
  delete payload.vendor;
  delete payload.created_by;
  delete payload.summary_template;
  delete payload.creator_unique_id;
  delete payload.status;
  delete payload.pending_cancel;
  delete payload.tag_mfg;

  return payload;
};

export const formatManufactureOrderQuery = (data, state) => {
  let balance_cod = 0;

  if (state && state.order_payment_list?.length > 0) {
    balance_cod =
      state.order_payment_list
        .filter((payment) => payment.payment_method === "COD")
        .slice(-1)[0]?.remaining_amount || 0;
  } else if (data.unique_id) {
    balance_cod = data.balance_cod;
  }

  const item_list = state ? state.item_list : data.item_list;

  const formattedItemListQueryPayload = item_list
    .map((item) => ({
      item_id: item.id,
      item_unique_id: item.item_unique_id,
      item_name: item.item_name,
      process_type: item.process_type,
      item_kind: item.item_kind,
      img_url: item.img_url ?? "",
      type: item.item_kind || "-",
      item_type: item.item_type,
      block: item.block,
      source_ready_shirt: item.source_ready_shirt,
      other_cost: item.other_cost ?? 0,
      printing_cost: item.printing_cost ?? 0,
      hot_rolled_cost: item.hot_rolled_cost ?? 0,
      fold_cost: item.fold_cost ?? 0,
      all_cost: item.all_cost ?? 0,
      neck: item.neck,
      amount: state ? item.item_amount || 0 : item.amount || 0,
      uom: item.uom,
      item_remark: item.item_remark,
      sku_name: item.sku_name,
      sort_index: item.sort_index ?? null,
    }))
    .sort((a, b) => {
      if (a.sort_index === null && b.sort_index === null) {
        return 0;
      } else if (a.sort_index === null) {
        return 1;
      } else if (b.sort_index === null) {
        return -1;
      } else {
        return a.sort_index - b.sort_index;
      }
    });

  const formattedData = {
    ...data,
    brand_name: state ? state.brand_name : data.brand_name,
    payment_date: state ? state.payment_date : data.payment_date,
    created_date: data.created_date ?? new Date(),
    production_date: data.production_date ?? new Date(),
    receive_date: state ? state.due_date : data.receive_date ?? new Date(),
    external_ref_document_id: state
      ? state.unique_id
      : data.external_ref_document_id,
    item_list: formattedItemListQueryPayload,
    customer: state ? state.contact : data.customer,
    customer_unique_id: state
      ? state.contact_unique_id
      : data.customer_unique_id,
    customer_name: state ? state.contact_name : data.customer_name,
    customer_phone_main: state ? state.phone_main : data.customer_phone_main,
    customer_phone_sub: state ? state.phone_sub : data.customer_phone_sub,
    customer_email: state ? state.email : data.customer_email,
    delivery_address: state ? state.delivery_address : data.delivery_address,
    remark: state ? state.remark : data.remark,
    status: formattedStatus(data),
    balance_cod,
  };

  return formattedData;
};

export const formattedStatus = (data) => {
  let status;
  if (data.flag_status) {
    if (data.flag_status.includes("cancelled")) return "cancelled";
  }
  switch (data.main_status) {
    case "draft":
      status = "draft";
      break;
    case "manufacturing":
      switch (data.sub_status) {
        case "รอผลิต":
        case "wait_manufacture":
          status = "wait_manufacture";
          break;
        case "กำลังผลิต":
        case "manufacturing":
          status = "manufacturing";
          break;
        default:
          status = "manufacturing";
      }
      break;
    case "finished":
      status = "finished";
      break;
    default:
      status = "";
  }
  return status;
};

export const formatExportedStatus = (render_status) => {
  switch (render_status) {
    case "draft":
      return "ร่าง";
    case "wait_manufacture":
      return "รอผลิต";
    case "manufacturing":
      return "กำลังผลิต";
    case "finished":
      return "เสร็จสิ้น";
    case "cancelled":
      return "ยกเลิก";
    default:
      return "";
  }
};

export const manufactureOrderExportFormatter = async (data) => {
  const result = [];

  data.forEach((mo) => {
    const filteredDeliveryAddress = mo.customer.address_list.filter(
      (address) => address.address_type === mo.delivery_address
    )[0];
    if (mo.item_list.length > 0) {
      mo.item_list.forEach((item) => {
        const total_all_cost = item.all_cost * item.amount;
        const total_other_cost = item.other_cost * item.amount;
        const formattedMO = {
          unique_id: mo.unique_id,
          external_ref_document_id: mo.external_ref_document_id,
          created_by: `${mo.created_by?.first_name ?? ""}${
            mo.created_by?.last_name ? ` ${mo.created_by?.last_name}` : ""
          }`,
          related_employees: mo.employee_list
            .map((employee) => {
              return `${employee.first_name ?? ""}${
                employee.last_name ? ` ${employee?.last_name}` : ""
              }`;
            })
            .join(", "),
          payment_date: mo.payment_date ? formatDate(mo.payment_date) : "",
          created_date: formatDate(mo.created_date),
          production_date: mo.production_date
            ? formatDate(mo.production_date)
            : "",
          receive_date: mo.receive_date ? formatDate(mo.receive_date) : "",
          vendor_unique_id: mo.vendor_unique_id,
          vendor_name: mo.vendor_name.replaceAll("null", ""),
          vendor_phone: mo.vendor_phone,
          brand_name: mo.brand_name,
          tag_mfg: mo.tag_list.join(", "),
          customer_unique_id: mo.customer_unique_id,
          customer_name: mo.customer_name.replaceAll("null", ""),
          customer_phone_main: mo.customer_phone_main,
          customer_phone_sub: mo.customer_phone_sub,
          customer_email: mo.customer_email,
          render_status: formatExportedStatus(mo.render_status),
          balance_cod: mo.balance_cod,
          delivery_channel: mo.delivery_channel,
          delivery_address: filteredDeliveryAddress?.address_number ?? "",
          sub_district: filteredDeliveryAddress?.sub_district ?? "",
          district: filteredDeliveryAddress?.district ?? "",
          province: filteredDeliveryAddress?.province ?? "",
          postal_code: filteredDeliveryAddress?.postal_code ?? "",
          zone: filteredDeliveryAddress?.zone ?? "",
          country: filteredDeliveryAddress?.country ?? "",
          item_unique_id: item.item_unique_id,
          sku_name: item.sku_name,
          item_name: item.item_name,
          item_kind: item.item_kind,
          source_ready_shirt: item.source_ready_shirt,
          other_cost: item.other_cost,
          printing_cost: item.printing_cost,
          hot_rolled_cost: item.hot_rolled_cost,
          fold_cost: item.fold_cost,
          all_cost: item.all_cost,
          neck: item.neck,
          item_amount: item.amount,
          uom: item.uom,
          item_remark: item.remark,
          total_printing_cost: item.printing_cost * item.amount,
          total_hot_rolled_cost: item.hot_rolled_cost * item.amount,
          total_fold_cost: item.fold_cost * item.amount,
          total_all_cost,
          total_other_cost,
          toal_all_cost_with_other_cost: total_all_cost + total_other_cost,
          remark: mo.remark,
          reject_remark: mo.reject_remark,
        };
        result.push(formattedMO);
      });
    } else {
      result.push({
        unique_id: mo.unique_id,
        external_ref_document_id: mo.external_ref_document_id,
        created_by: `${mo.created_by?.first_name ?? ""}${
          mo.created_by?.last_name ? ` ${mo.created_by?.last_name}` : ""
        }`,
        related_employees: mo.employee_list
          .map((employee) => {
            return `${employee.first_name ?? ""}${
              employee.last_name ? ` ${employee?.last_name}` : ""
            }`;
          })
          .join(", "),
        payment_date: mo.payment_date ? formatDate(mo.payment_date) : "",
        created_date: formatDate(mo.created_date),
        production_date: mo.production_date
          ? formatDate(mo.production_date)
          : "",
        receive_date: mo.receive_date ? formatDate(mo.receive_date) : "",
        vendor_unique_id: mo.vendor_unique_id,
        vendor_name: mo.vendor_name.replaceAll("null", ""),
        vendor_phone: mo.vendor_phone,
        brand_name: mo.brand_name,
        tag_mfg: mo.tag_list.join(", "),
        customer_unique_id: mo.customer_unique_id,
        customer_name: mo.customer_name.replaceAll("null", ""),
        customer_phone_main: mo.customer_phone_main,
        customer_phone_sub: mo.customer_phone_sub,
        customer_email: mo.customer_email,
        render_status: formatExportedStatus(mo.render_status),
        balance_cod: mo.balance_cod,
        delivery_channel: mo.delivery_channel,
        delivery_address: filteredDeliveryAddress?.address_number ?? "",
        sub_district: filteredDeliveryAddress?.sub_district ?? "",
        district: filteredDeliveryAddress?.district ?? "",
        province: filteredDeliveryAddress?.province ?? "",
        postal_code: filteredDeliveryAddress?.postal_code ?? "",
        zone: filteredDeliveryAddress?.zone ?? "",
        country: filteredDeliveryAddress?.country ?? "",
        remark: mo.remark,
        reject_remark: mo.reject_remark,
      });
    }
  });

  return result;
};
