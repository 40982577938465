import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const DeleteItemConfirmation = ({
  isUsed,
  openDeleteItemConfirmation,
  deleteItemConfirmationAction,
  closeDeleteItemConfirmationHandler,
}) => {
  return (
    <ModalUI
      title="ยืนยันการลบสินค้าออกจากระบบ"
      open={openDeleteItemConfirmation}
      handleClose={closeDeleteItemConfirmationHandler}
      maxWidth="sm"
      fullWidth
    >
      <Box sx={{ mt: 1 }}>
        {isUsed && (
          <Typography>
            สินค้านี้ได้ถูกใช้งานในเอกสารแล้ว ต้องการลบสินค้านี้หรือไม่
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeDeleteItemConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={deleteItemConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default DeleteItemConfirmation;
