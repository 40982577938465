import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledStatus = styled(Box)(({ bgcolor, variant }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "3px",
  backgroundColor: bgcolor,
  padding: variant !== "logStatus" && ".25rem 0",
  minWidth: variant !== "logStatus" ? 120 : 95,
}));

const CustomizedStatus = ({ status, variant, sx }) => {
  const { t } = useTranslation();
  const renderStatus = (status) => {
    switch (status) {
      case "draft":
      case "ร่าง":
        return (
          <StyledStatus bgcolor="#E6E6E6" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{ ...sx, color: "#333333" }}
            >
              {t("status.draft")}
            </Typography>
          </StyledStatus>
        );
      case "wait_customer_accept":
      case "รอลูกค้ายืนยัน":
        return (
          <StyledStatus bgcolor="#E5F0FF" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{ ...sx, color: "#1F5BB2" }}
            >
              {t("status.wait_customer_accept")}
            </Typography>
          </StyledStatus>
        );
      case "wait_payment_when_receive":
      case "รอชำระเมื่อรับสินค้า":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{ ...sx, color: "#C3762E" }}
            >
              {t("status.wait_payment_when_receive")}
            </Typography>
          </StyledStatus>
        );
      case "wait_payment_cod":
      case "รอชำระCOD":
      case "รอชำระ COD":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{ ...sx, color: "#C3762E" }}
            >
              {t("status.wait_payment_cod")}
            </Typography>
          </StyledStatus>
        );
      case "finished":
      case "เสร็จสิ้น":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.finished")}
            </Typography>
          </StyledStatus>
        );
      case "ready":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.ready")}
            </Typography>
          </StyledStatus>
        );
      case "fullyPaid":
      case "fully_paid":
      case "ชำระแล้ว":
      case "ชำระแล้วเต็มจำนวน":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.fully_paid")}
            </Typography>
          </StyledStatus>
        );
      case "active":
      case "TRUE":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
                textAlign: "center",
                width: 60,
              }}
            >
              {t("status.active")}
            </Typography>
          </StyledStatus>
        );
      case "inactive":
      case "FALSE":
        return (
          <StyledStatus bgcolor="#FFA396" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#B54839",
                textAlign: "center",
                width: 60,
              }}
            >
              {t("status.inActive")}
            </Typography>
          </StyledStatus>
        );
      case "completed":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.completed")}
            </Typography>
          </StyledStatus>
        );
      case "fullyOrdered":
      case "fully_ordered":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.fullyOrdered")}
            </Typography>
          </StyledStatus>
        );
      case "fullyImported":
      case "fully_imported":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.fullyImported")}
            </Typography>
          </StyledStatus>
        );
      case "partiallyPaid":
      case "partially_paid":
      case "ชำระแล้วบางส่วน":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.partially_paid")}
            </Typography>
          </StyledStatus>
        );
      case "partiallyOrdered":
      case "partially_ordered":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.partiallyOrdered")}
            </Typography>
          </StyledStatus>
        );
      case "partiallyImported":
      case "partially_imported":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.partiallyImported")}
            </Typography>
          </StyledStatus>
        );
      case "ตอบรับแล้ว":
      case "accepted":
        return (
          <StyledStatus bgcolor="#C4DBFD" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#1F5BB2",
              }}
            >
              {t("status.accepted")}
            </Typography>
          </StyledStatus>
        );
      case "รออนุมัติ":
      case "PENDING":
      case "waitApprove":
      case "wait_approve":
        return (
          <StyledStatus bgcolor="#FCE69F" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.waitApprove")}
            </Typography>
          </StyledStatus>
        );
      case "APPROVED":
      case "approved":
      case "อนุมัติแล้ว":
        return (
          <StyledStatus bgcolor="#E5F0FF" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#1F5BB2",
              }}
            >
              {t("status.approved")}
            </Typography>
          </StyledStatus>
        );
      case "notApproved":
      case "DECLINED":
      case "ไม่อนุมัติ":
      case "not_approved":
        return (
          <StyledStatus bgcolor="#FFA396" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#B54839",
              }}
            >
              {t("status.notApproved")}
            </Typography>
          </StyledStatus>
        );
      case "รอตอบรับ":
      case "waitAccept":
      case "wait_accept":
        return (
          <StyledStatus bgcolor="#FCE69F" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.waitAccept")}
            </Typography>
          </StyledStatus>
        );
      case "late":
      case "expired":
        return (
          <StyledStatus bgcolor="#FFF2EB" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#CC6200",
              }}
            >
              {t("status.expired")}
            </Typography>
          </StyledStatus>
        );
      case "cancelled":
      case "ยกเลิก":
        return (
          <StyledStatus bgcolor="#D3D3D3" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                color: "#FFFFFF",
              }}
            >
              {t("status.cancelled")}
            </Typography>
          </StyledStatus>
        );
      case "waitPayment":
      case "wait_payment":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.waitPayment")}
            </Typography>
          </StyledStatus>
        );
      case "wait_manufacture":
      case "รอผลิต":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{ ...sx, color: "#C3762E" }}
            >
              {t("status.wait_manufacture")}
            </Typography>
          </StyledStatus>
        );
      case "manufacturing":
      case "กำลังผลิต":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{ ...sx, color: "#C3762E" }}
            >
              {t("status.manufacturing")}
            </Typography>
          </StyledStatus>
        );
      case "waitDeliver":
      case "wait_deliver":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.waitDeliver")}
            </Typography>
          </StyledStatus>
        );
      // wait for data dict to be update more status
      default:
    }
  };

  return renderStatus(status);
};

export default CustomizedStatus;
