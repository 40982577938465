import { useCallback, useRef, useState, useEffect } from "react";
import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import NewReportDateFilter from "../../../components/UI/newReportDateFilter";
import { useTranslation } from "react-i18next";
import { getAllSalesOrdersExport } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { salesOrderColumnDef } from "../../../components/Table/ColumnDefs/Sales/Order";
import AgGrid from "../../../components/Table/AgGrid";
import { exportAsExcel } from "../../../utils/exporter";
import { useAuth } from "../../../hooks/use-auth";

const OrderReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isInit, setIsInit] = useState(false);
  const { allSalesOrdersExport, isLoading } = useSelector(
    (state) => state.salesOrder
  );
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef();
  const { permissions } = useAuth();
  const salesPermission = permissions.sales;

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(
        dayjs().endOf("day").subtract(7, "day").format("DD/MM/YYYY")
      ),
      dateTo: new Date(dayjs().endOf("day")),
    },
  });

  const breadcrumbs = [
    {
      name: t("sales.report.index"),
      to: "/sales/report",
    },
    {
      name: t("sales.report.order"),
    },
  ];

  useEffect(() => {
    if (!isInit) {
      getFilteredOrder();
      setIsInit(true);
    }
  }, [isInit]);

  const getFilteredOrder = useCallback(() => {
    let startDate = getValues("date");
    let endDate = getValues("dateTo");

    dispatch(
      getAllSalesOrdersExport(
        {
          startRow: 0,
          endRow: Math.pow(10, 9),
          filterModel: {
            created_date: {
              filter: startDate ? startDate.toISOString() : undefined,
              filterTo: endDate ? endDate.toISOString() : undefined,
              filterType: "date",
              type: "inRange",
            },
          },
        },
        enqueueSnackbar
      )
    );
  }, [dispatch, enqueueSnackbar, getValues]);

  const onBtnExport = useCallback(async () => {
    gridRef.current.api.selectAllFiltered();
    const filteredData = gridRef.current.api.getSelectedRows();
    gridRef.current.api.deselectAllFiltered();
    exportAsExcel(filteredData, t("sales.order.index"), null, t, "sales_order");
    enqueueSnackbar("นำออกใบสั่งขายสำเร็จ", {
      variant: "success",
    });
  }, [enqueueSnackbar, t, allSalesOrdersExport]);

  return (
    <>
      {salesPermission === "NONE" && <Navigate to="/no-permission" />}
      {isLoading.allSalesOrdersExport && <LinearProgress />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        sx={{
          mt: 3,
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{t("sales.report.order")}</Typography>
        <CustomizedButton
          title="นำออกใบสั่งขายด้วยไฟล์ Excel"
          variant="outlined"
          onClick={onBtnExport}
          testId="order-export-btn"
        />
      </Box>
      <CustomizedBox margin="2rem 0">
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFilteredOrder}
                disabled={isLoading.allSalesOrdersExport}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.allSalesOrders}
                fullWidth
              />
            </Grid> */}
          </Grid>
        </Box>
      </CustomizedBox>
      <AgGrid
        ref={gridRef}
        columnDefs={salesOrderColumnDef(t, true)}
        height={649}
        rowData={allSalesOrdersExport}
      />
    </>
  );
};

export default OrderReport;
