import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  th: {
    translation: {
      button: {
        trigger: "trigger",
        back: "ย้อนกลับ",
        add: "เพิ่ม",
        delete: "ลบ",
        create: "สร้าง",
        export: "นำออกข้อมูล",
        import: "นำเข้าข้อมูล",
        download: "ดาวน์โหลด Template",
        submitFilter: "กรองข้อมูล",
        resetFilter: "ล้างตัวกรอง",
        save: "บันทึก",
        cancel: "ยกเลิก",
      },
      date: {
        range: "ช่วงเวลา",
        from: "ตั้งแต่",
        to: "จนถึง",
      },
      report: "รายงาน",
      list: "รายการ",
      print: "พิมพ์เอกสาร",
      description: "รายละเอียด",
      document_id: "เลขที่เอกสาร",
      reference_document_id: "อ้างอิงถึง",
      documentInfo: "ข้อมูลเอกสาร",
      status: {
        index: "สถานะ",
        active: "ใช้งาน",
        inActive: "ไม่ใช้งาน",
        all: "ทั้งหมด",
        draft: "ร่าง",
        wait_customer_accept: "รอลูกค้ายืนยัน",
        wait_manufacture: "รอผลิต",
        wait_payment_when_receive: "รอชำระเมื่อรับสินค้า",
        wait_payment_cod: "รอชำระCOD",
        partially_paid: "ชำระแล้วบางส่วน",
        fully_paid: "ชำระแล้วเต็มจำนวน",
        manufacturing: "กำลังผลิต",
        finished: "เสร็จสิ้น",
        cancelled: "ยกเลิก",
      },
      activityLog: {
        activityId: "เลขที่การเคลื่อนไหว",
        employeeIid: "เปลี่ยนแปลงเอกสารโดย",
        documentId: "เลขที่เอกสาร",
        payload: "ส่วนประกอบข้อมูล",
        createdDate: "วันที่สร้าง",
        documentType: "ประเภทเอกสาร",
        activityType: {
          index: "ประเภทการเคลื่อนไหว",
          comment: "คอมเมนต์",
          status_change: "เปลี่ยนสถานะ",
          delete: "ลบ",
          edit: "แก้ไข",
          copy: "คัดลอก",
          cancel: "ยกเลิก",
          create: "สร้าง",
          related_document: "เอกสารที่เกี่ยวข้อง",
          relatedEmployee: "ผู้เกี่ยวข้อง",
          delete_related_employee: "ลบผู้เกี่ยวข้อง",
          add_related_employee: "เพิ่มผู้เกี่ยวข้อง",
          fact_change: "เปลี่ยนแปลงเอกสาร",
        },
      },
      inventory: require("./inventory.json"),
      contact: require("./contact.json"),
      sales: require("./sales.json"),
      purchase: require("./purchase.json"),
      accounting: require("./account.json"),
      logistic: require("./logistic.json"),
      manufacture: require("./manufacture.json"),
      user: require("./user.json"),
      setting: require("./setting.json"),
      reports: require("./report.json"),
      tag: require("./tag.json"),
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "th",
  fallbackLng: "th",
  interpolation: {
    escapeValue: false,
  },
});
