import { Box, Typography } from "@mui/material";
// import { formatDate } from "../../utils/date-converter";
import { CustomizedBox } from "../Custom/CustomizedBox";

const CustomizedTotalBox = ({
  title,
  total,
  maxWidth,
  minWidth,
  unit,
}) => {
  return (
    <CustomizedBox
      radius="10px"
      sx={{
        maxWidth: maxWidth || "165px",
        maxHeight: "91px",
        minWidth: minWidth ?? undefined,
      }}
      border="1px solid #8B94CA"
      boxShadow={false}
      padding="1rem 1.5rem"
    >
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "16px",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: unit ? "flex-end" : "flex-start",
          mt: "1rem",
        }}
      >
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "30px",
            lineHeight: "35px",
            color: "#32418F",
          }}
        >
          {total}
        </Typography>
        {unit && (
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "35px",
              color: "#333333",
              height: "100%",
              mx: 2,
            }}
          >
            {unit}
          </Typography>
        )}
      </Box>
      {/* <Box sx={{ position: "relative" }}>
        <Typography
          sx={{
            position: "absolute",
            right: 1,
            bottom: -15,
            color: "#9F9F9F",
          }}
          variant="caption"
        >
          {`อัพเดทล่าสุดเมื่อ ${date ? date : formatDate(new Date())}`}
          {unit && `${unit}`}
        </Typography>
      </Box> */}
    </CustomizedBox>
  );
};

export default CustomizedTotalBox;
