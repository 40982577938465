import { useState, useEffect } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@mui/material";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedFreeSolo from "../../../Custom/CustomizedFreeSolo";
import NewAddress from "./NewAddress";
import ControlledAddressBox from "../../../Controlled/ControlledAddressBox";
import LabelInput from "../../../UI/LabelInput";

const defaultNewAddressValues = {
  address_type: "ที่อยู่จัดส่ง",
  is_default_address: false,
  same_as_default_address: false,
  name: "",
  phone: "",
  address_number: "",
  building: "",
  sub_district: "",
  district: "",
  province: "",
  postal_code: "",
  zone: "",
  country: "",
};

const AddressTab = ({
  control,
  errors,
  setValue,
  getValues,
  viewOnly,
  addressList,
}) => {
  const { t } = useTranslation();

  const [addressValue, setAddressValue] = useState("");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "address_list",
  });

  const watchFieldArray = useWatch({ control, name: "address_list" });
  const isSameAddress = watchFieldArray?.map((field) => {
    return field.same_as_default_address;
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    if (addressList?.length > 0) {
      setAddressValue(addressList[0]);
    }
  }, [addressList]);

  const countryOptions = ["ไทย"];

  const renderNewAddress = controlledFields
    .slice(1)
    .map((item, index) => (
      <NewAddress
        key={item.id}
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        remove={remove}
        viewOnly={viewOnly}
        itemId={item.id}
        index={index + 1}
        isSameAddress={isSameAddress}
      />
    ));

  const addNewAddressHandler = () => {
    if (controlledFields.length === 0) {
      append({
        ...defaultNewAddressValues,
        address_type: "ที่อยู่หลัก",
        is_default_address: true,
        same_as_default_address: null,
      });
    } else {
      append({ ...defaultNewAddressValues });
    }
  };

  const updateAdditionalAddressHandler = (name, newValue) => {
    const additionalAdresses = getValues("address_list").slice(1);
    additionalAdresses.forEach((item, index) => {
      if (item.same_as_default_address && item[name] !== newValue) {
        const updatedItem = { ...item, [name]: newValue };
        setValue(`address_list.${index + 1}`, updatedItem);
      }
    });
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <CustomizedButton
          title={"เพิ่มที่อยู่"}
          variant="outlined"
          size="medium"
          onClick={addNewAddressHandler}
          disabled={viewOnly || fields.length >= 3}
        />
      </Box>
      <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
        ที่อยู่หลัก
      </Typography>
      {controlledFields.slice(0, 1).map((item) => (
        <Grid container spacing={2} key={item.id}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {viewOnly ? (
              <LabelInput
                label={t(`contact.address.primary`)}
                value={getValues(`address_list.0.address_number`)}
              />
            ) : (
              <ControlledTextField
                name={"address_list.0.address_number"}
                label={t("contact.address.primary")}
                control={control}
                error={errors.address_list?.["0"]?.address_number}
                disabled={viewOnly}
                onBlur={(e) => {
                  updateAdditionalAddressHandler(
                    "address_number",
                    e.target.value
                  );
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {viewOnly ? (
              <LabelInput
                label={t(`contact.address.secondary`)}
                value={getValues(`address_list.0.building`)}
              />
            ) : (
              <ControlledTextField
                name={"address_list.0.building"}
                label={t("contact.address.secondary")}
                control={control}
                error={errors.address_list?.["0"]?.building}
                disabled={viewOnly}
                onBlur={(e) => {
                  updateAdditionalAddressHandler("building", e.target.value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.0`}
              type="sub_district"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly}
              error={Boolean(errors.address_list?.[0].sub_district)}
              helperText={
                errors.address && errors.address_list?.[0].sub_district?.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.0`}
              type="district"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly}
              error={Boolean(errors.address_list?.[0].district)}
              helperText={
                errors.address && errors.address_list?.[0].district?.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.0`}
              type="province"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly}
              error={Boolean(errors.address_list?.[0].district)}
              helperText={
                errors.address && errors.address_list?.[0].district?.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.0`}
              type="postal_code"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly}
              error={Boolean(errors.address_list?.[0].postal_code)}
              helperText={
                errors.address && errors.address_list?.[0].postal_code?.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.0`}
              type="zone"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly}
              error={Boolean(errors.address_list?.[0].zone)}
              helperText={
                errors.address && errors.address_list?.[0].zone?.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {viewOnly ? (
              <LabelInput
                label={t(`contact.address.country`)}
                value={getValues(`address_list.0.country`)}
              />
            ) : (
              <Controller
                key={`${item.id}-address_main-country`}
                name="address_list.0.country"
                control={control}
                render={({ field }) => (
                  <CustomizedFreeSolo
                    {...field}
                    options={countryOptions}
                    title={t("contact.address.country")}
                    onInputChange={(e, inputValue) => {
                      return field.onChange(inputValue);
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        updateAdditionalAddressHandler("country", "");
                        return field.onChange("");
                      }
                      if (option.value) {
                        updateAdditionalAddressHandler("country", option.value);
                        return field.onChange(option.value);
                      }
                      if (option.inputValue) {
                        updateAdditionalAddressHandler(
                          "country",
                          option.inputValue
                        );
                        return field.onChange(option.inputValue);
                      }
                      updateAdditionalAddressHandler("country", option);
                      return field.onChange(option);
                    }}
                    disabled={viewOnly}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      ))}
      {renderNewAddress}
    </>
  );
};

export default AddressTab;
