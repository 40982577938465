import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { formatSalesOrderQuery } from "../../../utils/salesPayloadFormatter";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentTab from "./DocumentTab";
import ManufactureTab from "./ManufactureTab";
import PaymentTab from "./PaymentTab";
import { orderValidation } from "../../../components/Form/Order/schema";
import { useAuth } from "../../../hooks/use-auth";
import {
  createSalesOrder,
  updateSalesOrder,
  cancelSalesOrder,
  getSalesOrder,
} from "../../../features/Sales/SalesOrder/sales-order-actions";
import { initialState } from "../../../features/Sales/SalesOrder/sales-order-initial";
import { salesOrderActions } from "../../../features/Sales/SalesOrder/sales-order-slice";
import InventoryService from "../../../services/Inventory";
import StatusChangeConfirmation from "../../../components/UI/Confirmation/StatusChangeConfirmation";

const SalesOrderContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const tab = searchParams.get("tab");
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const { user, permissions } = useAuth();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { salesOrder, isLoading } = useSelector((state) => state.salesOrder);
  const [currentTab, setCurrentTab] = useState(pathname);
  const [isInit, setIsInit] = useState(false);
  const [existingItemIds, setExistingItemIds] = useState(null);
  const [statusChangeConfirmation, setStatusChangeConfirmation] =
    useState(false);

  const salesPermission = permissions.sales;

  const documentTabUseForm = useForm({
    defaultValues: id
      ? salesOrder
      : {
          ...initialState.salesOrder,
          created_date: new Date(),
          payment_date: null,
          due_date: new Date(),
          delivery_date: null,
          receive_date: null,
          creator_unique_id: user?.unique_id,
          created_by: {
            unique_id: user?.unique_id,
            first_name: user?.first_name,
            last_name: user?.last_name,
            img_url: user?.img_url,
          },
        },
    resolver: yupResolver(orderValidation),
  });

  const { control, reset, getValues } = documentTabUseForm;

  const watchStatus = useWatch({ control, name: "status" });

  useEffect(() => {
    if (!isInit) {
      const loadedSalesOrder = async () => {
        if (state) {
          dispatch(
            salesOrderActions.loadedSalesOrder({
              ...state,
              order_payment_list: [],
            })
          );
          if (salesOrder.unique_id) {
            const formattedSalesOrder = formatSalesOrderQuery({
              ...salesOrder,
            });
            reset({
              ...formattedSalesOrder,
              unique_id: "",
              render_status: null,
              main_status: null,
              sub_status: null,
              flag_status: null,
              status: null,
            });
          }
        } else if (id) {
          const loadSalesOrderPayload = {
            unique_id: decodeURIComponent(id),
          };
          const loadedSalesOrder = await dispatch(
            getSalesOrder(loadSalesOrderPayload, enqueueSnackbar, navigate)
          );
          if (salesOrder.unique_id) {
            const formattedSalesOrder = formatSalesOrderQuery(loadedSalesOrder);
            reset({
              ...formattedSalesOrder,
            });
          }
        } else {
          dispatch(getSalesOrder("init", enqueueSnackbar));
        }
        setIsInit(true);
      };
      loadedSalesOrder();
    }
  }, [dispatch, id, enqueueSnackbar, salesOrder, isInit]);

  useEffect(() => {
    if (!existingItemIds) {
      const getItemIds = async () => {
        const itemIds = await InventoryService.getItemIds();
        setExistingItemIds(itemIds);
      };
      getItemIds();
    }
  }, [existingItemIds]);

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.order.index"),
      to: "/sales/order",
    },
    {
      name: id
        ? decodeURIComponent(id) ?? id
        : `สร้าง${t("sales.order.index")}`,
    },
  ];

  const tabs = [
    {
      label: t("sales.documentInfo"),
      path: `${pathname}`,
    },
    {
      label: t("sales.payment"),
      path: `${pathname}?tab=payment`,
      disabled: !salesOrder.main_status,
    },
    {
      label: t("sales.manufacture"),
      path: `${pathname}?tab=manufacture`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "payment":
        if (!salesOrder.main_status) {
          navigate(pathname);
          setCurrentTab(pathname);
          break;
        }
        setCurrentTab(pathname + "?tab=payment");
        break;
      case "manufacture":
        setCurrentTab(pathname + "?tab=manufacture");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab) => {
    switch (tab) {
      case "payment":
        return (
          <PaymentTab
            documentTabUseForm={documentTabUseForm}
            uniqueId={decodeURIComponent(id)}
            setMainIsInit={setIsInit}
          />
        );
      case "manufacture":
        return <ManufactureTab control={control} />;
      default:
        return (
          <DocumentTab
            documentTabUseForm={documentTabUseForm}
            saveDraftHandler={saveDraftHandler}
            sendToCustomer={sendToCustomer}
            saveDocumentHandler={saveDocumentHandler}
            existingItemIds={existingItemIds}
            setIsInit={setIsInit}
            currentState={state?.id ?? salesOrder}
          />
        );
    }
  };

  const itemListIsValid = async (data) => {
    let itemListIsValid = true;

    if (data.item_list.length === 0) {
      itemListIsValid = false;
      enqueueSnackbar("กรุณาเลือกสินค้าอย่างน้อย 1 รายการ", {
        variant: "error",
      });
    }

    const itemIds = await InventoryService.getItemIds();
    setExistingItemIds(itemIds);

    data.item_list.forEach((item) => {
      if (!itemIds.includes(item.item_unique_id)) {
        if (itemListIsValid) {
          itemListIsValid = false;
          enqueueSnackbar("กรุณาลบสินค้า", { variant: "error" });
        }
      }
    });
    return itemListIsValid;
  };

  const saveDraftHandler = async (data) => {
    const itemListValid = await itemListIsValid(data);
    if (!itemListValid) return;
    if (isLoading.salesOrder) return;
    if (!id) {
      dispatch(
        createSalesOrder(
          data,
          "draft",
          navigate,
          enqueueSnackbar,
          documentTabUseForm
        )
      );
    } else {
      dispatch(
        updateSalesOrder(
          data,
          "draft",
          enqueueSnackbar,
          setIsInit,
          documentTabUseForm
        )
      );
    }
  };

  const sendToCustomer = async (data) => {
    const itemListValid = await itemListIsValid(data);
    if (!itemListValid) return;
    if (!id) {
      dispatch(
        createSalesOrder(
          data,
          "sendToCustomer",
          navigate,
          enqueueSnackbar,
          documentTabUseForm
        )
      );
    } else {
      dispatch(
        updateSalesOrder(
          data,
          "sendToCustomer",
          enqueueSnackbar,
          setIsInit,
          documentTabUseForm
        )
      );
    }
  };

  const saveDocumentHandler = async (data) => {
    const itemListValid = await itemListIsValid(data);
    if (!itemListValid) return;
    const pendingCancel = getValues("pending_cancel");
    if (pendingCancel) {
      setStatusChangeConfirmation(true);
    } else {
      dispatch(
        updateSalesOrder(
          data,
          "submit",
          enqueueSnackbar,
          setIsInit,
          documentTabUseForm
        )
      );
    }
  };

  const statusOptionSelectHandler = () => {
    const data = getValues();
    dispatch(cancelSalesOrder(data, enqueueSnackbar, setIsInit));
    setStatusChangeConfirmation(false);
  };

  const closeStatusChangeConfirmationHandler = () => {
    setStatusChangeConfirmation(false);
  };

  return (
    <>
      {salesPermission === "NONE" && <Navigate to="/no-permission" />}
      {!isInit && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {isInit && (
        <>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
          <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
          {renderTab(tab)}
        </>
      )}
      <StatusChangeConfirmation
        currentStatus={watchStatus}
        targetStatus={"ยกเลิก"}
        openStatusChangeConfirmation={statusChangeConfirmation}
        statusChangeConfirmationAction={statusOptionSelectHandler}
        closeStatusChangeConfirmationHandler={
          closeStatusChangeConfirmationHandler
        }
        translate={t}
      />
    </>
  );
};

export default SalesOrderContainer;
