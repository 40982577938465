import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import CustomizedMenuOption from "../../../../components/Custom/CustomizedMenuOption";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { CustomizedTooltip } from "../../../../components/Custom/CustomizedTooltip";
import HeaderForm from "../../../../components/Form/Order/HeaderForm";
import RightDrawer from "../../../../components/UI/RightDrawer";
import { useWatch } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import CustomizedStatus from "../../../../components/Custom/CustomizedStatus";
import CopyConfirmation from "../../../../components/UI/Confirmation/CopyConfirmation";
import CancelConfirmation from "../../../../components/UI/Confirmation/CancelConfirmation";
import { updateSalesOrderStatus } from "../../../../features/Sales/SalesOrder/sales-order-actions";
import { useAuth } from "../../../../hooks/use-auth";
import StatusChangeConfirmation from "../../../../components/UI/Confirmation/StatusChangeConfirmation";

const statusDisableOptions = ["draft", "cancelled"];

const Header = ({
  control,
  errors,
  disabled,
  getValues,
  setValue,
  editClickHandler,
  openSummaryModalHandler,
  scrollToCancelDetailForm,
  setIsInit,
  documentType,
  currentState,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const modifyOptions = ["คัดลอก", "แก้ไข"];
  const statusOptions = [
    t("status.wait_payment_when_receive"),
    t("status.wait_payment_cod"),
    t("status.partially_paid"),
    t("status.fully_paid"),
    t("status.finished"),
    t("status.cancelled"),
  ];
  const [selectModifyOptions, setSelectModifyOptions] = useState(modifyOptions);
  const [selectStatusOptions, setSelectStatusOptions] = useState(statusOptions);
  const [disabledOption, setDisabledOption] = useState(false);
  const [disableSummary, setDisableSummary] = useState(false);
  const [statusChangeConfirmation, setStatusChangeConfirmation] =
    useState(false);
  const [tempTargetStatus, setTempTargetStatus] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [copyConfirmation, setCopyConfirmation] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [disableCreateManufactureOrder, setDisableCreateManufactureOrder] =
    useState(false);
  const { permissions } = useAuth();
  const salesPermission = permissions.sales;

  const watchStatus = useWatch({
    control,
    name: "status",
  });

  useEffect(() => {
    switch (watchStatus) {
      case "draft":
      case "wait_customer_accept":
        setSelectModifyOptions(modifyOptions);
        setSelectStatusOptions(statusOptions);
        setDisableCreateManufactureOrder(true);
        setDisabledOption(true);
        setDisableSummary(false);
        break;
      case "wait_payment_when_receive":
      case "wait_payment_cod":
      case "partially_paid":
      case "fully_paid":
        setSelectModifyOptions(modifyOptions);
        setSelectStatusOptions(statusOptions);
        setDisableCreateManufactureOrder(false);
        setDisabledOption(false);
        setDisableSummary(false);
        setDisableSummary(false);

        break;
      case "finished":
        setSelectModifyOptions([modifyOptions[0]]);
        setSelectStatusOptions([statusOptions[5]]);
        setDisableCreateManufactureOrder(true);
        setDisabledOption(false);
        setDisableSummary(false);
        break;
      case "cancelled":
        setSelectModifyOptions([modifyOptions[0]]);
        setDisabledOption(true);
        setDisableCreateManufactureOrder(true);
        setDisableSummary(true);
        break;
      default:
        setSelectModifyOptions(modifyOptions);
        setDisableSummary(false);
    }
  }, [watchStatus]);

  const openStatusChangeConfirmationHandler = (targetStatus) => {
    setStatusChangeConfirmation(true);
    setTempTargetStatus(targetStatus);
  };

  const closeStatusChangeConfirmationHandler = () => {
    setStatusChangeConfirmation(false);
    setTempTargetStatus(null);
  };

  const statusOptionSelectHandler = () => {
    const { unique_id, status } = getValues();
    dispatch(
      updateSalesOrderStatus(
        unique_id,
        status,
        tempTargetStatus,
        enqueueSnackbar,
        setIsInit
      )
    );
    setStatusChangeConfirmation(false);
    setTempTargetStatus(null);
  };

  const closeConfirmationHandler = () => {
    setCopyConfirmation(false);
  };

  const copyConfirmationAction = () => {
    switch (documentType) {
      case "order":
        {
          try {
            navigate("/sales/order/add", {
              state: {
                ...currentState,
                isCopied: true,
              },
            });
            enqueueSnackbar("คัดลอกสำเร็จ", {
              variant: "success",
            });
          } catch (err) {
            console.log(err);
            enqueueSnackbar("คัดลอกไม่สำเร็จ", {
              variant: "error",
            });
          }
        }
        break;
      default:
    }
  };

  const closeCancelConfirmationHandler = () => {
    setCancelConfirmation(false);
  };

  const cancelConfirmationAction = () => {
    setTempTargetStatus("ยกเลิก");
    statusOptionSelectHandler(t("status.cancelled"));
  };

  const onCancelStatusHandler = () => {
    setValue("pending_cancel", true);
    scrollToCancelDetailForm();
  };

  const createManufactureOrderHandler = () => {
    navigate("/manufacture/order/add", {
      state: {
        ...currentState,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            {t("sales.order.index")}
          </Typography>
          <CustomizedStatus status={watchStatus} />
        </Box>

        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <CustomizedButton
            title="สรุปใบสั่งขาย"
            variant="outlined"
            size="medium"
            disabled={!id || disableSummary}
            onClick={openSummaryModalHandler}
          />
          <CustomizedMenuOption
            fullWidth
            size="medium"
            label={"ตัวเลือก"}
            options={selectModifyOptions}
            onSelect={(e) => {
              switch (e.target.innerText) {
                case "ยกเลิก":
                  setCancelConfirmation(true);
                  break;
                case "คัดลอก":
                  setCopyConfirmation(true);
                  break;
                case "แก้ไข":
                  editClickHandler();
                  break;
                default:
                  break;
              }
            }}
            disabled={
              !id ||
              salesPermission === "VIEW__ONLY" ||
              selectModifyOptions.length === 0
            }
          />
          <CustomizedMenuOption
            fullWidth
            size="medium"
            label={"สร้าง"}
            options={[t("sales.manufacture")]}
            onSelect={(e) => {
              switch (e.target.innerText) {
                case t("sales.manufacture"):
                  createManufactureOrderHandler();
                  break;
                default:
                  break;
              }
            }}
            disabled={
              !id ||
              disabledOption ||
              salesPermission === "VIEW__ONLY" ||
              disableCreateManufactureOrder
            }
          />
          <CustomizedMenuOption
            fullWidth
            size="medium"
            label={"สถานะ"}
            variant="contained"
            options={selectStatusOptions}
            onSelect={(e) => {
              if (e.target.innerText === "ยกเลิก") {
                onCancelStatusHandler();
              } else {
                openStatusChangeConfirmationHandler(e.target.innerText);
              }
            }}
            disabled={
              !id ||
              statusDisableOptions.includes(watchStatus) ||
              salesPermission === "VIEW__ONLY"
            }
          />
          <CustomizedTooltip title="ดูการเคลื่อนไหว" enterNextDelay={200} arrow>
            <IconButton
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => setOpenDrawer(true)}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
          <RightDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            title={t("inventory.activity")}
            uniqueId={id}
            modelType="order"
            viewOnly={salesPermission === "VIEW__ONLY"}
          />
        </Box>
      </Box>
      <HeaderForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
      />
      <CopyConfirmation
        openCopyConfirmation={copyConfirmation}
        copyConfirmationAction={copyConfirmationAction}
        closeConfirmationHandler={closeConfirmationHandler}
      />
      <CancelConfirmation
        openCancelConfirmation={cancelConfirmation}
        cancelConfirmationAction={cancelConfirmationAction}
        closeCancelConfirmationHandler={closeCancelConfirmationHandler}
      />
      <StatusChangeConfirmation
        currentStatus={watchStatus}
        targetStatus={tempTargetStatus}
        openStatusChangeConfirmation={statusChangeConfirmation}
        statusChangeConfirmationAction={statusOptionSelectHandler}
        closeStatusChangeConfirmationHandler={
          closeStatusChangeConfirmationHandler
        }
        translate={t}
      />
    </>
  );
};

export default Header;
