import { gql } from "graphql-request";

export const GET_UNIQUE_ID = gql`
  query UniqueId($modelType: ModelType!) {
    utilGetUniqueId(modelType: $modelType)
  }
`;

export const GET_CREATABLES = gql`
  query CreatablesFindByField($findManyInput: CreatableFindByFieldInput!) {
    creatablesFindByField(findManyInput: $findManyInput) {
      name
      usage_field_type
      usage_field_name
    }
  }
`;

export const GET_WORKFLOW_PROGRESS = gql`
  query WorkflowProgressByDocument(
    $referenceDocumentInput: ReferenceDocumentInput!
  ) {
    workflowProgressByDocument(
      referenceDocumentInput: $referenceDocumentInput
    ) {
      id
      workflow_step_number
      workflow_document_type
      reference_unique_id
      reference_document_type
      status
      user_unique_iq
      changed_date
      payload
      user {
        unique_id
      }
      workflow {
        step_number
        document_type
        status_type
        initial_status
        condition
        target_status
        remark
      }
    }
  }
`;
