import { useState, useEffect, forwardRef } from "react";
import { useSnackbar } from "notistack";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ControlledDatePicker from "../../Controlled/ControlledDatePicker";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../Custom/CustomizedBreadcrumbs";
import CustomizedMenuOption from "../../Custom/CustomizedMenuOption";
import ContactFormGeneral from "./general";
import ModalUI from "../../UI/ModalUI";
import RightDrawer from "../../UI/RightDrawer";
import HistoryIcon from "@mui/icons-material/History";
import ContactService from "../../../services/Contact";
import { useAuth } from "../../../hooks/use-auth";

const ContactForm = forwardRef(
  (
    {
      id,
      control,
      onSubmit,
      onSubmitEdit,
      onCancel,
      setValue,
      getValues,
      watch,
      errors,
      isContactPage,
      contactTypeError,
      contactTags,
      isTest,
      isEditPage,
      mode,
      setMode,
      setCustomer,
      closeContactModal,
      addressList,
    },
    ref
  ) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { permissions } = useAuth();
    const [isViewOnly, setIsViewOnly] = useState(true);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const watchContactId = watch("unique_id");

    const contactPermission = permissions.contact;

    useEffect(() => {
      if (mode === "แก้ไข") {
        setIsViewOnly(false);
      } else if (mode === "ลบ") {
        setDeleteModalIsOpen(true);
      } else if (mode === "ดูข้อมูล") {
        setIsViewOnly(true);
      }
    }, [mode]);

    useEffect(() => {
      if (pathname === "/contact/add" || (!isEditPage && !id)) {
        setIsViewOnly(false);
        setMode("สร้าง");
      }
    }, [pathname]);

    useEffect(() => {
      if (getValues("created_date") === "") {
        setValue("created_date", new Date());
      }
    });

    const breadcrumbs = [
      {
        name: t("contact.index"),
        to: "/contact/contacts",
      },
      {
        name: isEditPage
          ? watchContactId || "แก้ไขผู้ติดต่อ"
          : "เพิ่มผู้ติดต่อ",
      },
    ];

    const cancelDeleteHandler = () => {
      setMode("แก้ไข");
      setDeleteModalIsOpen(false);
    };

    const deleteContactHandler = async () => {
      try {
        await ContactService.deleteContact({ unique_id: id });
        enqueueSnackbar("ลบข้อมูลผู้ติดต่อสำเร็จ", {
          variant: "success",
        });
        if (isContactPage) {
          navigate("/contact", { replace: true });
        } else {
          setCustomer();
          closeContactModal();
        }
      } catch (err) {
        const formatError = JSON.stringify(err);
        if (formatError.includes("Foreign key constraint failed")) {
          enqueueSnackbar("ไม่สามารถลบได้ เนื่องจากผู้ติดต่อถูกใช้งานอยู่", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("เกิดความผิดพลาดในการลบข้อมูลผู้ติดต่อ", {
            variant: "error",
          });
        }
      }
      setDeleteModalIsOpen(false);
    };

    const renderDatePicker = (
      <ControlledDatePicker
        name="created_date"
        control={control}
        error={errors.created_date?.error}
        isOpen={datePickerIsOpen}
        onClose={() => setDatePickerIsOpen(false)}
        onOpen={() => setDatePickerIsOpen(true)}
        label="วันที่สร้าง"
        disabled
      />
    );

    return (
      <>
        {isContactPage && (
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mt: isContactPage ? 0 : 1 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            </Grid>
          </Grid>
        )}
        <form>
          <Box sx={{ width: "100%" }}>
            {isContactPage && (
              <Grid container alignItems={"flex-end"} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                  <Typography fontWeight="bold">
                    {isEditPage
                      ? watchContactId || "แก้ไขผู้ติดต่อ"
                      : "เพิ่มผู้ติดต่อ"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <Grid item xs={5} sm={5} md={6} lg={6} xl={5}>
                      {renderDatePicker}
                    </Grid>
                    {isContactPage && contactPermission !== "VIEW__ONLY" && (
                      <Grid item xs={5} sm={5} md={4} lg={4} xl={3}>
                        <CustomizedMenuOption
                          size="medium"
                          label={"ตัวเลือก"}
                          options={["แก้ไข", "ลบ"]}
                          onSelect={(e) => {
                            setMode(e.target.innerText);
                          }}
                          disabled={mode === "สร้าง"}
                          btnTestId="contact-option-btn"
                          menuTestId="contact-option-menu"
                        />
                      </Grid>
                    )}
                    {isEditPage && (
                      <Grid item xs={2}>
                        <IconButton onClick={() => setOpenDrawer(true)}>
                          <HistoryIcon
                            fontSize="small"
                            color=" rgba(0, 0, 0, 0.54)"
                          />
                        </IconButton>
                        <RightDrawer
                          open={openDrawer}
                          onClose={() => setOpenDrawer(false)}
                          title={t("inventory.activity")}
                          uniqueId={id}
                          modelType="contact"
                          viewOnly={contactPermission === "VIEW__ONLY"}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!isContactPage && (
              <>
                <Grid container mt={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" ml={2}>
                      {watchContactId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid
                      container
                      alignItems={"flex-end"}
                      justifyContent={"flex-end"}
                      spacing={2}
                    >
                      <Grid item xs={5} sm={6} md={6} lg={6} xl={2}>
                        {renderDatePicker}
                      </Grid>
                      {contactPermission !== "VIEW__ONLY" && (
                        <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                          <CustomizedMenuOption
                            size="medium"
                            label={"ตัวเลือก"}
                            options={["แก้ไข", "ลบ"]}
                            onSelect={(e) => {
                              setMode(e.target.innerText);
                            }}
                            disabled={mode === "สร้าง"}
                            btnTestId="contact-option-btn"
                            menuTestId="contact-option-menu"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          <ContactFormGeneral
            ref={ref}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
            isContactPage={isContactPage}
            viewOnly={isViewOnly}
            isEditPage={isEditPage}
            contactTypeError={contactTypeError}
            contactTags={contactTags}
            id={id}
            isTest={isTest}
            addressList={addressList}
          />
          {!isViewOnly && (
            <Box sx={{ display: "flex", mt: 2 }}>
              <Box sx={{ mr: 1 }}>
                <CustomizedButton
                  title={"ยกเลิก"}
                  variant="outlined"
                  type="reset"
                  onClick={onCancel}
                  testId="contact-reset-btn"
                />
              </Box>
              <CustomizedButton
                title={"บันทึก"}
                variant="contained"
                onClick={isEditPage ? onSubmitEdit : onSubmit}
                testId="contact-submit-btn"
              />
            </Box>
          )}
        </form>
        <ModalUI
          open={deleteModalIsOpen}
          handleClose={cancelDeleteHandler}
          fullWidth
          maxWidth="sm"
        >
          <Typography fontWeight="bold">ยืนยันการลบข้อมูลผู้ติดต่อ</Typography>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ mr: 1 }}>
              <CustomizedButton
                title={"ยกเลิก"}
                variant="outlined"
                type="reset"
                size="medium"
                onClick={cancelDeleteHandler}
              />
            </Box>
            <CustomizedButton
              title={"ยืนยัน"}
              variant="contained"
              size="medium"
              onClick={deleteContactHandler}
            />
          </Box>
        </ModalUI>
      </>
    );
  }
);

export default ContactForm;
