import { Provider } from "react-redux";
import { store } from "./app/store";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { NavbarContextProvider } from "./contexts/navbar-context";
import { ContactContextProvider } from "./contexts/contact-context";
import { AuthContextProvider } from "./contexts/auth-context";
import CustomizedSnackbar from "./components/Custom/CustomizedSnackbar";
import { theme } from "./theme";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { CURRENT_VERSION } from "./config/variables";

Sentry.init({
  dsn: "https://5770a82d87074635c892cfae880b6191@o4505345515913216.ingest.sentry.io/4505800566046720",
  release: "aal-production@" + CURRENT_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/aal-api\.npr\.digital\/graphql/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ["https://aal-api.npr.digital/graphql"],
      networkRequestHeaders: ["X-Custom-Header"],
      networkResponseHeaders: ["X-Custom-Header"],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <CustomizedSnackbar>
          <AuthContextProvider>
            <NavbarContextProvider>
              <ContactContextProvider>
                <App />
              </ContactContextProvider>
            </NavbarContextProvider>
          </AuthContextProvider>
        </CustomizedSnackbar>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
