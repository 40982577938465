import * as Yup from "yup";

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

export const initialState = {
  isLoading: {
    allManufactureOrders: false,
    manufactureOrder: false,
    manufactureItemSettings: false,
    allManufactureOrdersExport: false,
  },
  allManufactureOrders: [],
  allManufactureOrdersExport: [],
  manufactureOrder: {
    status: null,
    pending_cancel: false,
    unique_id: null,
    created_date: null,
    payment_date: null,
    production_date: null,
    receive_date: null,
    vendor_unique_id: "",
    vendor_name: "",
    vendor_phone: "",
    brand_name: "",
    customer_unique_id: "",
    customer_name: "",
    customer_phone_main: "",
    customer_phone_sub: "",
    customer_email: "",
    delivery_channel: "Kerry",
    delivery_address: "",
    tag_list: [],
    employee_list: [],
    creator_unique_id: null,
    created_by: null,
    item_list: [],
    tag_mfg: "",
    remark: "",
    reject_remark: "",
  },
  manufactureItemSettings: [],
  error: null,
};

export const manufactureOrderValidation = Yup.object().shape({
  vendor_unique_id: Yup.string().required("กรุณาเลือกผู้ผลิต"),
  customer_unique_id: Yup.string().required("กรุณาเลือกลูกค้า"),
  delivery_address: Yup.string().required("กรุณาเลือกที่อยู่จัดส่ง"),
  customer_email: Yup.string().email("กรุณากรอกอีเมลให้ถูกต้อง"),
  reject_remark: Yup.string().when("pending_cancel", {
    is: (pending_cancel) => pending_cancel === true,
    then: Yup.string().required("กรุณาระบุ"),
    otherwise: Yup.string(),
  }),
  item_list: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number("กรุณาระบุ").min(1).required("กรุณาระบุ"),
    })
  ),
});
