import { gql } from "graphql-request";

export const GET_MANUFACTURE_ITEM_SETTINGS = gql`
  query ManufactureItemSettings {
    manufactureItemSettings {
      id
      setting_index
      brand_name
      vendor_unique_id
      vendor {
        name
        last_name
      }
      item_type
      block
      process_type
      printing_cost
      hot_rolled_cost
      fold_cost
    }
  }
`;

export const GET_MANUFACTURE_ITEM_SETTING = gql`
  query ManufactureItemSetting(
    $uniqueInput: ManufactureItemSettingUniqueInput
  ) {
    manufactureItemSetting(uniqueInput: $uniqueInput) {
      id
      setting_index
      brand_name
      vendor_unique_id
      vendor {
        name
        last_name
      }
      item_type
      block
      process_type
      printing_cost
      hot_rolled_cost
      fold_cost
    }
  }
`;
