import { formatDate } from "./date-converter";

export const contactExportFormatter = (data, isForImport) => {
  const formattedContacts = data
    .map((contact) => {
      const created_by = `${contact.created_by?.first_name ?? ""}${
        contact.created_by?.last_name ? ` ${contact.created_by?.last_name}` : ""
      }`;
      const last_updated_by = `${contact.last_updated_by?.first_name ?? ""}${
        contact.last_updated_by?.last_name
          ? ` ${contact.last_updated_by?.last_name}`
          : ""
      }`;
      let formattedContact = {
        unique_id: contact.unique_id,
        is_vendor: contact.is_vendor ? "TRUE" : "FALSE",
        is_customer: contact.is_customer ? "TRUE" : "FALSE",
        contact_type: contact.contact_type,
        identity_no: contact.identity_no,
        title: contact.title,
        name: contact.name,
        last_name: contact.last_name,
        gender: contact.gender,
        main_interested_field: contact.main_interested_field,
        secondary_interested_field: contact.secondary_interested_field,
        business_group_field: contact.business_group_field,
        business_type_field: contact.business_type_field,
        customer_quality: contact.customer_quality,
        contact_remark: contact.contact_remark,
        created_by,
        created_date: formatDate(contact.created_date),
        last_updated_by,
        last_updated_date: formatDate(contact.last_updated_date),
        tag_list: contact.tag_list.join(", "),
        is_active: contact.is_active ? "TRUE" : "FALSE",
        inactive_remark: contact.inactive_remark,
      };

      const contactChannelListLength = contact.contact_channel_list.length;
      const contactPersonListLength = contact.contact_person_list.length;
      const addressListLength = contact.address_list.length;

      if (
        contactChannelListLength === 0 &&
        contactPersonListLength === 0 &&
        addressListLength === 0
      ) {
        return [formattedContact];
      }

      const maxListLength = Math.max(
        contactChannelListLength,
        contactPersonListLength,
        addressListLength
      );
      const contactsWithList = [];
      for (let i = 0; i <= maxListLength - 1; i++) {
        const contact_channel_list =
          i < contactChannelListLength
            ? {
                contact_channel_name:
                  contact.contact_channel_list?.[i].contact_channel_name,
                contact_channel_type:
                  contact.contact_channel_list?.[i].contact_channel_type,
              }
            : {};
        const contact_person_list =
          i < contactPersonListLength
            ? {
                contact_person_name: contact.contact_person_phone?.[i].name,
                contact_person_phone: contact.contact_person_phone?.[i].phone,
                contact_person_position:
                  contact.contact_person_phone?.[i].position,
              }
            : {};
        const address_list =
          i < addressListLength
            ? {
                is_default_address: contact.address_list?.[i].is_default_address
                  ? "TRUE"
                  : "FALSE",
                address_type: contact.address_list?.[i].address_type,
                address_number: contact.address_list?.[i].address_number,
                building: contact.address_list?.[i].building,
                sub_district: contact.address_list?.[i].sub_district,
                district: contact.address_list?.[i].district,
                province: contact.address_list?.[i].province,
                postal_code: contact.address_list?.[i].postal_code,
                zone: contact.address_list?.[i].zone,
                country: contact.address_list?.[i].country,
                same_as_default_address: contact.address_list?.[i]
                  .same_as_default_address
                  ? "TRUE"
                  : "FALSE",
                address_name: contact.address_list?.[i].name,
                address_phone: contact.address_list?.[i].phone,
              }
            : {};
        const formattedContactCopy = i === 0 ? formattedContact : {};
        const contactWithList = {
          ...formattedContactCopy,
          unique_id: i === 0 ? formattedContact.unique_id : "",
          ...contact_channel_list,
          ...contact_person_list,
          ...address_list,
        };
        contactsWithList.push(contactWithList);
      }
      return contactsWithList;
    })
    .flat();

  const arrangedContacts = formattedContacts.map((contact) => {
    const arrangedContact = {
      unique_id: contact.unique_id,
      is_vendor: contact.is_vendor,
      is_customer: contact.is_customer,
      contact_type: contact.contact_type,
      identity_no: contact.identity_no,
      title: contact.title,
      name: contact.name,
      last_name: contact.last_name,
      ["contact.contact_channel_list.contact_channel_type"]:
        contact.contact_channel_type,
      ["contact_channel_list.contact_channel_name"]:
        contact.contact_channel_name,
      contact_channel_type: contact.contact_channel_type,
      contact_channel_name: contact.contact_channel_name,
      is_active: contact.is_active,
      inactive_remark: contact.inactive_remark,
      tag_list: contact.tag_list,
      ["address_list.is_default_address"]: contact.same_as_default_address,
      ["address_list.address_type"]: contact.address_type,
      ["address_list.address_number"]: contact.address_number,
      ["address_list.building"]: contact.building,
      ["address_list.sub_district"]: contact.sub_district,
      ["address_list.district"]: contact.district,
      ["address_list.province"]: contact.province,
      ["address_list.postal_code"]: contact.postal_code,
      ["address_list.zone"]: contact.zone,
      ["address_list.country"]: contact.country,
      ["address_list.same_as_default_address"]: contact.same_as_default_address,
      ["address_list.name"]: contact.address_name,
      ["address_list.phone"]: contact.address_phone,
      is_default_address: contact.is_default_address,
      address_type: contact.address_type,
      address_number: contact.address_number,
      building: contact.building,
      sub_district: contact.sub_district,
      district: contact.district,
      province: contact.province,
      postal_code: contact.postal_code,
      zone: contact.zone,
      country: contact.country,
      same_as_default_address: contact.same_as_default_address,
      address_name: contact.address_name,
      address_phone: contact.address_phone,
      ["contact_person_list.name"]: contact.contact_person_name,
      ["contact_person_list.position"]: contact.contact_person_position,
      ["contact_person_list.phone"]: contact.contact_person_phone,
      contact_person_name: contact.contact_person_name,
      contact_person_position: contact.contact_person_position,
      contact_person_phone: contact.contact_person_phone,
      gender: contact.gender,
      main_interested_field: contact.main_interested_field,
      secondary_interested_field: contact.secondary_interested_field,
      business_group_field: contact.business_group_field,
      business_type_field: contact.business_type_field,
      customer_quality: contact.customer_quality,
      contact_remark: contact.contact_remark,
      created_by: contact.created_by,
      created_date: contact.created_date,
      last_updated_by: contact.last_updated_by,
      last_updated_date: contact.last_updated_date,
    };
    if (isForImport) {
      delete arrangedContact.contact_channel_name;
      delete arrangedContact.contact_channel_type;
      delete arrangedContact.is_default_address;
      delete arrangedContact.address_type;
      delete arrangedContact.address_number;
      delete arrangedContact.building;
      delete arrangedContact.sub_district;
      delete arrangedContact.district;
      delete arrangedContact.province;
      delete arrangedContact.postal_code;
      delete arrangedContact.zone;
      delete arrangedContact.country;
      delete arrangedContact.same_as_default_address;
      delete arrangedContact.address_name;
      delete arrangedContact.address_phone;
      delete arrangedContact.created_by;
      delete arrangedContact.created_date;
      delete arrangedContact.last_updated_by;
      delete arrangedContact.last_updated_date;
      delete arrangedContact.contact_person_name;
      delete arrangedContact.contact_person_position;
      delete arrangedContact.contact_person_phone;
    } else {
      delete arrangedContact[
        "contact.contact_channel_list.contact_channel_type"
      ];
      delete arrangedContact["contact_channel_list.contact_channel_name"];
      delete arrangedContact["address_list.is_default_address"];
      delete arrangedContact["address_list.address_type"];
      delete arrangedContact["address_list.address_number"];
      delete arrangedContact["address_list.building"];
      delete arrangedContact["address_list.sub_district"];
      delete arrangedContact["address_list.district"];
      delete arrangedContact["address_list.province"];
      delete arrangedContact["address_list.postal_code"];
      delete arrangedContact["address_list.zone"];
      delete arrangedContact["address_list.country"];
      delete arrangedContact["address_list.same_as_default_address"];
      delete arrangedContact["address_list.name"];
      delete arrangedContact["address_list.phone"];
      delete arrangedContact["contact_person_list.name"];
      delete arrangedContact["contact_person_list.position"];
      delete arrangedContact["contact_person_list.phone"];
    }
    return arrangedContact;
  });

  return arrangedContacts;
};
