import { graphQLClientWithHeader } from "../graphQL";
import {
  GET_MANUFACTURE_ITEM_SETTINGS,
  GET_MANUFACTURE_ITEM_SETTING,
} from "./queries";
import {
  CREATE_MANUFACTURE_ITEM_SETTING,
  UPDATE_MANUFACTURE_ITEM_SETTING,
  DELETE_MANUFACTURE_ITEM_SETTING,
  UPSERT_OR_DELETE_MANUFACTURE_ITEM_SETTINGS,
} from "./mutations";

class SettingService {
  async getManufactureItemSettings() {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureItemSettings } = await graphQLClient.request(
      GET_MANUFACTURE_ITEM_SETTINGS
    );
    return manufactureItemSettings;
  }

  async getManufactureItemSetting(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureItemSetting } = await graphQLClient.request(
      GET_MANUFACTURE_ITEM_SETTING,
      { uniqueInput }
    );
    return manufactureItemSetting;
  }

  async createManufactureItemSetting(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureItemSettingCreate } = await graphQLClient.request(
      CREATE_MANUFACTURE_ITEM_SETTING,
      { createInput }
    );
    return manufactureItemSettingCreate;
  }

  async updateManufactureItemSetting(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureItemSettingUpdate } = await graphQLClient.request(
      UPDATE_MANUFACTURE_ITEM_SETTING,
      { uniqueInput, updateInput }
    );
    return manufactureItemSettingUpdate;
  }

  async deleteManufactureItemSetting(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureItemSettingDelete } = await graphQLClient.request(
      DELETE_MANUFACTURE_ITEM_SETTING,
      { uniqueInput }
    );
    return manufactureItemSettingDelete;
  }

  async upsertOrDeleteManufactureItemSettings(upsertManyInput, idsToDelete) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureItemSettingUpsertOrDeleteMany } =
      await graphQLClient.request(UPSERT_OR_DELETE_MANUFACTURE_ITEM_SETTINGS, {
        upsertManyInput,
        idsToDelete,
      });
    return manufactureItemSettingUpsertOrDeleteMany;
  }
}

export default new SettingService();
