import { forwardRef } from "react";
import Chip from "@mui/material/Chip";

const CustomizedChips = forwardRef(
  (
    { handleDelete, variant, value, color, onClick, component, ...props },
    ref
  ) => {
    return (
      <Chip
        ref={ref}
        label={value}
        variant={variant || "outlined"}
        onDelete={handleDelete}
        size="medium"
        color={color || "secondary"}
        onClick={onClick}
        component={component}
        {...props}
      />
    );
  }
);

export default CustomizedChips;
