import { graphQLClientWithHeader } from "../graphQL";
import {
  GET_SALES_ORDER,
  GET_SALES_ORDERS,
  GET_SALES_ORDERS_AGGRID,
  GET_SALES_ORDERS_ITEM_ID_LIST,
  GET_SALES_ORDER_PAYMENT,
  GET_SALES_ORDER_PAYMENTS,
  GET_SALES_ORDER_PAYMENTS_AGGRID,
  GET_SALES_ORDER_PAYMENTS_EXPORT_AGGRID,
  GET_SALES_ORDER_TAGS,
} from "./queries";
import { CREATE_SALES_ORDER, UPDATE_SALES_ORDER } from "./mutations";

class SalesService {
  async getSalesOrdersAgGrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { ordersAggrid } = await graphQLClient.request(
      GET_SALES_ORDERS_AGGRID,
      {
        aggridInput,
      }
    );
    return ordersAggrid;
  }

  async getSalesOrderPaymentsAgGrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { orderPaymentsAggrid } = await graphQLClient.request(
      GET_SALES_ORDER_PAYMENTS_AGGRID,
      {
        aggridInput,
      }
    );
    return orderPaymentsAggrid;
  }

  async getAllSalesOrders() {
    const graphQLClient = graphQLClientWithHeader();
    const { orders } = await graphQLClient.request(GET_SALES_ORDERS);
    return orders;
  }

  async getSalesOrder(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { order } = await graphQLClient.request(GET_SALES_ORDER, {
      uniqueInput,
    });
    return order;
  }

  async createSalesOrder(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { orderCreate } = await graphQLClient.request(CREATE_SALES_ORDER, {
      createInput,
    });
    return orderCreate;
  }

  async updateSalesOrder(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { orderUpdate } = await graphQLClient.request(UPDATE_SALES_ORDER, {
      uniqueInput,
      updateInput,
    });
    return orderUpdate;
  }

  async getAllSalesOrderPayments() {
    const graphQLClient = graphQLClientWithHeader();
    const { orderPayments } = await graphQLClient.request(
      GET_SALES_ORDER_PAYMENTS
    );
    return orderPayments;
  }

  async getSalesOrderPayment(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { orderPayment } = await graphQLClient.request(
      GET_SALES_ORDER_PAYMENT,
      { uniqueInput }
    );
    return orderPayment;
  }

  async getSalesOrderItemIdList() {
    const graphQLClient = graphQLClientWithHeader();
    const { orders } = await graphQLClient.request(
      GET_SALES_ORDERS_ITEM_ID_LIST
    );
    return orders;
  }

  async getSalesOrderPaymentExportAgGrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { ordersAggrid } = await graphQLClient.request(
      GET_SALES_ORDER_PAYMENTS_EXPORT_AGGRID,
      {
        aggridInput,
      }
    );
    return ordersAggrid;
  }

  async getSalesOrderTags() {
    const graphQLClient = graphQLClientWithHeader();
    const { orders } = await graphQLClient.request(GET_SALES_ORDER_TAGS);
    return orders;
  }
}

export default new SalesService();
