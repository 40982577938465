import { graphQLClientWithHeader } from "../graphQL";
import {
  GET_UNIQUE_ID,
  GET_CREATABLES,
  GET_WORKFLOW_PROGRESS,
} from "./queries";
import {
  CREATE_CREATABLE,
  CREATE_CREATABLES,
  DELETE_CREATABLE,
  DELETE_CREATABLE_WITH_TAGS,
  DOCUMENT_NEXT_STATUS,
  DOCUMENT_SET_STEP,
  DOCUMENT_CANCEL,
} from "./mutations";

class GlobalService {
  async getUniqueId(modelType) {
    const graphQLClient = graphQLClientWithHeader();
    const { utilGetUniqueId } = await graphQLClient.request(GET_UNIQUE_ID, {
      modelType,
    });
    return utilGetUniqueId;
  }

  async getCreatables(findManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creatablesFindByField } = await graphQLClient.request(
      GET_CREATABLES,
      {
        findManyInput,
      }
    );
    return creatablesFindByField;
  }

  async createCreatable(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creatableCreate } = await graphQLClient.request(CREATE_CREATABLE, {
      createInput,
    });
    return creatableCreate;
  }

  async createCreatables(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creatableCreateMany } = await graphQLClient.request(
      CREATE_CREATABLES,
      {
        createManyInput,
      }
    );
    return creatableCreateMany;
  }

  async deleteCreatable(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creatableDelete } = await graphQLClient.request(DELETE_CREATABLE, {
      uniqueInput,
    });
    return creatableDelete;
  }

  async deleteCreatableWithTags(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creatableDeleteWithTags } = await graphQLClient.request(
      DELETE_CREATABLE_WITH_TAGS,
      {
        uniqueInput,
      }
    );
    return creatableDeleteWithTags;
  }

  async getWorkflowProgress(referenceDocumentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { WorkflowProgressByDocument } = await graphQLClient.request(
      GET_WORKFLOW_PROGRESS,
      {
        referenceDocumentInput,
      }
    );
    return WorkflowProgressByDocument;
  }

  async postNextStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentNextStatus } = await graphQLClient.request(
      DOCUMENT_NEXT_STATUS,
      {
        documentInput,
      }
    );
    return documentNextStatus;
  }

  async documentSetStep(documentSetStepInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentSetStep } = await graphQLClient.request(DOCUMENT_SET_STEP, {
      documentSetStepInput,
    });
    return documentSetStep;
  }

  async cancelDocument(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentCancel } = await graphQLClient.request(DOCUMENT_CANCEL, {
      documentInput,
    });
    return documentCancel;
  }
}

export default new GlobalService();
