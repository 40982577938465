import {
  Box,
  Divider,
  Drawer,
  IconButton,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { LeftNavbarSection } from "./LeftNavbarSection";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CabinOutlinedIcon from "@mui/icons-material/CabinOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";
import { useNavbar } from "../../hooks/use-navbar";
import { useAuth } from "../../hooks/use-auth";
import { COMPANY_NAME, CURRENT_VERSION } from "../../config/variables";

const getSections = (t, permissions) => {
  const inventoryPermission = permissions?.inventory ?? "NONE";
  const contactPermission = permissions?.contact ?? "NONE";
  const salesPermission = permissions?.sales ?? "NONE";
  const manufacturePermission = permissions?.manufacture ?? "NONE";
  const userPermission = permissions?.user ?? "NONE";
  const configPermission = permissions?.config ?? "NONE";
  return [
    inventoryPermission !== "NONE"
      ? {
          title: t("inventory.index"),
          items: [
            {
              title: t("inventory.index"),
              path: "/inventory",
              icon: <Inventory2OutlinedIcon fontSize="small" />,
              children: [
                {
                  title: t("inventory.items.index"),
                  path: "/inventory/items",
                },
                // {
                //   title: t("inventory.receive.index"),
                //   path: "/inventory/receive",
                // },
                // {
                //   title: t("inventory.issue.index"),
                //   path: "/inventory/issue",
                // },
                // {
                //   title: t("inventory.transfer.index"),
                //   path: "/inventory/transfer",
                // },
                // {
                //   title: t("inventory.adjustment.index"),
                //   path: "/inventory/adjustment",
                // },
                {
                  title: t("inventory.report"),
                  path: "/inventory/report",
                },
              ],
            },
          ],
        }
      : undefined,
    contactPermission !== "NONE"
      ? {
          title: t("contact.index"),
          items: [
            {
              title: t("contact.index"),
              path: "/contact",
              icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
              children: [
                {
                  title: t("contact.contact_type.customer"),
                  path: "/contact/contacts?type=customer",
                },
                {
                  title: t("contact.contact_type.vendor"),
                  path: "/contact/contacts?type=vendor",
                },
                {
                  title: t("contact.report.index"),
                  path: "/contact/report",
                },
              ],
            },
          ],
        }
      : undefined,
    salesPermission !== "NONE"
      ? {
          title: t("sales.index"),
          items: [
            {
              title: t("sales.index"),
              path: "/sales",
              icon: <ControlPointOutlinedIcon fontSize="small" />,
              children: [
                {
                  title: t("sales.dashboard.index"),
                  path: "/sales/dashboard",
                },
                {
                  title: t("sales.order.index"),
                  path: "/sales/order",
                },
                {
                  title: t("sales.report.index"),
                  path: "/sales/report",
                },
              ],
            },
          ],
        }
      : undefined,
    manufacturePermission !== "NONE"
      ? {
          title: t("manufacture.index"),
          items: [
            {
              title: t("manufacture.index"),
              path: "/manufacture",
              icon: <CabinOutlinedIcon fontSize="small" />,
              children: [
                {
                  title: t("manufacture.order.index"),
                  path: "/manufacture/order",
                },
                {
                  title: t("report"),
                  path: "/manufacture/report",
                },
              ],
            },
          ],
        }
      : undefined,
    userPermission !== "NONE"
      ? {
          title: t("user.index"),
          items: [
            {
              title: t("user.index"),
              path: "/user",
              icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
              children: [
                {
                  title: t("user.account.index"),
                  path: "/user/account",
                },
                {
                  title: t("user.rbac.role"),
                  path: "/user/rbac",
                },
              ],
            },
          ],
        }
      : undefined,
    configPermission !== "NONE"
      ? {
          title: t("setting.index"),
          items: [
            {
              title: t("setting.index"),
              path: "/setting",
              icon: <SettingsOutlinedIcon fontSize="small" />,
            },
          ],
        }
      : undefined,
  ].filter((item) => item !== undefined);
};

const LeftNavbar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { sidebar } = useNavbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [searchParamsList, setsearchParamsList] = useState([]);
  const { permissions } = useAuth();

  useEffect(() => {
    const allSearchParams = [];
    searchParams.forEach((key, value) => {
      allSearchParams.push([key, value]);
    });
    setsearchParamsList(allSearchParams);
  }, [pathname, searchParams]);

  const sections = useMemo(() => getSections(t, permissions), [t, permissions]);

  const navItemNavigateHandle = (path) => {
    navigate(path);
    // verify();
    if (isSmallScreen) {
      sidebar.setIsSidebarOpen(false);
    }
  };

  const content = (
    <CustomizedScrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
        },
        "& .simplebar-vertical .simplebar-scrollbar:before": {
          backgroundColor: (theme) => theme.palette.primary.light,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            pt: 3,
            pl: 3,
          }}
        >
          <img
            style={{ marginLeft: "2.5rem", cursor: "pointer", width: 100 }}
            src="/static/logo.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
          <IconButton
            sx={{
              position: "absolute",
              right: 16,
              height: "auto",
              color: (theme) => theme.palette.primary.main,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.1),
              "&:hover": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.2),
              },
            }}
            onClick={() => sidebar.setIsSidebarOpen(false)}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider variant="middle" />
        <Box sx={{ flexGrow: 1 }}>
          {sections.map((section) => (
            <LeftNavbarSection
              key={section.title}
              path={pathname}
              searchParams={searchParamsList}
              onItemClick={navItemNavigateHandle}
              sx={{
                p: 0,
                "&:first-of-type": {
                  pt: 1,
                  mt: 2,
                },
              }}
              {...section}
              items={section.items}
            />
          ))}
        </Box>
        <Box sx={{ width: "100%", py: 3 }}>
          <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
            Powered by {COMPANY_NAME}
          </Typography>
          <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
            Application version {CURRENT_VERSION}
          </Typography>
        </Box>
      </Box>
    </CustomizedScrollbar>
  );
  return (
    <Drawer
      open={sidebar.isSidebarOpen}
      onClose={() => sidebar.setIsSidebarOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          borderRightColor: "divider",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          width: 260,
          zIndex: 2,
        },
      }}
      variant={isSmallScreen ? "temporary" : "persistent"}
    >
      {content}
    </Drawer>
  );
};

export default LeftNavbar;
