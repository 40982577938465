import { graphQLClientWithHeader } from "../graphQL";
import {
  GET_ALL_USERS,
  GET_ALL_ROLES,
  GET_ALL_USERS_NO_AGGRID,
  GET_ALL_ROLES_NO_AGGRID,
  GET_USER,
  GET_ROLE,
} from "./queries";
import {
  CREATE_USER,
  CREATE_ROLE,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_ROLE,
  DISABLE_USER,
  DELETE_ROLE,
} from "./mutations";

class UserService {
  async getAllUsers(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { usersAggrid } = await graphQLClient.request(GET_ALL_USERS, {
      aggridInput,
    });

    const formattedResults =
      process.env.REACT_APP_ENVIRONMENT.trim() === "production"
        ? usersAggrid.results.filter(
            (user) => user.unique_id !== "EP-ADMIN_NPR"
          )
        : usersAggrid.results;

    const formattedUsersAggrid = {
      results: formattedResults,
      count:
        process.env.REACT_APP_ENVIRONMENT.trim() === "production"
          ? usersAggrid.count - 1
          : usersAggrid.count,
    };
    return formattedUsersAggrid;
  }
  async getAllUsersNoAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { employees } = await graphQLClient.request(GET_ALL_USERS_NO_AGGRID, {
      aggridInput,
    });
    const formattedEmployees =
      process.env.REACT_APP_ENVIRONMENT.trim() === "production"
        ? employees.filter((employee) => employee.unique_id !== "EP-ADMIN_NPR")
        : employees;
    return formattedEmployees;
  }

  async getUser(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { user } = await graphQLClient.request(GET_USER, {
      uniqueInput,
    });
    return user;
  }

  async createUser(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { userCreate } = await graphQLClient.request(CREATE_USER, {
      createInput,
    });
    return userCreate;
  }

  async updateUser(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { userUpdate } = await graphQLClient.request(UPDATE_USER, {
      uniqueInput,
      updateInput,
    });
    return userUpdate;
  }

  async disableUser(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { userDisable } = await graphQLClient.request(DISABLE_USER, {
      uniqueInput,
    });
    return userDisable;
  }

  async deleteUser(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { userDelete } = await graphQLClient.request(DELETE_USER, {
      uniqueInput,
    });
    return userDelete;
  }

  async getAllRoles(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { rolesAggrid } = await graphQLClient.request(GET_ALL_ROLES, {
      aggridInput,
    });
    const formattedRoles = {
      results:
        process.env.REACT_APP_ENVIRONMENT.trim() === "production"
          ? rolesAggrid.results.map((role) => {
              return {
                ...role,
                user_list: role.user_list.filter(
                  (user) => user.unique_id !== "EP-ADMIN_NPR"
                ),
              };
            })
          : rolesAggrid.results,
      count: rolesAggrid.count,
    };
    return formattedRoles;
  }
  async getAllRolesNoAggrid(findManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { roles } = await graphQLClient.request(GET_ALL_ROLES_NO_AGGRID, {
      findManyInput,
    });
    return roles;
  }

  async getRole(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { role } = await graphQLClient.request(GET_ROLE, {
      uniqueInput,
    });
    const formattedRole =
      process.env.REACT_APP_ENVIRONMENT.trim() === "production"
        ? {
            ...role,
            user_list: role.user_list.filter(
              (role) => role.unique_id !== "EP-ADMIN_NPR"
            ),
          }
        : role;
    return formattedRole;
  }
  async postNewRole(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { roleCreate } = await graphQLClient.request(CREATE_ROLE, {
      createInput,
    });
    return roleCreate;
  }
  async updateRole(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { roleUpdate } = await graphQLClient.request(UPDATE_ROLE, {
      uniqueInput,
      updateInput,
    });
    return roleUpdate;
  }
  async deleteRole(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { roleDelete } = await graphQLClient.request(DELETE_ROLE, {
      uniqueInput,
    });
    return roleDelete;
  }
}

export default new UserService();
