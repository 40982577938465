import AgGrid from "./AgGrid";
import CustomizedButton from "../Custom/CustomizedButton";
import ModalUI from "../UI/ModalUI";

const CheckboxModalTable = ({
  gridRef,
  modalTitle,
  btnTitle,
  modalIsOpen,
  columnDefs,
  onGridReady,
  rowSelection,
  height,
  searchBox,
  rowData,
  onRowClicked,
  onRowDoubleClicked,
  onFinishEditing,
  enableRangeSelection,
  closeModal,
  selectedIds,
  setSelectedIds,
  idsSnapshot,
  setIdsSnapshot,
  selectionLimit,
  lockRows,
  getRowId,
  rowsToDisable,
  onFirstDataRendered,
  secondaryAction,
  secondaryActionTitle,
  allowDuplicates,
}) => {
  const checkboxColumn = {
    ...columnDefs[0],
    checkboxSelection: (params) => {
      if (!rowsToDisable || rowsToDisable.length === 0) {
        return true;
      }
      return !rowsToDisable.includes(params.data[columnDefs[0].field]);
    },
    cellRenderer: (params) => {
      if (!allowDuplicates) {
        if (isRowSelected(params)) {
          params.node.setSelected(true);
        } else {
          params.node.setSelected(false);
        }
      }
      return <span>{params.node.data[columnDefs[0].field]}</span>;
    },
  };
  const updatedColumnDefs = [
    checkboxColumn,
    ...columnDefs.slice(1, columnDefs.length),
  ];

  const isRowSelected = (params) => {
    const idKeyName = columnDefs[0].field;
    return selectedIds.includes(params.node.data[idKeyName]);
  };

  // this triggers on opening the modal too
  const rowSelectHandler = (params) => {
    const idKeyName = columnDefs[0].field;
    const selectedId = params.node.data[idKeyName];
    if (rowsToDisable?.includes(selectedId)) {
      params.node.setSelected(false);
      return;
    }
    if (lockRows?.includes(selectedId)) {
      params.node.setSelected(true);
      return;
    }
    if (params.node.selected) {
      if (
        selectionLimit &&
        params.api.getSelectedRows().length > selectionLimit
      ) {
        params.node.setSelected(false);
        return;
      }
      if (!selectedIds.includes(selectedId)) {
        setSelectedIds((prevIds) => [...prevIds, selectedId]);
      }
    } else {
      setSelectedIds((prevIds) => prevIds.filter((id) => id !== selectedId));
    }
  };

  const finishSelectingHandler = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setIdsSnapshot(selectedIds);
    if (rowSelection === "single") {
      onFinishEditing(selectedRows[0] || null);
    } else {
      onFinishEditing(selectedRows);
    }
  };

  const closeModalHandler = () => {
    setSelectedIds(idsSnapshot);
    closeModal();
  };

  return (
    <ModalUI
      open={modalIsOpen}
      handleClose={closeModalHandler}
      title={modalTitle}
      fullWidth
      maxWidth="lg"
    >
      {secondaryAction && (
        <CustomizedButton
          title={secondaryActionTitle}
          variant="outlined"
          onClick={secondaryAction}
          sx={{ mb: 2 }}
        />
      )}
      <AgGrid
        ref={gridRef}
        columnDefs={updatedColumnDefs}
        height={height}
        onGridReady={onGridReady}
        rowSelection={rowSelection}
        searchBox={searchBox}
        rowData={rowData}
        onRowSelected={rowSelectHandler}
        onRowClicked={onRowClicked}
        onRowDoubleClicked={onRowDoubleClicked}
        enableRangeSelection={enableRangeSelection}
        getRowId={getRowId}
        rowMultiSelectWithClick={rowSelection !== "single"}
        onFirstDataRendered={onFirstDataRendered}
      />
      <CustomizedButton
        title={btnTitle}
        variant="contained"
        onClick={finishSelectingHandler}
        sx={{ mt: 2 }}
      />
    </ModalUI>
  );
};

export default CheckboxModalTable;
