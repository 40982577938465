import { graphQLClientWithHeader } from "../graphQL";
import {
  GET_MANUFACTURE_ORDER,
  GET_MANUFACTURE_ORDERS,
  GET_MANUFACTURE_ORDER_AGGRID,
  GET_MANUFACTURE_ORDER_TAGS,
} from "./queries";
import {
  CREATE_MANUFACTURE_ORDER,
  UPDATE_MANUFACTURE_ORDER,
} from "./mutations";

class ManufactureService {
  async getManufactureOrdersAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrdersAggrid } = await graphQLClient.request(
      GET_MANUFACTURE_ORDER_AGGRID,
      {
        aggridInput,
      }
    );
    return manufactureOrdersAggrid;
  }

  async getAllManufactureOrders() {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrders } = await graphQLClient.request(
      GET_MANUFACTURE_ORDERS
    );
    return manufactureOrders;
  }

  async getManufactureOrder(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrder } = await graphQLClient.request(
      GET_MANUFACTURE_ORDER,
      { uniqueInput }
    );
    return manufactureOrder;
  }

  async createManufactureOrder(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrderCreate } = await graphQLClient.request(
      CREATE_MANUFACTURE_ORDER,
      { createInput }
    );
    return manufactureOrderCreate;
  }

  async updateManufactureOrder(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrderUpdate } = await graphQLClient.request(
      UPDATE_MANUFACTURE_ORDER,
      { uniqueInput, updateInput }
    );
    return manufactureOrderUpdate;
  }

  async getManufactureOrderTags() {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrders } = await graphQLClient.request(
      GET_MANUFACTURE_ORDER_TAGS
    );
    return manufactureOrders;
  }
}

export default new ManufactureService();
