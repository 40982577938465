import { useCallback, useRef, useEffect, useState } from "react";
import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import NewReportDateFilter from "../../../components/UI/newReportDateFilter";
import { useTranslation } from "react-i18next";
import { contactReportColumnDefs } from "../../../components/Table/ColumnDefs/Contact/Report";
import AgGrid from "../../../components/Table/AgGrid";
import { exportAsExcel } from "../../../utils/exporter";
import { contactExportFormatter } from "../../../utils/contactPayloadFormatter";
import ContactService from "../../../services/Contact";
import { useAuth } from "../../../hooks/use-auth";

const Report = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef();
  const { permissions } = useAuth();
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allContactsExport, setAllContactsExport] = useState([]);
  const [allContactsDisplayed, setAllContactsDisplayed] = useState([]);
  const contactPermission = permissions.contact;

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(
        dayjs().endOf("day").subtract(7, "day").format("DD/MM/YYYY")
      ),
      dateTo: new Date(dayjs().endOf("day")),
    },
  });

  useEffect(() => {
    if (!isInit) {
      getFilteredContacts();
      setIsInit(true);
    }
  }, [isInit]);

  const breadcrumbs = [
    {
      name: t("contact.report.index"),
      to: "/contact/report",
    },
    {
      name: t("contact.report.all"),
    },
  ];

  const onBtnExport = useCallback(async () => {
    gridRef.current.api.selectAllFiltered();
    const filteredData = gridRef.current.api.getSelectedRows();
    gridRef.current.api.deselectAllFiltered();
    const filteredIds = filteredData.map((contact) => contact.unique_id);

    const contactsToExport = [];
    let shouldExportRow = false;

    allContactsExport.forEach((contact) => {
      if (contact.unique_id && filteredIds.includes(contact.unique_id)) {
        shouldExportRow = true;
        contactsToExport.push(contact);
      }
      if (contact.unique_id && !filteredIds.includes(contact.unique_id)) {
        shouldExportRow = false;
      }
      if (!contact.unique_id && shouldExportRow) {
        contactsToExport.push(contact);
      }
    });
    exportAsExcel(contactsToExport, t("contact.index"), null, t, "contact");
    enqueueSnackbar("นำออกผู้ติดต่อสำเร็จ", {
      variant: "success",
    });
  }, [enqueueSnackbar, t, allContactsExport]);

  const getFilteredContacts = useCallback(async () => {
    let startDate = getValues("date");
    let endDate = getValues("dateTo");
    setIsLoading(true);

    const allContacts = await ContactService.getAllContactsAgGrid({
      startRow: 0,
      endRow: Math.pow(10, 9),
      filterModel: {
        created_date: {
          filter: startDate ? startDate.toISOString() : undefined,
          filterTo: endDate ? endDate.toISOString() : undefined,
          filterType: "date",
          type: "inRange",
        },
      },
    });

    const formattedContact = contactExportFormatter(allContacts.results);
    setAllContactsExport(formattedContact);
    setAllContactsDisplayed(
      formattedContact.filter((contact) => contact.unique_id)
    );

    setIsLoading(false);

    // dispatch(
    //   getAllItemsExport(
    //
    //     enqueueSnackbar
    //   )
    // );
  }, [enqueueSnackbar, getValues]);

  return (
    <>
      {contactPermission === "NONE" && <Navigate to="/no-permission" />}
      {isLoading && <LinearProgress />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        sx={{
          mt: 3,
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{t("contact.report.index")}</Typography>
        <CustomizedButton
          title="นำออกผู้ติดต่อด้วยไฟล์ Excel"
          variant="outlined"
          onClick={onBtnExport}
          testId="order-export-btn"
        />
      </Box>
      <CustomizedBox margin="2rem 0">
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFilteredContacts}
                disabled={isLoading}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      <AgGrid
        ref={gridRef}
        columnDefs={contactReportColumnDefs(t)}
        height={649}
        rowData={allContactsDisplayed}
      />
    </>
  );
};

export default Report;
