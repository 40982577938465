import { useState, useRef, useCallback, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Grid, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import ControlledTextField from "../../../components/Controlled/ControlledTextField";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import { useParams } from "react-router-dom";
import PermissionTable from "../../../components/Table/PermissionTable";
import { useSnackbar } from "notistack";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import UserTable from "../../../components/Table/UserTable";
import CheckboxModalTable from "../../../components/Table/CheckboxModalTable";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ModalUI from "../../../components/UI/ModalUI";
import { getAllUsers } from "../../../features/User/Account/account-actions";
import { departmentEngToThai } from "../../../utils/dataTransformer";

const PermissionTab = forwardRef(
  (
    {
      control,
      errors,
      viewOnly,
      admin,
      fields,
      onRoleDelete,
      getValues,
      setValue,
      append,
      addable,
      remove,
      option,
      setOption,
      permission,
      onReset,
    },
    ref
  ) => {
    const gridRef = useRef();
    const dispatch = useDispatch();
    const selectEmployeeRef = useRef();
    const employeeRef = useRef();
    const { t } = useTranslation();
    const { roleId } = useParams();
    const { role } = useSelector((state) => state.rbac);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [idsSnapshot, setIdsSnapshot] = useState([]);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    // const onButtonClicked = async () => {
    //   const validInput = await trigger(["name", "description"]);
    //   onTriggerRevalidate();
    //   if (validInput) navigate(`${pathname}?step=employee`);
    // };

    const addSelectedEmployeeHandler = () => {
      const selectedEmployees = selectEmployeeRef.current.api.getSelectedRows();
      selectedEmployees.forEach((employee) => {
        if (
          fields.findIndex(
            (existingEmp) => existingEmp.unique_id === employee.unique_id
          ) < 0
        ) {
          append(employee);
        }
      });
      setOpen(false);
    };

    const deleteRoleHandler = () => {
      if (fields.length === 0) {
        onRoleDelete(roleId);
      } else {
        enqueueSnackbar(
          "กรุณาเปลี่ยนกลุ่มการเข้าถึงของผู้ใช้งานที่อยู่ในกลุ่มนี้ จึงจะสามารถลบกลุ่มการเข้าถึงนี้ได้",
          {
            variant: "error",
          }
        );
      }
    };

    const cancelDeleteHandler = () => {
      setOption("");
      setDeleteModalIsOpen(false);
    };

    const removeSelectedEmployeeHandler = (index) => {
      remove(index);
    };

    const datasource = {
      getRows(params) {
        const request = params.request;
        dispatch(
          getAllUsers(
            {
              startRow: request.startRow,
              endRow: request.endRow,
              filterModel: request.filterModel,
              sortModel: request.sortModel,
            },
            params,
            enqueueSnackbar
          )
        );
      },
    };

    const onGridReady = (params) => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
      params.api.setServerSideDatasource(datasource);
    };

    const getRowId = useCallback((params) => {
      return params.data.unique_id;
    }, []);

    const columnDefs = [
      {
        field: "unique_id",
        headerName: t("user.account.employeeId"),
        filter: "agTextColumnFilter",
      },
      {
        field: "img_url",
        headerName: t("user.account.picture"),
        filter: false,
        floatingFilter: false,
        cellRenderer: (params) => {
          if (params.data.img_url) {
            return <Avatar alt="img_url" src={params.data.img_url} />;
          } else {
            return (
              <CustomizedLetterAvatar
                name={params.data.first_name + " " + params.data.last_name}
              />
            );
          }
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
      },
      {
        field: "first_name",
        headerName: t("user.account.firstname"),
        filter: "agTextColumnFilter",
      },
      {
        field: "last_name",
        headerName: t("user.account.lastname"),
        filter: "agTextColumnFilter",
      },
      {
        field: "department",
        headerName: t("user.account.department"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "sales",
            "inventory",
            "account",
            "purchase",
            "admin",
            "manufacture",
            "logistic",
          ],
          valueFormatter: (params) => {
            return departmentEngToThai(params.value);
          },
        },
        valueFormatter: (params) => {
          return departmentEngToThai(params.value);
        },
      },
      {
        field: "position",
        headerName: t("user.account.position"),
        filter: "agTextColumnFilter",
      },
      {
        field: "status",
        headerName: t("user.account.status"),
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <>
              <CustomizedStatus status={params.value} />
              {removeSelectedEmployeeHandler && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                  onClick={() =>
                    removeSelectedEmployeeHandler(params.node.rowIndex)
                  }
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </>
          );
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    ];

    const closeModalHandler = () => {
      setOpen(false);
      setSelectedIds(idsSnapshot);
    };

    const onFinishEditing = (data) => {
      // filter out employees not selected in current modal session
      let filteredUsers = fields.filter((employee) =>
        selectedIds.includes(employee.unique_id)
      );

      // get current employees id to prevent duplication when appending newly selected employees
      const filteredUsersId = filteredUsers.map(
        (employee) => employee.unique_id
      );

      data.forEach((employee) => {
        if (!filteredUsersId.includes(employee.unique_id)) {
          filteredUsers.push(employee);
        }
      });

      // sort for proerly render order (and for role assignment when submitting)
      filteredUsers = filteredUsers.sort((a, b) => {
        return (
          selectedIds.indexOf(a.unique_id) - selectedIds.indexOf(b.unique_id)
        );
      });
      setValue("user_list", filteredUsers);
      setIdsSnapshot(selectedIds);
      setOpen(false);
    };

    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 3 }}
          ref={ref}
        >
          <Grid item>
            <Typography variant="h5">{t("user.rbac.index")}</Typography>
          </Grid>
          {admin && viewOnly && permission !== "VIEW__ONLY" && (
            <Grid item>
              <CustomizedMenuOption
                label={"ตัวเลือก"}
                options={["แก้ไข", "ลบ"]}
                onSelect={(e) => {
                  setOption(e.target.innerText);
                  if (e.target.innerText === "ลบ") {
                    setDeleteModalIsOpen(true);
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        <CustomizedBox>
          <Typography fontWeight="bold" ml={1} mb={2}>
            {t("user.rbac.info")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="name"
                label={t("user.rbac.name")}
                control={control}
                error={errors.name}
                disabled={option === "แก้ไข" ? false : viewOnly}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="description"
                label={t("user.rbac.description")}
                control={control}
                error={errors.description}
                disabled={option === "แก้ไข" ? false : viewOnly}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
        <PermissionTable
          gridRef={gridRef}
          t={t}
          rowData={role.permission_list}
          control={control}
          getValues={getValues}
          errors={errors}
          editable={addable}
          viewOnly={option === "แก้ไข" ? false : viewOnly}
        />
        <CustomizedBox marginTop={0}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography fontWeight="bold" ml={1}>
              {t("user.rbac.employee")}
            </Typography>
          </Box>
          <UserTable
            gridRef={employeeRef}
            autoHeight
            rowData={fields || []}
            disabled={viewOnly && option !== "แก้ไข"}
            isRbacPage
          />
        </CustomizedBox>
        <Box mt={4}>
          {(!viewOnly || option === "แก้ไข") && (
            <CustomizedButton
              title={"ยกเลิก"}
              variant="outlined"
              onClick={onReset}
            />
          )}
          {(!viewOnly || option === "แก้ไข") && (
            <CustomizedButton
              sx={{ ml: 1 }}
              title={"บันทึก"}
              variant="contained"
              type="submit"
            />
          )}
        </Box>
        <ModalUI
          open={open}
          handleClose={() => setOpen(false)}
          fullWidth
          maxWidth="lg"
          title={t("user.rbac.employee")}
        >
          <CustomizedButton
            title="เพิ่ม"
            variant="contained"
            onClick={addSelectedEmployeeHandler}
          />
        </ModalUI>
        <CheckboxModalTable
          gridRef={selectEmployeeRef}
          columnDefs={columnDefs}
          height={650}
          onGridReady={onGridReady}
          getRowId={getRowId}
          enableRangeSelection
          headerCheckboxSelection
          enableCheckbox
          rowSelection={"multiple"}
          modalIsOpen={open}
          closeModal={closeModalHandler}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          idsSnapshot={idsSnapshot}
          setIdsSnapshot={setIdsSnapshot}
          modalTitle="ผู้ใช้งาน"
          btnTitle="เพิ่ม"
          onFinishEditing={onFinishEditing}
        />
        <ModalUI
          open={deleteModalIsOpen}
          handleClose={cancelDeleteHandler}
          fullWidth
          maxWidth="xs"
        >
          <Typography fontWeight="bold">ยืนยันการลบกลุ่มการเข้าถึง</Typography>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ mr: 1 }}>
              <CustomizedButton
                title={"ยกเลิก"}
                variant="outlined"
                type="reset"
                size="medium"
                onClick={cancelDeleteHandler}
              />
            </Box>
            <CustomizedButton
              title={"ยืนยัน"}
              variant="contained"
              size="medium"
              onClick={deleteRoleHandler}
            />
          </Box>
        </ModalUI>
      </>
    );
  }
);

export default PermissionTab;
