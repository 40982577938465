import dayjs from "dayjs";

export const unixToDate = (unix) => {
  return dayjs.unix(unix).format();
};

export const unixToDateWithFormat = (unix) => {
  return dayjs.unix(unix).format("DD/MM/YYYY");
};

export const unixToDateTimeWithFormat = (unix) => {
  return dayjs.unix(unix).format("DD/MM/YYYY, HH:mm A");
};

export const dateToUnix = (date) => {
  return parseInt(dayjs(date).format("X"));
};

export const formatDate = (date) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("DD/MM/YYYY");
};

export const formatDateTime = (date) => {
  return dayjs(date).format("DD/MM/YYYY HH:mm A");
};

export const formatDateTimeNoAMPM = (date) => {
  return dayjs(date).format("DD/MM/YYYY HH:mm");
};

export const formatDateTimeWithSeconds = (date) => {
  return dayjs(date).format("DD/MM/YYYY HH:mm:ss");
};

export const documentTypeToThai = (input) => {
  switch (input) {
    case "buy":
      return "ซื้อ";
    case "sell":
      return "ขาย";
    case "manufacture":
      return "ผลิต";
    case "return":
      return "ส่งคืน";
    case "add":
      return "เพิ่ม";
    case "deduct":
      return "ลด";
    default:
      return input;
  }
};
