import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CustomizedLetterAvatar from "./CustomizedLetterAvatar";
import { CustomizedTooltip } from "./CustomizedTooltip";

const CustomizedAvatar = ({ avatars, sx, noTooltip }) => {
  return (
    <AvatarGroup max={3} sx={{ justifyContent: "flex-end" }}>
      {avatars.map((avatar, index) => {
        if (avatar.img_url) {
          if (noTooltip) {
            return (
              <Avatar
                key={avatar.img_url}
                alt={`img${index}`}
                src={avatar.img_url}
                sx={sx}
              />
            );
          } else {
            return (
              <CustomizedTooltip
                title={`${avatar.first_name} ${avatar.last_name}`}
                key={index}
              >
                <Avatar alt={`img${index}`} src={avatar.img_url} sx={sx} />
              </CustomizedTooltip>
            );
          }
        } else {
          return (
            <CustomizedLetterAvatar
              name={avatar.first_name + " " + avatar.last_name}
              key={index}
              sx={sx}
              noTooltip={noTooltip}
            />
          );
        }
      })}
    </AvatarGroup>
  );
};

export default CustomizedAvatar;
