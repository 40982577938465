import { graphQLClientWithHeader } from "../graphQL";
import {
  GET_ALL_CONTACTS_AGGRID,
  GET_ALL_CONTACTS,
  GET_ALL_CONTACT_IDS,
  GET_CONTACT,
  GET_CONTACT_TAGS,
  GET_ALL_CONTACT_NAMES,
} from "./queries";
import {
  CREATE_CONTACT,
  CREATE_CONTACTS,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  UPSERT_CONTACTS,
} from "./mutations";

class ContactService {
  async getAllContactsAgGrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactsAggrid } = await graphQLClient.request(
      GET_ALL_CONTACTS_AGGRID,
      {
        aggridInput,
      }
    );
    return contactsAggrid;
  }

  async getAllContacts(findManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contacts } = await graphQLClient.request(GET_ALL_CONTACTS, {
      findManyInput,
    });
    return contacts;
  }

  async getContactIds() {
    const graphQLClient = graphQLClientWithHeader();
    const { contacts } = await graphQLClient.request(GET_ALL_CONTACT_IDS);
    return contacts;
  }

  async getContact(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contact } = await graphQLClient.request(GET_CONTACT, {
      uniqueInput,
    });
    return contact;
  }

  async createContact(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactCreate } = await graphQLClient.request(CREATE_CONTACT, {
      createInput,
    });
    return contactCreate;
  }

  async createContacts(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactsCreateMany } = await graphQLClient.request(
      CREATE_CONTACTS,
      createManyInput
    );
    return contactsCreateMany;
  }

  async createOrUpdateContacts(upsertManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactsUpsertMany } = await graphQLClient.request(
      UPSERT_CONTACTS,
      upsertManyInput
    );
    return contactsUpsertMany;
  }

  async updateContact(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactUpdate } = await graphQLClient.request(UPDATE_CONTACT, {
      uniqueInput,
      updateInput,
    });
    return contactUpdate;
  }

  async deleteContact(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactDelete } = await graphQLClient.request(DELETE_CONTACT, {
      uniqueInput,
    });
    return contactDelete;
  }

  async getContactTags() {
    const graphQLClient = graphQLClientWithHeader();
    const { contacts } = await graphQLClient.request(GET_CONTACT_TAGS);
    return contacts;
  }

  async getAllContactNames() {
    const graphQLClient = graphQLClientWithHeader();
    const { contacts } = await graphQLClient.request(GET_ALL_CONTACT_NAMES);
    return contacts;
  }
}

export default new ContactService();
