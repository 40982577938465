import { forwardRef, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../table.css";
import { InputAdornment, Typography } from "@mui/material";
import CustomizedTextField from "../Custom/CustomizedTextField";
import SearchIcon from "@mui/icons-material/Search";
import AG_GRID_LOCALE_TH from "../../locale.th";
import CustomDate from "../UI/CustomDate";

const AgGrid = forwardRef(
  (
    {
      isClientSide,
      rowData,
      columnDefs,
      onRowDoubleClicked,
      height,
      groupDisplayType,
      groupDefaultExpanded,
      groupIncludeTotalFooter,
      searchBox,
      onGridReady,
      onFirstDataRendered,
      masterDetail,
      detailCellRenderer,
      detailRowHeight,
      autoHeight,
      rowSelection,
      onRowClicked,
      onRowSelected,
      enableRangeSelection,
      components,
      onRowDataUpdated,
      suppressMenu,
      rowDragManaged,
      onRowDragMove,
      disabledSidebar,
      disableFloatingFilter,
      rowDragEntireRow,
      getRowId,
      suppressContextMenu,
      headerClass,
      rowDragMultiRow,
      rowMultiSelectWithClick,
      testId,
      path,
    },
    ref
  ) => {
    const sideBar = {
      toolPanels: ["columns", "filters"],
    };

    const defaultColDef = useMemo(() => {
      return {
        editable: false,
        floatingFilter: !(isClientSide || disableFloatingFilter),
        filter: !isClientSide,
        resizable: true,
        sortable: !isClientSide,
        // filterParams: filterParams(),
        suppressMenu: !!suppressMenu,
        headerClass: headerClass || [],
      };
    }, [disableFloatingFilter, headerClass, isClientSide, suppressMenu]);

    const localeText = useMemo(() => {
      return AG_GRID_LOCALE_TH;
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
      ref.current.api.setQuickFilter(
        document.getElementById("filter-text-box").value
      );
    }, [ref]);

    const CustomizedNoRowsOverlay = ({ noRowsMessageFunc }) => (
      <Typography>{noRowsMessageFunc()}</Typography>
    );

    const noRowsOverlayComponent = useMemo(() => {
      return CustomizedNoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
      return {
        noRowsMessageFunc: () => "ไม่มีข้อมูล",
      };
    }, []);

    const getContextMenuItems = useCallback((params) => {
      return [
        ...params.defaultItems,
        {
          name: "Export Selected Rows",
          icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
          subMenu: [
            {
              name: "CSV Export",
              icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
              action: () =>
                params.api.exportDataAsCsv({
                  onlySelected: true,
                }),
            },
            {
              name: "Excel Export",
              icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
              action: () =>
                params.api.exportDataAsExcel({
                  onlySelected: true,
                }),
            },
          ],
        },
        path && {
          name: "Open Document in New Tab",
          action: () => {
            if (params.node != null) {
              let documentPath;
              const uniqueId = params.node.data.unique_id ?? "";

              if (path === "/inventory/items") {
                const { sku } = params.node.data;
                const { item_unique_id } = sku;
                documentPath = `/inventory/items/${encodeURIComponent(
                  item_unique_id
                )}`;
              } else {
                documentPath = `${path}/${encodeURIComponent(uniqueId)}`;
              }

              window.open(documentPath, "_blank");
            }
          },
        },
      ].filter(Boolean);
    }, []);

    const defaultComponents = useMemo(() => {
      return {
        agDateInput: CustomDate,
      };
    }, []);

    return (
      <>
        {searchBox && (
          <CustomizedTextField
            id="filter-text-box"
            onChange={onFilterTextBoxChanged}
            // sx={{ mt: 2, mb: 2, maxWidth: 300 }}
            placeholder="ค้นหา"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}

        <div
          id="myGrid"
          className="ag-theme-material"
          style={{ height: height || "100%", width: "100%" }}
          data-testid={testId}
        >
          <AgGridReact
            domLayout={autoHeight ? "autoHeight" : "normal"}
            ref={ref}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            rowData={rowData}
            localeText={localeText}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            sideBar={disabledSidebar ? false : sideBar}
            detailRowHeight={detailRowHeight}
            enableRangeSelection={enableRangeSelection}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            onRowDoubleClicked={onRowDoubleClicked}
            onRowClicked={onRowClicked}
            onRowSelected={onRowSelected}
            groupDisplayType={groupDisplayType}
            groupDefaultExpanded={groupDefaultExpanded}
            groupIncludeTotalFooter={groupIncludeTotalFooter}
            detailCellRenderer={detailCellRenderer}
            masterDetail={masterDetail}
            rowModelType={isClientSide || rowData ? "clientSide" : "serverSide"}
            serverSideStoreType={isClientSide || rowData ? "full" : "partial"}
            pagination={!(isClientSide && rowData)}
            paginationPageSize={isClientSide && rowData ? undefined : 10}
            cacheBlockSize={isClientSide && rowData ? undefined : 10}
            rowSelection={rowSelection}
            getContextMenuItems={getContextMenuItems}
            components={components || defaultComponents}
            onRowDataUpdated={onRowDataUpdated}
            rowDragMultiRow={rowDragMultiRow}
            rowDragManaged={rowDragManaged}
            rowMultiSelectWithClick={rowMultiSelectWithClick}
            onRowDragMove={onRowDragMove}
            rowDragEntireRow={rowDragEntireRow}
            getRowId={getRowId}
            suppressContextMenu={suppressContextMenu}
            suppressMovableColumns={!!isClientSide}
          />
        </div>
      </>
    );
  }
);

export default AgGrid;
