import { createSlice } from "@reduxjs/toolkit";
import * as Yup from "yup";

export const initialState = {
  isLoading: {
    allItems: false,
    allItemsExport: false,
    item: false,
  },
  allItems: [],
  allItemsExport: [],
  allItemsCount: 0,
  filteredItemsCount: 0,
  allItemsExportCount: 0,
  item: {
    unique_id: "",
    is_variation: false,
    name: "",
    description: "",
    process_type: "",
    item_type: "",
    item_kind: "",
    uom: "",
    collection_name: "",
    type_name: "",
    design_owner: "",
    design_type: "",
    design_code: "",
    block: "",
    shirt_cost: 0,
    sale_price: 0,
    other_cost: 0,
    supplier: "",
    process: "",
    is_active: true,
    inactive_remark: "",
    variation_list: [],
    variation_color: [],
    variation_size: [],
    variation_arm: [],
    variation_color_images: null,
  },
};

export const validation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุรหัสสินค้า"),
  name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
});

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllItems(state, action) {
      // state.allItems = action.payload.results;
      state.allItemsExport = action.payload.allItemsExport;
      state.allItemsCount = action.payload.count_all;
      // state.filteredItemsCount = action.payload.count;
      // state.isLoading.allItems = false;
    },
    loadedAllItemsExport(state, action) {
      state.allItemsExport = action.payload.results;
      state.allItemsExportCount = action.payload.count;
      state.isLoading.allItemsExport = false;
    },
    appendedNewItem(state, action) {
      state.allItems = [...state.allItems, action.payload];
      state.isLoading.allItems = false;
      state.isLoading.item = false;
    },
    loadedItem(state, action) {
      state.item = action.payload;
      state.isLoading.item = false;
    },
    resetItem(state) {
      state.item = initialState.item;
      state.isLoading.item = false;
    },
    updatedItem(state, action) {
      const updatedItem = action.payload;
      const updatedIndex = state.allitems.findIndex(
        (item) => item.unique_id === updatedItem.unique_id
      );
      state.allItems[updatedIndex] = updatedItem;
      state.isLoading.allItems = false;
    },
    resetAllItems(state) {
      state.allItems = initialState.allItems;
      state.allItemsExport = initialState.allItemsExport;
      state.allItemsCount = initialState.allItemsCount;
      state.filteredItemsCount = initialState.filteredItemsCount
      state.isLoading.allItems = false;
      state.isLoading.allItemsExport = false;
    },
  },
});

export const inventoryActions = inventorySlice.actions;

export default inventorySlice.reducer;
