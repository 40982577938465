import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import UserTable from "../../../components/Table/UserTable";
import { getAllUsers } from "../../../features/User/Account/account-actions";
import { useAuth } from "../../../hooks/use-auth";
import GlobalService from "../../../services/Global";

const UserAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isFilter = searchParams.get("filter");
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [isInit, setIsInit] = useState(false);

  const { t } = useTranslation();
  const { permissions } = useAuth();

  const userPermission = permissions.user;

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
    },
  ];

  const tabs = [
    {
      label: t("user.account.tab.index"),
      path: `${pathname}`,
    },
  ];

  useEffect(() => {
    if (!isInit) {
      const getDepartmentOptions = async () => {
        const departmentCreatableOptions = await GlobalService.getCreatables({
          usage_field_type: "user",
          usage_field_name: "department",
        });
        const mappedOptions = departmentCreatableOptions.map(
          (option) => option.name
        );
        setDepartmentOptions(mappedOptions);
      };
      getDepartmentOptions();
      if (gridRef.current.api) {
        const departmentFilter =
          gridRef.current.api.getFilterInstance("department");
        departmentFilter.refreshFilterValues();
      }
      setIsInit(true);
    }
  }, [isInit]);

  const datasource = {
    getRows(params) {
      const request = params.request;
      const isActiveFilter = request.filterModel.is_user_active;
      const activeFilterValue =
        isActiveFilter?.values[0] === "active" ? "true" : "false";
      dispatch(
        getAllUsers(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: {
              ...request.filterModel,
              is_user_active:
                isActiveFilter && isActiveFilter?.values?.length === 1
                  ? {
                      filterType: "boolean",
                      type: "equals",
                      filter: activeFilterValue,
                    }
                  : undefined,
            },
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onRowDoubleClicked = (e) => {
    navigate(`${pathname}/${e.data.unique_id}`);
  };

  const getRowId = useCallback((params) => {
    return params.data.unique_id;
  }, []);

  const onFirstDataRendered = () => {
    const instance = gridRef.current.api.getFilterInstance("is_user_active");
    if (instance) {
      instance.setModel({ filterType: "set", values: ["active"] });
    }
    gridRef.current.api.onFilterChanged();
  };

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      {userPermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("user.account.index")}</Typography>
        {userPermission !== "VIEW__ONLY" && (
          <CustomizedButton
            title={t("user.account.addNewAccount")}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
        )}
      </Box>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <UserTable
        gridRef={gridRef}
        height={650}
        onGridReady={onGridReady}
        onRowDoubleClicked={onRowDoubleClicked}
        enableRangeSelection={true}
        rowSelection={"multiple"}
        getRowId={getRowId}
        departmentOptions={departmentOptions}
        onFirstDataRendered={onFirstDataRendered}
      />
    </>
  );
};

export default UserAccount;
