import UserService from "../../../services/User";
import GlobalService from "../../../services/Global";
import { rbacActions, initialState } from "./rbac-slice";

export const getAllRoles =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const allRoles = await UserService.getAllRoles(input);
      if (params) params.successCallback(allRoles.results, allRoles.count);
      dispatch(rbacActions.loadedAllRoles(allRoles.results));
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      if (params) params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllRolesNoAggrid =
  (findManyInput, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const allroles = await UserService.getAllRolesNoAggrid(findManyInput);
      dispatch(rbacActions.loadedAllRoles(allroles));
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getRole = (uniqueInput, enqueueSnackbar) => async (dispatch) => {
  dispatch(rbacActions.onLoading("role"));
  try {
    if (uniqueInput === "init") {
      dispatch(rbacActions.loadedRole(initialState.role));
    } else {
      const role = await UserService.getRole(uniqueInput);
      dispatch(rbacActions.loadedRole(role));
    }
  } catch (err) {
    dispatch(rbacActions.rejectedActions({ ...err, name: "role" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const postNewRole =
  (documentType, createRoleInput, navigate, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(rbacActions.onLoading("role"));
    try {
      const unique_id = await GlobalService.getUniqueId(documentType);
      const payload = {
        unique_id,
        ...createRoleInput,
      };
      const insertedRole = await UserService.postNewRole(payload);
      dispatch(rbacActions.appendedNewRole(insertedRole));
      enqueueSnackbar("เพิ่มสิทธิ์สำเร็จ", {
        variant: "success",
      });
      navigate(`/user/rbac/${unique_id}`);
    } catch (err) {
      console.log(err);
      dispatch(rbacActions.rejectedActions({ ...err, name: "role" }));
      enqueueSnackbar("เพิ่มสิทธิ์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const updateRole =
  (uniqueInput, updateInput, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const updatedRole = await UserService.updateRole(
        uniqueInput,
        updateInput
      );
      dispatch(rbacActions.updatedRole(updatedRole));
      enqueueSnackbar("แก้ไขสิทธิ์สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      enqueueSnackbar("แก้ไขสิทธิ์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const deleteRole =
  (uniqueInput, navigate, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const deletedRole = await UserService.deleteRole(uniqueInput);
      dispatch(rbacActions.deletedRole(deletedRole));
      enqueueSnackbar("ลบสิทธิ์สำเร็จ", {
        variant: "success",
      });
      navigate("/user/rbac");
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      enqueueSnackbar("ลบสิทธิ์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
