import InventoryService from "../../services/Inventory";
import { inventoryActions, initialState } from "./inventory-slice";
import {
  formatItemPayload,
  formatItemUpdatePayload,
  formatSkuDetailPayload,
} from "../../utils/dataTransformer";
import {
  createActivityLogPayload,
  createActivityLogEditPayload,
} from "../../utils/activityLogsPayloadFormatter";
import {
  itemExportFormatter,
  allItemsFormatterClientSide,
} from "../../utils/inventoryPayloadFormatter";
import ActivityLogsService from "../../services/ActivityLogs";

export const getAllItems =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllItemsAgGrid(input);
      params.successCallback(allItems.results, allItems.count);
      dispatch(
        inventoryActions.loadedAllItems({
          results: allItems.results,
          count: allItems.count,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(inventoryActions.rejectedActions({ ...err, name: "allItems" }));
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllItemsSku =
  (input, params, enqueueSnackbar, setIsLoading) => async (dispatch) => {
    dispatch(inventoryActions.onLoading("allItems"));

    try {
      const allItems = await InventoryService.getAllItemsSkuFlatAgGrid(input);

      if (params) {
        params.successCallback(allItems.results, allItems.count);
      }

      dispatch(
        inventoryActions.loadedAllItems({
          results: allItems.results,
          count: allItems.count,
          count_all: allItems.count_all,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(inventoryActions.rejectedActions({ ...err, name: "allItems" }));
      if (params) {
        params.failCallback();
      }
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
    if (setIsLoading) {
      setIsLoading(false);
    }
  };

export const getAllItemsSkuNoAgGrid =
  (input, enqueueSnackbar, setIsLoading, isMainPage) => async (dispatch) => {
    dispatch(inventoryActions.onLoading("allItems"));
    dispatch(inventoryActions.onLoading("allItemsExport"));
    setIsLoading(true);
    try {
      const allItems = await InventoryService.getAllitemsSkus(input);
      const formattedItems = allItemsFormatterClientSide(allItems, isMainPage);
      dispatch(
        inventoryActions.loadedAllItems({
          allItemsExport: allItems,
          results: formattedItems,
          count: allItems.length,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(inventoryActions.rejectedActions({ ...err, name: "allItems" }));
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

export const getAllItemsNoAggrid = () => async (dispatch) => {
  dispatch(inventoryActions.onLoading("allItems"));
  try {
    const allItems = await InventoryService.getAllItemsNoAggrid();
    dispatch(inventoryActions.loadedAllItems(allItems));
  } catch (err) {
    dispatch(inventoryActions.rejectedActions({ ...err, name: "allItems" }));
  }
};

export const createItem =
  (createItemInput, user, navigate, setIsInit, enqueueSnackbar, hasVariation) =>
  async (dispatch) => {
    dispatch(inventoryActions.onLoading("item"));
    try {
      const formattedPayload = await formatItemPayload(createItemInput, user);
      const result = await InventoryService.createItemDirect(formattedPayload);
      dispatch(inventoryActions.loadedItem(result));
      if (!hasVariation) {
        const itemSkuDetailUpdatePayload = formatSkuDetailPayload({
          ...createItemInput,
          img_url: formattedPayload.img_url,
        });

        await InventoryService.updateItemSkuDetail(
          itemSkuDetailUpdatePayload.uniqueInput,
          itemSkuDetailUpdatePayload.updateInput
        );
      }
      const createActivityLog = createActivityLogPayload(
        formattedPayload,
        "item",
        "สร้างสินค้า"
      );
      try {
        await ActivityLogsService.createActivityLogs({
          createInput: createActivityLog,
        });
        enqueueSnackbar("บันทึกสำเร็จ", {
          variant: "success",
        });
      } catch (err) {
        console.log("Cannot create item activity log");
      }
      setIsInit(false);
      navigate(`/inventory/items/${createItemInput.unique_id}`);
    } catch (err) {
      console.log(err);
      dispatch(inventoryActions.rejectedActions({ ...err, name: "item" }));
      enqueueSnackbar("บันทึกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const getItem =
  (uniqueInput, enqueueSnackbar, navigate) => async (dispatch) => {
    dispatch(inventoryActions.onLoading("item"));
    try {
      if (uniqueInput === "init") {
        dispatch(inventoryActions.loadedItem(initialState.item));
      } else {
        const item = await InventoryService.getItem(uniqueInput);
        dispatch(inventoryActions.loadedItem(item));
      }
    } catch (err) {
      dispatch(inventoryActions.rejectedActions({ ...err, name: "item" }));
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
      navigate("/inventory/items");
    }
  };

export const updateItem =
  (
    uniqueInput,
    updateInput,
    user,
    enqueueSnackbar,
    setMode,
    setIsInit,
    hasVariation
  ) =>
  async (dispatch) => {
    dispatch(inventoryActions.onLoading("item"));
    try {
      const formattedUpdatePayload = await formatItemUpdatePayload(
        updateInput,
        user
      );
      await InventoryService.updateItem(uniqueInput, formattedUpdatePayload);
      if (!hasVariation) {
        const itemSkuDetailUpdatePayload = formatSkuDetailPayload({
          ...updateInput,
          img_url: formattedUpdatePayload.img_url,
          unique_id: uniqueInput.unique_id,
        });
        await InventoryService.updateItemSkuDetail(
          itemSkuDetailUpdatePayload.uniqueInput,
          itemSkuDetailUpdatePayload.updateInput
        );
      }
      const editLogPayload = createActivityLogEditPayload(
        uniqueInput,
        "item",
        "แก้ไขสินค้า"
      );
      try {
        await ActivityLogsService.createActivityLogs({
          createInput: editLogPayload,
        });
        enqueueSnackbar("บันทึกสำเร็จ", {
          variant: "success",
        });
      } catch (err) {
        console.log("Cannot update item activity log");
      }
      setMode("view");
      setIsInit(false);
    } catch (err) {
      dispatch(inventoryActions.rejectedActions({ ...err, name: "allItems" }));
      enqueueSnackbar("บันทึกไม่สำเร็จ", {
        variant: "error",
      });
      console.log(err);
    }
  };

export const deleteItem =
  (uniqueInput, enqueueSnackbar, navigate) => async () => {
    try {
      await InventoryService.deleteItem(uniqueInput);
      navigate("/inventory/items", { replace: true });
      enqueueSnackbar("ลบสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ไม่สามารถลบได้", {
        variant: "error",
      });
      console.log(err);
    }
  };

export const getAllItemsExport =
  (input, enqueueSnackbar, callback) => async (dispatch) => {
    dispatch(inventoryActions.onLoading("allItemsExport"));
    try {
      const allItemsSku = await InventoryService.getAllItemsSkuFlatAgGrid(
        input
      );
      let items = await itemExportFormatter(allItemsSku.results);
      dispatch(
        inventoryActions.loadedAllItemsExport({
          results: items,
          count: allItemsSku.count,
        })
      );
      if (callback) {
        callback(items);
      }
    } catch (err) {
      console.log(err);
      dispatch(
        inventoryActions.rejectedActions({
          ...err,
          name: "allItemsExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
