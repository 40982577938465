import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { forwardRef } from "react";
const CustomizedSelect = forwardRef(
  (
    {
      id,
      name,
      label,
      value,
      onChange,
      options,
      disabled,
      error,
      helperText,
      sx,
      color,
      renderValue,
      defaultValue,
      required,
      helperTextSize,
      testId,
    },
    ref
  ) => {
    return (
      <FormControl
        size="small"
        fullWidth
        disabled={disabled}
        error={error}
        ref={ref}
        color={color}
        required={required}
      >
        <InputLabel id="demo-simple-select-label" required={required}>
          {label}
        </InputLabel>
        <Select
          id={id}
          error={error}
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          sx={sx}
          size="small"
          renderValue={renderValue}
          defaultValue={defaultValue || ""}
          data-testid={testId}
          displayEmpty
        >
          {options &&
            options.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option.value || option}
                  disabled={option.value === ""}
                >
                  {option.label || option}
                </MenuItem>
              );
            })}
        </Select>
        {helperText && (
          <FormHelperText sx={{ fontSize: helperTextSize }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default CustomizedSelect;
