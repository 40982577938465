import { useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Box, Grid, IconButton, Typography, Divider } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controlled/ControlledTextField";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import CustomizedButton from "../../Custom/CustomizedButton";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import ControlledNumberTextField from "../../Controlled/ControlledNumberTextField";
import ControlledSSCreatable from "../../Controlled/ControlledSSCreatable";
import DropzoneUI from "../../UI/DropzoneUI";
import Confirmation from "../../UI/Confirmation/Confirmation";
import ModalUI from "../../UI/ModalUI";

const GeneralTab = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      viewOnly,
      isEditPage,
      isItemPage,
      hasVariation,
      generateDocumentId,
      watchVariantList,
      files,
      setFiles,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { item } = useSelector((state) => state.inventory);
    const [editUniqueIdModal, setEditUniqueIdModal] = useState(false);
    const [openActiveConfirmationModal, setOpenActiveConfirmationModal] =
      useState(false);

    const handleChangeUniqueId = (e, field) => {
      if (watchVariantList.length === 0) {
        field.onChange(e);
      } else {
        setEditUniqueIdModal(true);
      }
    };

    const handleChangeActiveStatus = (value) => {
      if (hasVariation && value === "false") {
        setOpenActiveConfirmationModal(true);
      } else {
        const variationList = getValues("variation_list");
        const updatedVariationList = variationList.map((color) => {
          return {
            ...color,
            value: color.value.map((size) => {
              return {
                ...size,
                value: size.value.map((arm) => {
                  return { ...arm, is_active: true };
                }),
              };
            }),
          };
        });
        setValue("variation_list", updatedVariationList);
      }
    };

    const handleDisableActiveStatus = () => {
      setOpenActiveConfirmationModal(false);
      const variationList = getValues("variation_list");
      const updatedVariationList = variationList.map((color) => {
        return {
          ...color,
          value: color.value.map((size) => {
            return {
              ...size,
              value: size.value.map((arm) => {
                return { ...arm, is_active: false };
              }),
            };
          }),
        };
      });
      setValue("variation_list", updatedVariationList);
    };

    const handleCancelActiveStatus = () => {
      setValue("is_active", "true");
      setOpenActiveConfirmationModal(false);
    };

    const handleSubmitChangeUniqueId = () => {
      setValue("variation_list", []);
      setValue("variation_color", []);
      setValue("variation_size", []);
      setValue("variation_arm", []);
      setEditUniqueIdModal(false);
    };

    const variationRadiosList = [
      { value: true, label: "มี" },
      { value: false, label: "ไม่มี" },
    ];

    const statusRadiosList = [
      { value: true, label: "ใช้งาน" },
      { value: false, label: "ไม่ใช้งาน" },
    ];

    const watchImageUrl = useWatch({ control, name: "img_url" });

    useEffect(() => {
      if (watchImageUrl) {
        setFiles(watchImageUrl);
      } else {
        setFiles([]);
      }
    }, [viewOnly]);

    return (
      <>
        <CustomizedBox
          ref={ref}
          margin="none"
          boxShadow={!isItemPage ? "none" : undefined}
        >
          <Confirmation
            width={600}
            open={editUniqueIdModal}
            title={t("inventory.sentence.editUniqueId")}
            textContent={t("inventory.sentence.editUniqueIdDetail")}
            handleCancel={() => setEditUniqueIdModal(false)}
            handleSubmit={handleSubmitChangeUniqueId}
          />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      my: 2,
                      alignItems: "center",
                    }}
                  >
                    <Typography fontWeight="bold" ml={1}>
                      {"ตัวเลือกสินค้า"}
                    </Typography>
                    <CustomizedTooltip
                      title={
                        "เลือกเพื่อกำหนดว่าสินค้านี้มีตัวเลือกเพิ่มเติมหรือไม่ เช่น สี, ไซส์ และ แขน"
                      }
                      noMaxWidth
                    >
                      <ErrorOutlineOutlinedIcon
                        sx={{ fontSize: "14px", ml: 1 }}
                      />
                    </CustomizedTooltip>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="is_variation"
                    control={control}
                    render={({ field }) => (
                      <Grid item xs={12}>
                        <CustomizedRadioGroup
                          radioList={variationRadiosList}
                          row
                          {...field}
                          disabled={viewOnly}
                        />
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight="bold" sx={{ ml: 1 }}>
                    {"รายละเอียดสินค้า"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={isEditPage ? 12 : 10}
                      sm={isEditPage ? 12 : 10}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ControlledTextField
                        name="unique_id"
                        label={t("inventory.items.documentId")}
                        control={control}
                        error={errors.unique_id}
                        disabled={isEditPage}
                        onChange={handleChangeUniqueId}
                        required
                        testId="item-id-text-field"
                      />
                    </Grid>
                    {!isEditPage && (
                      <Grid item xs={2}>
                        <Box sx={{ ml: -1 }}>
                          <CustomizedTooltip
                            title={"คืนค่ารหัสเดิม"}
                            noMaxWidth
                          >
                            <IconButton
                              onClick={generateDocumentId}
                              sx={{
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <RestartAltOutlinedIcon />
                            </IconButton>
                          </CustomizedTooltip>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledTextField
                    name="name"
                    label={t("inventory.items.name")}
                    control={control}
                    error={errors.name}
                    disabled={viewOnly}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledTextField
                    name="description"
                    label={t("inventory.items.description")}
                    control={control}
                    error={errors.description}
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ height: "24px", my: 2 }}>
                <Typography fontWeight="bold" sx={{ mb: 2, ml: 1 }}>
                  รูปภาพ
                </Typography>
              </Box>
              {viewOnly && item?.img_url?.length > 0 && item.img_url[0] && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: 200,
                    p: 2,
                    border: "1px solid #C8C8C8",
                    borderRadius: "5px",
                    objectFit: "contain",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={item.img_url[0]}
                    alt="photo of the item"
                    style={{ height: "100%" }}
                  />
                </Box>
              )}
              {!viewOnly && (
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ErrorOutlineOutlinedIcon
                      sx={{ fontSize: "14px", mr: 1 }}
                    />
                    <Typography variant="caption" sx={{ color: "#333333" }}>
                      File size limit 10MB
                    </Typography>
                  </Box>
                  <DropzoneUI
                    valueToSet="img_url"
                    setValue={setValue}
                    files={files}
                    setFiles={setFiles}
                    filesLimit={1}
                    manualRevoke
                    acceptedFileType="image"
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          <Typography sx={{ my: 2, ml: 1 }}>
            {t("inventory.items.type")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    control={control}
                    name="process_type"
                    documentType="item"
                    error={errors.process_type}
                    defaultOptions={[]}
                    title={t("inventory.items.processType")}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    control={control}
                    name="item_type"
                    documentType="item"
                    error={errors.item_type}
                    defaultOptions={[]}
                    title={t("inventory.items.type")}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    control={control}
                    name="item_kind"
                    documentType="item"
                    error={errors.item_kind}
                    defaultOptions={[]}
                    title={t("inventory.items.kind")}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    name="uom"
                    documentType="item"
                    control={control}
                    error={errors.uom}
                    defaultOptions={[]}
                    title={t("inventory.unit")}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    control={control}
                    name="collection_name"
                    documentType="item"
                    error={errors.collection_name}
                    defaultOptions={[]}
                    title={"ชื่อคอลเลคชั่น"}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledTextField
                    name="type_name"
                    label={"ชื่อแบบ"}
                    control={control}
                    error={errors.type_name}
                    disabled={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    control={control}
                    name="design_owner"
                    documentType="item"
                    error={errors.design_owner}
                    defaultOptions={[]}
                    title={"เจ้าของแบบ"}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    control={control}
                    name="design_type"
                    documentType="item"
                    error={errors.design_type}
                    defaultOptions={[]}
                    title={"หมวดแบบ"}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledTextField
                    name="design_code"
                    label={"รหัสแบบ"}
                    control={control}
                    error={errors.design_code}
                    disabled={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledTextField
                    name="block"
                    label={"จำนวนบล็อก"}
                    control={control}
                    error={errors.block}
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!hasVariation && (
            <>
              <Typography sx={{ my: 2, ml: 1 }}>{"ราคา"}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ControlledNumberTextField
                        name="shirt_cost"
                        control={control}
                        error={errors.shirt_cost}
                        disabled={viewOnly}
                        label={"ราคาทุนเสื้อ"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ControlledNumberTextField
                        name="sale_price"
                        control={control}
                        error={errors.sale_price}
                        disabled={viewOnly}
                        label={t("inventory.items.pricePerUnit")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ControlledNumberTextField
                        name="other_cost"
                        control={control}
                        error={errors.other_cost}
                        disabled={viewOnly}
                        label={"ราคาทุนอื่นๆ"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Typography sx={{ my: 2, ml: 1 }}>{"ผลิต"}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    control={control}
                    name="supplier"
                    documentType="item"
                    error={errors.supplier}
                    defaultOptions={[]}
                    title={"ผู้ผลิต"}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledSSCreatable
                    control={control}
                    name="process"
                    documentType="item"
                    error={errors.process}
                    defaultOptions={[]}
                    title={"กระบวนการ"}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomizedBox>
        {!isItemPage && <Divider />}
        <CustomizedBox boxShadow={!isItemPage ? "none" : undefined}>
          <Typography fontWeight="bold" ml={1}>
            {"สถานะ"}
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Controller
                name="is_active"
                control={control}
                render={({ field }) => (
                  <Grid item xs={12}>
                    <CustomizedRadioGroup
                      radioList={statusRadiosList}
                      row
                      {...field}
                      onChange={(e) => {
                        handleChangeActiveStatus(e.target.value);
                        return field.onChange(e.target.value);
                      }}
                      disabled={viewOnly}
                    />
                  </Grid>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ControlledTextField
                name="inactive_remark"
                label={"หมายเหตุสถานะ"}
                control={control}
                error={errors.inactive_remark}
                disabled={viewOnly}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
        <ModalUI
          title={`ยืนยันหากต้องการปิดการใช้งานสินค้า`}
          open={openActiveConfirmationModal}
          handleClose={handleCancelActiveStatus}
          maxWidth="sm"
        >
          <Typography>
            หากปิดการใช้งานแล้ว รายการตัวเลือกนี้จะไม่แสดงในรายการเพิ่มสินค้า
            ของใบสั่งขาย
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              size="medium"
              onClick={handleCancelActiveStatus}
              sx={{ mr: 2 }}
            />
            <CustomizedButton
              title="ยืนยัน"
              variant="contained"
              onClick={handleDisableActiveStatus}
              size="medium"
            />
          </Box>
        </ModalUI>
      </>
    );
  }
);

export default GeneralTab;
