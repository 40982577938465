import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import AgGrid from "../../../components/Table/AgGrid";
import { salesOrderColumnDef } from "../../../components/Table/ColumnDefs/Sales/Order";
import { getAllSalesOrders } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { salesOrderActions } from "../../../features/Sales/SalesOrder/sales-order-slice";
import { useAuth } from "../../../hooks/use-auth";
import { dateFilterModel } from "../../../utils/dataTransformer";
import SalesService from "../../../services/Sales";

const SalesOrder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const navigate = useNavigate();
  const [tagOptions, setTagOptions] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const { permissions } = useAuth();
  const salesPermission = permissions.sales;

  useEffect(() => {
    if (!isInit) {
      const getTags = async () => {
        const loadedSalesOrders = await SalesService.getSalesOrderTags();
        const formattedTags = loadedSalesOrders
          .map((salesOrder) => salesOrder.tag_list)
          .flat();
        const uniqueTags = [...new Set(formattedTags)];
        setTagOptions([...uniqueTags, ""]);
      };
      getTags();
      setIsInit(true);
    }
  }, [isInit]);

  const rowSelectHandler = (params) => {
    const { unique_id } = params.data;
    navigate(`/sales/order/${encodeURIComponent(unique_id)}`);
  };

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.order.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_customer_accept"),
      path: `${pathname}?filter=wait_customer_accept`,
    },
    {
      label: t("status.wait_payment_when_receive"),
      path: `${pathname}?filter=wait_payment_when_receive`,
    },
    {
      label: t("status.wait_payment_cod"),
      path: `${pathname}?filter=wait_payment_cod`,
    },
    {
      label: t("status.partially_paid"),
      path: `${pathname}?filter=partially_paid`,
    },
    {
      label: t("status.fully_paid"),
      path: `${pathname}?filter=fully_paid`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const filterModel = {
        ...request.filterModel,
        unique_id: {
          ...request.filterModel.unique_id,
          mode: "insensitive",
        },
        created_date: dateFilterModel(request.filterModel.created_date),
        payment_date: dateFilterModel(request.filterModel.payment_date),
        due_date: dateFilterModel(request.filterModel.due_date),
        receive_date: dateFilterModel(request.filterModel.receive_date),
        tag_list: request.filterModel.tag_list
          ? {
              filterType: "array",
              type: "hasSome",
              values:
                request.filterModel.tag_list?.values?.filter((tag) => tag)
                  .length > 0
                  ? request.filterModel.tag_list?.values
                  : [],
            }
          : undefined,
        is_delivery:
          request.filterModel.is_delivery?.values?.length > 0
            ? {
                filterType: "boolean",
                type: "equals",
                filter: request.filterModel.is_delivery?.values?.includes(
                  "จัดส่งแล้ว"
                )
                  ? "true"
                  : "false",
              }
            : undefined,
        is_open_manufacture:
          request.filterModel.is_open_manufacture?.values?.length > 0
            ? {
                filterType: "boolean",
                type: "equals",
                filter:
                  request.filterModel.is_open_manufacture?.values?.includes(
                    "เปิดแล้ว"
                  )
                    ? "true"
                    : "false",
              }
            : undefined,
      };
      dispatch(
        getAllSalesOrders(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("render_status");
      switch (isFilter) {
        case "draft":
          instance.setModel({ values: ["draft"] });
          break;
        case "wait_customer_accept":
          instance.setModel({ values: ["wait_customer_accept"] });
          break;
        case "wait_payment_when_receive":
          instance.setModel({ values: ["wait_payment_when_receive"] });
          break;
        case "wait_payment_cod":
          instance.setModel({ values: ["wait_payment_cod"] });
          break;
        case "partially_paid":
          instance.setModel({ values: ["partially_paid"] });
          break;
        case "fully_paid":
          instance.setModel({ values: ["fully_paid"] });
          break;
        case "finished":
          instance.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance.setModel({ values: ["cancelled"] });
          break;
        default:
          instance.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(salesOrderActions.resetAllSalesOrders());
    };
  }, [dispatch, onFilterChanged]);

  return (
    <>
      {salesPermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("sales.order.index")}</Typography>
        <CustomizedButton
          title={"สร้างใบสั่งขาย"}
          variant="contained"
          onClick={() => navigate(`${pathname}/add`)}
        />
      </Box>
      <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
      <AgGrid
        ref={gridRef}
        columnDefs={salesOrderColumnDef(t, false, tagOptions)}
        onGridReady={onGridReady}
        height={649}
        onRowDoubleClicked={rowSelectHandler}
        test-id={"sales-order-table"}
        path="/sales/order"
      />
    </>
  );
};

export default SalesOrder;
