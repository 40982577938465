import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const ManufactureItemUpdateConfirmation = ({ open, handleClose, onUpdate }) => {
  return (
    <ModalUI
      title="ต้องการอัปเดตราคาทุนสินค้าตามแบรนด์ที่เลือกใหมใช่หรือไม่"
      open={open}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <Typography>
        หากเลือก ใช่ - ราคาทุนของสินค้าด้านล่าง จะถูกเปลี่ยนตามที่ตั้งค่าไว้ตาม
        แบรนด์/ผู้ผลิตตามที่เลือกใหม่
      </Typography>
      <Typography mt={2}>
        หากเลือก ไม่ - ราคาทุนของสินค้าด้านล่างจะเหมือนเดิม
        แต่แบรนด์/ผู้ผลิตจะถูกเปลี่ยนตามที่เลือกใหม่
      </Typography>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ไม่"
          variant="outlined"
          onClick={handleClose}
        />
        <CustomizedButton title="ใช่" variant="contained" onClick={onUpdate} />
      </Box>
    </ModalUI>
  );
};

export default ManufactureItemUpdateConfirmation;
