import { useState, useEffect, useMemo, useRef } from "react";
import {
  useLocation,
  useNavigate,
  Navigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createItem,
  getItem,
  updateItem,
} from "../../../features/Inventory/inventory-actions";
import { useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../hooks/use-auth";
import ItemForm from "../../../components/Form/Item";
import {
  initialState,
  validation,
} from "../../../features/Inventory/inventory-slice";
import { formatItem } from "../../../utils/dataTransformer";
import InventoryService from "../../../services/Inventory";
import { useTranslation } from "react-i18next";

const ItemContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { item } = useSelector((state) => state.inventory);
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions } = useAuth();
  const generalSectionRef = useRef();
  const [isInit, setIsInit] = useState(false);
  const [mode, setMode] = useState("สร้าง");
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialState.item },
    resolver: yupResolver(validation),
  });
  const isEditPage = useMemo(
    () => pathname.split("/")[3] !== "add",
    [pathname]
  );

  const inventoryPermission = permissions?.inventory;

  const watchVariation = useWatch({ control, name: "is_variation" });
  const hasVariation = watchVariation && watchVariation !== "false";

  useEffect(() => {
    if (!isInit) {
      const loadItem = async () => {
        if (id) {
          const loadItemPayload = { unique_id: id };
          await dispatch(getItem(loadItemPayload, enqueueSnackbar, navigate));
          if (item.document_id !== "") {
            const formattedItem = formatItem(item);
            reset({
              ...initialState.item,
              ...formattedItem,
            });
          }
        } else {
          dispatch(getItem("init", enqueueSnackbar));
        }
        setIsInit(true);
      };
      loadItem();
    }
  }, [dispatch, id, enqueueSnackbar, item, isInit]);

  const onResetForm = async () => {
    if (item) {
      const formattedItem = formatItem(item);
      reset({
        ...formattedItem,
      });
    } else {
      reset({ ...initialState.item });
    }
    generalSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const submitHandler = async (data) => {
    if (isEditPage) {
      const { unique_id } = data;
      dispatch(
        updateItem(
          { unique_id: item.unique_id || unique_id },
          { ...data, unique_id: undefined },
          user,
          enqueueSnackbar,
          setMode,
          setIsInit,
          hasVariation
        )
      );
    } else {
      const isAlreadyHasId = await InventoryService.getAllItemsAgGrid({
        startRow: 0,
        endRow: 10,
        filterModel: {
          unique_id: {
            filter: data.unique_id,
            filterType: "text",
            type: "equals",
          },
        },
      });
      if (isAlreadyHasId.results.length > 0)
        return enqueueSnackbar(t("inventory.sentence.errorHasUniqueId"), {
          variant: "error",
        });
      else
        dispatch(
          createItem(
            data,
            user,
            navigate,
            setIsInit,
            enqueueSnackbar,
            hasVariation
          )
        );
    }
  };

  const errorHandler = (err) => {
    const itemIdError = getFieldState("unique_id").error;
    const itemNameError = getFieldState("name").error;
    if (itemIdError) {
      enqueueSnackbar(itemIdError.message, {
        variant: "error",
      });
    }
    if (itemNameError) {
      enqueueSnackbar(itemNameError.message, {
        variant: "error",
      });
    }
    if (itemIdError || itemNameError) {
      generalSectionRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    console.log(err);
  };

  return (
    <>
      {inventoryPermission === "NONE" && <Navigate to="/no-permission" />}
      <ItemForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        viewOnly={
          (isEditPage && mode !== "แก้ไข") ||
          inventoryPermission === "VIEW__ONLY"
        }
        onSubmit={handleSubmit(submitHandler, errorHandler)}
        onReset={onResetForm}
        isEditPage={isEditPage}
        isItemPage
        mode={mode}
        setMode={setMode}
        isInit={isInit}
        ref={generalSectionRef}
        id={id}
      />
    </>
  );
};

export default ItemContainer;
