import { gql } from "graphql-request";

export const CREATE_MANUFACTURE_ORDER = gql`
  mutation ManufactureOrderCreate($createInput: ManufactureOrderCreateInput!) {
    manufactureOrderCreate(createInput: $createInput) {
      id
      unique_id
      creator_unique_id
      external_ref_document_id
      employee_list {
        unique_id
        first_name
        last_name
        img_url
      }
      created_by {
        unique_id
        first_name
        last_name
        img_url
      }
      created_date
      payment_date
      production_date
      delivery_date
      receive_date
      vendor_unique_id
      vendor_name
      vendor {
        id
        unique_id
        is_vendor
        is_customer
        is_active
        inactive_remark
        creator_unique_id
        created_date
        identity_no
        contact_type
        name
        last_name
        title
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          name
          phone
          is_default_address
          same_as_default_address
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
        }
        tag_list
      }
      vendor_phone
      brand_name
      tag_list
      customer_unique_id
      customer_name
      customer {
        id
        unique_id
        is_vendor
        is_customer
        is_active
        inactive_remark
        creator_unique_id
        created_date
        identity_no
        contact_type
        name
        last_name
        title
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          name
          phone
          is_default_address
          same_as_default_address
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
        }
        tag_list
      }
      customer_phone_main
      customer_phone_sub
      customer_email
      balance_cod
      delivery_channel
      delivery_address
      tracking_number
      status
      main_status
      sub_status
      flag_status
      render_status
      item_list {
        id
        item_unique_id
        item_name
        process_type
        item_kind
        item_type
        block
        source_ready_shirt
        other_cost
        printing_cost
        hot_rolled_cost
        fold_cost
        all_cost
        sku_name
        color
        size
        arm
        neck
        amount
        uom
        item_remark
        manufacture_order_unique_id
        img_url
        sort_index
      }
      total_printing_cost
      total_hot_rolled_cost
      total_fold_cost
      total_all_cost
      total_other_cost
      total_amount
      remark
      reject_remark
    }
  }
`;

export const UPDATE_MANUFACTURE_ORDER = gql`
  mutation ManufactureOrderUpdate(
    $uniqueInput: ManufactureOrderUniqueInput!
    $updateInput: ManufactureOrderUpdateInput!
  ) {
    manufactureOrderUpdate(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      unique_id
      creator_unique_id
      external_ref_document_id
      employee_list {
        unique_id
        first_name
        last_name
        img_url
      }
      created_by {
        unique_id
        first_name
        last_name
        img_url
      }
      created_date
      payment_date
      production_date
      delivery_date
      receive_date
      vendor_unique_id
      vendor_name
      vendor {
        id
        unique_id
        is_vendor
        is_customer
        is_active
        inactive_remark
        creator_unique_id
        created_date
        identity_no
        contact_type
        name
        last_name
        title
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          name
          phone
          is_default_address
          same_as_default_address
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
        }
        tag_list
      }
      vendor_phone
      brand_name
      tag_list
      customer_unique_id
      customer_name
      customer {
        id
        unique_id
        is_vendor
        is_customer
        is_active
        inactive_remark
        creator_unique_id
        created_date
        identity_no
        contact_type
        name
        last_name
        title
        gender
        main_interested_field
        secondary_interested_field
        business_group_field
        business_type_field
        customer_quality
        contact_remark
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          name
          phone
          is_default_address
          same_as_default_address
          address_number
          building
          sub_district
          district
          province
          postal_code
          zone
          country
        }
        tag_list
      }
      customer_phone_main
      customer_phone_sub
      customer_email
      balance_cod
      delivery_channel
      delivery_address
      tracking_number
      status
      main_status
      sub_status
      flag_status
      render_status
      item_list {
        id
        item_unique_id
        item_name
        process_type
        item_kind
        item_type
        block
        source_ready_shirt
        other_cost
        printing_cost
        hot_rolled_cost
        fold_cost
        all_cost
        sku_name
        color
        size
        arm
        neck
        amount
        uom
        item_remark
        manufacture_order_unique_id
        img_url
        sort_index
      }
      total_printing_cost
      total_hot_rolled_cost
      total_fold_cost
      total_all_cost
      total_other_cost
      total_amount
      remark
      reject_remark
    }
  }
`;
