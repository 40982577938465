import { useState, useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Box, Divider, IconButton, Typography } from "@mui/material";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedCheckboxes from "../../../Custom/CustomizedCheckboxes";
import CustomizedFreeSolo from "../../../Custom/CustomizedFreeSolo";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CloseIcon from "@mui/icons-material/Close";
import ModalUI from "../../../UI/ModalUI";
import ControlledAddressBox from "../../../Controlled/ControlledAddressBox";
import LabelInput from "../../../UI/LabelInput";

const defaultNewAddressValues = {
  address_type: "",
  is_default_address: false,
  same_as_default_address: false,
  name: "",
  phone: "",
  address_number: "",
  building: "",
  sub_district: "",
  district: "",
  province: "",
  postal_code: "",
  zone: "",
  country: "",
};

const NewAddress = ({
  control,
  errors,
  setValue,
  getValues,
  remove,
  viewOnly,
  itemId,
  index,
  isSameAddress,
}) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addressValue, setAddressValue] = useState("");
  const [addressSnapshot, setAddressSnapshot] = useState({});

  const defaultAddressValue = useWatch({
    control,
    name: `address_list.0`,
  });

  const isSameAsDefault = useWatch({
    control,
    name: `address_list.${index}.same_as_default_address`,
  });

  useEffect(() => {
    if (isSameAsDefault) {
      const currentAddress = getValues(`address_list.${index}`);
      const newAddressValue = {
        ...defaultAddressValue,
        name: currentAddress.name,
        phone: currentAddress.phone,
        country: defaultAddressValue.country,
        is_default_address: false,
        same_as_default_address: true,
        address_type: index === 1 ? "ที่อยู่จัดส่งสินค้า" : "",
      };
      setValue(`address_list.${index}`, newAddressValue);
      setAddressValue(newAddressValue);
    }
  }, [defaultAddressValue, index, isSameAsDefault, setValue, getValues]);

  // useEffect(() => {
  //   let filteredAddressData = addressData;
  //   if (zone) {
  //     filteredAddressData = addressData.filter((item) => item.zone === zone);
  //   }
  //   if (province) {
  //     filteredAddressData = addressData.filter((item) =>
  //       item.province.includes(province)
  //     );
  //   }
  //   if (district) {
  //     filteredAddressData = addressData.filter((item) =>
  //       item.district.includes(district)
  //     );
  //   }
  //   if (subdistrict) {
  //     filteredAddressData = addressData.filter((item) =>
  //       item.sub_district.includes(subdistrict)
  //     );
  //   }
  //   let filteredZones = [];
  //   filteredAddressData.forEach((item) => {
  //     if (!filteredZones.includes(item.zone)) {
  //       filteredZones = [...filteredZones, item.zone];
  //     }
  //   });
  //   setZoneOptions(filteredZones);

  //   let filteredProvinces = [];
  //   filteredAddressData.forEach((item) => {
  //     if (!filteredProvinces.includes(item.province)) {
  //       filteredProvinces = [...filteredProvinces, item.province];
  //     }
  //   });
  //   setProvinceOptions(filteredProvinces);

  //   let filteredDistricts = [];
  //   filteredAddressData.forEach((item) => {
  //     if (!filteredDistricts.includes(item.district)) {
  //       filteredDistricts = [...filteredDistricts, item.district];
  //     }
  //   });
  //   setDistrictOptions(filteredDistricts);

  //   let filteredSubDistricts = [];
  //   filteredAddressData.forEach((item) => {
  //     if (!filteredSubDistricts.includes(item.sub_district)) {
  //       filteredSubDistricts = [...filteredSubDistricts, item.sub_district];
  //     }
  //   });
  //   setSubdistrictOptions(filteredSubDistricts);
  // }, [district, subdistrict, province, zone]);

  const countryOptions = ["ไทย"];

  const autofillAddress = (index) => {
    // setModalIsOpen(false);
    // let contactName;
    // const addressList = getValues("address_list");
    // const contactType = getValues("contact_type");
    // if (contactType === "นิติบุคคล") {
    //   contactName = getValues("name");
    // } else if (contactType === "บุคคลธรรมดา") {
    //   contactName = getValues("name") + " " + getValues("last_name");
    // } else {
    //   contactName = null;
    // }
    // const contactChannelPhone = getValues("contact_channel_list").filter(
    //   (channel) => channel.contact_channel_type === "เบอร์โทรศัพท์"
    // )[0]?.contact_channel_name;
    // const formattedContactChannelPhone =
    //   contactChannelPhone?.toString()?.length > 0
    //     ? `0${contactChannelPhone}`
    //     : "";
    // const mainAddress = addressList[0];
    // const currentAddress = addressList[index];
    // setAddressSnapshot(currentAddress);
    // const updatedAddress = {
    //   is_default_address: false,
    //   same_as_default_address: currentAddress.same_as_default_address,
    //   address_type: currentAddress.address_type,
    //   name: contactName?.trim() ?? currentAddress.name,
    //   phone: contactChannelPhone?.trim()
    //     ? formattedContactChannelPhone
    //     : currentAddress.phone,
    //   address_number: mainAddress.address_number,
    //   building: mainAddress.building,
    //   sub_district: mainAddress.sub_district,
    //   district: mainAddress.district,
    //   province: mainAddress.province,
    //   postal_code: mainAddress.postal_code,
    //   zone: mainAddress.zone,
    //   country: mainAddress.country,
    // };
    // setValue(`address_list.${index}`, updatedAddress);
  };

  const cancelAutofillAddress = () => {
    setValue(`address_list.${index}.same_as_default_address`, false);
    setModalIsOpen(false);
  };

  // const clearAutofill = (index) => {
  //   setValue(`address_list.${index}`, {
  //     ...addressSnapshot,
  //   });
  // };

  return (
    <>
      <Box sx={{ m: 4 }}>
        <Divider variant="fullWidth" />
      </Box>
      {index === 1 && (
        <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
          ที่อยู่จัดส่ง
        </Typography>
      )}
      <Grid container spacing={2}>
        {index !== 1 && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
                <ControlledTextField
                  name={`address_list.${index}.address_type`}
                  label={t("contact.address.index")}
                  control={control}
                  error={errors.address_list?.[index]?.address_type}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item>
                {!viewOnly && index !== 1 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => remove(index)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Controller
              key={`same_address-${itemId}`}
              name={`address_list.${index}.same_as_default_address`}
              control={control}
              render={({ field }) => (
                <CustomizedCheckboxes
                  label={"เหมือนที่อยู่หลัก"}
                  {...field}
                  onChange={(e, selected) => {
                    // if (selected) {
                    //   setModalIsOpen(true);
                    // } else {
                    //   clearAutofill(index);
                    // }
                    let contactName;
                    if (selected) {
                      const contactType = getValues("contact_type");
                      if (contactType === "นิติบุคคล") {
                        contactName = getValues("name");
                      } else if (contactType === "บุคคลธรรมดา") {
                        contactName =
                          getValues("name") + " " + getValues("last_name");
                      } else {
                        contactName = null;
                      }
                      const contactChannelPhone = getValues(
                        "contact_channel_list"
                      ).filter(
                        (channel) =>
                          channel.contact_channel_type === "เบอร์โทรศัพท์"
                      )[0]?.contact_channel_name;
                      const formattedContactChannelPhone =
                        contactChannelPhone?.toString()?.length > 0
                          ? `0${contactChannelPhone}`
                          : "";
                      setValue(`address_list.${index}.name`, contactName);
                      setValue(
                        `address_list.${index}.phone`,
                        formattedContactChannelPhone
                      );
                    }
                    return field.onChange(selected);
                  }}
                  isDisabled={viewOnly}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {viewOnly ? (
                  <LabelInput
                    label={t(`contact.contact_person.name.first_name`)}
                    value={getValues(`address_list.${index}.name`)}
                  />
                ) : (
                  <ControlledTextField
                    name={`address_list.${index}.name`}
                    label={t("contact.contact_person.name.first_name")}
                    control={control}
                    error={errors.address_list?.[index]?.name}
                    disabled={viewOnly}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {viewOnly ? (
                  <LabelInput
                    label={t(`contact.address.phone_number`)}
                    value={getValues(`address_list.${index}.phone`)}
                  />
                ) : (
                  <ControlledTextField
                    name={`address_list.${index}.phone`}
                    label={t("contact.address.phone_number")}
                    control={control}
                    error={errors.address_list?.[index]?.phone}
                    disabled={viewOnly}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            {viewOnly || isSameAddress[index] ? (
              <LabelInput
                label={t(`contact.address.primary`)}
                value={getValues(`address_list.${index}.address_number`)}
              />
            ) : (
              <ControlledTextField
                name={`address_list.${index}.address_number`}
                label={t("contact.address.primary")}
                control={control}
                error={errors.address_list?.[index]?.address_number}
                disabled={viewOnly || isSameAddress[index]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {viewOnly || isSameAddress[index] ? (
              <LabelInput
                label={t(`contact.address.secondary`)}
                value={getValues(`address_list.${index}.building`)}
              />
            ) : (
              <ControlledTextField
                name={`address_list.${index}.building`}
                label={t("contact.address.secondary")}
                control={control}
                error={errors.address_list?.[index]?.building}
                disabled={viewOnly || isSameAddress[index]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.${index}`}
              type="sub_district"
              disabled={isSameAsDefault}
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly || isSameAddress[index]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.${index}`}
              type="district"
              disabled={isSameAsDefault}
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly || isSameAddress[index]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.${index}`}
              type="province"
              disabled={isSameAsDefault}
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly || isSameAddress[index]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.${index}`}
              type="postal_code"
              disabled={isSameAsDefault}
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly || isSameAddress[index]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address_list.${index}`}
              type="zone"
              disabled={isSameAsDefault}
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={viewOnly || isSameAddress[index]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {viewOnly || isSameAddress[index] ? (
              <LabelInput
                label={t(`contact.address.country`)}
                value={getValues(`address_list.${index}.country`)}
              />
            ) : (
              <Controller
                key={`country-${itemId}`}
                name={`address_list.${index}.country`}
                control={control}
                render={({ field }) => {
                  if (isSameAddress[index]) {
                    return (
                      <CustomizedTextField
                        fullWidth
                        error={Boolean(errors.address_list?.[index]?.country)}
                        helperText={
                          errors.address_list?.[index]?.country?.message
                        }
                        label={t("contact.address.country")}
                        {...field}
                        disabled={true}
                      />
                    );
                  }
                  return (
                    <CustomizedFreeSolo
                      {...field}
                      options={countryOptions}
                      title={t("contact.address.country")}
                      onInputChange={(e, inputValue) => {
                        return field.onChange(inputValue);
                      }}
                      onChange={(e, option) => {
                        if (!option) {
                          return field.onChange("");
                        }
                        if (option.value) {
                          return field.onChange(option.value);
                        }
                        if (option.inputValue) {
                          return field.onChange(option.inputValue);
                        }
                        return field.onChange(option);
                      }}
                      disabled={viewOnly || isSameAddress[index]}
                    />
                  );
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <ModalUI
        open={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        maxWidth="xs"
      >
        <Typography fontWeight="bold">
          ยืนยันหากต้องการระบุที่อยู่เหมือนกับที่อยู่หลัก
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <CustomizedButton
            title={"ยกเลิก"}
            variant="outlined"
            size="medium"
            onClick={cancelAutofillAddress}
          />
          <CustomizedButton
            sx={{ ml: 1 }}
            title={"ยืนยัน"}
            variant="contained"
            size="medium"
            onClick={() => autofillAddress(index)}
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default NewAddress;
