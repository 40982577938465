import {
  Avatar,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFieldArray, useWatch } from "react-hook-form";
import CustomizedButton from "../Custom/CustomizedButton";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useRef, useState } from "react";
import CheckboxModalTable from "./CheckboxModalTable";
import { useTranslation } from "react-i18next";
import { itemSkuColumnDefs } from "./ColumnDefs/Inventory/Items/sku";
import { getAllItemsSku } from "../../features/Inventory/inventory-actions";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CustomizedBox } from "../Custom/CustomizedBox";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ControlledTextField from "../Controlled/ControlledTextField";
import { formatNumber } from "../../utils/dataTransformer";
import ControlledServerSideCreatable from "../Controlled/ControlledSSCreatable";
import ItemInfoModalUI from "../Form/Order/ItemInfoModalUI";
import ModalUI from "../UI/ModalUI";
import { getManufactureItemSettings } from "../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TocIcon from "@mui/icons-material/Toc";

const MOItemTable = ({
  control,
  errors,
  disabled,
  setValue,
  isReport,
  existingItemIds,
}) => {
  const [header, setHeader] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { manufactureItemSettings } = useSelector(
    (state) => state.manufactureOrder
  );
  const selectItemListRef = useRef();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openItemListModal, setOpenItemListModal] = useState(false);
  const [openItemInfoModal, setOpenItemInfoModal] = useState(false);
  const [openItemDeleteConfirmation, setOpenItemDeleteConfirmation] =
    useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [itemId, setItemId] = useState(null);

  const [itemListIds, setItemListIds] = useState([]);
  const [itemListIdsSnapshot, setItemListIdsSnapshot] = useState([]);

  const watchVendorUniqueId = useWatch({ control, name: "vendor_unique_id" });
  const watchBrandName = useWatch({ control, name: "brand_name" });

  const { fields, remove, replace, move } = useFieldArray({
    control,
    name: "item_list",
  });

  const watchFieldArray = useWatch({ control, name: "item_list" });

  const openItemListModalHandler = () => {
    setOpenItemListModal(true);
  };

  const closeItemListModalHandler = () => {
    setOpenItemListModal(false);
  };

  const getRowId = useCallback((params) => {
    return params.data.sku_name;
  }, []);

  const openItemInfoModalHandler = (itemUniqueId) => {
    if (existingItemIds.includes(itemUniqueId)) {
      setItemId(itemUniqueId);
      setOpenItemInfoModal(true);
    } else {
      enqueueSnackbar("สินค้าถูกลบออกจากระบบแล้ว", { variant: "error" });
    }
  };

  const closeItemInfoModalHandler = () => {
    searchParams.delete("itemTab");
    setSearchParams(searchParams);
    setOpenItemInfoModal(false);
  };

  useEffect(() => {
    if (fields.length > 0 && itemListIds.length === 0 && !openItemListModal) {
      const ids = fields.map((field) => field.sku_name);
      setItemListIds(ids);
      setItemListIdsSnapshot(ids);
    }
  }, [fields, itemListIds, itemListIdsSnapshot, openItemListModal]);

  useEffect(() => {
    if (disabled) {
      setHeader([
        { label: "รายการ", width: isReport ? 40 : 75 },
        { label: "รูป", width: 90 },
        {
          label: "รหัสสินค้า / SKU - ชื่อสินค้า",
          width: isReport ? 130 : 325,
        },
        { label: "ชนิดสินค้า", width: isReport ? 50 : 120 },
        { label: "แหล่งเสื้อ", width: isReport ? 80 : 150 },
        { label: "ทุนอื่นๆ", width: isReport ? 60 : 100 },
        { label: "บล็อก", width: isReport ? 60 : 100 },
        { label: "ทุนพิมพ์", width: isReport ? 60 : 100 },
        { label: "ทุนทับร้อน", width: isReport ? 60 : 100 },
        { label: "ทุนพับ", width: isReport ? 60 : 100 },
        { label: "ทุนผลิตรวม", width: isReport ? 80 : 100 },
        { label: "คอ", width: isReport ? 50 : 125 },
        { label: "จำนวน", width: isReport ? 40 : 100 },
        { label: "หน่วย", width: isReport ? 50 : 100 },
        { label: "หมายเหตุ", width: isReport ? 90 : 150 },
      ]);
    } else {
      setHeader([
        { label: "รายการ", width: isReport ? 40 : 75 },
        { label: "รูป", width: 90 },
        {
          label: "รหัสสินค้า / SKU - ชื่อสินค้า",
          width: isReport ? 130 : 325,
        },
        { label: "ชนิดสินค้า", width: isReport ? 50 : 120 },
        { label: "แหล่งเสื้อ", width: isReport ? 80 : 150 },
        { label: "ทุนอื่นๆ", width: isReport ? 60 : 100 },
        { label: "บล็อก", width: isReport ? 60 : 100 },
        { label: "ทุนพิมพ์", width: isReport ? 60 : 100 },
        { label: "ทุนทับร้อน", width: isReport ? 60 : 100 },
        { label: "ทุนพับ", width: isReport ? 60 : 100 },
        { label: "ทุนผลิตรวม", width: isReport ? 80 : 100 },
        { label: "คอ", width: isReport ? 50 : 125 },
        { label: "จำนวน", width: isReport ? 40 : 100 },
        { label: "หน่วย", width: isReport ? 50 : 100 },
        { label: "หมายเหตุ", width: isReport ? 90 : 150 },
        { label: "", width: 20 },
      ]);
    }
  }, [disabled]);

  useEffect(() => {
    dispatch(getManufactureItemSettings());
  }, [dispatch, getManufactureItemSettings]);

  const openItemDeleteModalHandler = (index) => {
    setOpenItemDeleteConfirmation(true);
    setIndexToDelete(index);
  };

  const closeItemDeleteHandler = () => {
    setOpenItemDeleteConfirmation(false);
    setIndexToDelete(null);
  };

  const deleteItemActionHandler = () => {
    removeItem(indexToDelete);
    setOpenItemDeleteConfirmation(false);
    setIndexToDelete(null);
  };

  const datasource = {
    async getRows(params) {
      const request = params.request;
      const isActiveFilter = request.filterModel?.["is_active"];
      const activeFilterValue =
        isActiveFilter?.values[0] === "active" ? "true" : "false";
      const filterModel = {
        sku_name: {
          ...request.filterModel.sku_name,
          mode: "insensitive",
        },
        item_name: {
          ...request.filterModel.item_name,
          mode: "insensitive",
        },
        item_desc: {
          ...request.filterModel.item_desc,
          mode: "insensitive",
        },
        process_type: {
          ...request.filterModel.process_type,
          mode: "insensitive",
        },
        item_kind: {
          ...request.filterModel.item_kind,
          mode: "insensitive",
        },
        item_type: {
          ...request.filterModel.item_type,
          mode: "insensitive",
        },
        is_active:
          isActiveFilter && isActiveFilter?.values?.length === 1
            ? {
                filterType: "boolean",
                type: "equals",
                filter: activeFilterValue,
              }
            : undefined,
      };
      const skuNameSortModel = request.sortModel?.find(
        (column) => column.colId === "sku_name"
      );
      const sortModel = [
        ...request.sortModel,
        {
          colId: "sku_name",
          sort: skuNameSortModel ? skuNameSortModel.sort : "desc",
        },
      ];
      dispatch(
        getAllItemsSku(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterModel,
            sortModel: sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const finishItemListSelectHandler = async (data) => {
    await dispatch(getManufactureItemSettings());

    if (data && data.length > 0) {
      let filteredItemList = watchFieldArray.filter((item) =>
        itemListIds.includes(item.sku_name)
      );

      data.forEach((item) => {
        const sku = item.sku;
        let img_url = sku.item.is_variation
          ? null
          : sku.item.img_url[0] || null;
        if (sku.item.is_variation) {
          const variant_value =
            sku.item_variant_value?.[0]?.item_variant_value_name;
          const colorList =
            sku.item.item_variant_key_list?.[0].item_variant_value_list.map(
              (color) => color.item_variant_value_name
            );

          const foundColorIndex = colorList?.indexOf(variant_value);
          if (foundColorIndex !== -1) {
            img_url =
              sku.item.item_variant_key_list?.[3].item_variant_value_list?.[
                foundColorIndex
              ]?.item_variant_value_name;
          }
        }
        const matchingItemPriceSetting = manufactureItemSettings.find(
          (setting) =>
            setting.brand_name === watchBrandName &&
            setting.vendor_unique_id === watchVendorUniqueId &&
            setting.item_type === sku.item.item_type &&
            setting.block === sku.item.block
        );
        const formatItemList = {
          item_name: sku.item.name,
          item_unique_id: sku.item_unique_id,
          process_type: sku.item.process_type,
          item_type: sku.item.item_type,
          block: sku.item.block,
          img_url,
          item_kind: sku?.sku_detail?.item_kind || "-",
          source_ready_shirt: "",
          other_cost: sku.sku_detail?.other_cost || 0,
          printing_cost: matchingItemPriceSetting?.printing_cost || 0,
          hot_rolled_cost: matchingItemPriceSetting?.hot_rolled_cost || 0,
          fold_cost: matchingItemPriceSetting?.fold_cost || 0,
          neck: "",
          amount: 1,
          uom: sku?.item?.uom || "-",
          item_remark: "",
          sku_name: sku.sku_name,
        };
        filteredItemList.push(formatItemList);
      });

      replace(filteredItemList);
    } 

    closeItemListModalHandler();
  };

  const removeItem = (index) => {
    remove(index);
    setItemListIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setItemListIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const TotalPrintingCost = ({ control, index }) => {
    const watchQty = useWatch({
      control,
      name: `item_list[${index}].amount`,
    });
    const watchPrintingCost = useWatch({
      control,
      name: `item_list[${index}].printing_cost`,
    });
    const totalPrintingCost =
      parseInt(watchQty) * parseFloat(watchPrintingCost) ?? 0;
    return (
      <TableCell
        align="right"
        sx={{
          py: isReport ? 1 : 2,
          px: isReport ? 0.25 : 2,
        }}
        width={header[7]?.width + 32}
      >
        <Typography sx={{ fontSize: isReport ? 11 : 14 }}>
          {totalPrintingCost ? formatNumber(totalPrintingCost) : 0}
        </Typography>
      </TableCell>
    );
  };

  const TotalHotRolledCost = ({ control, index }) => {
    const watchQty = useWatch({
      control,
      name: `item_list[${index}].amount`,
    });
    const watchHotRolledCost = useWatch({
      control,
      name: `item_list[${index}].hot_rolled_cost`,
    });
    const totalHotRolledCost =
      parseInt(watchQty) * parseFloat(watchHotRolledCost) ?? 0;
    return (
      <TableCell
        align="right"
        sx={{
          py: isReport ? 1 : 2,
          px: isReport ? 0.25 : 2,
        }}
        width={header[8]?.width + 32}
      >
        <Typography sx={{ fontSize: isReport ? 11 : 14 }}>
          {totalHotRolledCost ? formatNumber(totalHotRolledCost) : 0}
        </Typography>
      </TableCell>
    );
  };

  const TotalFoldCost = ({ control, index }) => {
    const watchQty = useWatch({
      control,
      name: `item_list[${index}].amount`,
    });
    const watchFoldCost = useWatch({
      control,
      name: `item_list[${index}].fold_cost`,
    });
    const totalFoldCost = parseInt(watchQty) * parseFloat(watchFoldCost);
    return (
      <TableCell
        align="right"
        sx={{
          py: isReport ? 1 : 2,
          px: isReport ? 0.25 : 2,
        }}
        width={header[9]?.width + 32}
      >
        <Typography sx={{ fontSize: isReport ? 11 : 14 }}>
          {totalFoldCost ? formatNumber(totalFoldCost) : 0}
        </Typography>
      </TableCell>
    );
  };

  const TotalCost = ({ control, index }) => {
    const watchQty = useWatch({
      control,
      name: `item_list[${index}].amount`,
    });
    const watchPrintingCost = useWatch({
      control,
      name: `item_list[${index}].printing_cost`,
    });
    const watchHotRolledCost = useWatch({
      control,
      name: `item_list[${index}].hot_rolled_cost`,
    });
    const watchFoldCost = useWatch({
      control,
      name: `item_list[${index}].fold_cost`,
    });
    const totalPrintingCost =
      parseInt(watchQty) * parseFloat(watchPrintingCost);
    const totalHotRolledCost =
      parseInt(watchQty) * parseFloat(watchHotRolledCost);
    const totalFoldCost = parseInt(watchQty) * parseFloat(watchFoldCost);

    const totalCost = parseFloat(
      totalPrintingCost + totalHotRolledCost + totalFoldCost
    );

    return (
      <TableCell
        align="right"
        sx={{
          py: isReport ? 1 : 2,
          px: isReport ? 0.25 : 2,
        }}
        width={header[10]?.width + 32}
      >
        <Typography sx={{ fontSize: isReport ? 11 : 14 }}>
          {formatNumber(totalCost || 0)}
        </Typography>
      </TableCell>
    );
  };

  const dragEndHandler = (result) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  return (
    <CustomizedBox
      padding={0}
      margin={isReport ? "1rem 0 0 0" : "2rem 0 0 0"}
      boxShadow={isReport ? "none" : undefined}
      sx={{ breakAfter: "auto" }}
    >
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "#EFF2FF" }}>
            <TableRow>
              {header.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: isReport ? 0.25 : 2,
                    py: isReport ? 1 : 2,
                  }}
                >
                  <Typography
                    width={header.width}
                    fontSize={isReport ? 11 : 14}
                    fontWeight={500}
                    // fontWeight={isReport ? 600 : 700}
                  >
                    {header.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((row, index) => {
                    const isItemExist = existingItemIds?.includes(
                      row.item_unique_id
                    );
                    return (
                      <Draggable
                        key={row.id}
                        draggableId={"item-" + row.id}
                        index={index}
                        isDragDisabled={disabled}
                      >
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              ...provided.draggableProps.style,
                              breakInside: "avoid",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[0]?.width + 32}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {!disabled && <TocIcon fontSize="small" />}
                                <Typography fontSize={isReport ? 11 : 14}>
                                  {index + 1}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[1]?.width + 32}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Avatar
                                  alt={row.item_name}
                                  src={row.img_url}
                                  sx={{
                                    width: 60,
                                    height: 60,
                                    border: "1px solid #BEBEBE",
                                    borderRadius: "2px",
                                  }}
                                  variant="square"
                                >
                                  <ImageOutlinedIcon
                                    sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                                    fontSize={isReport ? "small" : "medium"}
                                  />
                                </Avatar>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[2]?.width + 32}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: isReport ? 1 : 2,
                                  // alignItems: "center",
                                }}
                              >
                                {/* <Avatar
                        alt={row.item_name}
                        src={row.img_url}
                        sx={{
                          width: isReport ? 28 : 40,
                          height: isReport ? 28 : 40,
                          border: "1px solid #BEBEBE",
                        }}
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          fontSize={isReport ? "small" : "medium"}
                        />
                      </Avatar> */}
                                <Box
                                  onClick={() =>
                                    openItemInfoModalHandler(row.item_unique_id)
                                  }
                                >
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: "#32418F",
                                      textAlign: "left",
                                      fontSize: isReport ? 11 : 16,
                                    }}
                                  >
                                    {`${row.sku_name}`}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: "#32418F",
                                      textAlign: "left",
                                      fontSize: isReport ? 11 : 16,
                                    }}
                                  >
                                    {`${row.item_name}`}
                                  </Typography>
                                  {!isReport &&
                                    existingItemIds?.length > 0 &&
                                    !isItemExist && (
                                      <Typography
                                        sx={(theme) => ({
                                          mt: 1,
                                          fontSize: "0.75rem",
                                          color: theme.palette.error.main,
                                        })}
                                      >
                                        สินค้านี้ถูกลบออกจากระบบแล้ว
                                        กรุณาลบสินค้า
                                      </Typography>
                                    )}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[3]?.width + 32}
                            >
                              <Typography fontSize={isReport ? 11 : 14}>
                                {row.item_kind}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[4]?.width + 32}
                            >
                              {disabled ? (
                                <Typography
                                  sx={{ fontSize: isReport ? 11 : 14 }}
                                >
                                  {row.source_ready_shirt}
                                </Typography>
                              ) : (
                                <Box>
                                  <ControlledServerSideCreatable
                                    name={`item_list[${index}].source_ready_shirt`}
                                    fieldToSet="source_ready_shirt"
                                    control={control}
                                    error={Boolean(
                                      errors.item_list &&
                                        errors.item_list[index] &&
                                        errors.item_list[index]
                                          .source_ready_shirt
                                    )}
                                    helperText={
                                      errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index]
                                        .source_ready_shirt &&
                                      errors.item_list[index].message
                                    }
                                    viewOnly={disabled}
                                    documentType="manufacture_order"
                                    defaultOptions={[]}
                                    setValue={setValue}
                                  />
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[5]?.width + 32}
                            >
                              {disabled ? (
                                <Typography
                                  sx={{ fontSize: isReport ? 11 : 14 }}
                                >
                                  {formatNumber(row.other_cost)}
                                </Typography>
                              ) : (
                                <ControlledTextField
                                  type="number"
                                  name={`item_list[${index}].other_cost`}
                                  control={control}
                                  InputProps={{
                                    inputProps: {
                                      min: 1,
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                  error={Boolean(
                                    errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index].other_cost
                                  )}
                                  helperText={
                                    errors.item_list &&
                                    errors.item_list[index] &&
                                    errors.item_list[index].other_cost &&
                                    errors.item_list[index].message
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              width={header[6]?.width + 32}
                            >
                              <Typography sx={{ fontSize: isReport ? 11 : 14 }}>
                                {row.block}
                              </Typography>
                            </TableCell>
                            <TotalPrintingCost
                              control={control}
                              index={index}
                            />
                            <TotalHotRolledCost
                              control={control}
                              index={index}
                            />
                            <TotalFoldCost control={control} index={index} />
                            <TotalCost control={control} index={index} />
                            {/* <TableCell
                    align="center"
                    sx={{
                      py: isReport ? 1 : 2,
                      px: isReport ? 0.25 : 2,
                    }}
                  >
                    <Typography sx={{ fontSize: isReport ? 11 : 14 }}>
                      {row.sku_name}
                    </Typography>
                  </TableCell> */}
                            <TableCell
                              align="right"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[11]?.width + 32}
                            >
                              {disabled ? (
                                <Typography
                                  sx={{ fontSize: isReport ? 11 : 14 }}
                                >
                                  {row.neck}
                                </Typography>
                              ) : (
                                <ControlledServerSideCreatable
                                  name={`item_list[${index}].neck`}
                                  fieldToSet="neck"
                                  control={control}
                                  error={
                                    errors.item_list &&
                                    errors.item_list[index] &&
                                    errors.item_list[index].neck
                                  }
                                  viewOnly={disabled}
                                  documentType="manufacture_order"
                                  defaultOptions={[]}
                                  setValue={setValue}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[12]?.width + 32}
                            >
                              {disabled ? (
                                <Typography
                                  sx={{ fontSize: isReport ? 11 : 14 }}
                                >
                                  {formatNumber(row.amount, true)}
                                </Typography>
                              ) : (
                                <ControlledTextField
                                  type="number"
                                  name={`item_list[${index}].amount`}
                                  control={control}
                                  InputProps={{
                                    inputProps: {
                                      min: 1,
                                    },
                                  }}
                                  error={Boolean(
                                    errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index].amount
                                  )}
                                  helperText={
                                    errors.item_list &&
                                    errors.item_list[index] &&
                                    errors.item_list[index].amount &&
                                    errors.item_list[index].message
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[13]?.width + 32}
                            >
                              <Typography sx={{ fontSize: isReport ? 11 : 14 }}>
                                {row.uom}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                py: isReport ? 1 : 2,
                                px: isReport ? 0.25 : 2,
                              }}
                              width={header[14]?.width + 32}
                            >
                              {disabled ? (
                                <Typography
                                  sx={{ fontSize: isReport ? 11 : 14 }}
                                >
                                  {row.item_remark}
                                </Typography>
                              ) : (
                                <ControlledTextField
                                  name={`item_list[${index}].item_remark`}
                                  control={control}
                                  error={Boolean(
                                    errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index].item_remark
                                  )}
                                  helperText={
                                    errors.item_list &&
                                    errors.item_list[index] &&
                                    errors.item_list[index].item_remark &&
                                    errors.item_list[index].message
                                  }
                                />
                              )}
                            </TableCell>
                            {disabled ? null : (
                              <TableCell
                                align="center"
                                sx={{
                                  py: isReport ? 1 : 2,
                                  px: isReport ? 0.25 : 2,
                                }}
                                width={header[15]?.width + 32}
                              >
                                <IconButton
                                  onClick={() =>
                                    openItemDeleteModalHandler(index)
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!disabled && (
        <CustomizedButton
          sx={{ m: 2 }}
          title="เพิ่มสินค้า"
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={openItemListModalHandler}
        />
      )}
      <ItemInfoModalUI
        itemId={itemId}
        modalIsOpen={openItemInfoModal}
        closeModal={closeItemInfoModalHandler}
      />
      <CheckboxModalTable
        getRowId={getRowId}
        modalTitle={t("inventory.items.index")}
        btnTitle={t("button.add")}
        gridRef={selectItemListRef}
        height={450}
        columnDefs={itemSkuColumnDefs(t)}
        rowSelection="multiple"
        onFinishEditing={finishItemListSelectHandler}
        modalIsOpen={openItemListModal}
        closeModal={closeItemListModalHandler}
        onGridReady={onGridReady}
        selectedIds={itemListIds}
        setSelectedIds={setItemListIds}
        idsSnapshot={itemListIdsSnapshot}
        setIdsSnapshot={setItemListIdsSnapshot}
        allowDuplicates
      />
      <ModalUI
        title="ยืนยันการลบรายการสินค้า"
        open={openItemDeleteConfirmation}
        handleClose={closeItemDeleteHandler}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={closeItemDeleteHandler}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={deleteItemActionHandler}
          />
        </Box>
      </ModalUI>
    </CustomizedBox>
  );
};

export default MOItemTable;
