import { Box } from "@mui/material";
import { useRef } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../components/Table/AgGrid";
import { manufactureOrderColumnDef } from "../../../components/Table/ColumnDefs/Manufacture/Order";

const ManufactureTab = ({ control }) => {
  const { t } = useTranslation();
  const gridRef = useRef();

  const watchMOList = useWatch({
    control,
    name: "manufacture_order_list",
    defaultValue: [],
  });

  const rowSelectHandler = (params) => {
    const { unique_id } = params.data;
    window.open(
      `/manufacture/order/${encodeURIComponent(unique_id)}`,
      "_blank"
    );
  };

  return (
    <>
      <Box sx={{ minHeight: "50px" }}>
        <AgGrid
          ref={gridRef}
          columnDefs={manufactureOrderColumnDef(t)}
          rowData={watchMOList}
          onRowDoubleClicked={rowSelectHandler}
          height={359}
          disableFloatingFilter
        />
      </Box>
    </>
  );
};

export default ManufactureTab;
