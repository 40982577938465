import { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  List,
  ListItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  useNavigate,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useForm, useWatch, Controller } from "react-hook-form";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import ImporterDropzoneUI from "../../components/UI/ImporterDropzoneUI";
import CustomizedCheckboxes from "../../components/Custom/CustomizedCheckboxes";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import { CustomizedTooltip } from "../../components/Custom/CustomizedTooltip";
import ModalUI from "../../components/UI/ModalUI";
import ContactService from "../../services/Contact";
import AgGrid from "../../components/Table/AgGrid";
import { contactColumnDefs } from "../../components/Table/ColumnDefs/Contact";
import { useAuth } from "../../hooks/use-auth";
import { formatBoolean } from "../../utils/dataTransformer";

const keys = [
  "contact_channel_list",
  "contact_person_list",
  "address_list",
  "tag_list",
];

const columns = [
  "unique_id",
  "is_vendor",
  "is_customer",
  "contact_type",
  "identity_no",
  "title",
  "name",
  "last_name",
  "is_active",
  "inactive_remark",
  "tag_list",
  "gender",
  "main_interested_field",
  "secondary_interested_field",
  "business_group_field",
  "business_type_field",
  "customer_quality",
  "contact_remark",
  "contact_channel_list",
  "address_list",
  "contact_person_list",
];

const requiredKeys = ["unique_id", "name"];

const ContactImporterPage = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, permissions } = useAuth();
  const gridRef = useRef();
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState([]);
  const [formattedCreateOnlyData, setFormattedCreateOnlyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createData, setCreateData] = useState([]);
  const [updateOrSkipData, setUpdateOrSkipData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [createErrors, setCreateErrors] = useState([]);

  const { control } = useForm({
    defaultValues: { is_upsert: true },
  });
  const watchIsUpsert = useWatch({ control, name: "is_upsert" });

  const contactPermission = permissions.contact;

  const columnDefs = contactColumnDefs(t, true);

  const breadcrumbs = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: "นำเข้าผู้ติดต่อ",
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "updates":
        if (watchIsUpsert) {
          setDisplayedData(updateOrSkipData);
        } else {
          setDisplayedData([]);
        }
        break;
      case "skips":
        if (!watchIsUpsert) {
          setDisplayedData(updateOrSkipData);
        } else {
          setDisplayedData([]);
        }
        break;
      default:
        setDisplayedData(createData);
    }
    // setDisplayedData(flattenedSkuList);
  }, [watchIsUpsert, tab, createData, updateOrSkipData]);

  const tabs = [
    {
      label: `สร้าง (${createData.length})`,
      path: `${pathname}`,
    },
    {
      label: `แก้ไข (${watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=updates`,
    },
    {
      label: `ข้าม (${!watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=skips`,
    },
  ];

  const formatContactData = async (data) => {
    const formatAddressList = (addressList) => {
      const result = addressList
        ?.map((address, index) => {
          let address_type;
          if (!address_type && index === 0) {
            address_type = "ที่อยู่หลัก";
          } else if (!address_type && index === 1) {
            address_type = "ที่อยู่จัดส่ง";
          } else {
            address.address_type?.toString().trim() ?? "";
          }
          return {
            ...address,
            is_default_address: formatBoolean(address.is_default_address),
            same_as_default_address: formatBoolean(
              address.same_as_default_address
            ),
            address_type,
            address_number: address.address_number?.toString().trim() ?? "",
            building: address.building?.toString().trim() ?? "",
            sub_district: address.sub_district?.toString().trim() ?? "",
            district: address.district?.toString().trim() ?? "",
            province: address.province?.toString().trim() ?? "",
            name: address.name?.toString().trim() ?? "",
            phone: address.phone?.toString().trim() ?? "",
            postal_code: address.postal_code?.toString() ?? "",
            country: address.country?.replace("ประเทศ", "").trim() ?? "",
            zone: address.zone?.toString().trim() ?? "",
          };
        })
        .filter((address) => {
          return !Object.values(address).every((item) => !item);
        });
      return result;
    };

    const formatContactChannelList = (contactChannelList) => {
      const result = contactChannelList
        ?.map((channel) => {
          return {
            contact_channel_name:
              channel.contact_channel_name?.toString().trim() ?? undefined,
            contact_channel_type:
              channel.contact_channel_type?.toString().trim() ?? undefined,
          };
        })
        .filter((channel) => {
          return channel.contact_channel_name && channel.contact_channel_type;
        });
      return result;
    };

    const formatContactPersonList = (contactPersonList) => {
      const result = contactPersonList
        ?.map((person) => {
          return {
            name: person.name?.toString().trim() ?? undefined,
            phone: person.phone?.toString().trim() ?? undefined,
            position: person.position?.toString().trim() ?? undefined,
          };
        })
        .filter((person) => {
          return !Object.values(person).every(
            (item) => typeof item === "undefined"
          );
        });
      return result;
    };

    let missingCols;

    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      data.forEach((contact) => {
        if (
          typeof contact.is_customer !== "boolean" ||
          typeof contact.is_vendor !== "boolean" ||
          typeof contact.is_active !== "boolean"
          //  ||  (contact.address_list.length > 0 &&
          //     typeof contact.address_list[0].is_default_address !== "boolean")
        ) {
          throw new Error("boolean");
        }
      });

      const formattedData = data.map((item) => {
        return {
          ...item,
          unique_id: item.unique_id.toString().trim(),
          creator_unique_id: user.unique_id,
          created_date: undefined,
          last_updator_unique_id: undefined,
          last_updated_date: undefined,
          // last_updator_unique_id: item.last_updator_unique_id ?? undefined,
          // last_updated_date: item.last_updated_date
          //   ? dateToUnix(item.last_upadated_date)
          //   : undefined,
          is_vendor: formatBoolean(item.is_vendor),
          is_customer: formatBoolean(item.is_customer),
          identity_no: item.identity_no?.toString().trim() ?? "",
          is_active: formatBoolean(item.is_active, true),
          tag_list: item.tag_list ?? undefined,
          contact_remark: item.contact_remark ?? "",
          address_list: formatAddressList(item.address_list),
          contact_channel_list: formatContactChannelList(
            item.contact_channel_list
          ),
          contact_person_list: formatContactPersonList(
            item.contact_person_list
          ),
          gender: item.gender?.toString().trim() ?? "",
          main_interested_field:
            item.main_interested_field?.toString().trim() ?? "",
          secondary_interested_field:
            item.secondary_interested_field?.toString().trim() ?? "",
          business_group_field:
            item.business_group_field?.toString().trim() ?? "",
          business_type_field:
            item.business_type_field?.toString().trim() ?? "",
          customer_quality: item.customer_quality?.toString() ?? "",
        };
      });
      const existingContacts = await ContactService.getContactIds();
      const existingContactIds = existingContacts.map(
        (contact) => contact.unique_id
      );
      const contactsToCreate = [];
      const contactsToSkipOrUpdate = [];

      formattedData.forEach((contact) => {
        if (existingContactIds.includes(contact.unique_id)) {
          contactsToSkipOrUpdate.push(contact);
        } else {
          contactsToCreate.push(contact);
        }
      });
      setCreateData(contactsToCreate);
      setUpdateOrSkipData(contactsToSkipOrUpdate);
      setFormattedCreateOnlyData(
        formattedData.filter(
          (contact) => !existingContactIds.includes(contact.unique_id)
        )
      );

      return formattedData;
    } catch (err) {
      console.log(err);
      if (err.message === "template") {
        enqueueSnackbar(
          `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`,
          {
            variant: "error",
          }
        );
      } else if (err.message === "boolean") {
        enqueueSnackbar(
          `ข้อมูลใน column ไม่ตรงตามรูปแบบ\nกรุณาระบุ TRUE/FALSE`,
          {
            variant: "error",
            style: {
              whiteSpace: "pre-line",
            },
          }
        );
      } else {
        enqueueSnackbar(`ข้อมูลใน column ไม่ตรงตาม Template`, {
          variant: "error",
        });
      }
      return [];
    }
  };

  const importFormattedDocuments = async () => {
    if (formattedData.length === 0) {
      return;
    }
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (Array.isArray(formattedDataSnapshot[i][requiredKeys[j]])) {
          let hasError;
          for (
            let k = 0;
            k < formattedDataSnapshot[i][requiredKeys[j]].length;
            k++
          ) {
            if (
              Object.values(formattedDataSnapshot[i][requiredKeys[j]][k]).some(
                (item) => item === null || typeof item === "undefined"
              )
            ) {
              hasError = true;
              break;
            }
          }
          if (hasError) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
            });
            formattedDataSnapshot.splice(i, 1);
            i--;
            break;
          }
        }
        if (
          formattedDataSnapshot[i][requiredKeys[j]] !== 0 &&
          !formattedDataSnapshot[i][requiredKeys[j]]
        ) {
          requiredFieldErrors.push({
            unique_id: formattedDataSnapshot[i].unique_id,
            type: "required",
            field: requiredKeys[j],
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setHasCreateError(true);
    } else {
      let idsWithSpace = [];
      try {
        let data;
        if (watchIsUpsert) {
          const dataWithFormattedId = formattedData.filter((data) => {
            if (data.unique_id.includes(" ")) {
              idsWithSpace.push(data.unique_id);
              return false;
            }
            return true;
          });
          data = await ContactService.createOrUpdateContacts({
            upsertManyInput: dataWithFormattedId,
          });
        } else {
          const dataWithFormattedId = formattedCreateOnlyData.filter((data) => {
            if (data.unique_id.includes(" ")) {
              idsWithSpace.push(data.unique_id);
              return false;
            }
            return true;
          });
          data = await ContactService.createContacts({
            createManyInput: dataWithFormattedId,
          });
        }
        if (idsWithSpace.length > 0) {
          throw new Error("id_whitespace");
        }
        if (data.errors?.length === 0) {
          let message = "";
          if (
            watchIsUpsert &&
            createData.length > 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "นำเข้าและแก้ไขผู้ติดต่อสำเร็จ";
          } else if (
            watchIsUpsert &&
            createData.length === 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "แก้ไขผู้ติดต่อสำเร็จ";
          } else if (
            (watchIsUpsert &&
              createData.length > 0 &&
              updateOrSkipData.length === 0) ||
            (!watchIsUpsert && createData.length > 0)
          ) {
            message = "นำเข้าสำเร็จ";
          }
          enqueueSnackbar(message, {
            variant: "success",
          });
          navigate("/contact/contacts");
        } else {
          setCreateErrors((prevErrors) => [...prevErrors, ...data.errors]);
          setHasCreateError(true);
          throw new Error();
        }
      } catch (err) {
        console.log(err);
        if (err.message === "id_whitespace") {
          setHasCreateError(true);
          setCreateErrors((prevErrors) => [
            ...prevErrors,
            { type: "id_whitespace", ids: idsWithSpace },
          ]);
        }
      }
    }
  };

  const renderCreateErrors = () => {
    if (createErrors.find((error) => error.type === "template_from_importer")) {
      enqueueSnackbar("Template ไม่ตรง", {
        variant: "error",
      });
    }
    const requiredFieldErrors = [
      ...new Set(
        createErrors
          .filter((error) => error.type === "required")
          .map((error) => error.field)
      ),
    ];
    if (requiredFieldErrors.length > 0) {
      enqueueSnackbar(
        `ไม่พบข้อมูลใน column\n${requiredFieldErrors.join(", ")}`,
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
    const whiteSpaceError = createErrors.find(
      (error) => error.type === "id_whitespace"
    );
    if (whiteSpaceError) {
      return (
        <ModalUI
          open={hasCreateError}
          handleClose={() => setHasCreateError(false)}
          fullWidth
          title="ไม่สามารถสร้างได้ เนื่องจากรหัสสินค้ามีการเว้นวรรค"
          titleSize="16px"
          maxWidth="xs"
        >
          <Typography fontWeight="bold">รายการที่ไม่สามารถสร้างได้:</Typography>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {whiteSpaceError.ids.map((id) => (
              <ListItem
                key={id}
                sx={{
                  display: "list-item",
                }}
              >
                <Typography
                  sx={{
                    display: "inline",
                    color: (theme) => theme.palette.warning.main,
                  }}
                >
                  {id}
                </Typography>
              </ListItem>
            ))}
          </List>
        </ModalUI>
      );
    }
    return <></>;
  };

  const currentTab = tab ? pathname + `?tab=${tab}` : pathname;

  return (
    <>
      {contactPermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้าผู้ติดต่อ"}</Typography>
      </Box>
      <Box
        sx={{ mt: 1, mb: 2, display: "flex", justifyContent: "space-between" }}
      >
        <CustomizedButton
          title="ดาวน์โหลด Template"
          variant="outlined"
          onClick={() =>
            window.open("/static/contact_import_template.xlsx", "_blank")
          }
        />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatContactData}
            setLoading={setIsLoading}
            setErrors={setCreateErrors}
            setHasError={setHasCreateError}
            hasLabelHeader
          />
        </Grid>
      </Grid>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Controller
              name={"is_upsert"}
              control={control}
              render={({ field }) => (
                <CustomizedCheckboxes
                  label="อัปเดตรายการปัจจุบัน"
                  {...field}
                  isDisabled={formattedData.length === 0}
                />
              )}
            />
            <CustomizedTooltip
              title={
                "กรณีที่มีรหัสผู้ติดต่อเดิมอยู่แล้ว จะเป็นการเลือกเพื่ออัปเดตข้อมูลใหม่ในรายการผู้ติดต่อเดิม"
              }
              noMaxWidth
            >
              <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomizedButton
              title="นำเข้า"
              variant="contained"
              onClick={importFormattedDocuments}
              disabled={
                formattedData.length === 0 ||
                isLoading ||
                (!watchIsUpsert && formattedCreateOnlyData.length === 0)
              }
            />
          </Box>
        </Grid>
      </Grid>

      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ maxWidth: "100%", overflow: "scroll", mt: 4 }}>
            <CustomizedTab
              tabs={tabs}
              currentTab={currentTab}
              scrollable
              table
            />
          </Box>
          <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            height={649}
            rowData={displayedData}
            groupDefaultExpanded={1}
            disableFloatingFilter={true}
          />
        </Box>
      )}
      {hasCreateError && renderCreateErrors()}
    </>
  );
};

export default ContactImporterPage;
