import { gql } from "graphql-request";

export const VERIFY = gql`
  query VerifyJwtToken {
    verifyJwtToken {
      id
      unique_id
      first_name
      last_name
      email
      phone
      department
      position
      is_user_active
      inactive_remarks
      last_login_date
      img_url
      role_list {
        id
        name
        permission_list {
          role_unique_id
          access_key_name
          access_value
        }
      }
    }
  }
`;
