import {
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
} from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/dataTransformer";
import { CustomizedBox } from "../Custom/CustomizedBox";
import { CreateOutlined } from "@mui/icons-material";
import { formatDateTime } from "../../utils/date-converter";

const PaymentTable = ({
  fields,
  disabled,
  editPaymentHandler,
  defaultTotalAmount,
}) => {
  const [header, setHeader] = useState([]);
  const { t } = useTranslation();

  const totalAmount = fields[0]?.total_amount ?? defaultTotalAmount ?? 0;
  const totalRemainingAmount =
    totalAmount -
    fields.reduce((acc, current) => acc + current.payment_amount, 0);

  useEffect(() => {
    if (disabled) {
      setHeader([
        "ครั้งที่",
        t("sales.order.total_amount"),
        t("sales.order.total_paid"),
        t("sales.order.payment_amount"),
        t("sales.order.remaining_amount"),
        t("sales.order.payment_method"),
        t("sales.order.payment_datetime"),
        t("sales.order.bank_account"),
        t("sales.order.payment_remark"),
        "รูป",
      ]);
    } else {
      setHeader([
        "ครั้งที่",
        t("sales.order.total_amount"),
        t("sales.order.total_paid"),
        t("sales.order.payment_amount"),
        t("sales.order.remaining_amount"),
        t("sales.order.payment_method"),
        t("sales.order.payment_datetime"),
        t("sales.order.bank_account"),
        t("sales.order.payment_remark"),
        "รูป",
        "",
      ]);
    }
  }, [disabled]);

  return (
    <CustomizedBox padding={0}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "#EFF2FF" }}>
            <TableRow>
              {header.map((header, index) => (
                <TableCell align="center" key={index} width="max-content">
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <Typography>{index + 1}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{formatNumber(row.total_amount)}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{formatNumber(row.total_paid)}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{formatNumber(row.payment_amount)}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{formatNumber(row.remaining_amount)}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{row.payment_method}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>
                    {formatDateTime(row.payment_date_time)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{row.bank_account}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{row.remark}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Avatar
                    alt={`payment-${row.payment_count}`}
                    src={row.payment_img_url}
                  >
                    <ImageOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                  </Avatar>
                </TableCell>
                {!disabled && (
                  <TableCell align="center">
                    <IconButton onClick={() => editPaymentHandler(row, index)}>
                      <CreateOutlined />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={2}
        mt={2}
      >
        <Grid item sx={6} md={2} mb={2}>
          <Typography fontWeight="bold">ยอดเงินคงเหลือ</Typography>
        </Grid>
        <Grid item sx={6} md={1.5} mb={2}>
          <Typography fontWeight="bold">
            {formatNumber(totalRemainingAmount)} บาท
          </Typography>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default PaymentTable;
