import Button from "@mui/material/Button";

const CustomizedButton = ({
  sx,
  variant,
  title,
  onClick,
  startIcon,
  type,
  fullWidth,
  color,
  size,
  disabled,
  testId,
}) => {
  return (
    <Button
      sx={sx}
      type={type || "button"}
      size={size || "small"}
      variant={variant}
      onClick={onClick}
      startIcon={startIcon}
      fullWidth={fullWidth}
      color={color}
      disabled={disabled}
      data-testid={testId}
    >
      {title}
    </Button>
  );
};

export default CustomizedButton;
