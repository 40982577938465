import { forwardRef } from "react";
import { Autocomplete, TextField } from "@mui/material";

const CustomizedFreeSolo = forwardRef(
  (
    {
      sx,
      options,
      title,
      name,
      error,
      value,
      helperText,
      required,
      disabled,
      placeholder,
      onChange,
      onInputChange,
      onBlur,
    },
    ref
  ) => {
    return (
      <Autocomplete
        freeSolo
        ref={ref}
        value={value}
        sx={sx}
        onChange={onChange}
        onBlur={onBlur}
        onInputChange={onInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            size="small"
            name={name}
            error={Boolean(error)}
            helperText={helperText}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
        readOnly={disabled}
      />
    );
  }
);

export default CustomizedFreeSolo;
