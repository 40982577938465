import { gql } from "graphql-request";

export const GET_ACTIVITY_LOGS = gql`
  query ActivityLogsByUniqueId($uniqueIdInput: String!) {
    activityLogsByUniqueId(uniqueIdInput: $uniqueIdInput) {
      id
      unique_id
      activity_details
      activity_type
      created_date
      creator_unique_id
      created_by {
        id
        unique_id
        first_name
        last_name
      }
      model_type
      attachment_list
    }
  }
`;
