import { Controller } from "react-hook-form";
import CustomizedSelect from "../Custom/CustomizedSelect";

const ControlledSelect = ({
  name,
  label,
  control,
  error,
  helperText,
  renderValue,
  options,
  disabled,
  required,
  testId,
  sx,
  onChange,
}) => {
  return (
    <Controller
      key={`${name}-select`}
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedSelect
          {...field}
          onChange={
            onChange
              ? (e) => {
                  field.onChange(e.target.value);
                  onChange(e);
                }
              : (e) => {
                  field.onChange(e.target.value);
                }
          }
          sx={sx}
          fullWidth
          error={Boolean(error)}
          helperText={helperText || error?.message}
          label={label}
          renderValue={renderValue}
          options={options}
          disabled={disabled}
          required={required}
          testId={testId}
        />
      )}
    />
  );
};

export default ControlledSelect;
