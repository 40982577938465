import { Box, IconButton, Typography } from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/use-auth";
import ControlledDatePicker from "../../Controlled/ControlledDatePicker";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import CustomizedChips from "../../Custom/CustomizedChips";
import RelatedEmployeeIcon from "../../UI/RelatedEmployeeIcon";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CheckboxModalTable from "../../Table/CheckboxModalTable";
import { userColumnDefs } from "../../Table/ColumnDefs/User";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { getAllUsers } from "../../../features/User/Account/account-actions";
import GlobalService from "../../../services/Global";

const HeaderForm = ({ control, errors, getValues, setValue, disabled }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectEmployeesGridRef = useRef();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const watchCreatorUniqueId = useWatch({
    control,
    name: "creator_unique_id",
  });

  const watchRelatedEmployees = useWatch({
    control,
    name: "employee_list",
  });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  const watchUniqueId = useWatch({
    control,
    name: "unique_id",
  });

  const watchExternalRef = useWatch({
    control,
    name: "external_ref_document_id",
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "employee_list",
  });

  const creatorRow =
    pathname.split("/").at(-1) === "add"
      ? [user.unique_id]
      : [watchCreatorUniqueId];

  const [showSelectEmployees, setShowSelectEmployees] = useState(false);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState([]);

  const [createdAtDateisOpen, setCreatedAtDateisOpen] = useState(false);
  const [paymentDateIsOpen, setPaymentDateIsOpen] = useState(false);
  const [dueDateIsOpen, setDueDateIsOpen] = useState(false);
  const [manufactureDateIsOpen, setManufactureDateIsOpen] = useState(false);

  const openEmployeeTable = () => {
    setShowSelectEmployees(true);
  };

  const closeEmployeeTable = () => {
    setShowSelectEmployees(false);
  };

  const getRowId = useCallback((params) => {
    return params.data.unique_id;
  }, []);

  useEffect(() => {
    if (
      pathname.split("/").at(-1) === "add" &&
      watchRelatedEmployees &&
      watchRelatedEmployees.length === 0
    ) {
      // setValue("employee_list", [{ ...user }]);
      setEmployeeIds([user.unique_id]);
      setEmployeeIdsSnapshot([user.unique_id]);
    } else {
      if (watchCreatorUniqueId) {
        const mappedEmployeeIds =
          getValues("employee_list")?.map((employee) => employee?.unique_id) ??
          [];
        setEmployeeIds([watchCreatorUniqueId, ...mappedEmployeeIds]);
        setEmployeeIdsSnapshot([watchCreatorUniqueId, ...mappedEmployeeIds]);
      }
    }
  }, [
    pathname,
    watchRelatedEmployees,
    watchCreatorUniqueId,
    user,
    setValue,
    getValues,
  ]);

  const navigateToSalesOrder = (ref_id) => {
    if (!ref_id) {
      return;
    }
    navigate(`/sales/order/${encodeURIComponent(ref_id)}`);
  };

  const finishEmployeesSelect = (data) => {
    // filter out employees not selected in current modal session
    let filteredEmployees = fields.filter((employee) =>
      employeeIds.includes(employee.unique_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.unique_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.unique_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        employeeIds.indexOf(a.unique_id) - employeeIds.indexOf(b.unique_id)
      );
    });
    setValue(
      "employee_list",
      filteredEmployees.filter(
        (employee) => employee.unique_id !== watchCreatorUniqueId
      )
    );
    setShowSelectEmployees(false);
  };

  const removeEmployee = (index) => {
    remove(index);
    setEmployeeIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setEmployeeIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const renderAvatars = () => {
    return fields.map((item, index) => {
      return (
        <RelatedEmployeeIcon
          key={item.id}
          first_name={item.first_name}
          last_name={item.last_name}
          index={index}
          avatar={item.img_url}
          remove={removeEmployee}
          viewOnly={disabled}
        />
      );
    });
  };

  const renderCreatorAvatar = () => {
    if (watchCreatorUniqueId) {
      return (
        <RelatedEmployeeIcon
          isMain={watchCreatedBy && fields.length > 0}
          first_name={watchCreatedBy?.first_name}
          last_name={watchCreatedBy?.last_name}
          avatar={watchCreatedBy?.img_url}
        />
      );
    } else {
      return null;
    }
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const filterModel = {
        ...request.filterModel,
        unique_id: {
          ...request.filterModel.unique_id,
          mode: "insensitive",
        },
        first_name: {
          ...request.filterModel.first_name,
          mode: "insensitive",
        },
        last_name: {
          ...request.filterModel.last_name,
          mode: "insensitive",
        },
      };
      dispatch(
        getAllUsers(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const generateUniqueId = async () => {
    const newUniqueId = await GlobalService.getUniqueId("manufacture_order");
    setValue("unique_id", newUniqueId);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ mr: 4, fontWeight: "bold" }}>
            {t("document_id")}
          </Typography>
          <Typography>{watchUniqueId || "-"}</Typography>
          {!id && (
            <IconButton onClick={generateUniqueId} color="primary">
              <RestartAltOutlinedIcon />
            </IconButton>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="payment_date"
              control={control}
              error={errors.payment_date}
              isOpen={paymentDateIsOpen}
              onClose={() => setPaymentDateIsOpen(false)}
              onOpen={() => setPaymentDateIsOpen(true)}
              label={t("manufacture.order.payment_date")}
              disabled={true}
            />
          </Box>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="created_date"
              control={control}
              error={errors.created_date}
              isOpen={createdAtDateisOpen}
              onClose={() => setCreatedAtDateisOpen(false)}
              onOpen={() => setCreatedAtDateisOpen(true)}
              label={t("manufacture.order.created_date")}
              disabled={true}
            />
          </Box>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="production_date"
              control={control}
              error={errors.production_date}
              isOpen={manufactureDateIsOpen}
              onClose={() => setManufactureDateIsOpen(false)}
              onOpen={() => setManufactureDateIsOpen(true)}
              label={t("manufacture.order.production_date")}
              disabled={disabled}
            />
          </Box>
          <Box sx={{ width: 155 }}>
            <ControlledDatePicker
              name="receive_date"
              control={control}
              error={errors.receive_date}
              isOpen={dueDateIsOpen}
              onClose={() => setDueDateIsOpen(false)}
              onOpen={() => setDueDateIsOpen(true)}
              label={t("manufacture.order.due_date")}
              disabled={disabled}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
        <Typography sx={{ mr: 6, fontWeight: "bold" }}>
          {t("reference_document_id")}
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedChips
            value={watchExternalRef ?? "-"}
            onClick={() =>
              window.open(
                `/sales/order/${watchExternalRef}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ mr: 4, fontWeight: "bold" }}>
          {t("manufacture.order.related_employee")}
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {renderCreatorAvatar()}
          {renderAvatars()}
          {!disabled && (
            <CustomizedTooltip title="เพิ่มผู้เกี่ยวข้อง" enterNextDelay={200}>
              <IconButton
                onClick={openEmployeeTable}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ControlPointOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
      </Box>
      <CheckboxModalTable
        modalTitle="ผู้เกี่ยวข้อง"
        btnTitle={t("button.add")}
        gridRef={selectEmployeesGridRef}
        height={450}
        columnDefs={userColumnDefs(t, false, true, true)}
        rowSelection="multiple"
        onFinishEditing={finishEmployeesSelect}
        modalIsOpen={showSelectEmployees}
        getRowId={getRowId}
        closeModal={closeEmployeeTable}
        onGridReady={onGridReady}
        selectedIds={employeeIds}
        setSelectedIds={setEmployeeIds}
        idsSnapshot={employeeIdsSnapshot}
        setIdsSnapshot={setEmployeeIdsSnapshot}
        lockRows={creatorRow}
      />
    </Box>
  );
};

export default HeaderForm;
