import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const CustomizedBox = styled(Box)(
  ({
    padding,
    margin,
    radius,
    total,
    boxShadow,
    clipPath,
    borderColor,
    url,
    sx,
  }) => ({
    boxShadow: boxShadow ?? "0px 2px 7px #E5E5E5",
    borderRadius: radius || "15px",
    padding: padding ?? "2rem",
    margin: margin ?? "2rem 0rem 0rem 0rem",
    ...(total && {
      borderLeft: `5px ${borderColor || "#32418F"} solid`,
    }),
    clipPath,
    cursor: url ? "pointer" : "auto",
    sx,
  })
);
