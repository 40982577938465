import * as Yup from "yup";

export const permissionValidator = Yup.object().shape({
  name: Yup.string().required("กรุณากรอก"),
  description: Yup.string(),
  permission_list: Yup.array().of(
    Yup.object().shape({
      access_value: Yup.string().required("กรุณาเลือก"),
    })
  ),
});
