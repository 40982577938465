import { filterParamsOptions } from "../../../../../utils/filterparams";
import { dateComparator } from "../../../../../utils/filterparams";

export const salesOrderPaymentColumnDef = (t, isReport) => {
  if (isReport) {
    return [
      {
        field: "unique_id",
        headerName: t("reports.order_payment.unique_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "created_date",
        headerName: t("sales.order.created_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "payment_count",
        headerName: t("reports.order_payment.payment_count"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 100,
      },
      {
        field: "total_amount",
        headerName: t("reports.order_payment.total_amount"),
        filter: false,
        width: 150,
      },
      {
        field: "total_paid",
        headerName: t("reports.order_payment.total_paid"),
        filter: false,
        width: 175,
      },
      {
        field: "payment_amount",
        headerName: t("reports.order_payment.payment_amount"),
        filter: false,
        width: 150,
      },
      {
        field: "remaining_amount",
        headerName: t("reports.order_payment.remaining_amount"),
        filter: false,
        width: 175,
      },
      {
        field: "payment_method",
        headerName: t("reports.order_payment.payment_method"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 200,
      },
      {
        field: "payment_date_time",
        headerName: t("reports.order_payment.payment_date_time"),
        filter: "agDateColumnFilter",
        width: 200,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "bank_account",
        headerName: t("reports.order_payment.bank_account"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "remark",
        headerName: t("reports.order_payment.remark"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
    ];
  }
  return [];
};
