import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import ControlledSelect from "../../../components/Controlled/ControlledSelect";
import ControlledTextField from "../../../components/Controlled/ControlledTextField";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ModalUI from "../../../components/UI/ModalUI";
import GlobalService from "../../../services/Global";
import ContactService from "../../../services/Contact";
import SettingService from "../../../services/Setting";
import { useSnackbar } from "notistack";
import { validation } from "./validation";
import {
  formatManufactureItemSettingsPayload,
  formatManufactureItemSettingsQuery,
} from "../../../utils/settingPayloadFormatter";

const defaultItemSetting = {
  brand_name: "",
  vendor_unique_id: "",
  item_type: "",
  block: "",
  process_type: "",
  printing_cost: 0,
  hot_rolled_cost: 0,
  fold_cost: 0,
};

const ManufactureItemSetting = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [brandOptions, setBrandOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [itemTypeOptions, setItemTypeOptions] = useState([]);
  const [duplicatedIndexes, setDuplicatedIndexes] = useState([]);
  const [openDeleteSettingConfirmation, setOpenDeleteSettingConfirmation] =
    useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [isInit, setIsInit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues: { item_cost_setting_list: [] },
    resolver: yupResolver(validation),
  });

  const watchFieldArray = useWatch({ control, name: "item_cost_setting_list" });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "item_cost_setting_list",
  });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const header = [
    "รายการ",
    "แบรนด์",
    "ผู้ผลิต",
    "ประเภทสินค้า",
    "จำนวนบล็อก",
    "ราคาทุนพิมพ์",
    "ราคาทับร้อน",
    "ราคาทุนพับ",
    "",
  ];

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.manufacture.manufactureItemCost"),
    },
  ];

  useEffect(() => {
    const getOptions = async () => {
      const loadedBrands = await GlobalService.getCreatables({
        usage_field_type: "order",
        usage_field_name: "brand_name",
      });
      const loadedItemTypes = await GlobalService.getCreatables({
        usage_field_type: "item",
        usage_field_name: "item_type",
      });
      const loadedContacts = await ContactService.getAllContactNames();

      setBrandOptions(
        loadedBrands.map((option) => {
          return {
            value: option.name,
            label: option.name,
          };
        })
      );
      setItemTypeOptions(
        loadedItemTypes.map((option) => {
          return {
            value: option.name,
            label: option.name,
          };
        })
      );

      setVendorOptions(
        loadedContacts
          .filter((contact) => contact.is_vendor)
          .map((option) => {
            return {
              value: option.unique_id,
              label: `${option.name}${
                option.last_name ? ` ${option.last_name}` : ""
              }`,
            };
          })
      );
    };
    getOptions();
  }, []);

  useEffect(() => {
    if (!isInit) {
      const getItemSettings = async () => {
        const loadedItemSettings =
          await SettingService.getManufactureItemSettings();
        const formattedData =
          formatManufactureItemSettingsQuery(loadedItemSettings);
        setValue("item_cost_setting_list", formattedData);
        setIsInit(true);
      };
      getItemSettings();
    }
  }, [isInit]);

  const addItemCostSettingHandler = () => {
    append({ ...defaultItemSetting });
  };

  const saveItemCostSettingsHandler = async () => {
    setIsSubmitting(true);

    const itemCostSettingList = formatManufactureItemSettingsPayload(
      getValues("item_cost_setting_list")
    );

    try {
      const { manufactureItemSettings, duplicatedIndexes } =
        await SettingService.upsertOrDeleteManufactureItemSettings(
          itemCostSettingList,
          []
        );
      if (duplicatedIndexes.length > 0) {
        setDuplicatedIndexes(duplicatedIndexes);
        throw new Error("Duplicated settings");
      }
      const formattedData = formatManufactureItemSettingsQuery(
        manufactureItemSettings
      );

      setValue("item_cost_setting_list", formattedData);
      enqueueSnackbar("บันทึกสำเร็จ", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("บันทึกไม่สำเร็จ", { variant: "error" });
      console.log(err);
    }
    setIsSubmitting(false);
  };

  const openDeleteSettingConfirmationHandler = (index) => {
    setIndexToDelete(index);
    setOpenDeleteSettingConfirmation(true);
  };

  const closeDeleteSettingConfirmationHandler = () => {
    setOpenDeleteSettingConfirmation(false);
    setIndexToDelete(null);
  };

  const onError = () => {
    enqueueSnackbar("บันทึกไม่สำเร็จ", { variant: "error" });
  };

  const deleteItemCostSettingHandler = async () => {
    const updatedDuplicatedIndexes = duplicatedIndexes
      .filter((currentIndex) => currentIndex !== indexToDelete)
      .map((currentIndex) => {
        if (currentIndex > indexToDelete) {
          return currentIndex - 1;
        }
        return currentIndex;
      });
    setDuplicatedIndexes(updatedDuplicatedIndexes);
    const id = controlledFields[indexToDelete].setting_id;
    if (id) {
      try {
        await SettingService.deleteManufactureItemSetting({ id });
        enqueueSnackbar("ลบสำเร็จ", { variant: "success" });
      } catch (err) {
        enqueueSnackbar("ลบไม่สำเร็จ", { variant: "error" });
        console.log(err);
      }
    }
    remove(indexToDelete);
    setIndexToDelete(null);
    setOpenDeleteSettingConfirmation(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(saveItemCostSettingsHandler, onError)}
        noValidate
      >
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box my={3}>
          <Typography variant="h5">
            {t("setting.manufacture.manufactureItemCost")}
          </Typography>
        </Box>
        <CustomizedBox padding={0}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650, overflow: "scroll" }}
              aria-label="manufacture item cost table"
            >
              <TableHead sx={{ backgroundColor: "#EFF2FF" }}>
                <TableRow>
                  {header.map((header, index) => (
                    <TableCell align="center" key={index} width="max-content">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {controlledFields.map((row, index) => {
                  return (
                    <TableRow key={row.id || `item-${index}`}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            width: 150,
                            alignItems: "center",
                          }}
                        >
                          <ControlledSelect
                            name={`item_cost_setting_list[${index}].brand_name`}
                            control={control}
                            error={
                              (errors?.item_cost_setting_list &&
                                errors?.item_cost_setting_list[index] &&
                                errors?.item_cost_setting_list[index]
                                  ?.brand_name) ||
                              duplicatedIndexes.includes(index)
                            }
                            helperText={
                              duplicatedIndexes.includes(index) &&
                              "ข้อมูลซ้ำกรุณาเลือกใหม่"
                            }
                            options={brandOptions}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            width: 150,
                            alignItems: "center",
                          }}
                        >
                          <ControlledSelect
                            name={`item_cost_setting_list[${index}].vendor_unique_id`}
                            control={control}
                            error={
                              (errors?.item_cost_setting_list &&
                                errors?.item_cost_setting_list[index] &&
                                errors?.item_cost_setting_list[index]
                                  ?.vendor_unique_id) ||
                              duplicatedIndexes.includes(index)
                            }
                            helperText={
                              duplicatedIndexes.includes(index) &&
                              "ข้อมูลซ้ำกรุณาเลือกใหม่"
                            }
                            options={vendorOptions}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            width: 150,
                            alignItems: "center",
                          }}
                        >
                          <ControlledSelect
                            name={`item_cost_setting_list[${index}].item_type`}
                            control={control}
                            error={
                              (errors?.item_cost_setting_list &&
                                errors?.item_cost_setting_list[index] &&
                                errors?.item_cost_setting_list[index]
                                  ?.item_type) ||
                              duplicatedIndexes.includes(index)
                            }
                            helperText={
                              duplicatedIndexes.includes(index) &&
                              "ข้อมูลซ้ำกรุณาเลือกใหม่"
                            }
                            options={itemTypeOptions}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <ControlledTextField
                          sx={{ width: 80 }}
                          name={`item_cost_setting_list[${index}].block`}
                          error={
                            (errors?.item_cost_setting_list &&
                              errors?.item_cost_setting_list[index] &&
                              errors?.item_cost_setting_list[index]?.block) ||
                            duplicatedIndexes.includes(index)
                          }
                          helperText={
                            duplicatedIndexes.includes(index) &&
                            "ข้อมูลซ้ำกรุณาเลือกใหม่"
                          }
                          control={control}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <ControlledTextField
                          sx={{ width: 120 }}
                          type="number"
                          name={`item_cost_setting_list[${index}].printing_cost`}
                          control={control}
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <ControlledTextField
                          sx={{ width: 120 }}
                          type="number"
                          name={`item_cost_setting_list[${index}].hot_rolled_cost`}
                          control={control}
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <ControlledTextField
                          sx={{ width: 120 }}
                          type="number"
                          name={`item_cost_setting_list[${index}].fold_cost`}
                          control={control}
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() =>
                            openDeleteSettingConfirmationHandler(index)
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomizedButton
            sx={{ m: 2 }}
            title="เพิ่มรายการ"
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={addItemCostSettingHandler}
          />
        </CustomizedBox>
        <Box mt={2}>
          <CustomizedButton
            sx={{ m: 2 }}
            title="บันทึก"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
          />
        </Box>
      </form>
      <ModalUI
        title="ยืนยันการลบ"
        open={openDeleteSettingConfirmation}
        handleClose={closeDeleteSettingConfirmationHandler}
        maxWidth="xs"
        fullWidth
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={closeDeleteSettingConfirmationHandler}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={deleteItemCostSettingHandler}
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default ManufactureItemSetting;
