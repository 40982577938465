import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  SvgIcon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as NewTabIcon } from "../../assets/newTabIcon.svg";

const ModalUI = ({
  open,
  handleClose,
  fullWidth,
  maxWidth,
  title,
  titleSize,
  navigateTo,
  children,
  width,
  isLoading,
  isRed,
  startIcon,
}) => {
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: width ?? "auto",
          },
        },
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth || "100%"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isLoading && <LinearProgress />}
      {navigateTo && (
        <IconButton
          onClick={() => {
            window.open(navigateTo, "_blank");
          }}
        >
          <SvgIcon
            component={NewTabIcon}
            sx={{
              position: "absolute",
              right: 50,
              top: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          />
        </IconButton>
      )}
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}

      {startIcon ? (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", m: 0, p: 2 }}>
          {startIcon}
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              color: isRed ?? "inherit",
              padding: 0,
              fontSize: titleSize ? titleSize : "18px",
            }}
          >
            {title}
          </DialogTitle>
        </Box>
      ) : (
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            m: 0,
            p: 2,
            color: isRed ?? "inherit",
            fontSize: titleSize ? titleSize : "18px",
          }}
        >
          {title}
        </DialogTitle>
      )}

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default ModalUI;
