import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledServerSideCreatable from "../../Controlled/ControlledSSCreatable";
import ControlledTextField from "../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../Custom/CustomizedBox";

const salesChannelOptions = ["Shopee", "Lazada", "Line@", "Facebook"];
const brandOptions = ["Am A Love", "I Am Premium"];

const SalesDetailForm = ({ control, errors, setValue, disabled }) => {
  const { t } = useTranslation();
  return (
    <CustomizedBox>
      <Typography sx={{ ml: 1, fontWeight: "bold" }}>
        {t("sales.order.sales_detail")}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            name="sales_channel"
            title={t("sales.order.sales_channel")}
            control={control}
            error={Boolean(errors?.sales_channel)}
            helperText={errors?.sales_channel && errors?.sales_channel.message}
            viewOnly={disabled}
            documentType="order"
            defaultOptions={[]}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="account_id"
            label={t("sales.order.account_id")}
            control={control}
            error={Boolean(errors?.account_id)}
            helperText={errors?.account_id && errors?.account_id.message}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            name="brand_name"
            title={t("sales.order.brand_name")}
            control={control}
            error={Boolean(errors?.brand_name)}
            helperText={errors?.brand_name && errors?.brand_name.message}
            viewOnly={disabled}
            documentType="order"
            defaultOptions={[]}
            setValue={setValue}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default SalesDetailForm;
