import { Avatar, Box, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../Custom/CustomizedLetterAvatar";

const RelatedEmployeeIcon = ({
  isMain,
  first_name,
  last_name,
  index,
  avatar,
  remove,
  viewOnly,
}) => {
  return (
    <>
      <Box>
        <CustomizedTooltip
          title={`${first_name} ${last_name}`}
          enterNextDelay={200}
        >
          <Box
            sx={{
              display: "flex",
              position: "relative",
            }}
          >
            {avatar && <Avatar alt={`img${index}`} src={avatar} />}
            {!avatar && (
              <CustomizedLetterAvatar name={first_name + " " + last_name} />
            )}
            {!viewOnly && remove && (
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                <IconButton
                  className="removeBtn"
                  aria-label="delete"
                  onClick={() => remove(index)}
                  sx={{
                    position: "absolute",
                    bottom: "-0.7rem",
                    right: "-1rem",
                    color: "rgba(0, 0, 0, 0.54)",
                    backgroundColor: "#ffffff",
                    transform: "scale(0.6)",
                    zIndex: 2,
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </CustomizedTooltip>
      </Box>
      {isMain && <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />}
    </>
  );
};

export default RelatedEmployeeIcon;
