import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../components/Custom/CustomizedMenuBox";
import { useAuth } from "../../hooks/use-auth";

const Sales = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { permissions } = useAuth();
  const salesPermission = permissions.sales;

  const breadcrumbs = [
    {
      name: t("sales.index"),
    },
  ];

  const menuList = [
    { title: t("sales.dashboard.index"), navigateTo: "dashboard" },
    { title: t("sales.order.index"), navigateTo: "order" },
    { title: t("sales.report.index"), navigateTo: "report" },
  ];

  const renderMenuList = () =>
    menuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          onClick={() => navigate(`${pathname}/${menu.navigateTo}`)}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      {salesPermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default Sales;
