import {
  Avatar,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFieldArray, useWatch } from "react-hook-form";
import CustomizedButton from "../Custom/CustomizedButton";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useRef, useState } from "react";
import CheckboxModalTable from "./CheckboxModalTable";
import { useTranslation } from "react-i18next";
import { itemSkuColumnDefs } from "./ColumnDefs/Inventory/Items/sku";
import { getAllItemsSku } from "../../features/Inventory/inventory-actions";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { CustomizedBox } from "../Custom/CustomizedBox";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ControlledServerSideCreatable from "../Controlled/ControlledSSCreatable";
import ControlledTextField from "../Controlled/ControlledTextField";
import { formatNumber } from "../../utils/dataTransformer";
import ModalUI from "../UI/ModalUI";
import ItemInfoModalUI from "../Form/Order/ItemInfoModalUI";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TocIcon from "@mui/icons-material/Toc";

// const neckOptions = ["คอกลม", "คอวี"];

const SOItemTable = ({
  control,
  setValue,
  errors,
  disabled,
  existingItemIds,
}) => {
  const [header, setHeader] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectItemListRef = useRef();
  const { t } = useTranslation();
  const [openItemListModal, setOpenItemListModal] = useState(false);
  const [openItemInfoModal, setOpenItemInfoModal] = useState(false);
  const [openItemDeleteConfirmation, setOpenItemDeleteConfirmation] =
    useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [itemId, setItemId] = useState(null);

  const [itemListIds, setItemListIds] = useState([]);
  const [itemListIdsSnapshot, setItemListIdsSnapshot] = useState([]);

  const { fields, remove, replace, move } = useFieldArray({
    control,
    name: "item_list",
  });

  const watchFieldArray = useWatch({ control, name: "item_list" });

  const openItemListModalHandler = () => {
    setOpenItemListModal(true);
  };

  const closeItemListModalHandler = () => {
    setOpenItemListModal(false);
  };

  const getRowId = useCallback((params) => {
    return params.data.sku_name;
  }, []);

  const openItemInfoModalHandler = (itemUniqueId) => {
    if (existingItemIds.includes(itemUniqueId)) {
      setItemId(itemUniqueId);
      setOpenItemInfoModal(true);
    } else {
      enqueueSnackbar("สินค้าถูกลบออกจากระบบแล้ว", { variant: "error" });
    }
  };

  const openItemDeleteModalHandler = (index) => {
    setOpenItemDeleteConfirmation(true);
    setIndexToDelete(index);
  };

  const closeItemDeleteHandler = () => {
    setOpenItemDeleteConfirmation(false);
    setIndexToDelete(null);
  };

  const deleteItemActionHandler = () => {
    removeItem(indexToDelete);
    setOpenItemDeleteConfirmation(false);
    setIndexToDelete(null);
  };

  useEffect(() => {
    if (fields.length > 0 && itemListIds.length === 0 && !openItemListModal) {
      const ids = fields.map((field) => field.sku_name);
      setItemListIds(ids);
      setItemListIdsSnapshot(ids);
    }
  }, [fields, itemListIds, itemListIdsSnapshot, openItemListModal]);

  useEffect(() => {
    if (disabled) {
      setHeader([
        { label: "รายการ", width: 75 },
        { label: "รูป", width: 90 },
        {
          label: "รหัสสินค้า / SKU - ชื่อสินค้า",
          width: 325,
        },
        { label: "สี", width: 90 },
        { label: "ไซส์", width: 90 },
        { label: "แขน", width: 90 },
        { label: "คอ", width: 125 },
        { label: "จำนวน", width: 100 },
        { label: "ราคาขาย/หน่วย", width: 125 },
        { label: "หน่วย", width: 100 },
        { label: "ส่วนลด/หน่วย", width: 125 },
        { label: "รวม", width: 125 },
        { label: "หมายเหตุรายสินค้า", width: 150 },
      ]);
    } else {
      setHeader([
        { label: "รายการ", width: 75 },
        { label: "รูป", width: 90 },
        {
          label: "รหัสสินค้า / SKU - ชื่อสินค้า",
          width: 325,
        },
        { label: "สี", width: 90 },
        { label: "ไซส์", width: 90 },
        { label: "แขน", width: 90 },
        { label: "คอ", width: 125 },
        { label: "จำนวน", width: 100 },
        { label: "ราคาขาย/หน่วย", width: 125 },
        { label: "หน่วย", width: 100 },
        { label: "ส่วนลด", width: 125 },
        { label: "รวม", width: 125 },
        { label: "หมายเหตุรายสินค้า", width: 150 },
        { label: "", width: 20 },
      ]);
    }
  }, [disabled]);

  const datasource = {
    async getRows(params) {
      const request = params.request;
      const isActiveFilter = request.filterModel?.["is_active"];
      const activeFilterValue =
        isActiveFilter?.values[0] === "active" ? "true" : "false";
      const filterModel = {
        sku_name: {
          ...request.filterModel.sku_name,
          mode: "insensitive",
        },
        item_name: {
          ...request.filterModel.item_name,
          mode: "insensitive",
        },
        item_desc: {
          ...request.filterModel.item_desc,
          mode: "insensitive",
        },
        process_type: {
          ...request.filterModel.process_type,
          mode: "insensitive",
        },
        item_kind: {
          ...request.filterModel.item_kind,
          mode: "insensitive",
        },
        item_type: {
          ...request.filterModel.item_type,
          mode: "insensitive",
        },
        is_active:
          isActiveFilter && isActiveFilter?.values?.length === 1
            ? {
                filterType: "boolean",
                type: "equals",
                filter: activeFilterValue,
              }
            : undefined,
      };
      const skuNameSortModel = request.sortModel?.find(
        (column) => column.colId === "sku_name"
      );
      const sortModel = [
        ...request.sortModel,
        {
          colId: "sku_name",
          sort: skuNameSortModel ? skuNameSortModel.sort : "desc",
        },
      ];
      dispatch(
        getAllItemsSku(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterModel,
            sortModel: sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const finishItemListSelectHandler = (data) => {
    if (data && data.length > 0) {
      let filteredItemList = watchFieldArray.filter((item) =>
        itemListIds.includes(item.sku_name)
      );

      data.forEach((item) => {
        const sku = item.sku;
        let img_url = sku.item.is_variation
          ? null
          : sku.item.img_url[0] || null;
        if (sku.item.is_variation) {
          const variant_value =
            sku.item_variant_value?.[0]?.item_variant_value_name;
          const colorList =
            sku.item.item_variant_key_list?.[0].item_variant_value_list.map(
              (color) => color.item_variant_value_name
            );

          const foundColorIndex = colorList?.indexOf(variant_value);
          if (foundColorIndex !== -1) {
            img_url =
              sku.item.item_variant_key_list?.[3].item_variant_value_list?.[
                foundColorIndex
              ]?.item_variant_value_name;
          }
        }
        const splitedSkuName = sku.sku_name.split("-");
        const formatItemList = {
          item_unique_id: sku.item_unique_id,
          sku_name: sku.sku_name,
          item_name: sku.item.name,
          img_url,
          item_type: sku.item.item_type,
          block: sku.item.block,
          process_type: sku.item.process_type,
          item_kind: sku?.sku_detail?.item_kind || "-",
          other_cost: sku.sku_detail?.other_cost || 0,
          color:
            sku.item.is_variation &&
            splitedSkuName.length > 1 &&
            sku.sku_name.split("-")[splitedSkuName.length - 3]
              ? sku.sku_name.split("-")[splitedSkuName.length - 3]
              : "-",
          size:
            sku.item.is_variation &&
            splitedSkuName.length > 1 &&
            sku.sku_name.split("-")[splitedSkuName.length - 2]
              ? sku.sku_name.split("-")[splitedSkuName.length - 2]
              : "-",
          arm:
            sku.item.is_variation &&
            splitedSkuName.length > 1 &&
            sku.sku_name.split("-")[splitedSkuName.length - 1]
              ? sku.sku_name.split("-")[splitedSkuName.length - 1]
              : "-",
          neck: sku.item.is_variation ? "คอกลม" : "",
          qty: 1,
          price_per_unit: sku?.sku_detail?.sale_price ?? 0,
          uom: sku?.item?.uom || "-",
          discount: 0,
          total_amount: 0,
          item_remark: "",
        };
        filteredItemList.push(formatItemList);
      });

      replace(filteredItemList);
    }

    closeItemListModalHandler();
  };

  const removeItem = (index) => {
    remove(index);
    setItemListIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setItemListIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const TotalAmount = ({ control, index }) => {
    const watchQty = useWatch({
      control,
      name: `item_list[${index}].qty`,
    });
    const watchPricePerUnit = useWatch({
      control,
      name: `item_list[${index}].price_per_unit`,
    });
    const watchDiscount = useWatch({
      control,
      name: `item_list[${index}].discount`,
    });
    const totalAmount = watchQty * (watchPricePerUnit - watchDiscount);
    return (
      <TableCell align="right" width={header[11]?.width + 32}>
        <Typography sx={{ minWidth: 150 }}>
          {formatNumber(totalAmount)}
        </Typography>
      </TableCell>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const dragEndHandler = (result) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  return (
    <CustomizedBox padding={0}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "#EFF2FF" }}>
            <TableRow>
              {header.map((header, index) => (
                <TableCell align="center" key={index} width="max-content">
                  <Typography
                    width={header.width}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {header.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((row, index) => {
                    const isItemExist =
                      existingItemIds &&
                      existingItemIds.includes(row.item_unique_id);
                    return (
                      <Draggable
                        key={row.id}
                        draggableId={"item-" + row.id}
                        index={index}
                        isDragDisabled={disabled}
                      >
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <TableCell
                              align="center"
                              width={header[0]?.width + 32}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {!disabled && <TocIcon fontSize="small" />}
                                <Typography fontSize={14}>
                                  {index + 1}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              width={header[1]?.width + 32}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  minWidth: "90px",
                                  justifyContent: "center",
                                }}
                              >
                                <Avatar
                                  alt={row.item_name}
                                  src={row.img_url}
                                  sx={{
                                    width: 60,
                                    height: 60,
                                    border: "1px solid #BEBEBE",
                                    borderRadius: "2px",
                                  }}
                                  variant="square"
                                >
                                  <ImageOutlinedIcon
                                    sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                                    fontSize="medium"
                                  />
                                </Avatar>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              width={header[2]?.width + 32}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  width: 325,
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  onClick={() =>
                                    openItemInfoModalHandler(row.item_unique_id)
                                  }
                                >
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: "#32418F",
                                      textAlign: "left",
                                    }}
                                  >
                                    {`${row.sku_name}`}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: "#32418F",
                                      textAlign: "left",
                                    }}
                                  >
                                    {`${row.item_name}`}
                                  </Typography>
                                  {existingItemIds?.length > 0 &&
                                    !isItemExist && (
                                      <Typography
                                        sx={(theme) => ({
                                          mt: 1,
                                          fontSize: "0.75rem",
                                          color: theme.palette.error.main,
                                        })}
                                      >
                                        สินค้านี้ถูกลบออกจากระบบแล้ว
                                        กรุณาลบสินค้า
                                      </Typography>
                                    )}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              width={header[3]?.width + 32}
                            >
                              <Typography>{row.color}</Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              width={header[4]?.width + 32}
                            >
                              <Typography>{row.size}</Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              width={header[5]?.width + 32}
                            >
                              <Typography>{row.arm}</Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              width={header[6]?.width + 32}
                            >
                              {disabled ? (
                                <Typography>{row.neck}</Typography>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                    width: 125,
                                    alignItems: "center",
                                  }}
                                >
                                  <ControlledServerSideCreatable
                                    control={control}
                                    name={`item_list[${index}].neck`}
                                    fieldToSet="item_neck"
                                    documentType="order"
                                    error={
                                      errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index].neck
                                    }
                                    defaultOptions={[]}
                                    setValue={setValue}
                                  />
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              width={header[7]?.width + 32}
                            >
                              {disabled ? (
                                <Typography>
                                  {formatNumber(row.qty, true)}
                                </Typography>
                              ) : (
                                <ControlledTextField
                                  sx={{ width: 80 }}
                                  type="number"
                                  name={`item_list[${index}].qty`}
                                  control={control}
                                  InputProps={{
                                    inputProps: {
                                      min: 1,
                                      style: {
                                        textAlign: "right",
                                      },
                                    },
                                  }}
                                  error={Boolean(
                                    errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index].qty
                                  )}
                                  helperText={
                                    errors.item_list &&
                                    errors.item_list[index] &&
                                    errors.item_list[index].qty &&
                                    errors.item_list[index].message
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              width={header[8]?.width + 32}
                            >
                              {disabled ? (
                                <Box sx={{ minWidth: 100 }}>
                                  <Typography>
                                    {formatNumber(row.price_per_unit)}
                                  </Typography>
                                </Box>
                              ) : (
                                <ControlledTextField
                                  sx={{ width: 150 }}
                                  name={`item_list[${index}].price_per_unit`}
                                  control={control}
                                  type="number"
                                  InputProps={{
                                    inputProps: {
                                      min: 0,
                                      style: {
                                        textAlign: "right",
                                      },
                                    },
                                  }}
                                  error={Boolean(
                                    errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index].price_per_unit
                                  )}
                                  helperText={
                                    errors.item_list &&
                                    errors.item_list[index] &&
                                    errors.item_list[index].price_per_unit &&
                                    "กรุณาระบุ"
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              width={header[9]?.width + 32}
                            >
                              <Typography sx={{ minWidth: 80 }}>
                                {row.uom}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              width={header[10]?.width + 32}
                            >
                              {disabled ? (
                                <Typography>
                                  {formatNumber(row.discount)}
                                </Typography>
                              ) : (
                                <ControlledTextField
                                  sx={{ width: 150 }}
                                  name={`item_list[${index}].discount`}
                                  control={control}
                                  type="number"
                                  InputProps={{
                                    inputProps: {
                                      min: 0,
                                      style: {
                                        textAlign: "right",
                                      },
                                    },
                                  }}
                                  error={Boolean(
                                    errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index].discount
                                  )}
                                  helperText={
                                    errors.item_list &&
                                    errors.item_list[index] &&
                                    errors.item_list[index].discount &&
                                    errors.item_list[index].message
                                  }
                                />
                              )}
                            </TableCell>
                            <TotalAmount control={control} index={index} />
                            <TableCell
                              align="center"
                              width={header[12]?.width + 32}
                            >
                              {disabled ? (
                                <Box sx={{ minWidth: 120 }}>
                                  <Typography>{row.item_remark}</Typography>
                                </Box>
                              ) : (
                                <ControlledTextField
                                  sx={{ width: 150 }}
                                  name={`item_list[${index}].item_remark`}
                                  control={control}
                                  error={Boolean(
                                    errors.item_list &&
                                      errors.item_list[index] &&
                                      errors.item_list[index].item_remark
                                  )}
                                  helperText={
                                    errors.item_list &&
                                    errors.item_list[index] &&
                                    errors.item_list[index].item_remark &&
                                    errors.item_list[index].message
                                  }
                                />
                              )}
                            </TableCell>
                            {disabled ? null : (
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    openItemDeleteModalHandler(index)
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!disabled && (
        <CustomizedButton
          sx={{ m: 2 }}
          title="เพิ่มสินค้า"
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={openItemListModalHandler}
        />
      )}
      <CheckboxModalTable
        getRowId={getRowId}
        modalTitle={t("inventory.items.index")}
        btnTitle={t("button.add")}
        gridRef={selectItemListRef}
        height={450}
        columnDefs={itemSkuColumnDefs(t, false, true)}
        rowSelection="multiple"
        onFinishEditing={finishItemListSelectHandler}
        modalIsOpen={openItemListModal}
        closeModal={closeItemListModalHandler}
        onGridReady={onGridReady}
        selectedIds={itemListIds}
        setSelectedIds={setItemListIds}
        idsSnapshot={itemListIdsSnapshot}
        setIdsSnapshot={setItemListIdsSnapshot}
        allowDuplicates
      />
      <ItemInfoModalUI
        itemId={itemId}
        modalIsOpen={openItemInfoModal}
        closeModal={() => setOpenItemInfoModal(false)}
      />
      <ModalUI
        title="ยืนยันการลบรายการสินค้า"
        open={openItemDeleteConfirmation}
        handleClose={closeItemDeleteHandler}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={closeItemDeleteHandler}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={deleteItemActionHandler}
          />
        </Box>
      </ModalUI>
    </CustomizedBox>
  );
};

export default SOItemTable;
