// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { useState } from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ResponsiveBar } from "@nivo/bar";
import { formatDashboardStatus } from "../../utils/salesPayloadFormatter";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const tooltipBackgroundColors = {
  draft_count: "#9FA8DA",
  wait_customer_accept_count: "#1565C0",
  wait_payment_when_receive_count: "#FFCA28",
  wait_payment_cod_count: "#FB8C00",
  partially_paid_count: "#CDDC39",
  fully_paid_count: "#7CB342",
  finished_count: "#33691E",
  cancelled_count: "#DADADA",
};

const unfocusedTooltipbackgroundColors = {
  draft_count: "#CBCFEB",
  wait_customer_accept_count: "#ADD3FF",
  wait_payment_when_receive_count: "#FDEEBF",
  wait_payment_cod_count: "#FFCA87",
  partially_paid_count: "#E7EEA6",
  fully_paid_count: "#C6DFAC",
  finished_count: "#9FC590",
  cancelled_count: "#EEEDED",
};

const statusList = [
  "draft_count",
  "wait_customer_accept_count",
  "wait_payment_when_receive_count",
  "wait_payment_cod_count",
  "partially_paid_count",
  "fully_paid_count",
  "finished_count",
  "cancelled_count",
];

const BarTotalsLayer =
  ({ currentStatus }) =>
  (props) => {
    const labelOffset = 10;
    const labelFontSize = 12;
    if (props.bars.length === 0) return null;
    // compute totals for each index/bar
    const totals = {};
    const bandwidth = props.bars[0].width;
    props.bars.forEach((bar) => {
      const indexValue = bar.data.indexValue;
      if (!(indexValue in totals)) {
        totals[indexValue] = 0;
      }
      if (!bar.data.hidden) {
        totals[indexValue] += bar.data.value;
      }
    });
    // place text elements above the bars
    const labels = Object.keys(totals).map((indexValue) => {
      const x = props.xScale(indexValue) + bandwidth / 2;
      const y = props.yScale(totals[indexValue]) - labelOffset;
      return (
        <text
          key={"total." + indexValue}
          x={x}
          y={y}
          textAnchor={"middle"}
          fontSize={labelFontSize}
        >
          {totals[indexValue]}
        </text>
      );
    });
    return <>{labels}</>;
  };

const SOCountBarChart = ({ data, bottomAxisLabel }) => {
  const theme = useTheme();
  const [palette, setPalette] = useState(
    Object.values(tooltipBackgroundColors)
  );
  const [currentStatus, setCurrentStatus] = useState(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const shouldShrinkLabel = data?.length >= 18 || isSmallScreen;

  const onMouseEnterHandler = (id) => {
    setCurrentStatus(id);
    const updatedPalette = {
      ...unfocusedTooltipbackgroundColors,
      [id]: tooltipBackgroundColors[id],
    };
    setPalette(Object.values(updatedPalette));
  };

  const onMouseLeaveHandler = () => {
    setCurrentStatus(null);
    setPalette(Object.values(tooltipBackgroundColors));
  };

  return (
    <Box
      sx={{
        height: 450,
        border: "1px solid #BEBEBE",
        borderRadius: "15px",
        mt: 4,
      }}
    >
      <ResponsiveBar
        data={data}
        keys={[
          "draft_count",
          "wait_customer_accept_count",
          "wait_payment_when_receive_count",
          "wait_payment_cod_count",
          "partially_paid_count",
          "fully_paid_count",
          "finished_count",
          "cancelled_count",
        ]}
        indexBy="created_date"
        margin={{
          top: isSmallScreen ? 250 : 100,
          right: 50,
          bottom: shouldShrinkLabel ? 100 : 50,
          left: 120,
        }}
        padding={0.3}
        theme={{ fontFamily: ["Kanit", "sans-serif"].join(", ") }}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        onMouseEnter={({ id }) => onMouseEnterHandler(id)}
        onMouseLeave={onMouseLeaveHandler}
        colors={palette}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        tooltip={({ data, id, value }) => {
          return (
            <Box
              sx={{
                boxShadow: "0px 2px 10px #E5E5E5",
                borderRadius: "5px",
                overflow: "hidden",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#EFF2FF",
                  backgroundColor: tooltipBackgroundColors[id],
                  p: 1,
                }}
              >{`สถานะ ${formatDashboardStatus(id)}`}</Box>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#FFFFFF",
                  color: "#333333",
                  p: 1,
                }}
              >
                <Typography
                  fontWeight={400}
                >{`${data.created_date}:`}</Typography>
                <Typography
                  sx={{ ml: 0.5 }}
                  fontWeight={600}
                >{`${value} ออเดอร์`}</Typography>
              </Box>
            </Box>
          );
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: shouldShrinkLabel ? -90 : 0,
          legend: bottomAxisLabel,
          legendPosition: "middle",
          legendOffset: shouldShrinkLabel ? 80 : 32,
        }}
        axisLeft={{
          tickSize: 10,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 5,
          legend: "จำนวน",
          legendPosition: "middle",
          legendOffset: -90,
        }}
        layers={[
          "grid",
          "axes",
          "markers",
          "bars",
          "legends",
          "annotations",
          BarTotalsLayer({ currentStatus }),
        ]}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legendLabel={(datum) => {
          return formatDashboardStatus(datum.id);
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: isSmallScreen ? "top-left" : "top",
            direction: isSmallScreen ? "column" : "row",
            justify: false,
            translateX: isSmallScreen ? -50 : 0,
            translateY: isSmallScreen ? -225 : -50,
            itemsSpacing: 2,
            itemWidth: 120,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Sales Order Confirmed Status Count Chart"
        barAriaLabel={function (e) {
          return (
            e.id + ": " + e.formattedValue + " in country: " + e.indexValue
          );
        }}
        enableLabel={false}
      />
    </Box>
  );
};

export default SOCountBarChart;
