import { Box } from "@mui/material";
import { formatNumber } from "../../../../../utils/dataTransformer";
import {
  unixToDateWithFormat,
  formatDate,
} from "../../../../../utils/date-converter";
import {
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../../../utils/filterparams";
import CustomizedAvatar from "../../../../Custom/CustomizedAvatar";
import CustomizedChips from "../../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../../Custom/CustomizedStatus";
import { dateComparator } from "../../../../../utils/filterparams";

export const salesOrderColumnDef = (t, isReport, tagOptions) => {
  if (isReport) {
    return [
      {
        field: "unique_id",
        headerName: t("sales.documentId"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "created_by",
        headerName: t("sales.order.created_by"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 125,
      },
      {
        field: "related_employees",
        headerName: t("sales.order.related_employee"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
        sortable: false,
        suppressColumnsToolPanel: true,
        width: 125,
      },
      {
        field: "render_status",
        headerName: t("status.index"),
        sortable: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "ร่าง",
            "รอลูกค้ายืนยัน",
            "รอชำระเมื่อรับสินค้า",
            "รอชำระ COD",
            "ชำระแล้วบางส่วน",
            "ชำระแล้วเต็มจำนวน",
            "เสร็จสิ้น",
            "ยกเลิก",
          ],
          suppressSorting: true,
        },
        cellRenderer: ({ value }) => {
          return <CustomizedStatus status={value} />;
        },
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
      },
      {
        field: "is_open_manufacture",
        headerName: t("sales.order.is_open_manufacture"),
        filter: false,
        hide: true,
        sortable: false,
        suppressColumnsToolPanel: true,
      },
      {
        field: "created_date",
        headerName: t("sales.order.created_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "payment_date",
        headerName: t("sales.order.payment_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "due_date",
        headerName: t("sales.order.due_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "delivery_date",
        headerName: t("sales.order.delivery_date"),
        filter: false,
        hide: true,
        sortable: false,
        suppressColumnsToolPanel: true,
      },
      {
        field: "receive_date",
        headerName: t("sales.order.receive_date"),
        filter: "agDateColumnFilter",
        width: 150,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "contact_unique_id",
        headerName: t("sales.order.contact_unique_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "contact_name",
        headerName: t("sales.order.customer_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 125,
      },
      {
        field: "phone_main",
        headerName: t("sales.order.phone_main"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "phone_sub",
        headerName: t("sales.order.phone_sub"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "email",
        headerName: t("sales.order.email"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "delivery_address",
        headerName: t("sales.order.delivery_address"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "sub_district",
        headerName: t("contact.address.sub_district"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "district",
        headerName: t("contact.address.district"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "province",
        headerName: t("contact.address.province"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "postal_code",
        headerName: t("contact.address.postal_code"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "zone",
        headerName: t("contact.address.region"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "country",
        headerName: t("contact.address.country"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "item_unique_id",
        headerName: t("reports.sales_order.item_unique_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "sku_name",
        headerName: "รหัสสินค้า / SKU - ชื่อสินค้า",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "color",
        headerName: t("inventory.variant.color"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "size",
        headerName: t("inventory.variant.size"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "arm",
        headerName: t("inventory.variant.arm"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "item_remark",
        headerName: "หมายเหตุรายการสินค้า",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "item_amount",
        headerName: t("inventory.quantity"),
        filter: false,
        suppressColumnsToolPanel: true,
        width: 125,
      },
      {
        field: "price_per_unit",
        headerName: t("inventory.items.pricePerUnit"),
        filter: false,
        suppressColumnsToolPanel: true,
        width: 160,
      },
      {
        field: "uom",
        headerName: t("inventory.unit"),
        filter: false,
        suppressColumnsToolPanel: true,
        width: 100,
      },
      {
        field: "item_discount",
        headerName: t("inventory.discount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "total_after_item_discount",
        headerName: "รวมก่อนส่วนลดเพิ่มเติมรายสินค้า",
        filter: false,
        width: 275,
      },
      // {
      //   field: "item_total_price_pre_additional_discount",
      //   headerName: "รวมก่อนส่วนลดเพิ่มเติมรายสินค้า",
      //   filter: false,
      //   hide: true,
      //   suppressColumnsToolPanel: true,
      // },
      {
        field: "item_additional_discount",
        headerName: "ส่วนลดเพิ่มเติมรายสินค้า",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "item_total_price_additional_discount",
        headerName: "รวมหลังส่วนลดเพิ่มเติมรายสินค้า",
        filter: false,
        width: 275,
      },
      {
        field: "item_price_exclude_vat",
        headerName: "ไม่รวมภาษีมูลค่าเพิ่มรายสินค้า",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "item_vat",
        headerName: "ภาษีมูลค่าเพิ่มรายสินค้า",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "total_price",
        headerName: "รวมทั้งหมด",
        filter: false,
        width: 125,
      },
      {
        field: "additional_discount",
        headerName: "ส่วนลดเพิ่มเติม",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "total_discount",
        headerName: "ส่วนลดทั้งหมด",
        filter: false,
        width: 150,
      },
      {
        field: "total_amount",
        headerName: "ยอดรวมทั้งสิ้น",
        filter: false,
        width: 150,
      },
      {
        field: "vat_type",
        headerName: t("sales.order.vat_type"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "vat_amount",
        headerName: "ภาษีมูลค่าเพิ่มรวม",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "total_price_exclude_vat",
        headerName: "ราคาไม่รวมภาษีมูลค่าเพิ่ม",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "remark",
        headerName: t("sales.order.remark"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "sales_channel",
        headerName: t("sales.order.sales_channel"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 175,
      },
      {
        field: "account_id",
        headerName: t("sales.order.account_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "brand_name",
        headerName: t("sales.order.brand_name"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "tracking_number",
        headerName: t("sales.order.tracking_number"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "is_delivery",
        headerName: t("sales.order.delivery_status"),
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
          values: ["จัดส่งแล้ว", "ยังไม่จัดส่ง"],
        },
        width: 150,
      },
      {
        field: "tag_order",
        headerName: "Tag Order",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 150,
      },
      {
        field: "sale_cancel_remark",
        headerName: "หมายเหตุการยกเลิกเกี่ยวกับการขายและการบริการ",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "delivery_cancel_remark",
        headerName: "หมายเหตุการยกเลิกเกี่ยวกับคุณภาพและการจัดส่ง",
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "mo_unique_id",
        headerName: t("reports.sales_order.mo_unique_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 175,
      },
    ];
  }

  const columnDefs = [
    {
      field: "unique_id",
      headerName: t("sales.documentId"),
      filter: "agTextColumnFilter",
      sort: "desc",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 175,
    },
    {
      field: "created_date",
      headerName: t("sales.order.created_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: ({ data }) =>
        data.created_date ? formatDate(data.created_date) : "-",
      width: 150,
    },
    {
      field: "payment_date",
      headerName: t("sales.order.payment_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: ({ data }) =>
        data.payment_date ? formatDate(data.payment_date) : "-",
      width: 150,
    },
    {
      field: "due_date",
      headerName: t("sales.order.due_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: ({ data }) =>
        data.due_date ? formatDate(data.due_date) : "-",
      width: 150,
    },
    {
      field: "receive_date",
      headerName: t("sales.order.receive_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: ({ data }) =>
        data.receive_date ? formatDate(data.receive_date) : "-",
      width: 150,
    },
    {
      field: "sales_channel",
      headerName: t("sales.order.sales_channel"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 175,
    },
    {
      field: "account_id",
      headerName: t("sales.order.account_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
    },
    {
      field: "contact_name",
      headerName: t("sales.order.customer_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueFormatter: ({ value }) => value ?? "-",
      width: 150,
    },
    {
      field: "net_amount",
      headerName: t("sales.order.net_amount"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: ({ value }) => (value ? formatNumber(value) : "-"),
      width: 150,
    },
    {
      field: "phone_main",
      headerName: t("sales.order.phone"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueFormatter: ({ value }) => value ?? "-",
      width: 150,
    },
    // {
    //   field: "tag_list",
    //   headerName: t("sales.order.tag"),
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     filterOptions: filterParamsOptions("string"),
    //   },
    // },
    {
      field: "tag_list",
      headerName: t("sales.order.tag"),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          if (params.value) {
            return params.value;
          }
          return "-";
        },
        values: tagOptions,
      },
      cellRenderer: ({ value }) => {
        if (value.length === 0) {
          return "";
        }
        return value.map((tag, index) => (
          <Box sx={{ display: "inline-block", mr: 1 }} key={`${tag}-${index}`}>
            <CustomizedChips value={tag} />
          </Box>
        ));
      },
      width: 125,
    },
    {
      field: "created_by",
      headerName: t("sales.order.created_by"),
      filter: false,
      // filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
      cellRenderer: (params) => (
        <CustomizedAvatar avatars={[params.data.created_by]} />
      ),
      valueGetter: (params) =>
        params.data.created_by.first_name +
        " " +
        params.data.created_by.last_name,
      width: 125,
    },
    {
      field: "remark",
      headerName: t("sales.order.remark"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 125,
    },
    {
      field: "is_delivery",
      headerName: t("sales.order.delivery_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        values: ["จัดส่งแล้ว", "ยังไม่จัดส่ง"],
      },
      valueFormatter: ({ value }) => (value ? "จัดส่งแล้ว" : "ยังไม่จัดส่ง"),
      width: 150,
    },
    {
      field: "render_status",
      headerName: t("status.index"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value, t);
        },
        values: [
          "draft",
          "wait_customer_accept",
          "wait_payment_when_receive",
          "wait_payment_cod",
          "partially_paid",
          "fully_paid",
          "finished",
          "cancelled",
        ],
        suppressSorting: true,
      },
      cellRenderer: ({ value }) => {
        return <CustomizedStatus status={value} />;
      },
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "is_open_manufacture",
      headerName: t("sales.order.is_open_manufacture"),
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        values: ["เปิดแล้ว", "ยังไม่เปิด"],
      },
      valueFormatter: ({ value }) => (value ? "เปิดแล้ว" : "ยังไม่เปิด"),
      width: 175,
    },
  ];
  return columnDefs;
};
