import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledSelect from "../../Controlled/ControlledSelect";
import ControlledTextField from "../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../Custom/CustomizedBox";

const deliveryOptions = ["ยังไม่จัดส่ง", "จัดส่งแล้ว"];

const DeliveryDetailForm = ({ control, errors, disabled }) => {
  const { t } = useTranslation();

  return (
    <CustomizedBox>
      <Typography sx={{ ml: 1, fontWeight: "bold" }}>
        {t("sales.order.delivery_detail")}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="tracking_number"
            label={t("sales.order.tracking_number")}
            control={control}
            error={Boolean(errors?.tracking_number)}
            helperText={
              errors?.tracking_number && errors?.tracking_number.message
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledSelect
            name="is_delivery"
            label={t("sales.order.delivery_status")}
            control={control}
            error={errors.is_delivery}
            options={deliveryOptions}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default DeliveryDetailForm;
