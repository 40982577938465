import ManufactureService from "../../../services/Manufacture";
import { manufactureOrderActions } from "./manufacture-order-slice";
import { initialState } from "./manufacture-order-initial";
import {
  formatManufactureOrderPayload,
  formattedStatus,
  manufactureOrderExportFormatter,
} from "../../../utils/manufacturePayloadFormatter";
import {
  createActivityLogPayload,
  createActivityLogEditPayload,
  createActivityLogStatusPayload,
} from "../../../utils/activityLogsPayloadFormatter";
import ActivityLogsService from "../../../services/ActivityLogs";
import GlobalService from "../../../services/Global";
import SettingService from "../../../services/Setting";

export const getAllManufactureOrders =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("allManufactureOrders"));
    try {
      const allManufactureOrders =
        await ManufactureService.getManufactureOrdersAggrid(input);
      params.successCallback(
        allManufactureOrders.results,
        allManufactureOrders.count
      );
      dispatch(
        manufactureOrderActions.loadedAllManufactureOrders({
          results: allManufactureOrders.results,
          count: allManufactureOrders.count,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "allManufactureOrders",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createManufactureOrder =
  (createManufactureOrderInput, submitType, navigate, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      const formattedPayload = formatManufactureOrderPayload(
        createManufactureOrderInput,
        "create",
        submitType
      );
      const result = await ManufactureService.createManufactureOrder({
        ...formattedPayload,
        render_status:
          submitType === "sendToManufacture" ? "wait_manufacture" : "draft",
        sub_status: "wait_manufacture",
      });
      await GlobalService.documentSetStep({
        unique_id: result.unique_id,
        document_type: "manufacture_order",
        step: 1,
      });
      if (submitType === "sendToManufacture") {
        await GlobalService.postNextStatus({
          unique_id: result.unique_id,
          document_type: "manufacture_order",
        });
      }
      dispatch(manufactureOrderActions.loadedManufactureOrder(result));
      const createActivityLog = createActivityLogPayload(
        { ...formattedPayload, unique_id: result.unique_id },
        "manufacture_order",
        "สร้างใบสั่งผลิต"
      );
      const statusLogPayload = createActivityLogStatusPayload(
        { unique_id: result.unique_id },
        "manufacture_order",
        "",
        submitType === "sendToManufacture" ? "รอผลิต" : "ร่าง"
      );
      try {
        await ActivityLogsService.createActivityLogs({
          createInput: createActivityLog,
        });
        await ActivityLogsService.createActivityLogs({
          createInput: statusLogPayload,
        });
      } catch (err) {
        console.log("Cannot create manufacture order activity log");
      }
      navigate(`/manufacture/order/${result.unique_id}`);
      if (submitType === "draft") {
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("บันทึกสำเร็จ", {
          variant: "success",
        });
      }
    } catch (err) {
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      if (JSON.stringify(err).includes("Unique constraint failed")) {
        enqueueSnackbar("บันทึกไม่ได้ เนื่องจากเลขที่เอกสารซ้ำ", {
          variant: "error",
        });
        return;
      }
      if (JSON.stringify(err).includes("Reference already exists")) {
        enqueueSnackbar("ไม่สามารถสร้างได้ เนื่องจากใบสั่งขายมีใบสั่งผลิตอยู่แล้ว", {
          variant: "error",
        });
        return;
      }
      enqueueSnackbar("บันทึกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const updateManufactureOrder =
  (updateInput, submitType, enqueueSnackbar, setIsInit, setDisabled) =>
  async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      const uniqueInput = { unique_id: updateInput.unique_id };
      const formattedUpdatePayload = await formatManufactureOrderPayload(
        updateInput,
        "update",
        submitType
      );
      if (submitType === "sendToManufacture") {
        await ManufactureService.updateManufactureOrder(uniqueInput, {
          ...formattedUpdatePayload,
          render_status: "wait_manufacture",
        });
        await GlobalService.postNextStatus({
          unique_id: updateInput.unique_id,
          document_type: "manufacture_order",
        });
      } else {
        await ManufactureService.updateManufactureOrder(
          uniqueInput,
          formattedUpdatePayload
        );
      }
      const editLogPayload = createActivityLogEditPayload(
        uniqueInput,
        "manufacture_order",
        "แก้ไขใบสั่งผลิต"
      );
      let statusLogPayload;
      if (submitType === "sendToManufacture") {
        statusLogPayload = createActivityLogStatusPayload(
          uniqueInput,
          "manufacture_order",
          updateInput.status,
          "รอผลิต"
        );
      }
      try {
        if (statusLogPayload) {
          await ActivityLogsService.createActivityLogs({
            createInput: editLogPayload,
          });
          await ActivityLogsService.createActivityLogs({
            createInput: statusLogPayload,
          });
        }
      } catch (err) {
        console.log("Cannot update manufacture order activity log");
      }
      if (submitType === "draft") {
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("บันทึกสำเร็จ", {
          variant: "success",
        });
      }
      setIsInit(false);
      setDisabled(true);
    } catch (err) {
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      enqueueSnackbar("บันทึกไม่สำเร็จ", {
        variant: "error",
      });
      console.log(err);
    }
  };

export const updateManufactureOrderStatus =
  (uniqueId, status, targetStatus, enqueueSnackbar, setIsInit) =>
  async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));

    const currentManufacturingOptions = ["wait_manufacture", "manufacturing"];
    const manufacturingStatusOptions = ["รอผลิต", "กำลังผลิต"];
    const uniqueInput = { unique_id: uniqueId };

    try {
      if (manufacturingStatusOptions.includes(targetStatus)) {
        if (!currentManufacturingOptions.includes(status)) {
          await GlobalService.documentSetStep({
            unique_id: uniqueId,
            document_type: "manufacture_order",
            step: 2,
          });
        }
        await ManufactureService.updateManufactureOrder(uniqueInput, {
          main_status: "manufacturing",
          sub_status: targetStatus,
          render_status: formattedStatus({
            main_status: "manufacturing",
            sub_status: targetStatus,
          }),
        });
      }
      if (targetStatus === "เสร็จสิ้น") {
        await GlobalService.documentSetStep({
          unique_id: uniqueId,
          document_type: "manufacture_order",
          step: 3,
        });
        await ManufactureService.updateManufactureOrder(uniqueInput, {
          main_status: "finished",
          render_status: formattedStatus({
            main_status: "finished",
          }),
        });
      }
      if (targetStatus === "ยกเลิก") {
        await ManufactureService.updateManufactureOrder(uniqueInput, {
          flag_status: "cancelled",
          render_status: formattedStatus({
            flag_status: "cancelled",
          }),
        });
      }
      try {
        const statusLogPayload = createActivityLogStatusPayload(
          { unique_id: uniqueId },
          "manufacture_order",
          status,
          targetStatus
        );
        await ActivityLogsService.createActivityLogs({
          createInput: statusLogPayload,
        });
      } catch (err) {
        console.log("Cannot create manufacture order status activity log");
      }
      const manufactureOrder = await ManufactureService.getManufactureOrder(
        uniqueInput
      );
      dispatch(
        manufactureOrderActions.loadedManufactureOrder(manufactureOrder)
      );
      enqueueSnackbar("เปลี่ยนสถานะสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.log(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
    setIsInit(false);
  };

export const cancelManufactureOrder =
  (updateInput, enqueueSnackbar, setIsInit) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      const uniqueInput = { unique_id: updateInput.unique_id };
      const formattedUpdatePayload = await formatManufactureOrderPayload(
        updateInput,
        "update",
        "submit"
      );
      await ManufactureService.updateManufactureOrder(uniqueInput, {
        ...formattedUpdatePayload,
        flag_status: "cancelled",
        render_status: formattedStatus({
          flag_status: "cancelled",
        }),
      });
      try {
        const statusLogPayload = createActivityLogStatusPayload(
          { unique_id: updateInput.unique_id },
          "manufacture_order",
          updateInput.status,
          "cancelled"
        );
        await ActivityLogsService.createActivityLogs({
          createInput: statusLogPayload,
        });
      } catch (err) {
        console.log("Cannot create sales order status activity log");
      }
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      setIsInit(false);
    } catch (err) {
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
      console.log(err);
    }
  };

export const getManufactureOrder =
  (uniqueInput, enqueueSnackbar, navigate) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      if (uniqueInput === "init") {
        dispatch(
          manufactureOrderActions.loadedManufactureOrder(
            initialState.manufactureOrder
          )
        );
      } else {
        const manufactureOrder = await ManufactureService.getManufactureOrder(
          uniqueInput
        );
        dispatch(
          manufactureOrderActions.loadedManufactureOrder(manufactureOrder)
        );
        return manufactureOrder;
      }
    } catch (err) {
      console.log(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
      navigate("/manufacture/order");
    }
  };

export const getManufactureItemSettings = () => async (dispatch) => {
  dispatch(manufactureOrderActions.onLoading("manufactureItemSettings"));
  try {
    const manufactureItemSettings =
      await SettingService.getManufactureItemSettings();
    dispatch(
      manufactureOrderActions.loadedManufactureItemSettings(
        manufactureItemSettings
      )
    );
  } catch (err) {
    console.log(err);
  }
};

export const getAllManufactureOrdersExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("allManufactureOrdersExport"));
    try {
      const { results } = await ManufactureService.getManufactureOrdersAggrid(
        input
      );
      let payments = await manufactureOrderExportFormatter(results);
      dispatch(
        manufactureOrderActions.loadedAllManufactureOrdersExport(payments)
      );
    } catch (err) {
      console.log(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "allManufactureOrdersExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
