import { gql } from "graphql-request";

export const CREATE_MANUFACTURE_ITEM_SETTING = gql`
  mutation ManufactureOrderCreate(
    $createInput: ManufactureItemSettingCreateInput!
  ) {
    manufactureItemSettingCreate(createInput: $createInput) {
      id
      setting_index
      brand_name
      vendor_unique_id
      vendor {
        name
        last_name
      }
      item_type
      block
      process_type
      printing_cost
      hot_rolled_cost
      fold_cost
    }
  }
`;

export const UPDATE_MANUFACTURE_ITEM_SETTING = gql`
  mutation ManufactureItemSettingUpdate(
    $uniqueInput: ManufactureItemSettingUniqueInput!
    $updateInput: ManufactureItemSettingUpdateInput!
  ) {
    manufactureItemSettingUpdate(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      setting_index
      brand_name
      vendor_unique_id
      vendor {
        name
        last_name
      }
      item_type
      block
      process_type
      printing_cost
      hot_rolled_cost
      fold_cost
    }
  }
`;

export const UPSERT_OR_DELETE_MANUFACTURE_ITEM_SETTINGS = gql`
  mutation ManufactureItemSettingUpsertOrDeleteMany(
    $idsToDelete: [Int]!
    $upsertManyInput: [ManufactureItemCreateOrUpdateInput]!
  ) {
    manufactureItemSettingUpsertOrDeleteMany(
      idsToDelete: $idsToDelete
      upsertManyInput: $upsertManyInput
    ) {
      duplicatedIndexes
      manufactureItemSettings {
        id
        setting_index
        brand_name
        vendor_unique_id
        vendor {
          name
          last_name
        }
        item_type
        block
        process_type
        printing_cost
        hot_rolled_cost
        fold_cost
      }
    }
  }
`;

export const DELETE_MANUFACTURE_ITEM_SETTING = gql`
  mutation ManufactureItemSettingDelete(
    $uniqueInput: ManufactureItemSettingUniqueInput!
  ) {
    manufactureItemSettingDelete(uniqueInput: $uniqueInput) {
      id
      setting_index
      brand_name
      vendor_unique_id
      vendor {
        name
        last_name
      }
      process_type
      printing_cost
      hot_rolled_cost
      fold_cost
    }
  }
`;
