import { Navigate, Route, Routes } from "react-router-dom";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import DashboardLayout from "./components/Layout/DashboardLayout";
import ContactIndex from "./pages/Contact";
import Contacts from "./pages/Contact/contacts";
import ContactFormPage from "./pages/Contact/ContactFormPage";
import ContactReportIndex from "./pages/Contact/Report";
import ContactImporter from "./pages/Contact/Importer";
import User from "./pages/User";
import UserAccount from "./pages/User/Account";
import Login from "./pages/Login";
import AccountContainer from "./pages/User/Account/Container";
import Rbac from "./pages/User/Rbac";
import RbacContainer from "./pages/User/Rbac/Container";
import NotFound from "./pages/NotFound";
import NoPermission from "./pages/NoPermission";
import AuthVerify from "./pages/AuthVerify";
import { RemoveTrailingSlash } from "./pages/RemoveTrailingSlash";
import { useAuth } from "./hooks/use-auth";
import Items from "./pages/Inventory/Items";
import ItemImporter from "./pages/Inventory/Items/importer";
import ItemFormPage from "./pages/Inventory/Items/ItemFormPage";
import Inventory from "./pages/Inventory";
import ItemReport from "./pages/Inventory/Items/Report";
import Sales from "./pages/Sales";
import SalesReport from "./pages/Sales/Report";
import OrderReport from "./pages/Sales/Report/OrderReport";
import OrderPaymentReport from "./pages/Sales/Report/OrderPaymentReport";
import Dashboard from "./pages/Sales/Dashboard";
import SalesOrder from "./pages/Sales/SalesOrder";
import SalesOrderContainer from "./pages/Sales/SalesOrder/Container";
import ImageMassUploadPage from "./pages/Tools/ImageMassUpload";
import ManufactureReport from "./pages/Manufacture/Report";
import Manufacture from "./pages/Manufacture";
import ManufactureOrder from "./pages/Manufacture/ManufactureOrder";
import ManufactureOrderContainer from "./pages/Manufacture/ManufactureOrder/Container";
import Setting from "./pages/Setting";
import ManufactureItemSetting from "./pages/Setting/ManufactureItem";

function App() {
  const {
    isInitialized,
    isAuthenticated,
    isAccessTokenExpired,
    isRefreshTokenExpired,
    permissions,
  } = useAuth();
  const inventoryPermission = permissions?.inventory;

  return (
    <>
      <RemoveTrailingSlash />
      <AuthVerify />
      {(!isInitialized || isAccessTokenExpired) && !isRefreshTokenExpired && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {isInitialized && (
        <>
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated && !isRefreshTokenExpired ? (
                  inventoryPermission !== "NONE" ? (
                    <Navigate replace to="/inventory" />
                  ) : (
                    <DashboardLayout />
                  )
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
            <Route
              path="/login"
              element={
                isAuthenticated && !isRefreshTokenExpired ? (
                  <Navigate replace to="/inventory" />
                ) : (
                  <Login />
                )
              }
            />
            {!isAccessTokenExpired && (
              <>
                <Route path="/" element={<DashboardLayout />}>
                  <Route path="/inventory">
                    <Route index element={<Inventory />} />
                    <Route path="items">
                      <Route index element={<Items />} />
                      <Route path="add" element={<ItemFormPage />} />
                      <Route path=":id" element={<ItemFormPage viewOnly />} />
                      <Route path="import" element={<ItemImporter />} />
                    </Route>
                    <Route path="report" element={<ItemReport />} />
                  </Route>
                  <Route path="/contact">
                    <Route index element={<ContactIndex />} />
                    <Route path="contacts">
                      <Route index element={<Contacts />} />
                      <Route path="import" element={<ContactImporter />} />
                    </Route>
                    <Route path="report">
                      <Route index element={<ContactReportIndex />} />
                    </Route>
                    <Route path="add" element={<ContactFormPage />} />
                    <Route path=":id" element={<ContactFormPage />} />
                  </Route>
                  <Route path="/sales">
                    <Route index element={<Sales />} />
                    <Route path="dashboard">
                      <Route index element={<Dashboard />} />
                    </Route>
                    <Route path="order">
                      <Route index element={<SalesOrder />} />
                      <Route path="add" element={<SalesOrderContainer />} />
                      <Route path=":id">
                        <Route index element={<SalesOrderContainer />} />
                      </Route>
                    </Route>
                    <Route path="report">
                      <Route index element={<SalesReport />} />
                      <Route path="order" element={<OrderReport />} />
                      <Route path="payment" element={<OrderPaymentReport />} />
                    </Route>
                  </Route>
                  <Route path="/manufacture">
                    <Route index element={<Manufacture />} />
                    <Route path="order">
                      <Route index element={<ManufactureOrder />} />
                      <Route
                        path="add"
                        element={<ManufactureOrderContainer />}
                      />
                      <Route path=":id">
                        <Route index element={<ManufactureOrderContainer />} />
                      </Route>
                    </Route>
                    <Route path="report">
                      <Route index element={<ManufactureReport />} />
                    </Route>
                  </Route>
                  <Route path="/user">
                    <Route index element={<User />} />
                    <Route path="account">
                      <Route index element={<UserAccount />} />
                      <Route
                        path="add"
                        element={<AccountContainer addable />}
                      />
                      <Route
                        path=":employeeId"
                        element={<AccountContainer viewOnly />}
                      />
                    </Route>
                    <Route path="rbac">
                      <Route index element={<Rbac />} />
                      <Route path="add" element={<RbacContainer addable />} />
                      <Route
                        path=":roleId"
                        element={<RbacContainer viewOnly />}
                      />
                    </Route>
                  </Route>
                  <Route path="/setting">
                    <Route index element={<Setting />} />
                    <Route
                      path="manufacture-item"
                      element={<ManufactureItemSetting />}
                    />
                  </Route>
                  <Route
                    path="tools/image-upload"
                    element={<ImageMassUploadPage />}
                  />
                </Route>
                <Route path="/no-permission" element={<NoPermission />} />
                <Route path="/*" element={<NotFound />} />
              </>
            )}
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
