import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import SOPaidBarChart from "../../../components/UI/SOPaidBarChart";
import SOCountBarChart from "../../../components/UI/SOCountBarChart";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import _ from "lodash";
import { formatDate } from "../../../utils/date-converter";
import { formatNumber } from "../../../utils/dataTransformer";
import { formattedStatus } from "../../../utils/salesPayloadFormatter";
import { getAllSalesOrders } from "../../../features/Sales/SalesOrder/sales-order-actions";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTotalBox from "../../../components/Custom/CustomizedTotalBox";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import DashboardDateFilter from "../../../components/UI/DashboardDateFilter";
import { useTranslation } from "react-i18next";
import { eachDayOfInterval } from "date-fns";
import { useAuth } from "../../../hooks/use-auth";

const getDatesInRange = (startDate, endDate) => {
  return eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((day) => dayjs(day).format("DD/MM/YYYY"));
};

const months_th = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];
const months_th_mini = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, allSalesOrders } = useSelector(
    (state) => state.salesOrder
  );
  const { permissions } = useAuth();
  const salesPermission = permissions.sales;
  const [isInit, setIsInit] = useState(false);
  const [formattedSalesOrders, setFormattedSalesOrders] = useState([]);
  const [datesInRange, setDatesInRange] = useState([]);
  const [bottomAxisLabel, setBottomAxisLabel] = useState("วันที่");

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(
        dayjs().endOf("day").subtract(7, "day").format("DD/MM/YYYY")
      ),
      dateTo: new Date(dayjs().endOf("day")),
    },
  });

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.dashboard.index"),
    },
  ];

  const maxTotalPaid = formattedSalesOrders.reduce(
    (prev, orderGroup) =>
      prev > orderGroup.total_paid ? prev : orderGroup.total_paid,
    0
  );

  const totalPaidConfirmed = formattedSalesOrders.reduce(
    (acc, orderGroup) => acc + orderGroup.total_confirmed_paid,
    0
  );

  const totalConfirmedDocumentsCount = formattedSalesOrders.reduce(
    (acc, orderGroup) => acc + orderGroup.confirmed_count,
    0
  );

  useEffect(() => {
    if (!isInit) {
      getFilteredOrder();
      setIsInit(true);
    }
  }, [isInit]);

  useEffect(() => {
    // if (allSalesOrders.count > 0) {
    let grouped;
    let formattedGroup;
    let sortedGroup = [];
    const dateType = getValues("dateType");

    if (dateType === "lastWeek") {
      grouped = _.groupBy(allSalesOrders.results, (order) =>
        new Date(order.created_date).setHours(0, 0, 0, 0)
      );
    } else if (dateType === "currentYear") {
      grouped = _.groupBy(allSalesOrders.results, (order) =>
        dayjs(new Date(order.created_date).setHours(0, 0, 0, 0)).month()
      );
    } else if (dateType === "allYears") {
      grouped = _.groupBy(allSalesOrders.results, (order) =>
        dayjs(new Date(order.created_date).setHours(0, 0, 0, 0)).year()
      );
    }

    const mappedGrouped = Object.keys(grouped).reduce((acc, key, index) => {
      const currentGroup = Object.values(grouped)[index];
      let formattedKey;
      if (dateType === "lastWeek") {
        formattedKey = formatDate(new Date(currentGroup[0].created_date));
      } else if (dateType === "currentYear") {
        formattedKey =
          months_th[dayjs(new Date(currentGroup[0].created_date)).month()];
      } else if (dateType === "allYears") {
        formattedKey = dayjs(new Date(currentGroup[0].created_date)).year();
      }
      acc[formattedKey] = currentGroup;

      return acc;
    }, []);

    const orderGroupTemplate = {
      draft: 0,
      wait_customer_accept: 0,
      wait_payment_when_receive: 0,
      wait_payment_cod: 0,
      partially_paid: 0,
      fully_paid: 0,
      finished: 0,
      cancelled: 0,
      draft_paid: 0,
      wait_customer_accept_paid: 0,
      wait_payment_when_receive_paid: 0,
      wait_payment_cod_paid: 0,
      partially_paid_paid: 0,
      fully_paid_paid: 0,
      finished_paid: 0,
      cancelled_paid: 0,
      total_confirmed_paid: 0,
      total_paid: 0,
      draft_count: 0,
      wait_customer_accept_count: 0,
      wait_payment_when_receive_count: 0,
      wait_payment_cod_count: 0,
      partially_paid_count: 0,
      fully_paid_count: 0,
      finished_count: 0,
      cancelled_count: 0,
      confirmed_count: 0,
      count: 0,
    };

    formattedGroup = Object.keys(mappedGrouped).map((group, index) => {
      let formattedOrderGroup = { ...orderGroupTemplate };
      formattedOrderGroup["created_date"] = group;
      Object.values(mappedGrouped)[index].forEach((order) => {
        const orderStatus = formattedStatus(order);
        const currentTotalPaid =
          order.order_payment_list[order.order_payment_list?.length - 1]
            ?.total_paid || 0;
        if (
          !["draft", "wait_customer_accept", "cancelled"].includes(orderStatus)
        ) {
          formattedOrderGroup["total_confirmed_paid"] += currentTotalPaid;
          formattedOrderGroup["confirmed_count"]++;
        }
        formattedOrderGroup["total_paid"] += currentTotalPaid;
        formattedOrderGroup[`${orderStatus}_paid`] += currentTotalPaid;
        formattedOrderGroup[`${orderStatus}_count`]++;
        formattedOrderGroup[orderStatus]++;
        formattedOrderGroup["count"]++;
      });
      return formattedOrderGroup;
    });

    if (dateType === "lastWeek") {
      if (datesInRange) {
        const existingDates = Object.keys(mappedGrouped);
        datesInRange.forEach((date) => {
          if (!existingDates.includes(date)) {
            formattedGroup.push({
              ...orderGroupTemplate,
              created_date: date,
            });
          }
        });
      }
      sortedGroup = formattedGroup.sort((a, b) => {
        const aa = a.created_date.split("/").reverse().join();
        const bb = b.created_date.split("/").reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
      setBottomAxisLabel("วันที่");
    } else if (dateType === "currentYear") {
      const existingMonths = Object.keys(mappedGrouped);
      months_th.forEach((month) => {
        if (!existingMonths.includes(month)) {
          formattedGroup.push({
            ...orderGroupTemplate,
            created_date: month,
          });
        }
      });
      sortedGroup = formattedGroup.sort((a, b) => {
        const aMonth = months_th.indexOf(a.created_date);
        const bMonth = months_th.indexOf(b.created_date);
        if (aMonth < bMonth) {
          return -1;
        }
        if (aMonth > bMonth) {
          return 1;
        }
        return 0;
      });
      setBottomAxisLabel("เดือน");
    } else if (dateType === "allYears") {
      sortedGroup = formattedGroup.sort((a, b) => {
        const aYear = a.created_date;
        const bYear = b.created_date;
        if (aYear < bYear) {
          return -1;
        }
        if (aYear > bYear) {
          return 1;
        }
        return 0;
      });
      setBottomAxisLabel("ปี");
    }
    setFormattedSalesOrders(sortedGroup);
    // }
  }, [allSalesOrders]);

  const getFilteredOrder = useCallback(() => {
    let dateType = getValues("dateType");
    let startDate = getValues("date");
    let endDate = getValues("dateTo");

    dispatch(
      getAllSalesOrders(
        {
          startRow: 0,
          endRow: Math.pow(10, 9),
          filterModel: {
            created_date: {
              filter: startDate ? startDate.toISOString() : undefined,
              filterTo: endDate ? endDate.toISOString() : undefined,
              filterType: "date",
              type: "inRange",
            },
          },
        },
        undefined,
        enqueueSnackbar
      )
    );

    if (dateType === "lastWeek") {
      const rangeOfDate = getDatesInRange(startDate, endDate);
      setDatesInRange(rangeOfDate);
    }
  }, [dispatch, enqueueSnackbar, getValues]);

  return (
    <>
      {salesPermission === "NONE" && <Navigate to="/no-permission" />}{" "}
      {isLoading.allSalesOrders && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        Dashboard
      </Typography>
      <CustomizedBox margin="1rem 0 0 0">
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <DashboardDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
                updateDashboard={getFilteredOrder}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFilteredOrder}
                disabled={isLoading.allSalesOrders}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.allSalesOrderPayments}
                fullWidth
              />
            </Grid> */}
          </Grid>
        </Box>
      </CustomizedBox>
      {!isLoading.allSalesOrders && (
        <>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <CustomizedTotalBox
                title={"ยอดขายรวม (ยืนยันแล้ว)"}
                total={formatNumber(totalPaidConfirmed) || 0}
                minWidth={250}
                maxWidth={900}
                unit={"บาท"}
              />
            </Grid>
          </Grid>
          <SOPaidBarChart
            data={formattedSalesOrders}
            bottomAxisLabel={bottomAxisLabel}
            maxTotalPaid={maxTotalPaid}
          />
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <CustomizedTotalBox
                title={"จำนวนออเดอร์ (ยืนยันแล้ว)"}
                total={`${totalConfirmedDocumentsCount}` || 0}
                minWidth={250}
                maxWidth={900}
                unit={"ออเดอร์"}
              />
            </Grid>
          </Grid>
          <SOCountBarChart
            data={formattedSalesOrders}
            bottomAxisLabel={bottomAxisLabel}
          />
        </>
      )}
    </>
  );
};

export default Dashboard;
