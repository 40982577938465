import { Fragment, useCallback, useState, useEffect, forwardRef } from "react";
import { Grid, Typography, Box, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PublicIcon from "@mui/icons-material/Public";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { Controller, useFieldArray } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContact } from "../../../hooks/use-contact";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controlled/ControlledTextField";
import ControlledSelect from "../../Controlled/ControlledSelect";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckboxes";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import CustomizedButton from "../../Custom/CustomizedButton";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import ControlledSSCreatable from "../../Controlled/ControlledSSCreatable";
import ControlledPhoneTextField from "../../Controlled/ControlledPhoneTextField";
import CustomizedChips from "../../Custom/CustomizedChips";
import CustomizedTab from "../../Custom/CustomizedTab";
import AddressTab from "./AddressTab/AddressTab";
import NewContactTab from "./NewContactTab/NewContactTab";
import SpecificTab from "./SpecificTab/SpecificTab";
import GlobalService from "../../../services/Global";

const defaultContactChannelValues = {
  contact_channel_type: "เบอร์โทรศัพท์",
  contact_channel_name: "",
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const ContactFormGeneral = forwardRef(
  (
    {
      id,
      control,
      errors,
      setValue,
      getValues,
      isContactPage,
      viewOnly,
      isEditPage,
      watch,
      contactTags,
      contactTypeError,
      isTest,
      addressList,
    },
    ref
  ) => {
    const {
      state: { contactSnapshot, isInit },
    } = useContact();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const [identityNoError, setIdentityNoError] = useState(false);
    const [contactChannelErrors, setContactChannelErrors] = useState([]);
    const tab = searchParams.get(isContactPage ? "tab" : "contactTab");
    const { t } = useTranslation();

    const {
      fields: contactFields,
      append: appendContact,
      remove: removeContact,
    } = useFieldArray({
      control,
      name: "contact_channel_list",
    });

    const {
      fields: groupFields,
      append: appendGroup,
      remove: removeGroup,
    } = useFieldArray({
      control,
      name: "tag_list",
    });

    const watchContactType = watch("contact_type");
    const contactTypeIsSelected = Boolean(watch("contact_type"));
    const watchIsVendor = watch("is_vendor");
    const watchIsCustomer = watch("is_customer");

    const watchContactFieldArray = watch("contact_channel_list");
    const controlledContactFields = contactFields.map((field, index) => {
      return { ...field, ...watchContactFieldArray[index] };
    });

    useEffect(() => {
      if (isInit) {
        if (watchContactType === "บุคคลธรรมดา") {
          if (contactSnapshot?.title) {
            setValue("title", contactSnapshot?.title);
          } else {
            setValue("title", "คุณ");
          }
        } else {
          setValue("title", "");
        }
        setValue("last_name", "");
      }
    }, [watchContactType, setValue, isInit, contactSnapshot]);

    useEffect(() => {
      if (isInit && contactSnapshot) {
        if (contactSnapshot.contact_type === "บุคคลธรรมดา") {
          setValue("title", contactSnapshot?.title);
          setValue("last_name", contactSnapshot?.last_name);
        }
      }
    }, [isInit, setValue, contactSnapshot]);

    const generateDocumentId = useCallback(async () => {
      if (viewOnly) {
        return;
      }
      try {
        if (watchIsCustomer) {
          const customer_id = await GlobalService.getUniqueId("contact");
          setValue("unique_id", `${customer_id}${isTest ? "_TEST" : ""}`);
        } else if (watchIsVendor) {
          const vendor_id = await GlobalService.getUniqueId("vendor");
          setValue("unique_id", `${vendor_id}${isTest ? "_TEST" : ""}`);
        }
      } catch (err) {
        console.log("Could not generate contact ID");
      }
    }, [setValue, watchIsCustomer, viewOnly, watchIsVendor]);

    useEffect(() => {
      if (pathname === "/contact/add" || (!isEditPage && !id)) {
        generateDocumentId();
      }
    }, [pathname, generateDocumentId, watchIsCustomer, watchIsVendor]);

    useEffect(() => {
      if (!viewOnly && isInit) {
        if ((watchIsVendor && watchIsCustomer) || watchIsVendor) {
          setValue(
            "business_group_field",
            contactSnapshot?.business_group_field ?? "B2B"
          );
        } else if (watchIsCustomer) {
          setValue(
            "business_group_field",
            contactSnapshot?.business_group_field ?? "B2C"
          );
        }
      }
    }, [isInit, contactSnapshot, viewOnly, watchIsVendor, watchIsCustomer]);

    const businessTypes = [
      {
        id: 1,
        label: t("contact.info.coperate_type.index"),
        value: "นิติบุคคล",
      },
      {
        id: 2,
        label: t("contact.info.natural_person_type.index"),
        value: "บุคคลธรรมดา",
      },
    ];

    const prefixes = [
      {
        id: 1,
        label: t("contact.contact_person.prefix.sir"),
        value: t("contact.contact_person.prefix.sir"),
      },
      {
        id: 2,
        label: t("contact.contact_person.prefix.mr"),
        value: t("contact.contact_person.prefix.mr"),
      },
      {
        id: 3,
        label: t("contact.contact_person.prefix.mrs"),
        value: t("contact.contact_person.prefix.mrs"),
      },
      {
        id: 4,
        label: t("contact.contact_person.prefix.ms"),
        value: t("contact.contact_person.prefix.ms"),
      },

      {
        id: 5,
        label: t("contact.contact_person.prefix.none"),
        value: t("contact.contact_person.prefix.none"),
      },
    ];

    const updateAddressPhoneNo = (value) => {
      const addressList = getValues("address_list");
      for (let i = 0; i < addressList.length; i++) {
        if (i === 0) {
          continue;
        }
        setValue(`address_list.${i}.phone`, value);
      }
    };

    const updateAddressName = (type, value) => {
      const addressList = getValues("address_list");
      const contactType = watchContactType;
      for (let i = 0; i < addressList.length; i++) {
        if (i === 0) {
          continue;
        }
        if (type === "name") {
          const name =
            contactType === "บุคคลธรรมดา"
              ? `${value} ${getValues("last_name")}`
              : value;
          setValue(`address_list.${i}.name`, name);
        } else {
          const name =
            contactType === "บุคคลธรรมดา"
              ? `${getValues("name")} ${value}`
              : value;
          setValue(`address_list.${i}.name`, name);
        }
      }
    };

    // const sources = [
    //   { id: 1, label: "Sales", value: "sales" },
    //   {
    //     id: 2,
    //     label: "Facebook",
    //     value: "facebook",
    //   },
    //   {
    //     id: 3,
    //     label: "Instagram",
    //     value: "instagram",
    //   },
    //   {
    //     id: 4,
    //     label: "Website",
    //     value: "website",
    //   },
    //   {
    //     id: 5,
    //     label: "Email",
    //     value: "email",
    //   },
    //   {
    //     id: 6,
    //     label: "Line@",
    //     value: "line@",
    //   },
    //   {
    //     id: 7,
    //     label: "โทรศัพท์ call in",
    //     value: "โทรศัพท์ call in",
    //   },
    //   {
    //     id: 8,
    //     label: "Marketplace",
    //     value: "marketplace",
    //   },
    //   {
    //     id: 9,
    //     label: "อื่นๆ",
    //     value: "อื่นๆ",
    //   },
    // ];

    const contactChannels = ["เบอร์โทรศัพท์"];

    const checkBoxField = [
      {
        name: "is_customer",
        label: t("contact.contact_type.customer"),
        xs: 12,
        testId: "is-customer-checkbox",
      },
      {
        name: "is_vendor",
        label: t("contact.contact_type.vendor"),
        xs: 12,
        testId: "is-vendor-checkbox",
      },
    ];

    const radioLists = [
      { value: true, label: "ใช้งาน" },
      { value: false, label: "หยุดใช้งาน" },
    ];

    const tabs = [
      {
        label: t("contact.tab.address"),
        path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=address`,
      },
      {
        label: t("contact.tab.contact_person"),
        path: `${pathname}?${
          isContactPage ? "tab" : "contactTab"
        }=contact_person`,
      },
      {
        label: t("contact.tab.specific_info"),
        path: `${pathname}?${
          isContactPage ? "tab" : "contactTab"
        }=specific_info`,
      },
    ];

    const appendTag = (value) => {
      const tagList = getValues("tag_list");
      if (!tagList.includes(value)) {
        appendGroup(value);
      }
    };

    const removeTag = (value) => {
      const tagList = getValues("tag_list");
      const tagIndex = tagList.indexOf(value);
      if (tagIndex !== -1) {
        removeGroup(tagIndex);
      }
    };

    const renderCheckBox = (name, label, testId) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CustomizedCheckboxes
            label={label}
            {...field}
            isDisabled={viewOnly}
            error={contactTypeError}
            testId={testId}
          />
        )}
      />
    );

    const renderJuristicPersonType = () => {
      return (
        <>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              name="identity_no"
              label={t("contact.info.taxpayer_id")}
              control={control}
              error={errors.identity_no || identityNoError}
              helperText={
                identityNoError
                  ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                  : errors.identity_no?.message
              }
              onChange={(e, field) => {
                if (e.target.value.length > 13) {
                  setIdentityNoError(true);
                  return getValues("identity_no");
                }
                setIdentityNoError(false);
                return field.onChange(e.target.value);
              }}
              disabled={viewOnly || !contactTypeIsSelected}
              testId="identity-no-text-field"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              name="name"
              label={t("contact.info.brand_name")}
              control={control}
              error={errors.name}
              onChange={(e, field) => {
                // updateAddressName("name", e.target.value);
                return field.onChange(e.target.value);
              }}
              disabled={viewOnly || !contactTypeIsSelected}
              required
              testId="contact-name-text-field"
            />
          </Grid>
        </>
      );
    };

    const renderContactChannelAction = (type, info) => {
      if (!info) {
        return null;
      }
      let icon;
      let action;
      switch (type) {
        case "เบอร์โทรศัพท์":
        case "แฟกซ์":
        case "เบอร์บ้าน":
        case "เบอร์มือถือ":
          icon = <PhoneIcon />;
          action = () => {
            window.open(`tel:${info}`, "_self");
          };
          break;
        case "Email":
        case "email":
        case "E-mail":
        case "E-Mail":
          icon = <EmailIcon />;
          action = () => {
            window.open("mailto:" + info, "_self");
          };
          break;
        case "Line":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://line.me/R/ti/p/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        case "Facebook":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://facebook.com/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        case "Website":
          icon = <PublicIcon />;
          action = () => {
            window.open(`https://${info}`, "_blank", "noopener,noreferrer");
          };
          break;
        case "Instagram":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://instagram.com/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        default:
          icon = null;
          action = () => {};
          break;
      }
      if (!icon) {
        return null;
      }
      return (
        <IconButton
          onClick={action}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {icon}
        </IconButton>
      );
    };

    const renderNormalPersonType = () => {
      return (
        <>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledTextField
                  name="identity_no"
                  label={t("contact.info.taxpayer_id")}
                  control={control}
                  error={errors.identity_no || identityNoError}
                  helperText={
                    identityNoError
                      ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                      : errors.identity_no?.message
                  }
                  onChange={(e, field) => {
                    if (e.target.value.length > 13) {
                      setIdentityNoError(true);
                      return getValues("identity_no");
                    }
                    setIdentityNoError(false);
                    return field.onChange(e.target.value);
                  }}
                  disabled={viewOnly || !contactTypeIsSelected}
                  testId="identity-no-text-field"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledSelect
              name="title"
              label={t("contact.contact_person.prefix.index")}
              control={control}
              error={errors.title}
              options={prefixes}
              disabled={viewOnly || !contactTypeIsSelected}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              name="name"
              label={t("contact.contact_person.name.first_name")}
              control={control}
              error={errors.name}
              onChange={(e, field) => {
                // updateAddressName("name", e.target.value);
                return field.onChange(e.target.value);
              }}
              disabled={viewOnly || !contactTypeIsSelected}
              required
              testId="contact-name-text-field"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              name="last_name"
              label={t("contact.contact_person.name.last_name")}
              control={control}
              error={errors.last_name}
              onChange={(e, field) => {
                // updateAddressName("last_name", e.target.value);
                return field.onChange(e.target.value);
              }}
              disabled={viewOnly || !contactTypeIsSelected}
              testId="contact-last-name-text-field"
            />
          </Grid>
        </>
      );
    };

    const renderContactChannels = () => {
      return controlledContactFields.map((item, index) => {
        return (
          <Grid item xs={12} key={item.id}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                order={{ xs: 2, md: 1 }}
              >
                {/* <Controller
                  control={control}
                  error={errors}
                  name={`contact_channel_list.${index}.contact_channel_type`}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={t("contact.contact_channel.type")}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }
                        return option === value;
                      }}
                      renderValue={(value) => {
                        return value;
                      }}
                      onChange={(e, value) => field.onChange(value)}
                      options={contactChannels}
                      disabled={viewOnly || index === 0}
                    />
                  )}
                /> */}
                <ControlledSSCreatable
                  control={control}
                  fieldToSet="contact_channel_type"
                  name={`contact_channel_list.${index}.contact_channel_type`}
                  documentType="contact"
                  errors={
                    errors.contact_channel_list?.[index].contact_channel_type
                  }
                  title={t("contact.contact_channel.type")}
                  defaultOptions={contactChannels}
                  viewOnly={viewOnly || index === 0}
                  setValue={setValue}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                order={{ xs: 2, md: 3 }}
              >
                {item.contact_channel_type === "เบอร์โทรศัพท์" && (
                  <ControlledPhoneTextField
                    name={`contact_channel_list.${index}.contact_channel_name`}
                    label={t("contact.contact_channel.info")}
                    control={control}
                    helperText={
                      contactChannelErrors[index]?.message || undefined
                    }
                    error={
                      errors.contact_channel_list?.[index]?.contact_channel_name
                    }
                    disabled={viewOnly}
                  />
                )}
                {item.contact_channel_type !== "เบอร์โทรศัพท์" && (
                  <ControlledTextField
                    name={`contact_channel_list.${index}.contact_channel_name`}
                    label={t("contact.contact_channel.info")}
                    control={control}
                    error={
                      errors.contact_channel_list?.[index]
                        ?.contact_channel_name ||
                      contactChannelErrors[index]?.type === "Email"
                    }
                    helperText={
                      contactChannelErrors[index]?.message || undefined
                    }
                    onBlur={(e) => {
                      const channelType = getValues(
                        `contact_channel_list.${index}.contact_channel_type`
                      );
                      if (channelType === "เบอร์โทรศัพท์") {
                        const phoneNo = e.target.value.replace(/[^0-9]/g, "");
                        setValue(
                          `contact_channel_list.${index}.contact_channel_name`,
                          phoneNo
                        );
                        // if (index === 0) {
                        //   updateAddressPhoneNo(phoneNo);
                        // }
                      }
                      if (
                        ["Email", "email", "E-mail", "E-Mail"].includes(
                          channelType
                        )
                      ) {
                        if (
                          e.target.value === "" ||
                          validateEmail(e.target.value)
                        ) {
                          setContactChannelErrors((prevErrors) => {
                            const newErrors = [...prevErrors];
                            newErrors[index] = {};
                            return newErrors;
                          });
                        } else {
                          setContactChannelErrors((prevErrors) => {
                            const newErrors = [...prevErrors];
                            newErrors[index] = {
                              type: "Email",
                              message: "กรุณากรอกอีเมลให้ถูกต้อง",
                            };
                            return newErrors;
                          });
                        }
                      }
                    }}
                    disabled={viewOnly}
                  />
                )}
              </Grid>
              {!viewOnly && index !== 0 && (
                <Grid item xs={1} order={{ xs: 1, md: 4 }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      removeContact(index);
                      setContactChannelErrors((prevErrors) => [
                        ...prevErrors.slice(0, index),
                        ...prevErrors.slice(index + 1),
                      ]);
                    }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )}
              {viewOnly && (
                <Grid item xs={1} order={{ xs: 1, md: 4 }}>
                  {renderContactChannelAction(
                    item.contact_channel_type,
                    item.contact_channel_name
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      });
    };

    const renderGroupChips = () => {
      return groupFields.map((item, index) => (
        <Controller
          key={item.id}
          name={`tag_list.${index}`}
          control={control}
          render={({ field }) => (
            <Box sx={{ display: "inline-block", mr: 1 }}>
              <CustomizedChips
                onDelete={!viewOnly ? () => removeGroup(index) : null}
                {...field}
              />
            </Box>
          )}
        />
      ));
    };

    const renderTab = (tab) => {
      switch (tab) {
        case "address":
        case null:
          return (
            <AddressTab
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              viewOnly={viewOnly}
              addressList={addressList}
            />
          );
        case "contact_person":
          return (
            <NewContactTab
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              viewOnly={viewOnly}
            />
          );
        case "specific_info":
          return (
            <SpecificTab
              control={control}
              setValue={setValue}
              errors={errors}
              viewOnly={viewOnly}
            />
          );
        default:
      }
    };

    const currentTab = tab
      ? pathname + `?${isContactPage ? "tab" : "contactTab"}=${tab}`
      : pathname + `?${isContactPage ? "tab" : "contactTab"}=address`;

    return (
      <>
        <CustomizedBox
          boxShadow={!isContactPage ? "none" : undefined}
          ref={ref}
        >
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
                  ประเภทผู้ติดต่อ
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ ml: 1 }}>
                      {checkBoxField.map((field) => (
                        <Fragment key={field.label}>
                          {renderCheckBox(
                            field.name,
                            field.label,
                            field.testId
                          )}
                        </Fragment>
                      ))}
                    </Box>
                    {contactTypeError && (
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          sx={{ color: "error.main" }}
                          variant="caption"
                        >
                          กรุณาเลือกประเภทผู้ติดต่อ
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 1 }} spacing={2}>
                  <Grid
                    item
                    xs={isEditPage ? 12 : 10}
                    sm={isEditPage ? 12 : 10}
                    md={6}
                    lg={6}
                    xl={6}
                  >
                    <ControlledTextField
                      name="unique_id"
                      label={t("contact.document_id")}
                      control={control}
                      error={errors.unique_id}
                      disabled={viewOnly || isEditPage}
                      required
                      testId="contact-id-text-field"
                    />
                  </Grid>
                  {!isEditPage && (
                    <Grid item xs={2}>
                      <Box sx={{ ml: -1 }}>
                        <CustomizedTooltip title="เรียกเลขที่ผู้ติดต่อใหม่">
                          <IconButton
                            onClick={generateDocumentId}
                            sx={{
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <RestartAltOutlinedIcon />
                          </IconButton>
                        </CustomizedTooltip>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}></Grid>
            </Grid>

            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              ข้อมูลกิจการ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledSelect
                  name="contact_type"
                  label={t("contact.info.business_type")}
                  control={control}
                  error={errors.contact_type}
                  options={businessTypes}
                  required
                  disabled={viewOnly}
                  testId="contact-type-select"
                />
              </Grid>
              {watchContactType === "บุคคลธรรมดา"
                ? renderNormalPersonType()
                : renderJuristicPersonType()}
            </Grid>

            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("contact.contact_channel.index")}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomizedButton
                  title={"เพิ่มช่องทางติดต่อ"}
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    appendContact({ ...defaultContactChannelValues });
                    setContactChannelErrors((prevErrors) => [
                      ...prevErrors,
                      {},
                    ]);
                  }}
                  disabled={viewOnly}
                />
              </Grid>
              {renderContactChannels()}
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("contact.contact_status")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12}>
                      <CustomizedRadioGroup
                        radioList={radioLists}
                        row
                        {...field}
                        disabled={viewOnly}
                      />
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextField
                  name="inactive_remark"
                  label={t("contact.contact_status_remark")}
                  control={control}
                  error={errors.inactive_remark}
                  disabled={viewOnly}
                />
              </Grid>
            </Grid>

            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              Tag
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledSSCreatable
                  control={control}
                  name="tag_contact"
                  documentType="contact"
                  defaultOptions={[]}
                  setValue={setValue}
                  viewOnly={viewOnly}
                  actionAfterAdd={appendTag}
                  actionAfterSelect={appendTag}
                  actionAfterDelete={removeTag}
                  isTagCreatable
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                sx={{ alignSelf: "center" }}
              >
                {renderGroupChips()}
              </Grid>
            </Grid>
          </Grid>
        </CustomizedBox>
        {!isContactPage && <Divider />}
        <CustomizedBox boxShadow={!isContactPage ? "none" : undefined}>
          <Box sx={{ width: "100%", mt: -4 }}>
            <CustomizedTab
              tabs={tabs}
              currentTab={currentTab}
              scrollable
              divider
            />
          </Box>
          <Grid container>
            <Grid item xs={12}>
              {renderTab(tab)}
            </Grid>
          </Grid>
        </CustomizedBox>
        {!isContactPage && <Divider />}
        <CustomizedBox boxShadow={!isContactPage ? "none" : undefined}>
          <Typography fontWeight="bold" marginBottom={1} marginLeft={1}>
            หมายเหตุ
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="contact_remark"
                multiline
                rows={4}
                control={control}
                error={errors.contact_remark}
                disabled={viewOnly}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      </>
    );
  }
);

export default ContactFormGeneral;
