import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./manufacture-order-initial";

const manufactureOrderSlice = createSlice({
  name: "manufactureOrder",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllManufactureOrders(state, action) {
      state.allManufactureOrders = action.payload;
      state.isLoading.allManufactureOrders = false;
    },
    loadedAllManufactureOrdersExport(state, action) {
      state.allManufactureOrdersExport = action.payload;
      state.isLoading.allManufactureOrdersExport = false;
    },
    loadedManufactureOrder(state, action) {
      state.manufactureOrder = action.payload;
      state.isLoading.manufactureOrder = false;
    },
    loadedManufactureItemSettings(state, action) {
      state.manufactureItemSettings = action.payload;
      state.isLoading.manufactureItemSettings = false;
    },
    resetAllManufactureOrders(state) {
      state.allManufactureOrders = initialState.allManufactureOrders;
      state.error = initialState.error;
    },
  },
});

export const manufactureOrderActions = manufactureOrderSlice.actions;

export default manufactureOrderSlice.reducer;
