import { combineReducers } from "@reduxjs/toolkit";
import activityLogsReducer from "../features/ActivityLogs/activityLogs-slice";
import salesOrderReducer from "../features/Sales/SalesOrder/sales-order-slice";
import accountReducer from "../features/User/Account/account-slice";
import rbacReducer from "../features/User/Rbac/rbac-slice";
import inventoryReducer from "../features/Inventory/inventory-slice";
import manufactureOrderReducer from "../features/Manufacture/ManufactureOrder/manufacture-order-slice";

export const rootReducer = combineReducers({
  account: accountReducer,
  rbac: rbacReducer,
  inventory: inventoryReducer,
  activityLogs: activityLogsReducer,
  salesOrder: salesOrderReducer,
  manufactureOrder: manufactureOrderReducer,
});
