import { Box, Divider, Grid, Typography } from "@mui/material";
import { forwardRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useWatch } from "react-hook-form";
import SummaryForm from "../../../components/Form/ManufactureOrder/SummaryForm";
import MOItemTable from "../../../components/Table/MOItemTable";
import { useAuth } from "../../../hooks/use-auth";
import { formatDate } from "../../../utils/date-converter";

const ManufactureOrderPdf = forwardRef(
  ({ control, errors, getValues, setValue }, ref) => {
    const { t } = useTranslation();
    const { permissions } = useAuth();
    const manufacturePermission = permissions.manufacture;

    const watchCustomer = useWatch({ control, name: "customer" });
    const watchAddress = useWatch({ control, name: "delivery_address" });

    const renderAddressString = (values) => {
      const orderedValues = {
        address_number: values?.address_number,
        building: values?.building,
        sub_district: values?.sub_district,
        district: values?.district,
        province: values?.province,
        postal_code: values?.postal_code,
        zone: values?.zone,
        country: values?.country,
      };
      let result = "";
      if (values) {
        const keys = Object.keys(orderedValues);
        keys.forEach((key) => {
          if (values[key]?.length > 0) {
            if (key === "address_type" || key === "name" || key === "phone") {
              return;
            } else {
              result = result + values[key] + ", ";
            }
          }
        });
      }

      if (result?.trim().length === 0) {
        return "-";
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
    };

    const address = renderAddressString(
      watchCustomer?.address_list?.find(
        (address) => address.address_type === watchAddress
      )
    );

    const leftHeader = [
      t("sales.order.brand_name"),
      t("document_id"),
      t("reference_document_id"),
      t("manufacture.order.manufacturer_unique_id") +
        " - " +
        t("manufacture.order.manufacturer_name"),
      t("manufacture.order.phone"),
      t("manufacture.order.created_date"),
      t("manufacture.order.production_date"),
      t("manufacture.order.due_date"),
    ];

    const created_date = getValues("created_date");
    const production_date = getValues("production_date");
    const receive_date = getValues("receive_date");

    const leftInfo = [
      getValues("brand_name") || "-",
      getValues("unique_id") || "-",
      getValues("external_ref_document_id") || "-",
      getValues("vendor_unique_id") +
        " - " +
        `${getValues("vendor.title") ?? ""}` +
        getValues("vendor_name"),
      getValues("vendor_phone") || "-",
      created_date ? formatDate(created_date) : "-",
      production_date ? formatDate(production_date) : "-",
      receive_date ? formatDate(receive_date) : "-",
    ];

    const rightHeader = [
      t("manufacture.order.customer_unique_id") +
        " - " +
        t("manufacture.order.customer_name"),
      t("manufacture.order.phone"),
      t("manufacture.order.payment_detail"),
      t("manufacture.order.remaining_amount"),
      t("manufacture.order.delivery_detail"),
      t("manufacture.order.delivery_channel"),
      t("sales.order.delivery_address"),
    ];
    const rightInfo = [
      getValues("customer_unique_id") +
        " - " +
        `${getValues("customer.title") ?? ""}` +
        getValues("customer_name"),
      getValues("customer_phone_main") || "-",
      "",
      getValues("balance_cod") || "-",
      "",
      getValues("delivery_channel") || "-",
      address,
    ];

    return (
      <>
        {manufacturePermission === "NONE" && <Navigate to="/no-permission" />}
        <Box sx={{ display: "none" }}>
          <Box
            ref={ref}
            sx={{
              width: "297mm",
              border: "1px solid #eee",
              p: 4,
              overflowY: "auto",
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
                  {t("manufacture.order.index")} / Manufacturing Order
                </Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Divider sx={{ mb: 2 }} />
                    <Box sx={{ mx: 2 }}>
                      {leftHeader.map((name, index) => (
                        <Box sx={{ display: "flex" }} key={index}>
                          <Typography
                            sx={{
                              fontSize: 12,
                              letterSpacing: "0.15px",
                              fontWeight: 600,
                              color: "#333333",
                              flex: 1,
                            }}
                          >
                            {name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 12,
                              letterSpacing: "0.15px",
                              flex: 1,
                              width: "100%",
                              wordBreak: "break-word",
                            }}
                          >
                            {leftInfo[index]}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Divider sx={{ mt: 2 }} />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Box
                      sx={{ backgroundColor: "#F4F6FF", p: 2, borderRadius: 2 }}
                    >
                      {rightHeader.map((name, index) => {
                        if (index === 1 || index === 3) {
                          return (
                            <Fragment key={index}>
                              <Box sx={{ display: "flex" }}>
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    letterSpacing: "0.15px",
                                    fontWeight: 600,
                                    color: "#333333",
                                    flex: 1,
                                  }}
                                >
                                  {name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    letterSpacing: "0.15px",
                                    flex: 1,
                                    width: "100%",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {rightInfo[index]}
                                </Typography>
                              </Box>
                              <Divider sx={{ my: 1, bgcolor: "#32418F" }} />
                            </Fragment>
                          );
                        } else {
                          return (
                            <Box sx={{ display: "flex" }} key={index}>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  letterSpacing: "0.15px",
                                  fontWeight: 600,
                                  color: "#333333",
                                  flex: 1,
                                }}
                              >
                                {name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  letterSpacing: "0.15px",
                                  flex: 1,
                                  width: "100%",
                                  wordBreak: "break-word",
                                }}
                              >
                                {rightInfo[index]}
                              </Typography>
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 12,
                  letterSpacing: "0.15px",
                  color: "#333333",
                }}
              >
                รายการสินค้า
              </Typography>
              <MOItemTable
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={true}
                isReport
              />
              <Box sx={{ my: 2, display: "block", breakInside: "avoid" }}>
                <SummaryForm control={control} errors={errors} isReport />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
);

export default ManufactureOrderPdf;
